import { TMapData } from "./maptypes"

export type TLoadingCell = {
    id: string,
    cell_x: number,
    cell_y: number,
    needed: boolean,
    state: 'unloaded' | 'loading' | 'loaded',
    data: TMapData | null
}

export class MapLoader {
    started: boolean = false
    requests: number = 0
    latest: string = ""
    districts: { [id:string]: TLoadingCell } = {}
    loaders_running: number = 0;
    on_update?: (cell: TLoadingCell | null)=>void

    constructor() {

    }

    start(on_update: (cell: TLoadingCell | null)=>void) {
        console.log("Start map loader")
        this.started = true;
        this.on_update = on_update;
        this._init();
    }

    stop() {
        this.on_update = undefined;
        this.started = false;
        console.log("Stop map loader")
    }

    request_district(cell_x: number, cell_y: number) {
        const id = `${cell_x}-${cell_y}`;
        let district = this.districts[id];
        if(!district) {
            district = {
                id,cell_x,cell_y,
                needed: false,
                state: 'unloaded',
                data: null
            }
            this.districts[id] = district;
        }
        district.needed = true;
        this._loader();
        return district;
    }

    request_window(minx: number, miny: number, maxx: number, maxy: number) {
        const cell_minx = Math.floor(minx/300+2147483647);
        const cell_miny = Math.floor(miny/300+2147483647);
        const cell_maxx = Math.ceil(maxx/300+2147483647);
        const cell_maxy = Math.ceil(maxy/300+2147483647);
        
        for(let x = cell_minx; x <= cell_maxx; x++) {
            for(let y = cell_miny; y <= cell_maxy; y++) {
                this.request_district(x,y)
            }
        }

    }

    _calcPostCode(district: TLoadingCell) {
        let x = district.cell_x-2147483647;
        let y = district.cell_y-2147483647;
        const xprefix = x < 0 ? 'W' : 'E'
        const yprefix = y < 0 ? 'S' : 'N'
        x = Math.abs(x)
        y = Math.abs(y)
        return `${yprefix}${y}-${xprefix}${x}`
    }

    async _init() {
        try {
            const latest_rsp = await fetch(`https://devblobs.shapevrcloud.com/infiniverse/public/latest.txt`, { method: "GET" })
            if(latest_rsp.status != 200) {
                throw new Error("Failed to fetch latest")
            }
            this.latest = await latest_rsp.text();             
            for(let i = 0; i < 10; i++) {
                this._loader()
            }
        } catch(err) {
            console.log(err)
        }        
    }

    async _loader() {
        if(this.loaders_running > 10) {
            return;
        }
        if(this.latest === "") {
            return;
        }
        this.loaders_running++;
        try {
            while(this.started)
            {
                const wanted = Object.values(this.districts).filter(x => x.state === 'unloaded' && x.needed)
                if(wanted.length === 0) {
                    break
                }
                await this._load_district(wanted[0])
            }
        } catch(err) {
            console.log(err)
        }
        this.loaders_running--
    }

    async _load_district(district: TLoadingCell) {
        try {
            district.state = 'loading'
            const req = `https://devblobs.shapevrcloud.com/infiniverse/public/${this.latest}/${this._calcPostCode(district)}.json`
            const json_rsp = await fetch(req, { method: "GET" })
            if(json_rsp.status != 200) {
                throw new Error(`Failed to fetch district ${req} ${district.cell_x}, ${district.cell_y}: ${json_rsp.status}`)
            }
            district.data = await json_rsp.json();
            district.state = 'loaded'
            console.log(`Loaded ${this._calcPostCode(district)}`)
            if(this.on_update) {
                this.on_update(district)
            }
        } catch(err) {
            console.log(err)
        }
    }

}