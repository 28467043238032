import React from 'react';
import { Button, Card, Col, Container, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

import {BiWorld} from 'react-icons/bi'
import { getIconSrc } from '../../utils/entitycache';

type DomainIconProps = {
    domainuri?: string
    showName?: boolean;
    name?: string;
    onClick?: ()=>void;
} & WithMultiverseApiProps;

type DomainIconState = {
    domain?: MultiverseDomain;
    name: string;
    iconLoaded: boolean;
};
 
class _DomainIcon extends React.Component<DomainIconProps, DomainIconState> {
    constructor(props: DomainIconProps) {
        super(props);
        this.state = {
            name: props.name || "",
            iconLoaded: false
        }
    }

    componentDidMount = async () => {
        const { name, domainuri, domain, multiverse } = this.props;

        let domainEntity: MultiverseDomain | undefined;
        if(!domainuri) {
            domainEntity = domain;
        } else {
            domainEntity = await multiverse.get<MultiverseDomain>(`/v2/domains/${domainuri}`)
        }

        this.setState({
            domain: domainEntity,
            name: name || domainEntity?.name || ""
        })
    }

    domainIconImage = () => {
        const { domain, iconLoaded } = this.state;
        if(!domain) {
            return undefined;
        } else {
            return <Image className="icon-image" src={getIconSrc(domain)} roundedCircle style={{position:'absolute',left:0}} onLoad={this.onIconLoaded} hidden={!iconLoaded}></Image>
        }

    }

    onIconLoaded = () => {
        this.setState({
            iconLoaded: true
        })
    }

    domainIcon = () => {
        const domainIcon = this.domainIconImage();
        return <div style={{display:'inline-block'}}>
            <div className="icon-image-container"><BiWorld className="icon-image p-0"/></div>
            {domainIcon}
        </div>
    }


    render() {
        const { domain, name } = this.state;

        return (
        <>
        <Button className="profile-icon" onClick={this.props.onClick}>
        { this.domainIcon() }
        { this.props.showName && <div className="profile-icon-username">{name}</div> }
        </Button>
        </>)
    }
}
export const DomainIcon = withMultiverseApi(_DomainIcon);

export default DomainIcon;
