import React, { CSSProperties, FunctionComponent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import { createButton, FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { AiOutlineMail } from 'react-icons/ai';

type LoginButtonProps = {
    connection?: 'google' | 'facebook' | 'email',
    small?: boolean;
};

const CONNECTION_MAP: Record<string, string> = {
    google: 'google-oauth2',
    facebook: 'facebook',
};

const DEFAULT_BUTTON_STYLE: CSSProperties = {
    width: "200px",
    height: '45px',
    display: "inline"
}

const SMALL_BUTTON_STYLE: CSSProperties = {
    width: "45px",
    height: '45px',
    display: "inline"
}


/** My Facebook login button. */
const EmailLoginButton = createButton({
    text: "Login with Facebook",
    icon: AiOutlineMail,
    style: { background: "rgb(113 185 109)" },
    activeStyle: { background: "rgb(79 129 76)" }
});

const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
    const { loginWithRedirect } = useAuth0();
    const { connection, small } = props;

    const curr_path = document.location.pathname;
    const root_redirect = `${document.location.origin}/profile`;
    console.log({
        curr_path, root_redirect
    })

    const onClick = (): Promise<void> => loginWithRedirect({ 
        connection: connection && CONNECTION_MAP[connection],
        redirectUri: curr_path.length <= 1 ? root_redirect : undefined
    });

    const style = small ? SMALL_BUTTON_STYLE : DEFAULT_BUTTON_STYLE;

    if (connection === 'google') {
        return <GoogleLoginButton onClick={onClick} style={style}>{small ? " ": "Login" }</GoogleLoginButton>;
    }
    else if (connection === 'facebook') {
        return <FacebookLoginButton onClick={onClick} style={style}>{small ? " ": "Login" }</FacebookLoginButton>;
    } else if (connection === 'email') {
        return <EmailLoginButton onClick={onClick} style={style} >{small ? " ": "Login / Create" }</EmailLoginButton>;
    } else {
        return <Button onClick={onClick} variant='link' style={{color:"white"}} className="login-text">Log In</Button>
    }
};

export default LoginButton;
