import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DialogBox from '../../components/dialogBox';
import ValidatedField from '../../components/validatedField';

export function SelectVideoLinkDialog(props: {show: boolean, onComplete: (val?: string)=>void}) {
    const [text, setText] = useState<string>("")
    const [valid, setValid] = useState<boolean>(false)

    const onChange = (newText: string,newValid: boolean) => {
        setText(newText)
        setValid(newValid)
    }

     return (
     <DialogBox 
        title="Select Video Link" 
        show={props.show} 
        onCancel={() => props.onComplete()} 
        onConfirm={() => props.onComplete(text)} 
        closeButton
        button0Text="Ok"
        cancelButtonText="Cancel"
        disableConfirm={!valid}
        >
            Enter an m3u8 video URL, eg https://mystreamsite.com/myvideostream.m3u8
            <ValidatedField regex={/https\:\/\/.*\.m3u8.*/} value={text} onChange={onChange}/>

    </DialogBox>)
}

