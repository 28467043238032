import React, { FunctionComponent } from 'react';
import { Button, FormControl, FormControlProps, Modal } from 'react-bootstrap';
import { Subtract } from 'utility-types';

type DialogBoxProps = {
    title: string;
    button0Text?: string;
    button1Text?: string;
    cancelButtonText?: string;
    show?: boolean;
    type?: 'normal' | 'danger'
    disableConfirm?: boolean;
    disableConfirm0?: boolean;
    disableConfirm1?: boolean;
    closeButton?: boolean;
    onConfirm?: (button?: 0 | 1) => void;
    onCancel?: () => void;
    className?: string;
};

type DialogBoxState = {
};

const NORMAL_HEADER_STYLE = {

}
const DANGER_HEADER_STYLE = {
    backgroundColor: "indianred"
}
const HEADER_STYLES = {
    'normal': NORMAL_HEADER_STYLE,
    'danger': DANGER_HEADER_STYLE
}

//simple dialog box
class DialogBox extends React.Component<DialogBoxProps, DialogBoxState> {
    constructor(props: DialogBoxProps) {
        super(props);
        this.state = {};
    }

    private _handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) {
            onCancel();
        }
    }

    private _handleConfirm = (button: 0 | 1) => {
        const { onConfirm } = this.props;
        if (onConfirm) {
            onConfirm(button);
        }
    }

    render() {
        const { show, title, children, type, button0Text, button1Text, cancelButtonText, disableConfirm, disableConfirm0, disableConfirm1, closeButton, className } = this.props;

        let use_type = type || 'normal';

        return (
            <Modal dialogClassName={className} show={show} onHide={this._handleCancel}>
                <Modal.Header style={HEADER_STYLES[use_type]} closeButton={closeButton}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                { (button0Text || button1Text || cancelButtonText) && <Modal.Footer>
                    {button0Text &&
                        <Button variant="primary" disabled={disableConfirm || disableConfirm0} onClick={() => this._handleConfirm(0)}>
                            {button0Text}
                        </Button>}
                    {button1Text &&
                        <Button variant="primary" disabled={disableConfirm || disableConfirm1} onClick={() => this._handleConfirm(1)}>
                            {button1Text}
                        </Button>}
                    {cancelButtonText &&
                        <Button variant="secondary" onClick={this._handleCancel}>
                            {cancelButtonText}
                        </Button>}
                </Modal.Footer>}
            </Modal>
        )
    }

}

export default DialogBox;
