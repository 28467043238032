import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import api from '../../utils/api';
import { TCustomStyleInfo, TSpec, TVariableValue } from '../../utils/types';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-css'
import 'prismjs/themes/prism.css'
import "./restapi.css"


type TStylesRowProps = {
    stylesUri: string,
    release: string
}

export function SpecRow(props: TStylesRowProps) {
    const { release, stylesUri } = props;
    const [specData, setSpecData] = useState<TSpec | null>(null)
 
    const loadData = async(uri: string) => {
        return await api.tryGet<TSpec>(`${uri}/spec?release=${release}`)
    }

    useEffect(() => {
        if(stylesUri.length > 0) {
            loadData(stylesUri)
            .then(x => setSpecData(x))
            .catch(err => console.log(err))
        }
    }, [stylesUri, release])

    return (
    <Row>
        <Col>
            <pre>{JSON.stringify(specData,null,4)}</pre>
        </Col>
    </Row>)    
}

export function VariablesRow(props: TStylesRowProps) {
    const { release, stylesUri } = props;
    const [variablesData, setVariablesData] = useState<TVariableValue[] | null>(null)

    const loadData = async(uri: string) => {
        return await api.tryGet<TVariableValue[]>(`${uri}/variables?release=${release}`)
    }

    useEffect(() => {
        if(stylesUri.length > 0) {
            loadData(stylesUri)
            .then(x => setVariablesData(x))
            .catch(err => console.log(err))
        }
    }, [stylesUri, release])

    const renderVariable = (x: TVariableValue) => (
        <PageTable.Row key={x.id}>
            <PageTableCell>{x.id}</PageTableCell>
            <PageTableCell>{x.value}</PageTableCell>
        </PageTable.Row>
    )

    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Style Variables
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        {(variablesData && variablesData.length > 0) ? (
                        <PageTable>
                            <tbody>
                                {variablesData.map(x => renderVariable(x))}
                            </tbody>
                        </PageTable>) : <div>No Style Variables Found</div>}
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}

export function CustomStyleRow(props: TStylesRowProps) {
    const { release, stylesUri } = props;
    const [customStyleData, setCustomStyleData] = useState<TCustomStyleInfo | null>(null)
    const [currentSource, setCurrentSource] = useState<string>("")
    const [showCompiled, setShowCompiled] = useState<boolean>(false)

    const loadData = async(uri: string) => {
        return await api.tryGet<TCustomStyleInfo>(`${uri}/customstyle?release=${release}`)
    }

    const reload = async() => {
        if(stylesUri.length > 0) {
            try {
                const style_data = await loadData(stylesUri)
                setCustomStyleData(style_data); 
                setCurrentSource(style_data?.source || "")    
            } catch(err) {
                console.log(err)
            }
        }        
    }

    const save = async() => {
        try {
            const style_data = await api.post<TCustomStyleInfo>(`${stylesUri}/customstyle`, {
                release,
                source: currentSource
            })
            setCustomStyleData(style_data); 
            setCurrentSource(style_data?.source || "")    
        } catch(err) {
            console.log(err)
        }
    }        

    useEffect(() => {
        if(stylesUri.length > 0) {
            reload()
        }
    }, [stylesUri, release])

    return (
        <Row className="restapi-custom-styles">
            <Col >
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Custom Style CSS
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" > 
                            <Container fluid>
                                <Row>
                                    <Col>
                                        <Button variant="outline-primary" disabled={currentSource === customStyleData?.source} onClick={() => save()}>Save</Button>
                                        <Button variant="outline-primary" onClick={() => setShowCompiled(!showCompiled)}>{showCompiled ? "Show Editor" : "Show Built"}</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="restapi-code-editor">
                                        {!showCompiled && <Editor
                                            value={currentSource}
                                            onValueChange={(val)=>setCurrentSource(val)}
                                            highlight={code => highlight(code, languages.css, "css")}
                                            padding={10}
                                            tabSize={4}
                                            style={{
                                                fontFamily: "'Courier New', Courier, monospace",
                                                border: "solid",
                                                borderRadius: 3,
                                                borderWidth: 1,
                                                borderColor: "gray"
                                            }}
                                        />}
                                        {showCompiled && <pre>
                                            {JSON.stringify(customStyleData?.style, null, 4)}
                                        </pre>}
                                    </Col>
                                </Row>
                            </Container>  
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>)    
}

export function StylesRows(props: TStylesRowProps) {
    return (
        <>
            {/*<SpecRow {...props}/>*/}
            <VariablesRow {...props}/>
            <CustomStyleRow {...props}/>
        </>)    
}
