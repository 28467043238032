import React from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, FormControl, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import IsDeveloper from '../../components/isDeveloper';
import IsNotDeveloper from '../../components/isNotDeveloper';
import PageTable from '../../components/pageTable';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { getDomainPlanInfo, MultiverseDomain, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import * as entitycache from '../../utils/entitycache';
import "./domainspage.css"

export interface IDomainMembershipResult {
    domain: string;
    permissions: string[];
    name: string;
    uri: string;
    owner: string;
    plan: string;
    activated: boolean;
    trialend?: Date;
    iconblob?: string;
}

type Filter = "none" | "owner" | "manager" | "manageronly" | "memberonly";

type DomainsTableProps ={
    filter?: Filter
    group?: boolean
} & WithMultiverseApiProps & RouteComponentProps;

type DomainsTableState = {
    isLoading: boolean;
    hasError: boolean;
    domains: IDomainMembershipResult[];
    showDeleteDialog: boolean;
    domainToDelete?: IDomainMembershipResult;
    deleteDomainValidationText: string;
};
 
const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

class _DomainsTable extends React.Component<DomainsTableProps, DomainsTableState> {
    constructor(props: DomainsTableProps) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            domains: [],
            showDeleteDialog: false,
            deleteDomainValidationText: ""
        };
    }

    async componentDidMount(): Promise<void> {
        const { multiverse: { user } } = this.props;
        await this.refreshList();
    }

    filterDomainList = (domains: IDomainMembershipResult[], filter?: Filter): IDomainMembershipResult[] => {
        const { multiverse: { user } } = this.props;
        if(filter === "owner") {
            return domains.filter(x => x.owner === user!.id)
        } else if(filter=="manager") {
            return domains.filter(x => this.canManage(x))
        } else if(filter=="manageronly") {
            return domains.filter(x => x.owner !== user!.id && this.canManage(x))
        } else if(filter=="memberonly") {
            return domains.filter(x => !this.canManage(x))
        } else {
            return domains;
        }
    }

    refreshList = async () => {
        const { multiverse: { get, user } } = this.props;

        try {
            //switch to loading
            this.setState({ isLoading: true });

            //get and store domains list
            let domains = this.filterDomainList(await get<IDomainMembershipResult[]>('/v2/domains'), this.props.filter);
            domains.sort((a,b) => a.name.localeCompare(b.name));
            this.setState({ domains });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    onDeleteDomainClick = (domain: IDomainMembershipResult) => {
        this.setState({
            showDeleteDialog: true,
            domainToDelete: domain,
            deleteDomainValidationText: ""
        })
    }

    onManageDomainClick = async (domain: IDomainMembershipResult) => {
        const { multiverse: { openDomainPath } } = this.props;
        await openDomainPath(domain.uri, "/manage");
    }

    onUpgradeDomainClick = async (domain: IDomainMembershipResult) => {
        const { multiverse: { openDomainPath } } = this.props;
        await openDomainPath(domain.uri, "/upgrade");
    }

    onDeleteDomainValidationTextChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({
            deleteDomainValidationText: e.target.value,
        });
    };

    renderrows_nodomains() {
        return (
            <>
                {this.props.filter !== "memberonly" && <>
                    <Row className="pt-2">
                        <Col className="text-center pb-2">
                            You've got no metaverses yet! To have your very own personal metaverse and view it in VR you'll need to link this account to your Oculus account. For now though, feel free to create and set up some new ones!<br></br>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="text-center">
                            <Link to="/createdomain">
                                <Button variant="outline-primary">Create New Metaverse</Button>
                            </Link>
                        </Col>
                    </Row>
                </>}     
                { this.props.filter === "memberonly" && <>
                    <Row className="pt-2">
                        <Col className="text-center pb-2">
                            You aren't a member of any Metaverses yet!<br></br>
                        </Col>
                    </Row>                
                </>}
            </>
        );
    }

    getTypeName = (x: IDomainMembershipResult): string => {
        if (x.plan) {
            return x.plan.replace(/\b(\w)/, (x) => x.toUpperCase());
        } else {
            return "Free";
        }
    }

    getMembershipDesc = (x: IDomainMembershipResult): string => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        if (x.owner === user.id) {
            return "Owner";
        }
        if (!x.permissions) {
            return "Unknown";
        }
        if (x.permissions.includes("manage_domain")) {
            return "Manager";
        }
        return "Member";
    }

    canDelete = (x: IDomainMembershipResult): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return x.owner == user.id;
    }

    canUpgrade = (x: IDomainMembershipResult): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return x.owner == user.id;
    }

    canManage = (x: IDomainMembershipResult): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return x.permissions ? x.permissions.includes("manage_domain") : false;
    }

    canDoAnything = (x: IDomainMembershipResult): boolean => {
        return this.canDelete(x) || this.canUpgrade(x) || this.canManage(x);
    }

    getIconSrc = (x: IDomainMembershipResult) => {
        return entitycache.getIconSrc({
            id: x.uri,
            type: 'domain',            
            ...x
        })
    }

    onRowClick = (x: IDomainMembershipResult) => {
        const { history } = this.props;
        history.push(`/domains/${x.uri}`)
    }

    renderTableContainerRow = (domains: IDomainMembershipResult[]) => {        
        return (
            domains.map((x) => {
                const plan_info = getDomainPlanInfo(x);
                return (
                    <PageTable.Row key={x.uri}>
                        <PageTable.IconCell className="dp-icon-cell" src={this.getIconSrc(x)} roundedCircle/> 
                        <PageTable.InfoCell className="dp-name-cell" title={x.name} subtitle={<Link to={`/domains/${x.uri}`}>{x.uri}</Link>}/>
                        <PageTable.InfoCell className="dp-type-cell d-none d-sm-table-cell" title={plan_info.nameText} subtitle={<span style={{ color: plan_info.expiresColor }}>{plan_info.expiresText}</span>}/>
                        <PageTable.InfoCell className="dp-membership-cell d-none d-md-table-cell" title={this.getMembershipDesc(x)}/>
                    </PageTable.Row>
                )
            })
        )
    }

    concatTableContainerRow =  (tables: JSX.Element[], domains: IDomainMembershipResult[]) => {
        if(domains.length > 0) {
            if(tables.length > 0) {
                tables.push(<PageTable.Row style={{height: 15}}/>)
            }
            this.renderTableContainerRow(domains).forEach(x => tables.push(x));
        }
    }

    renderrows_domains() {

        let tables: JSX.Element[] = [];
        if(this.props.group) {
            this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "owner"))            
            this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "manageronly"))
            this.concatTableContainerRow(tables, this.filterDomainList(this.state.domains, "memberonly"))
        } else {
            tables = tables.concat(this.renderTableContainerRow(this.state.domains));
        }

        return (
            <>
            <Row className="p-0">
            <Col>
                <PageTable className="m-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th className="d-none d-sm-table-cell">Type</th>
                            <th className="d-none d-md-table-cell">Membership</th>
                        </tr>
                    </thead>
                    <tbody>                    
                {tables}
                </tbody>
                </PageTable>
            </Col>
        </Row>                {this.props.filter !== "memberonly" && <Row className="pt-2 pb-2">
                    <Col className="text-center">
                        <Link to="/createdomain">
                            <Button variant="outline-primary">Create New Metaverse</Button>
                        </Link>
                    </Col>
                </Row>}           
            </>
        )
    }

    render_rows() {
        if (!this.state.domains)
            return (<pre>error</pre>)
        else if (this.state.domains.length > 0)
            return this.renderrows_domains();
        else
            return this.renderrows_nodomains();
    }

    handleDeleteDialogClose = () => {
        this.setState({
            showDeleteDialog: false
        })
    }

    handleDeleteDialogConfirm = async () => {
        this.setState({
            showDeleteDialog: false,
            isLoading: true
        })
        try {
            await this.props.multiverse.del(`/v2/domains/${this.state.domainToDelete?.uri}`);
        } catch (err) {

        } finally {
            //leave this for the list refresh
            /*this.setState({
                isLoading: false
            })*/
        }
        await this.refreshList();
    }

    render_delete_dialog() {
        return (
            <Modal show={this.state.showDeleteDialog} onHide={this.handleDeleteDialogClose}>
                <Modal.Header style={{ backgroundColor: "indianred" }} closeButton>
                    <Modal.Title>Delete Domain</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Warning! Deleting a domain is a permanent action. Once done, you won't be able to get it back.</p><p>If you're sure, type <span className="font-weight-bold">{this.state.domainToDelete?.uri}</span> here:</p>
                    <FormControl
                        placeholder=""
                        value={this.state.deleteDomainValidationText}
                        onChange={this.onDeleteDomainValidationTextChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleDeleteDialogClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={this.state.deleteDomainValidationText !== this.state.domainToDelete?.uri} onClick={this.handleDeleteDialogConfirm}>
                        Delete Permanently
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderLoading() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
            {this.render_delete_dialog()}
                <Container fluid className="p-0">
                    {!isLoading && this.render_rows()}
                    {isLoading && this.renderLoading()}
                </Container>
            </>);
    }
}

export const DomainsTable = withRouter(withMultiverseApi(_DomainsTable));
export default DomainsTable;
