import React from 'react';
import BasicPage from '../../components/basicPage';
import { useCurrentDomainUri } from '../../utils/entitycache';
import { DomainEntityTable } from '../../components/domainEntityTable';
import { Tabs, Tab } from 'react-bootstrap';
import { MultiverseBaseEntity, MultiverseEvent } from '../../hoc/multiverseApiProvider';
import { PageTableCell } from '../../components/pageTable';

export function EventsPage(props: {}) {
    const targeturl = useCurrentDomainUri();

    const COLUMNS = ["Start","End","Published"]

    const getColumnValues = (x: MultiverseBaseEntity) => {
        const event = x as MultiverseEvent;
        return [
            <PageTableCell key="start">{new Date(event.startdate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="end">{new Date(event.enddate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="published">{event.published ? "Yes" : "No"}</PageTableCell>
        ];
    }

    return (
        <BasicPage title="Events">
            <Tabs  defaultActiveKey="upcoming">
                <Tab eventKey="upcoming" title="Upcoming">
                    <DomainEntityTable targeturl={targeturl} type="event" query={{filter: "upcoming"}} emptyMessage="You have no upcoming events" showHeader additionalColumnNames={COLUMNS} additionalColumnValues={getColumnValues} canCopy/>
                </Tab>
                <Tab eventKey="past" title="Past">
                    <DomainEntityTable targeturl={targeturl} type="event" query={{filter: "past"}} emptyMessage="You've had no previous events" showHeader additionalColumnNames={COLUMNS} additionalColumnValues={getColumnValues} canCopy/>
                </Tab>
            </Tabs>
        </BasicPage>
    )    
}
export default EventsPage;