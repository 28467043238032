import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { isError } from '../../utils/error';

type UnlinkButtonState = {
  isLoading: boolean,
  error?: string,
};

type UnlinkButtonProps = {
  connection: 'auth0userid',
} & WithMultiverseApiProps;

class UnlinkButton extends React.Component<UnlinkButtonProps, UnlinkButtonState> {
  constructor(props:UnlinkButtonProps) {
    super(props);
    this.state = { isLoading: false };
  }

  onclick = async ():Promise<void> => {
    const { multiverse: { post, reauthenticate, logout }, connection } = this.props;
    this.setState({ isLoading: true });

    try {
      await post('/entity/unlinkaccount', { connection });
      if (connection === 'auth0userid') {
        await logout();
      } else {
        await reauthenticate();
      }
    } catch (e) {
      if (isError(e)) {
        this.setState({ isLoading: false });
      } else {
        throw e;
      }
    }
  };

  render = ():JSX.Element => {
    const { isLoading } = this.state;
    return isLoading ? (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    ) : <Button onClick={this.onclick} variant="link">(Unlink)</Button>;
  };
}

export default withMultiverseApi(UnlinkButton);
