import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormControlProps, Modal, Spinner } from 'react-bootstrap';
import { Subtract } from 'utility-types';
import { MultiverseLocation, MultiverseBaseEntity } from '../../hoc/multiverseApiProvider';
import api from '../../utils/api';
import { useInterval } from '../../utils/hooks';
import DialogBox from '../dialogBox';
import { LocationUriField, DomainOrLocationUriField, EntityUriField, DomainOrEntityUriField } from '../validatedField';

export function LocationUriDialog(props: { show: boolean, allowDomains?: boolean, allowEvents?: boolean, onComplete: (val?: string, id?: string)=>void }) {

    const [refreshing, setRefreshing] = useState<boolean>(false)
    const [changed, setChanged] = useState<boolean>(false)
    const [location, setLocation] = useState<MultiverseBaseEntity | null>(null);
    const [input,setInput] = useState<{text: string, valid: boolean}>({text: "", valid: false})

    const onChange = (text: string,valid: boolean) => {
        setInput({
            text, valid
        })
        setChanged(true)
    }

    useInterval(() => {
        refresh()
    }, 1000)


    const refresh = async() => {
        if(!refreshing && changed && input.valid && input.text.length > 0) {
            console.log("Refresh: " + input.text)
            setRefreshing(true);
            setChanged(false);
            const loc = await api.tryGet<MultiverseBaseEntity>(`/v2/domains/${input.text}`)
            setLocation(loc);
            setRefreshing(false)
        }
    }

    const selectField = () => {
        if(props.allowDomains) {
            return <DomainOrEntityUriField value={input.text} onChange={onChange}/>
        }
        if(props.allowEvents) {
            return <EntityUriField value={input.text} onChange={onChange}/>
        }
        return <LocationUriField value={input.text} onChange={onChange}/>
    }

     return (
     <DialogBox 
        title="Select Multiverse Location" 
        show={props.show} 
        onCancel={() => props.onComplete()} 
        onConfirm={() => props.onComplete(input.text, location?.id)} 
        closeButton
        button0Text="Ok"
        cancelButtonText="Cancel"
        disableConfirm={!location || refreshing || !input.valid}
        >
            Enter a Multiverse URL (eg ftl.multiverseonline.io/locations/infiniverse)
            {selectField()}
            {refreshing && <span>Loading...</span>}
            {!refreshing && !location && <span>Location not found</span>}
            {!refreshing && location && <span>Location found: {location.nickname}</span>}
    </DialogBox>)
}

