import eventBus from "./eventBus";

export type TBackgroundTaskArgs = {
    onProgress?: (progress: number, status: string) => void;
    onError?: (err: Error) => void;
    onComplete?: () => void;
}
export type TBackgroundTask = {
    name: string;
    task: (args: TBackgroundTaskArgs) => Promise<void>
}

class BackgroundTaskManager
{
    tasks: TBackgroundTask[] = [];
    activeTask: TBackgroundTask | null = null;
    activeProgress: number = 0;
    activeStatus: string = "";

    constructor() {

    }

    addBackgroundTask = (task: TBackgroundTask) => {
        this.tasks.push(task);
        this.poll();
    }

    runActiveTask = async() => {    
        if(this.activeTask) {
            eventBus.dispatch("BackgroundTaskManager.onTaskStarted", this.activeTask);           
            await this.activeTask.task({
                onProgress: (progress: number, status: string) => {
                    this.activeProgress = progress;
                    this.activeStatus = status;
                    this.onUpdate();
                }
            })
            eventBus.dispatch("BackgroundTaskManager.onTaskFinished", this.activeTask);
            this.activeTask = null;
        }
        this.poll();
    }

    poll = () => {
        if(this.activeTask === null && this.tasks.length > 0) {
            this.activeTask = this.tasks.shift()!;
            this.activeProgress = 0;       
            this.activeStatus = "";
            this.runActiveTask();
        }
        this.onUpdate();
    }

    onUpdate = () => {
        eventBus.dispatch("BackgroundTaskManager.onUpdate", {});
    }
}
const BGTaskManager = new BackgroundTaskManager;

//@ts-ignore set global background task manager
window.$backgroundTaskManager = BGTaskManager;

export default BGTaskManager;

