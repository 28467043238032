import React from "react"
import { useRelease } from "./entityHeaderCard";
import { useEntity } from "../../utils/entitycache";
import { MultiverseDomain, MultiverseEvent, MultiverseLocation } from "../../hoc/multiverseApiProvider";
import LaunchCard from "../../components/launchCard";


export function EntityLaunchCard(props: { targeturl: string} ) {
    const slash_idx = props.targeturl.indexOf("/");
    const release = useRelease();
    
    //figure out domain uri + load domain
    let domainuri: string;
    if(slash_idx === -1) {
        domainuri = props.targeturl;
    } else {
        domainuri = props.targeturl.substr(0,props.targeturl.indexOf("/"));
    }
    const domain = useEntity<MultiverseDomain>(domainuri)
    const event = useEntity<MultiverseEvent>(props.targeturl.includes("/events/") ? props.targeturl : null)
    const location = useEntity<MultiverseLocation>(props.targeturl.includes("/locations/") ? props.targeturl : null)
    
    return <LaunchCard
        domainName={domain?.name}
        domainUri={domain?.uri}
        locationName={location?.name}
    />
}