import { getBlobUrl } from '../../config/api';
import './adminMissionConfigsPage.css';
import { IoMdCamera } from 'react-icons/io';
import PageTable from '../../components/pageTable';
import { Image, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { MultiverseLocation, MultiverseMissionConfig, MultiverseMissionConfigUpdateArgs, MultiverseUpdateIconsArgs, WithMultiverseApiProps }
    from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import React from 'react';
import BasicPage from '../../components/basicPage';
import AspectRatioBox from '../../components/aspectRatioBox';

type MissionConfigsPageProps = {
} & WithMultiverseApiProps;

// icons
interface ISaveIconsResult {
}

interface IBuildBannersResult {    
}

type IconsPageState = {
    isSavingIcons: boolean;
    isBuildingBanners: boolean;
    saveIconsResult: ISaveIconsResult | null;
    buildBannersResult: IBuildBannersResult | null;
}

const ICON_TYPES = ".zip"

// end icons

type MissionConfigsPageState = {
    isSavingMissionConfig: boolean;
    saveMissionConfigResult: ISaveMissionConfigResult | null;
    isLoadingCount: number;
    hasError: boolean;
    missionConfigs: MultiverseMissionConfig[];
    locations: MultiverseLocation[];
    editingItem: MultiverseMissionConfig;
    isSavingBanner: boolean;
    isDirty: boolean;
} & IconsPageState;

interface IUploadBannerResult {
    bannerblob: string
}

interface ISaveMissionConfigResult {
    mission_config?: MultiverseMissionConfig,
    msg?: string
}

// probably not the right place for .zip, but needed to stick it in somewhere quickly
const IMAGE_TYPES = ".jpg,.jpeg,.png,.zip"

class _AdminMissionConfigsPage extends React.Component<MissionConfigsPageProps, MissionConfigsPageState> {
    bannerInputRef = React.createRef<HTMLInputElement>();
    missionConfigFocusOnEditRef = React.createRef<HTMLSelectElement>();

    constructor(props: MissionConfigsPageProps) {
        super(props);

        // icons
        const icons_page_state: IconsPageState = {
            saveIconsResult: null,
            buildBannersResult: null,
            isSavingIcons: false,
            isBuildingBanners: false
        }

        this.state = {
            saveMissionConfigResult: null,
            isSavingMissionConfig: false,
            isLoadingCount: 0,
            hasError: false,
            missionConfigs: [],
            locations: [],
            editingItem: _AdminMissionConfigsPage.createEmptyMissionConfig(),
            isSavingBanner: false,
            isDirty: false,
            ...icons_page_state
        };

        // icons
        this.onBuildBannersSubmit = this.onBuildBannersSubmit.bind(this);

        this.onMissionConfigSubmit = this.onMissionConfigSubmit.bind(this);
        this.onMissionConfigChange = this.onMissionConfigChange.bind(this);
    }

    // icons 
    // just fudging this in here temporarily.  Should be in a separate component

    iconsInputRef = React.createRef<HTMLInputElement>();

    onChangeIconsButtonClicked = () => {
        this.iconsInputRef.current?.click()
    }

    onIconsFileInputChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.iconsInputRef.current && this.iconsInputRef.current.files && this.iconsInputRef.current.files.length > 0) {
            this.setState({
                isSavingIcons: true
            })
            try {
                await this.onSetIcons(this.iconsInputRef.current.files[0])
            } catch(err) {
                console.log(err);
            }
            this.setState({
                isSavingIcons: false
            })            
        }        
    }      

    onBuildBannersSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        this.setState({
            isSavingIcons: true,
            buildBannersResult: null
        })

        try {
            const { multiverse: { put, post, getCurrentChildProductId } } = this.props;

            const { child_product, timestamp, ...item } = this.state.editingItem           
            const updateItem: MultiverseUpdateIconsArgs = {
            }

            let result: IBuildBannersResult;
            result = await put<IBuildBannersResult>({
                url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/buildbanners`,
                body: updateItem
            })    

            // assume success
            this.setState((prevState) => ({
                isDirty: false
            }))

            this.setState({
                buildBannersResult: result
            });

        } catch (e) {
            console.log(e);
            this.setState({
                buildBannersResult: { msg: "error" }
            })
        }

        this.setState({
            isBuildingBanners: false
        })
    }

    onSetIcons = async(icons: File) => {
        const { multiverse: { post, getCurrentChildProductId } } = this.props;
        const result = await post<IUploadBannerResult>({
            url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/icons`,
            body: icons,
            headers: {
                'Content-Type': icons.type,
                'x-shapevr-name': icons.name,
            }
        })
        
        this.setState((prev) => {
            const item = { ...prev.editingItem }
            return {
                editingItem: item,
                saveIconsResult: null
            }
        })
    }    

    // end icons

    // mission configs

    static createEmptyMissionConfig(): MultiverseMissionConfig {
        return {
            description: "",
            location: "",
            metadata: "{}",
            selection_weight: 0.5,
            banner_blob: "",
            feature: "",
            importance: 0,
            environment: "",
            mode: ""
        }
    }

    async componentDidMount(): Promise<void> {
        const { multiverse: { refreshCurrentChildProduct } } = this.props;
        await refreshCurrentChildProduct();
        await this.refreshLocations();
        await this.refreshMissionConfigs();
    }

    refreshLocations = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load locations
            const { multiverse: { getLocationsForChildProduct } } = this.props;
            const locations = await getLocationsForChildProduct();

            this.setState({ locations: locations });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }  

    refreshMissionConfigs = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load mission configs
            const { multiverse: { getMissionConfigsForChildProduct } } = this.props;
            let result = await getMissionConfigsForChildProduct();

            this.setState({ missionConfigs: result.mission_configs.map(c => { return {
                ...c, 
                feature: c.feature ?? "",
                importance: c.importance ?? 0,
                environment: c.environment ?? "",
                mode: c.mode ?? ""
            } } ) });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }

    onMissionConfigChange = (event: any) => {
        const { name, value } = event.target;
        this.setState((prev) => {
            return {
                editingItem: {
                    ...prev.editingItem,
                    [name]: value
                },
                isDirty: true,
                saveMissionConfigResult: null
            }
        });    
    }

    onMissionConfigSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        this.setState({
            isSavingMissionConfig: true,
            saveMissionConfigResult: null
        })

        try {
            const { multiverse: { put, post, getCurrentChildProductId } } = this.props;

            const { child_product, timestamp, ...item } = this.state.editingItem           
            const updateItem: MultiverseMissionConfigUpdateArgs = {
                banner_blob: item.banner_blob,
                description: item.description,
                location: item.location,
                metadata: item.metadata,
                selection_weight: item.selection_weight,
                id: item.id,
                feature: item.feature,
                importance: item.importance,
                environment: item.environment,
                mode: item.mode
            }

            let result: ISaveMissionConfigResult;
            if(this.state.editingItem.id) {
                result = await put<ISaveMissionConfigResult>({
                    url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/config`,
                    body: updateItem
                })    
            }
            else {
                result = await post<ISaveMissionConfigResult>({
                    url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/config`,
                    body: updateItem
                })    
            }

            if(!result.msg) {   // success
                this.setState((prevState) => ({
                    editingItem: {
                        ...prevState.editingItem,
                        id: result?.mission_config?.id ? result?.mission_config?.id : prevState.editingItem.id
                    },
                    isDirty: false
                }), () => this.refreshMissionConfigs())
            }

            this.setState({
                saveMissionConfigResult: result
            });

        } catch (e) {
            console.log(e);
            this.setState({
                saveMissionConfigResult: { msg: "error" }
            })
        }

        this.setState({
            isSavingMissionConfig: false
        })

        await this.refreshMissionConfigs();
    }

    onNewItemClick = (e: React.SyntheticEvent) => {
        this.setState({
            editingItem: _AdminMissionConfigsPage.createEmptyMissionConfig(),
            isDirty: false,
            saveMissionConfigResult: null
        });        
    }

    onCancelItemClick = (e: React.SyntheticEvent) => {
        const item = this.getCurrentMissionConfigFromList();
        if(item) {
            this.selectItem(item);
            return;
        }

        this.setState({
            editingItem: _AdminMissionConfigsPage.createEmptyMissionConfig(),
            isDirty: false,
            saveMissionConfigResult: null
        });

    }

    getCurrentMissionConfigFromList() {
        if(this.state.editingItem.id) {
            const matching = this.state.missionConfigs.filter(m => m.id === this.state.editingItem.id);
            if(matching.length === 1) {
                return matching[0];
            }
        }

        return null;
    }

    renderLoadingSpinner() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    renderNoMissionConfigs() {
        return (
            <>
                <Row>
                    <Col className="text-center pb-3">
                        This product currently contains no mission configs.
                    </Col>
                </Row>
            </>
        );
    }

    canDelete = (x: MultiverseMissionConfig): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return true;
    }

    canManage = (x: MultiverseMissionConfig): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return true;
    }

    canDoAnything = (x: MultiverseMissionConfig): boolean => {
        return this.canDelete(x) || this.canManage(x);
    }

    selectItem = (missionConfig: MultiverseMissionConfig) => {
        this.setState({
            editingItem: { ...missionConfig },
            isDirty: false,
            saveMissionConfigResult: null            
        })

        this.missionConfigFocusOnEditRef.current?.focus();
    }

    cameraButton = (click: ()=>void, loading: boolean) => {
        return (
            <Button disabled={loading} className="hc-circlebutton hc-camerabutton" variant='secondary' onClick={click}>
                {!loading && <IoMdCamera/>}
                {loading && <Spinner animation='border'/>}
            </Button>
        )
    }

    getBlobUrlFromHash(blobHash: string | undefined) {
        return blobHash ? `${getBlobUrl()}/${blobHash}` : undefined
    }

    onChangeBannerButtonClicked = () => {
        this.bannerInputRef.current?.click()
    }

    onSetBanner = async(banner: File) => {
        const { multiverse: { post, getCurrentChildProductId } } = this.props;
        const result = await post<IUploadBannerResult>({
            url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/config/${this.state.editingItem.id}/banner`,
            body: banner,
            headers: {
                'Content-Type': banner.type,
                'x-shapevr-name': banner.name,
            }
        })
        
        await this.onPropertyUpdatedOnServer((item: MultiverseMissionConfig) => {
            if(result.bannerblob) {
                item.banner_blob = result.bannerblob
            }
        })
    }

    onPropertyUpdatedOnServer = async (setProperty: (item: MultiverseMissionConfig) => void) => {
        this.setState((prev) => {
            const item = { ...prev.editingItem }
            setProperty(item);
            return {
                editingItem: item,
                saveMissionConfigResult: null
            }
        })

        await this.refreshMissionConfigs();
    }

    onBannerFileInputChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.bannerInputRef.current && this.bannerInputRef.current.files && this.bannerInputRef.current.files.length > 0) {
            this.setState({
                isSavingBanner: true
            })
            try {
                await this.onSetBanner(this.bannerInputRef.current.files[0])
            } catch(err) {
                console.log(err);
            }
            this.setState({
                isSavingBanner: false
            })            
        }        
    }    

    getLocation = (id: string) => {
        const matches = this.state.locations.filter(location => location.id === id);
        if(matches.length === 0) return null;
        return matches[0];
    }

    renderMissionConfigs = () => {
        const { } = this.props;

        return (
            <>
                <div className="table-responsive">
                    <PageTable>
                        <tbody>
                            <PageTable.Row key="header">
                                <th>Location</th>
                                <th>Description</th>
                                <th>Selection Weight</th>
                                <th>Feature</th>
                                <th>Importance</th>
                                <th>Environment</th>
                                <th>Mode</th>
                            </PageTable.Row>
                            {this.state.missionConfigs.map((x) => {
                                const location = this.getLocation(x.location);
                                return (
                                    <PageTable.Row className="clickable" key={x.id} onClick={() => this.selectItem(x)}>
                                        <PageTable.InfoCell title={location?.nickname}/>
                                        <PageTable.InfoCell title={x.description}/>
                                        <PageTable.InfoCell title={x.selection_weight.toFixed(2)}/>
                                        <PageTable.InfoCell title={x.feature}/>
                                        <PageTable.InfoCell title={x.importance?.toString()}/>
                                        <PageTable.InfoCell title={x.environment}/>
                                        <PageTable.InfoCell title={x.mode}/>
                                    </PageTable.Row>
                                )
                            })}
                        </tbody>
                    </PageTable>
                </div>
            </>
        )
    }

    renderUpdateIcons = () => {
        const { isSavingIcons } = this.state;

        return (
            <>
                <Form className="edit-item" onSubmit={this.onBuildBannersSubmit}>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Icons
                        </Col>
                        <Col className="col-md-3">
                            <AspectRatioBox className="image" aspect={4}>
                                {this.cameraButton(this.onChangeIconsButtonClicked, isSavingIcons)}
                            </AspectRatioBox>                            
                            <input
                                className="hc-fileinput"
                                onChange={this.onIconsFileInputChange}
                                ref={this.iconsInputRef}
                                type="file"
                                accept={ICON_TYPES}
                            />
                        </Col>
                        <Col className="col-md-3">
                            <Button variant='outline-primary' type='submit'>Build Banners</Button>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }            

    renderCreateOrUpdateMissionConfig = () => {
        const { saveMissionConfigResult, editingItem, isSavingBanner, isDirty, locations } = this.state;

        return (
            <>
                <Form className="edit-item" onSubmit={this.onMissionConfigSubmit}>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Location
                        </Col>
                        <Col className="col-md-9">
                            <select name="location" ref={this.missionConfigFocusOnEditRef} className="responsive-select" value={editingItem.location} onChange={this.onMissionConfigChange}>
                                <option value="">--Select a location--</option>
                                {locations.map((location) => (
                                    <option key={location.id} value={location.id}>
                                        {location.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Description
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="description" value={editingItem.description} onChange={this.onMissionConfigChange} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Metadata
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control as="textarea" rows={5} name="metadata" value={editingItem.metadata} onChange={this.onMissionConfigChange} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Selection Weight
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control className="number" type="text" name="selection_weight" value={editingItem.selection_weight} onChange={this.onMissionConfigChange}/>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Feature
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="feature" value={editingItem.feature} onChange={this.onMissionConfigChange} placeholder="copied to new missions" />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Importance
                        </Col>
                        <Col className="col-md-9">
                            <select name="importance" className="responsive-select" value={editingItem.importance} onChange={this.onMissionConfigChange}>                                
                                <option value="">--Select an importance level--</option>
                                <option key="0" value="0">0</option>
                                <option key="1" value="1">1</option>
                                <option key="2" value="2">2</option>
                                <option key="3" value="3">3</option>
                            </select>                            
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Environment
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="environment" value={editingItem.environment} onChange={this.onMissionConfigChange} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Mode
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="mode" value={editingItem.mode} onChange={this.onMissionConfigChange} />
                        </Col>
                    </Row>                                        
                    {editingItem?.id && (
                        <>
                            <Row className="pt-2">
                                <Col className="col-md-12">
                                    Banner
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-12">
                                    <AspectRatioBox className="image" aspect={4}>
                                        <Image className="full-width" src={this.getBlobUrlFromHash(editingItem?.banner_blob)} />
                                        {this.cameraButton(this.onChangeBannerButtonClicked, isSavingBanner)}
                                    </AspectRatioBox>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className="pt-2">
                        <Col className="col-md-12 evenly-spaced-children">
                            {!isDirty && editingItem?.id && 
                                <Button variant='outline-primary' type='button' onClick={this.onNewItemClick}>New</Button>}
                            {isDirty && (
                                <>
                                    {editingItem?.id
                                        ? <Button variant='outline-primary' type='submit'>Update</Button>
                                        : <Button variant='outline-primary' type='submit'>Create</Button>}
                                    <Button variant='outline-secondary' type='button' onClick={this.onCancelItemClick}>Cancel</Button>
                                </>)}
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-12">
                            {saveMissionConfigResult && (
                                <p className={saveMissionConfigResult.msg ? 'text-danger' : 'text-success'}>
                                    { saveMissionConfigResult.msg ? saveMissionConfigResult.msg : "Saved" }
                                </p>
                            )}
                        </Col>
                    </Row>

                    <input
                        className="hc-fileinput"
                        onChange={this.onBannerFileInputChange}
                        ref={this.bannerInputRef}
                        type="file"
                        accept={IMAGE_TYPES}
                    />
                </Form>
            </>
        )
    }    

    renderPage() {
        if (this.state.isLoadingCount > 0)
            return this.renderLoadingSpinner();
        else if (!this.state.missionConfigs) {
            return (<pre>error</pre>)
        }
        else if (this.state.missionConfigs.length > 0)
            return this.renderMissionConfigs();
        else
            return this.renderNoMissionConfigs();
    }

    render() {
        const { childProduct } = this.props;

        return (
            <>
                <BasicPage title={(childProduct ? `${childProduct.name} ` : "") + "Mission Configs"}>
                    {this.renderUpdateIcons()}
                    {this.renderCreateOrUpdateMissionConfig()}
                    {this.renderPage()}
                </BasicPage>
            </>);
    }
}

export const AdminMissionConfigsPage = withMultiverseApi(_AdminMissionConfigsPage);

export default AdminMissionConfigsPage;
