import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as QueryString from 'querystring'

import './browsedrivepage.css'
import DriveBrowser from '../../components/driveBrowser';

type BrowseDrivePageProps = WithMultiverseApiProps & RouteComponentProps;
type BrowseDrivePageState = {
    path: string;
};
class BrowseDrivePage extends React.Component<BrowseDrivePageProps, BrowseDrivePageState> {
    fileInputRef = React.createRef<HTMLInputElement>();;

    constructor(props: BrowseDrivePageProps) {
        super(props);

        this.state = {
            path: ""
        };
    }

    loadQuery = (search_string: string) => {
        try {
            const query = search_string.substr(1);
            const qs = QueryString.parse(query);
            const loc = qs.location as string;
            return { path: loc };
        } catch (err) {
            return { path: "" };
        }
    }

    componentDidMount = async (): Promise<void> => {
        this.setState({
            ...this.loadQuery(this.props.location.search)
        })

        this.props.history.listen((new_loc) => {
            this.setState({
                ...this.loadQuery(new_loc.search)
            })
        })
    };

    onChangePath = (newpath: string) => {
        if (newpath.length > 0) {
            this.props.history.push(`/browsedrive?location=${encodeURIComponent(newpath)}`);
        } else {
            this.props.history.push("/drives");
        }
    }


    render() {
        const { path } = this.state;
        return (
            <>
                <DriveBrowser
                    path={path}
                    onChangePath={this.onChangePath}/>
            </>
        );
    }
}

export default withRouter(withMultiverseApi(BrowseDrivePage));
