import { MapLoader, TLoadingCell } from "./maploader";
import { TVector2, TPoly, TMapDistrict, TMapBuilding, TMapSubdistrict } from "./maptypes";
import { calc_world2screen, calc_screen2world } from "./maphelpers";
import * as vector from "./vector"

export class MapCanvas {
    map: MapLoader;
    centre: TVector2;
    size: number;
    canvas: HTMLCanvasElement;
    needed: boolean;
    cells: { [id: string]: TLoadingCell };


    constructor(map: MapLoader, centre: TVector2, size: number)
    {
        this.map = map;
        this.centre = centre;
        this.size = size;
        this.canvas = document.createElement("canvas")
        this.canvas.width = 512;
        this.canvas.height = 512;
        this.needed = false;
        this.cells = {};
    }

    destroy() {
        this.canvas.remove();
    }

    world2screen(world_ppos: TVector2) {
        const scale = this.canvas.width / this.size;
        return calc_world2screen(world_ppos,this.canvas.width,this.canvas.height,this.centre[0],this.centre[1],scale)
    }
    screen2world(screen_pos: TVector2) {
        const scale = this.canvas.width / this.size;
        return calc_screen2world(screen_pos,this.canvas.width,this.canvas.height,this.centre[0],this.centre[1],scale)
    }
    moveTo(context: CanvasRenderingContext2D, world_pos: TVector2) {
        const screen_pos = this.world2screen(world_pos)
        context.moveTo(screen_pos[0],screen_pos[1])
    }
    lineTo(context: CanvasRenderingContext2D, world_pos: TVector2) {
        const screen_pos = this.world2screen(world_pos)
        context.lineTo(screen_pos[0],screen_pos[1])
    }
    polyPath(context: CanvasRenderingContext2D, poly: TPoly) {
        if(poly.length >= 1) {
            context.beginPath();
            this.moveTo(context, poly[0])
            for(let i = 1; i < poly.length; i++) {
                this.lineTo(context, poly[i])
            }
            context.closePath()         
        }
    }

    getMin() {
        return vector.subs(this.centre,this.size*0.5)
    }
    getMax() {
        return vector.adds(this.centre,this.size*0.5);
    }

    rebuildAndRedraw()
    {
        const map = this.map;
        const context = this.canvas.getContext('2d');  
        if(context) {
            context.clearRect(0,0,this.canvas.width,this.canvas.height);
            this.cells = {}
            Object.values(map.districts).forEach((cell) => {
                this.addCell(cell)
            })
            this.redraw()
        }
    }

    redraw() {
        const context = this.canvas.getContext('2d');  
        if(context) {
            context.clearRect(0,0,this.canvas.width,this.canvas.height);
            Object.values(this.cells).forEach((cell) => {
                this._drawCellInternal(context,cell);
            })        
        }
    }

    addCell(cell: TLoadingCell) {
        this.cells[cell.id] = cell;
    }

    _drawCellInternal(context: CanvasRenderingContext2D, cell: TLoadingCell) {
        try {
            if(cell.data) {
                context.lineWidth = 1;
                context.strokeStyle = '#000'
                context.fillStyle = '#CCC4';
                this.polyPath(context, cell.data.district.boundingpoly)
                context.stroke()
                context.fill();    
                
                cell.data.subdistricts.forEach(sd => { 
                    if(sd.curbpoly) {
                        this.polyPath(context, sd.curbpoly)
                        context.stroke()
                    } else if(sd.outerpoly) {
                        this.polyPath(context, sd.outerpoly)
                        context.stroke()
                    } else if(sd.innerpoly) {
                        this.polyPath(context, sd.innerpoly)
                        context.stroke()
                    }
                })

                cell.data.buildings.forEach(sd => {
                    if(sd.boundingpoly) {
                        this.polyPath(context, sd.boundingpoly)
                        context.stroke()
                    }
                })
            }
        } catch(err) {

        }         
    }

    getFeaturesAtPoint(world_pos: TVector2) {
        const screen_pos = this.world2screen(world_pos);
        if(screen_pos[0] < 0 || screen_pos[1] < 0 || screen_pos[0] >= this.canvas.width || screen_pos[1] >= this.canvas.width) {
            return null;
        }

        const context = this.canvas.getContext('2d');  
        const results: any[] = []
        if(context) {
            Object.values(this.cells).forEach((cell) => {
                this._pointInCellInternal(context,cell, screen_pos, results);
            })        
        }       
        return results.length > 0 ? results : null;
    }

    _pointInCellInternal(context: CanvasRenderingContext2D, cell: TLoadingCell, screen_pos: TVector2, results: any[]) {

        try {
            if(cell.data) {
                this.polyPath(context, cell.data.district.boundingpoly)
                if(context.isPointInPath(screen_pos[0],screen_pos[1])) {
                    results.push({
                        type: 'district',
                        district: cell.data.district,
                        poly: cell.data.district.boundingpoly,
                        code: cell.data.district.code
                    })
                }
                  
                cell.data.subdistricts.forEach(sd => {
                    if(sd.curbpoly) {
                        this.polyPath(context, sd.curbpoly)
                        if(context.isPointInPath(screen_pos[0],screen_pos[1])) {
                            results.push({
                                type: 'subdistrict',
                                subdistrict: sd,
                                poly: sd.curbpoly,
                                code: sd.code
                            })
                        }
                    } else if(sd.outerpoly) {
                        this.polyPath(context, sd.outerpoly)
                        if(context.isPointInPath(screen_pos[0],screen_pos[1])) {
                            results.push({
                                type: 'subdistrict',
                                subdistrict: sd,
                                poly: sd.outerpoly,
                                code: sd.code
                            })
                        }
                    } else if(sd.innerpoly) {
                        this.polyPath(context, sd.innerpoly)
                        if(context.isPointInPath(screen_pos[0],screen_pos[1])) {
                            results.push({
                                type: 'subdistrict',
                                subdistrict: sd,
                                poly: sd.innerpoly,
                                code: sd.code
                            })
                        }
                    }
                })

                cell.data.buildings.forEach(building => {
                    if(building.boundingpoly) {
                        this.polyPath(context, building.boundingpoly)
                        if(context.isPointInPath(screen_pos[0],screen_pos[1])) {
                            results.push({
                                type: 'building',
                                building: building,
                                poly: building.boundingpoly,
                                code: building.code
                            })
                        }
                    }
                })
            }
        } catch(err) {

        }         
    }

}
