import React, { FunctionComponent } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Button, ButtonProps, Col, Container, Form, FormControl, Modal, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ValidatedField, { DomainNameField } from '../../components/validatedField';
import BasicPage from '../../components/basicPage';
import PurchaseDomain from '../../components/purchaseDomain';

type DomainInfo = {
    id: string;
    name: string;
    uri: string;
}

const VALID_NAME = /^[A-Za-z0-9\s]*$/;
const VALID_URI = /^[a-z][a-z0-9\-]*$/;

//main page
type CreateDomainPageProps = {

} & WithMultiverseApiProps;
type CreateDomainPageState = {
    name: string;
    uri: string;
    showConfirmDialog: boolean;
    uriValidationError: string;
    nameValidationError: string;
    plan: string;
};
class CreateDomainPage extends React.Component<CreateDomainPageProps, CreateDomainPageState> {
    constructor(props: CreateDomainPageProps) {
        super(props);
        this.state = {
            name: "",
            uri: "",
            showConfirmDialog: false,
            uriValidationError: "",
            nameValidationError: "",
            plan: ""
        };
    }


    //when create clicked, a load of validation happens. if any errors are found, an error
    //toast is shown. if all succesful, the create domain dialog is shown
    createClicked = async(plan: string) => {
        const { multiverse: { post, get, showErrorToast } } = this.props;
        const { uriValidationError, nameValidationError, name, uri } = this.state;

        let name_error = false;
        let uri_error = false;
        let name_message = ""
        let uri_message = ""

        //check for zero length name/uri
        if(!name_error) {
            if(name.length < 1) {
                name_message = "Please enter a name for your domain";
                name_error = true;
            }
        }
        if(!uri_error) {
            if(uri.length < 1) {
                uri_message = "Please enter a URL for your domain";
                uri_error = true;
            }
        }

        //check for errors generated in onValidatedChange events coming from the text boxes (verify against regex)
        if(!name_error) {
            if(nameValidationError.length > 0) {
                name_message = nameValidationError;
                name_error = true;
            }
        }
        if(!uri_error) {
            if(uriValidationError.length > 0) {
                uri_message = uriValidationError;
                uri_error = true;
            }
        }  

        //if no uri error, do a request to check the domain uri isn't already taken
        //here a 404 error is what we want, as it indicates domain isn't found
        if(!uri_error) {
            try {
                const existing = await get({
                    url: `/v2/domains/${this.state.uri}.multiverseonline.io`,
                    hideErrors: true
                });
                uri_message = "Domain URL is already in use";
                uri_error = true;
            } catch(err) {
                console.log({...err});
            }
        }

        //if any errors occured, show/store the error and return
        if(name_error || uri_error) {
            let msg = "";
            if(name_error) { msg = name_message; }
            else if(uri_error) { msg = uri_message; }; 
            showErrorToast(msg);
            return;
        }

        this.setState({
            plan: plan,
            showConfirmDialog: true
        })
    }

    //event handlers from the validated fields
    onNameChange = (val: string, isvalid: boolean) => {
        this.setState({
            name: val,
            nameValidationError: isvalid ? "" : "Names must be at least 1 character long, and contain only letters, numbers and spaces"
        });
    };
    onUriChange = (val: string, isvalid: boolean) => {
        this.setState({
            uri: val,
            uriValidationError: isvalid ? "" : "URLs must be at least 1 character long, start with a letter and contain only lower case letters, numbers or hiphens"
        });
    };

    //confirmation dialog
    render_confirm_dialog() {
        return (
        <Modal show={this.state.showConfirmDialog}>
            <Modal.Header>
            <Modal.Title>Create Domain</Modal.Title>
            </Modal.Header>
            <Modal.Body className="  pb-0">
                <p className="text-center">Please confirm your domain details:</p>
                <Table borderless className="low-padding-table"><tbody>
                    <tr><td>Name</td><td>{this.state.name}</td></tr>
                    <tr><td>URL</td><td>{this.state.uri}.multiverseonline.io</td></tr>
                    <tr><td>Plan</td><td>{this.state.plan}</td></tr>
                </tbody></Table>
             </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCreateDialogClose}>
                Cancel
            </Button>
            <Button variant="primary"onClick={this.handleCreateDialogConfirm}>
                Create
            </Button>
            </Modal.Footer>
        </Modal>
        )
    }

    //confirmation cancelled event just hides it
    handleCreateDialogClose = () => {
        this.setState({
            showConfirmDialog: false
        })
    }

    //confirmation confirmed event hides the dialog, creates the domain then
    //goes to the manage page for the new domain
    handleCreateDialogConfirm = async() => {
        const { multiverse: { post, openDomainPath } } = this.props;
        const { name, uri, plan } = this.state;

        this.setState({
            showConfirmDialog: false
        })

        try {
            const domain = await post<DomainInfo>("/v2/domains",{
                name: name,
                uri: uri+".multiverseonline.io",
                plan: plan
            })
            openDomainPath(domain.id, "/manage")
        } catch(err) {
            console.log(err);
        }


    }

    //main render
    render(): JSX.Element {
        const { name, uri} = this.state;

        const main_col_classname = "col-12 col-sm-6 col-lg-3";

		return (
            <>
                { this.render_confirm_dialog()}
                <BasicPage title="Create a Metaverse">
                        <Row className="pb-2">
                            <Col>
                            <h4 style={{textAlign:"center"}}>Create, customise and share your own Metaverse!</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <PurchaseDomain/>
                            </Col>
                        </Row>
                </BasicPage>
            </>);
    }
}

export default withMultiverseApi(CreateDomainPage);
