import React, { CSSProperties, FunctionComponent } from 'react';
import {
    Container, Row, Col, Spinner, Button, Image
} from 'react-bootstrap';
import delay from 'delay';
import rollbar from '../../utils/setUpRollbar';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import ConnectToOculusButton from '../connectToOculusButton';
import { MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import UnlinkButton from './unlinkButton';
import EditableField from '../editableField';
import IsDeveloper from '../isDeveloper';
import DialogBox from '../dialogBox';
import ImageUploader from 'react-images-upload';

type ProfileProps = {
} & WithMultiverseApiProps;

type ProfileState = {
    showUploadDialog: boolean;
    uploading: boolean;
    uploadError?: string;
}

const CODE_STYLE: CSSProperties = {
    background: '#333',
    color: '#EEE',
    width: '100%',
    borderRadius: 4,
    padding: '1em',
};

const TITLE_STYLE: CSSProperties = {
    opacity: '0.5',
    fontWeight: 'bold',
};

function getProviderFromId(auth0userid: string): string {
    const platform = auth0userid.split(/\W/)[0];
    return platform.charAt(0).toUpperCase() + platform.substr(1);
}

const ProfileRow: FunctionComponent = (props) => {
    return (
        <>
            <Row className="pb-2 pb-md-3 pt-md-2">
                 {props.children}
            </Row>
        </>
    );
}
const PropNCol: FunctionComponent = (props) => {
    return (
        <>
            <Col style={TITLE_STYLE}>{props.children}</Col>
        </>
    );
}
const PropVCol: FunctionComponent = (props) => {
    return (
        <>
            <Col className="pl-md-5">{props.children}</Col>
        </>
    );
}

const LOADING_DIV_STYLE: CSSProperties = {
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)",
    textAlign: "center"
}

class _Profile extends React.Component<ProfileProps, ProfileState> {

    constructor(props: ProfileProps) {
        super(props);
        this.state = {
            showUploadDialog: false,
            uploading: false,
            uploadError: undefined
        };
    }


    updateUserField = async (fieldName: string, value: string) => {
        const { multiverse: { post, user, overrideUser } } = this.props;
        if (!user) return;
        const { id } = user;
        const result = await post<MultiverseUser>(`/v2/users/${id}`, { [fieldName]: value });
        overrideUser(result);
    };

    onChangePictureClicked = () => {
        this.setState({
            showUploadDialog: true,
            uploading: false,
            uploadError: undefined
        })
    }

    onUploadPicture = async (files: File[]): Promise<void> => {
        const { multiverse: { post, updateUser, user } } = this.props;
        if (!user) return;
        try {
            this.setState({ uploading: true });
            await post({
                url: '/v2/profilepicture',
                body: files[0],
                hideErrors: true,
                headers: {
                    'Content-Type': files[0].type,
                    'x-shapevr-name': files[0].name,
                }
            });
            await updateUser();
            this.setState({ showUploadDialog: false });
        } catch (err) {
            let msg = err.message;
            if (msg === "Error: Adult Content") {
                msg = "Oops! We've got a lot of younger viewers in Multiverse, and our magic algorithms think that might contain innapropriate content for a profile picture. Please check it for nudity, adult themes or violence.";
            } else {
                msg = "An unknown error occured uploading your picture. Please try again or contact support@ftl.ltd.";
            }
            this.setState({ uploadError: msg });
        }
    };

    onCancelChangePictureClicked = () => {
        this.setState({
            showUploadDialog: false
        })
    }

    render() {
        const { multiverse: { isAuthenticated, user, getUserIconUrl } } = this.props;
        const { showUploadDialog, uploading, uploadError } = this.state;

        if (!isAuthenticated || !user) {
            return (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            );
        }

        const {
            name, auth0userid, oculusaccount, steamaccount, nickname,
        } = user;

        const pictureUrl = getUserIconUrl();
        rollbar.info('Profile Rendered');
        return (
            <>
                <DialogBox title="Change Profile Picture" show={showUploadDialog} closeButton onCancel={this.onCancelChangePictureClicked} >
                    <div style={{ position: 'relative' }}>
                        {
                            !uploadError && <Col className="col-12 p-0" style={{ visibility: !uploading ? "unset" : "hidden" }}>
                                <ImageUploader
                                    buttonStyles={{ bottom: 0 }}
                                    onChange={this.onUploadPicture}
                                    maxFileSize={5242880}
                                    singleImage={true}
                                />
                            </Col>
                        }

                        {!uploadError && uploading &&
                            <Col className="col-12 p-0" style={LOADING_DIV_STYLE}>
                                <Spinner animation="border" role="status"></Spinner>
                            </Col>
                        }

                        {uploadError &&
                            <Col className="col-12 p-0">
                                {uploadError}
                            </Col>
                        }
                    </div>

                </DialogBox>
                <Container className="basic-page pt-2" style={{maxWidth:500}}>
                    <Row className="basic-page-title-row pt-2">
                        <Col className="pb-3 text-center basic-page-title-col">
                            <h2 className="text-center">Profile</h2>
                        </Col>
                    </Row>

                    <ProfileRow>
                        <PropNCol>Nickname</PropNCol>
                        <PropVCol>{nickname}</PropVCol>
                    </ProfileRow>

                    <ProfileRow>
                        <PropNCol>Profile Picture <Button variant="link" onClick={this.onChangePictureClicked}>(change)</Button></PropNCol>
                        <PropVCol>
                            <Image src={pictureUrl} roundedCircle style={{ maxWidth: "100%", maxHeight: 150 }} />
                        </PropVCol>
                    </ProfileRow>

                    <IsDeveloper>
                        {auth0userid && (
                            <ProfileRow>
                                <PropNCol>[DEV] {getProviderFromId(auth0userid)}</PropNCol>
                                <PropVCol>
                                    Connected
                                    <UnlinkButton connection="auth0userid" />
                                </PropVCol>
                            </ProfileRow>
                        )}
                    </IsDeveloper>

                    <ProfileRow>
                        <PropNCol>Oculus Account</PropNCol>
                        <PropVCol>
                            {oculusaccount ? 'Connected' : <ConnectToOculusButton />}
                        </PropVCol>
                    </ProfileRow>

                    <IsDeveloper>
                        <ProfileRow>
                            <PropNCol>[DEV] Steam</PropNCol>
                            <PropVCol>
                                {steamaccount ? 'Connected' : 'Not Connected'}
                            </PropVCol>
                        </ProfileRow>
                    </IsDeveloper>

                </Container>
            </>
        );
    }
}

export { ProfileIcon } from './profileicon'
export { DomainIcon } from './domainicon'
export const Profile = withMultiverseApi(_Profile);
export default Profile;
