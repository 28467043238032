import React from 'react';
import { Card, Row, Col, Container, Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './discoverycontentcard.css'
import IsDeveloper, { IsNotDeveloper } from '../isDeveloper';
import { useEntity } from '../../utils/entitycache';
import { EntityImageUploadBox } from '../entityImageUploadBox';
import { EntityTextAreaPropertyBox } from '../entityTextAreaPropertyBox';
import { isLockedEvent } from '../../hoc/multiverseApiProvider';
import { EntitySelect } from '../entitySelect';

export function DiscoveryContentCard(props: { targeturl: string, requireContent?: boolean }) { 

    const entity = useEntity<any>(props.targeturl)
    const lock_desc = isLockedEvent(entity);

    return ( 
        <Accordion defaultActiveKey="0">
            <Card className="mt-2 discovery-content-card">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div>Discovery</div>
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                    <>
                    <div className="px-3 pt-3 pb-1" style={{fontSize:"smaller"}}>Visit <a href="https://www.multiverseupdates.com/discovery-menu" target="_blank">our updates page</a> for more info on the upcoming Discovery Menu</div>
                    {entity && entity.type === 'event' &&
                    <div className="p-3">
                        <div>
                            <EntityImageUploadBox targeturl={props.targeturl} requireContent={props.requireContent} contentname={"discoverybanner"} aspect={4} title="Featured Banner" description="Large banner to show when item is featured (4:1 / 2880x720)"/>
                        </div>
                        <div className="mt-2" style={{display:"flex", flexWrap:"wrap"}}>
                            <EntityImageUploadBox className="col-25-16 pr-1" targeturl={props.targeturl} requireContent={props.requireContent} contentname={"discoveryicon"} aspect={16.0/9.0} title="Discovery Menu Icon" description="Icon to show when item is listed in the discovery menu (16:9 / 1280x720)"/>
                            <EntityImageUploadBox className="col-25-9 pl-1" targeturl={props.targeturl} requireContent={props.requireContent} contentname={"doorimage"} aspect={1} title="Door Image" description={`Image to show on doors to this ${entity?.type}`}/>                        
                        </div>
                        <div className="mt-2">
                            <EntityTextAreaPropertyBox targeturl={props.targeturl} requireContent={props.requireContent} fieldname="description" disabled={lock_desc} title="Description" description="Description to show in discovery menu"/>
                        </div>
                    </div>}
                    {entity && entity.type !== 'event' &&
                    <Container>
                        <Row>
                            <Col xs="12" className="mb-2 mt-1">
                                <EntityImageUploadBox targeturl={props.targeturl} requireContent={props.requireContent} contentname={"discoverybanner"} aspect={4} title="Featured Banner" description="Large banner to show when item is featured (4:1 / 2880x720)"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm="6" className="mb-2 mt-1">
                                <EntityImageUploadBox targeturl={props.targeturl} requireContent={props.requireContent} contentname={"discoveryicon"} aspect={16.0/9.0} title="Discovery Menu Icon" description="Icon to show when item is listed in the discovery menu (16:9 / 1280x720)"/>
                            </Col>
                            <Col xs="12" sm="6" className="mb-2 mt-1">
                                <EntityTextAreaPropertyBox className="mb-2" targeturl={props.targeturl} requireContent={props.requireContent} fieldname="description" disabled={lock_desc} title="Description" description="Description to show in discovery menu"/>
                                <EntitySelect targeturl={props.targeturl} requireContent={props.requireContent} fieldname="visibility" disabled={lock_desc} title="Visibility" description="Whether or not to list this in the discovery menu"/>
                            </Col>
                        </Row>
                        {entity.type === 'plot' &&
                        <Row>
                            <Col xs="12" className="mb-2 mt-1">
                                <EntityImageUploadBox targeturl={props.targeturl} requireContent={props.requireContent} contentname={"doorimage"} aspect={1} title="Hubscraper Branding" description="Large icon to show on hubscraper door and tower (1:1 / 1024x1024)"/>
                            </Col>
                        </Row>}

                    </Container>}
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default DiscoveryContentCard;

/*

<Container>
    <Row>
        <Col xs="12" sm="6" className="mb-2 mt-1">
            <EntityImageUploadBox targeturl={props.targeturl} contentname={"discoveryicon"} aspect={16.0/9.0} title="Discovery Menu Icon" description="Icon to show when item is listed in the discovery menu (640x360)"/>
        </Col>
        <Col xs="12" sm="6"  className="mb-2 mt-1">
            <EntityTextAreaPropertyBox targeturl={props.targeturl} fieldname="description" disabled={lock_desc} title="Description" description="Description to show in discovery menu"/>
        </Col>
    </Row>
</Container>
*/