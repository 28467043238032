import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { TItemInfo } from '../../utils/driveapi';
import AspectRatioBox from '../aspectRatioBox';
import DialogBox from "../dialogBox"
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';
import "./drivebrowser.css"

function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

export type TDriveBrowserDeleteDialogProps = {
    item: TItemInfo | null;
    path: string;
    onCancel?: ()=>void;
    onConfirm?: ()=>Promise<void>;
}
export default function DriveBrowserDeleteDialog(props: TDriveBrowserDeleteDialogProps) {
     
    function getSizeString() {
        if(props.item && props.item.size) {
            return (props.item.size / (1024*1024)).toFixed(2) + " MB";
        } else {
            return "0 MB";
        }
    }
    
    return <DialogBoxWithSpinner
        className="drive-browser-info-dialog"
        show={props.item != null} 
        title="Delete File"
        button0Text="OK"
        cancelButtonText="Cancel"
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
        closeButton>
            <Container fluid>
                <Row className="dbid-thumbnail-row">
                    <Col>
                        <AspectRatioBox aspect={1.777778}>
                            <Image src={props.item?.thumbnailUri}/>
                        </AspectRatioBox>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Are you sure you wish to <strong>permanently</strong> delete {props.item?.name}?<p></p>
                        If this file is used in any existing Metaverses it may no longer load or display correctly.<p></p>
                        This will save {getSizeString()} in your Multiverse storage.
                    </Col>
                </Row>
            </Container>
    </DialogBoxWithSpinner>
}

