export type TTime = {
    hour?: number,
    minute?: number
}

export type TFormatDateTimeOptions = {
    isUtc?: boolean
}
export function formatDateTime(
    date: Date | string | undefined, opts: TFormatDateTimeOptions = {}): string {
    if (!date) return "";
    if (typeof date === "string") date = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric'
    };
    if(opts.isUtc) {
        return date.toUTCString();
    } else {
        return `${shortDayOfWeek(date.getDay())} ${date.toLocaleDateString()} ${date.toLocaleTimeString([], options)}`;
    }
}

export function formatTime(time?: TTime): string | undefined {
    if (!time) return undefined;
    return `${time.hour === undefined ? "  " : time.hour.toString().padStart(2, '0')}:` + 
        `${time.minute === undefined ? "  " : time.minute.toString().padStart(2, '0')}`;
}

export function dayOfWeek(dayOfWeek: number) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayOfWeek === undefined ? undefined : daysOfWeek[dayOfWeek];
}

export function shortDayOfWeek(dayOfWeek?: number) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return dayOfWeek === undefined ? undefined : daysOfWeek[dayOfWeek];
}

export function removeSeconds(date: Date) {
    const milliseconds = 1000 * 60;
    return new Date(Math.floor(date.getTime() / milliseconds) * milliseconds)        
}
