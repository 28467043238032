import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsExclamationCircle } from 'react-icons/bs';
import { getApiUrl } from '../../config/api';
import VideoPlayer from '../../pages/locationPage/videoplayer';
import api from '../../utils/api';
import "./contentbox.css"

type TContentBoxProps = {
    src?: string;
    type?: string;
    thumbnail?: string;
    autoplay?: boolean;
    muted?: boolean;
    loop?: boolean;
    bigPlayButton?: boolean;
    controls?: boolean;
    className?: string;
    getWarning?: (container: "empty" | "error" | "video" | "image" | "iframe" | "youtube", src: string, type: string)=>string | null;
}

type TLoadedContent = {
    src: string;
    mimeType: string;
    error: boolean;
}
const NO_CONTENT: TLoadedContent = { src: "", mimeType: "", error: false}

export default function ContentBox(props: TContentBoxProps) {
    const [loadedContent, setLoadedContent] = useState(NO_CONTENT)
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);

    //effect to load the content
    useEffect(() => {

        //clear state
        setLoadedContent(NO_CONTENT)

        //check if mime type supplied
        if(props.src && props.src != "") {
            if(props.type) {
                //got mime type, so can just store it
                setLoadedContent({
                    src: props.src,
                    mimeType: props.type.toLowerCase(),
                    error: false
                })
            } else {
                //no mime type so have to load it
                const loading_url = props.src;
                api.get<any>({ url: `/v2/head?url=${encodeURIComponent(loading_url)}`, hideErrors: true })
                .then((res) => {
                    if(loading_url === props.src) { //ensures url hasn't changed since request
                        if(res.headers["content-type"]) {
                            setLoadedContent({
                                src: props.src,
                                mimeType: res.headers["content-type"].toLowerCase(),
                                error: false
                            })
                        } else {
                            setError(true);
                        }
                    }
                })
                .catch((err)=>{
                    if(loading_url === props.src) { //ensures url hasn't changed since request
                        setLoadedContent({
                            src: props.src,
                            mimeType: "",
                            error: true
                        })
                    }
                })
            }            
        }
    }, [props.src, props.type])

    /*
    useEffect(() => {
        console.log("Loaded content changed");
        console.log(loadedContent);
    }, [loadedContent])
    */

    const onImageLoaded = () => {
        setLoaded(true);
    }

    const getContainerType = () => {
        const {src,mimeType} = loadedContent;
        if(mimeType == "") {
            return "empty";
        } else if(mimeType.startsWith("image/")) {
            return "image";
        } else if((mimeType.startsWith("video/") || mimeType.startsWith("application/x-mpegurl") || mimeType.startsWith("application/vnd.apple.mpegurl"))) {
            return "video";
        } else if(mimeType.startsWith("text/html")) {
            return src.includes("youtube.com") ? "youtube" : "iframe";
        } else {
            return "error";
        }       
    }

    const emptyContainer = () => (
        <div></div>
    )

    const imageContainer = () => (
        <img 
            referrerPolicy="no-referrer" 
            src={loadedContent.src} 
            onLoad={onImageLoaded} 
        />
    )

    const videoContainer = () => (
        <VideoPlayer 
            autoplay={props.autoplay} 
            muted={props.muted} 
            loop={props.loop} 
            bigPlayButton={props.bigPlayButton} 
            controls={props.controls}
            sources={[{ src: loadedContent.src, type: loadedContent.mimeType }]}
        />
    )

    const iFrameContainer = () => (
        <iframe src={loadedContent.src} scrolling="no"/>
    )

    const youtubeContainer = () => {
        const vid_id = loadedContent.src.match(/.*v\=(.*)/)
        return <iframe src={`${getApiUrl()}/v2/public/viewers/youtube/${vid_id![1]}`} scrolling="no" />
    }

    const errorContainer = () => (
        <div></div>
    )

    const containers = {
        "empty": emptyContainer,
        "image": imageContainer,
        "video": videoContainer,
        "iframe": iFrameContainer,
        "youtube": youtubeContainer,
        "error": errorContainer
    }

    const container = () => containers[getContainerType()]();

    const warningText = () => {
        return props.getWarning && props.getWarning(getContainerType(), loadedContent.src, loadedContent.mimeType);
    }

    const warningTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {warningText()}
        </Tooltip>
    );

    const warningOverlay = () => (
        warningText() && (<OverlayTrigger placement="bottom-end" delay={{ show: 0, hide: 400 }} overlay={warningTooltip}>
            <div className="mv-content-box-warning">
                <BsExclamationCircle size="30px"/>
            </div>
        </OverlayTrigger>)
    )

    return (
        <div className={"mv-content-box " + props.className || ""}> 
            {container()}
            {warningOverlay()}
        </div>
    )
}

