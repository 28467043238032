import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import { getBlobUrl } from '../../config/api';
import { MultiverseFile } from '../../hoc/multiverseApiProvider';
import VideoPlayer from '../../pages/locationPage/videoplayer';
import driveapi, { TItemInfo } from '../../utils/driveapi';
import { BackButton } from '../circularButton';
import ContentBox from '../contentBox';
import DialogBox from "../dialogBox"
import { DriveBrowseItemIconAndTitle } from './driveBrowserItem';


export type TDriveBrowserPreviewDialogProps = {
    path: string;
    item: TItemInfo | null;
    onCancel?: ()=>void;
}

type TDriveBrowserPreviewDialogState = {
    fullPath: string;
    entity: MultiverseFile | null;
    mainImageLoaded: boolean;
};

class _DriveBrowserPreviewDialog extends React.Component<TDriveBrowserPreviewDialogProps, TDriveBrowserPreviewDialogState> {

    mainImageRef = React.createRef<HTMLImageElement>()
    previewImageRef = React.createRef<HTMLImageElement>()

    constructor(props: TDriveBrowserPreviewDialogProps) {
        super(props);
        this.state = {
            fullPath: "",
            entity: null,
            mainImageLoaded: false
        };
    }

    componentDidMount = () => {
        this.refresh();
    }
    componentDidUpdate = () => {
        this.refresh();
    }

    refresh = () => {
        let new_path = "";
        if(this.props.item) {
            new_path = `${this.props.path}/${this.props.item.id}`;
        } 
        if(new_path != this.state.fullPath) {
            this.setState({ 
                fullPath: new_path, 
                entity: null,
                mainImageLoaded: false
            }, this.load)
        }

        if(this.props.item) {

        }
    }

    load = async() => {
        if(this.state.fullPath != "") {
            this.setState({
                entity: await driveapi.getEntity(this.state.fullPath)
            })
        }
    }

    
    renderItemViewer = () => {
        const entity = this.state.entity;
        if(entity && entity.mimetype && entity.type === "file") {
            let uri: string;
            if(entity.blob) {
                uri = `${getBlobUrl()}/${entity.blob}`;
            } else if(entity.link) {
                uri = entity.link!;
            } else {
                return null;
            }
    
            return <ContentBox src={uri} autoplay muted loop/>
            /*
            const mimetype = entity.mimetype;
            if(mimetype.startsWith("image/")) {
                return <img ref={this.mainImageRef} referrerPolicy="no-referrer" style={{ maxWidth: "100%" }} src={uri} onLoad={()=>this.setState({mainImageLoaded:true})} />
            } else if((mimetype.startsWith("video/") || mimetype === "application/x-mpegurl" || mimetype === "application/vnd.apple.mpegurl")) {
                return <VideoPlayer className="content" autoplay={true} muted={true} loop={true} sources={[{ src: uri, type: mimetype }]} />
            } else if(mimetype == "text/html") {
                return <iframe style={{ width: "100%" }} src={uri} scrolling="no"/>
            }
            */
        }
        return null;
    }
    
    renderThumbnail = () => {
        const props = this.props;
        const {entity, mainImageLoaded} = this.state;
        if(props.item && props.item.mimeType && props.item.mimeType.startsWith("image/") && !mainImageLoaded) {
            return <img ref={this.previewImageRef} src={props.item?.thumbnailUri}/>;
        } else {
            return null;
        }
    }

    render(): JSX.Element {
        const props = this.props;
        const state = this.state;
  
        return <Modal 
            dialogClassName="drive-browser-preview-dialog"
            show={props.item != null} 
            onCancel={props.onCancel}
            onHide={props.onCancel}
            closeButton>
                <Modal.Header>
                    <BackButton className="drive-browser-transparent-button" onClick={props.onCancel}/>
                    {props.item && DriveBrowseItemIconAndTitle({...props.item, large: true})}
                </Modal.Header>
                <Modal.Body>
                    {this.renderThumbnail()}
                    {this.renderItemViewer()}
                </Modal.Body>

        </Modal>
    }
}
export const DriveBrowserPreviewDialog = _DriveBrowserPreviewDialog;

export default DriveBrowserPreviewDialog;

