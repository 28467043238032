import cookies from 'browser-cookies';
import { getApiUrl, isDev } from '../config/api';
import eventBus from './eventBus';
import { useState, useEffect } from 'react';
import { MultiverseAuthData, MultiverseUser } from '../hoc/multiverseApiProvider';

let g_web_socket: WebSocket | null = null;
let g_web_socket_interval_handle = 0;

const MULTIVERSE_TOKEN_COOKIE_EXPIRE_TIME = 24 * 60 * 60 // One Day

export type FetchOptions = {
    url: string,
    body?: Record<string, unknown> | Blob,
    method?: 'PUT' | 'POST' | 'GET' | 'DELETE',
    headers?: Record<string, unknown>,
    hideErrors?:boolean
};

//@ts-ignore
export class FetchError extends Error{
    statusCode: number;

    constructor(message: string, statusCode: number) {
      super(message);
      this.name = this.constructor.name;
      this.statusCode = statusCode;
    }
}

//PUT to multiverse API
export const put = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
    if(typeof(opts) === 'string') {
        opts = {
            method: 'PUT',
            url: opts,
            body: body,
        }
    } else {
        opts = {
            ...opts,
            method: 'PUT'
        }
    }
    return authenticatedFetchAndRead<T>(opts as FetchOptions);
} 

//POST to multiverse API
export const post = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
    if(typeof(opts) === 'string') {
        opts = {
            method: 'POST',
            url: opts,
            body: body,
        }
    } else {
        opts = {
            ...opts,
            method: 'POST'
        }
    }
    return authenticatedFetchAndRead<T>(opts as FetchOptions);
} 

//GET from multiverse API
export const get = <T extends unknown>(opts: FetchOptions | string): Promise<T> => {
    if(typeof(opts) === 'string') {
        opts = {
            method: 'GET',
            url: opts,
        }
    } else {
        opts = {
            ...opts,
            method: 'GET'
        }
    }
    return authenticatedFetchAndRead<T>(opts as FetchOptions);
} 

export const tryGet = async <T extends unknown>(opts: FetchOptions | string): Promise<T | null> => {
    try {
        return await get<T>(opts);    
    } catch {
        return null;
    }
}

export function setDataFromUrl<T>(
    url: string | null | undefined, 
    setData: React.Dispatch<React.SetStateAction<T | null | undefined>>,
    callback?: (data: T | null) => void) {
    if(url) {
        const loading_url = url;
        tryGet<T>(url).then((res) => {
            if(url === loading_url) {
                setData(res)
                console.log(url)
                console.log(res)
                if(callback) {
                    callback(res)
                }
            }
        }).catch((err) => {
            console.log(err);
            if(url === loading_url) {
                setData(null)
                if(callback) {
                    callback(null)
                }
            }
        })
    } else {
        setData(null)
        if(callback) {
            callback(null)
        }
    }
}

export const useGet = <T extends unknown>(url: string | null | undefined)
: T | null | undefined => {
    const [data,setData] = useState<T | null | undefined>(undefined)

    useEffect(() => {
        setDataFromUrl(url, setData);
    }, [url])

    return data
}

export type TGet = {
    url: string,
    effect: () => void,
    getData: () => void
}    
export const useGetAll = (gets: TGet[]) => {
    useEffect(() => {
        gets.forEach(get => get.effect());
    }, gets.map(x => x.url))    

    gets.forEach(get => get.getData());
}

//DELETE to multiverse API
export const del = <T extends unknown>(opts: FetchOptions | string, body?: any): Promise<T> => {
    if(typeof(opts) === 'string') {
        opts = {
            method: 'DELETE',
            url: opts,
            body: body,
        }
    } else {
        opts = {
            ...opts,
            method: 'DELETE'
        }
    }
    return authenticatedFetchAndRead<T>(opts as FetchOptions);
} 

//helpers to read active user info from cookies
export const getAccount = () => {
    return cookies.get('shapevr-account') || "";
}
export const getToken = () => {
    return cookies.get('shapevr-token') || "";
}
export const getProduct = () => {
    return cookies.get('shapevr-product') || "";
}

//helper to perform a request to the Multiverse api and attempt to deal with the
//various forms of result/error that shape vr servers return as standard
export async function authenticatedFetchAndRead<T>(opts: FetchOptions): Promise<T> {
    const apiUrl = getApiUrl();
    try {
        //check for unauthenticated access to non-public uris. 
        //note: these will fail server side - this is just to help with debugging/perf
        const token = cookies.get('shapevr-token');
        if(!token || token === "") {
            if(!opts.url.startsWith("/v2/public") && !opts.url.endsWith("/auth0login")) {
                throw new Error("Attempt to fetch from non-public path when not authenticated");
            }
        }

        //first perform the http request
        //TODO: dig into what this is doing with headers and blobs
        /*console.log({
            url: `${apiUrl}${opts.url}`,
            body: opts.body
        })*/
        const resp = await fetch(`${apiUrl}${opts.url}`, {
            method:opts.method,
            headers: {
                'Content-Type': 'text/plain',
                'x-shapevr-account': cookies.get('shapevr-account') || "",
                'x-shapevr-token': cookies.get('shapevr-token') || "",
                'x-shapevr-product': cookies.get('shapevr-product') || "",
                ...opts.headers,
            },
            credentials: 'include',
            body: (opts.headers && opts.headers['Content-Type'] !== 'text/plain') ? (opts.body as Blob) : opts.body && JSON.stringify(opts.body),
        });

        //waits for result and on receiving it attempts to do json decode
        const text = await resp.text();
        if (resp.status === 200) {
            try {
                const json = JSON.parse(text);
                if(json.result) {
                    return json.result;
                } else {
                    return json;
                }
            } catch (_) {
                // The fetch takes full responsibility of the type of the result
                return text as unknown as T;
            }
        }

        //detect http error, and if available attempt to parse the multiverse json
        //error data in the response body. Then throws a FetchError
        let error: FetchError;
        try {
            const json = JSON.parse(text) as { error: string };
            error = new FetchError(json.error, resp.status);
        } catch(e) {
            error = new FetchError(text, resp.status);
        }
        throw error;
    } catch(e) {
        //optionally show error toast then re-throw the error so it gets passed up
        if(!opts.hideErrors) {
            eventBus.on('API.fetcherror', e);
        }
        throw e;
    }
}

export const isAuthenticated = () => {
    const token = cookies.get('shapevr-token');
    return token && token !== "";
}

//helper to perform a request to the Multiverse api in raw form with no additional
//processing of response
export const authenticatedFetch = async(opts: FetchOptions): Promise<Response> => {
    const apiUrl = getApiUrl();
    try {
        //check for unauthenticated access to non-public uris. 
        //note: these will fail server side - this is just to help with debugging/perf
        const token = cookies.get('shapevr-token');
        if(!token || token === "") {
            if(!opts.url.startsWith("/v2/public") && !opts.url.endsWith("/auth0login")) {
                throw new Error("Attempt to fetch from non-public path when not authenticated");
            }
        }
        const resp = await fetch(`${apiUrl}${opts.url}`, {
            method:opts.method,
            headers: {
                'Content-Type': 'text/plain',
                'x-shapevr-account': cookies.get('shapevr-account') || "",
                'x-shapevr-token': cookies.get('shapevr-token') || "",
                'x-shapevr-product': cookies.get('shapevr-product') || "",
                ...opts.headers,
            },
            credentials: 'include',
            body: (opts.headers && opts.headers['Content-Type'] !== 'text/plain') ? (opts.body as Blob) : opts.body && JSON.stringify(opts.body),
        });
        return resp;

    } catch(e) {
        //optionally show error toast then re-throw the error so it gets passed up
        if(!opts.hideErrors) {
            eventBus.on('API.fetcherror', e);
        }
        throw e;
    }
}

export const calcEntityUrl = (entity: { id: string, type: string, domain?: string }) => {
    switch(entity.type) {
        case "domain":
            return entity.id;
        case "location":
            return `${entity.domain}/locations/${entity.id}`
        case "event":
            return `${entity.domain}/events/${entity.id}`
        case "user":
            return `users/${entity.id}`
        default:
            throw new Error("Can't evaluate entity uri")
    }
}

export const onAuthenticated = (user: MultiverseUser & MultiverseAuthData) => {
    document.cookie = `cachedUserObject=${JSON.stringify(user)}; max-age=${MULTIVERSE_TOKEN_COOKIE_EXPIRE_TIME}; path=/;`;
    document.cookie = `shapevr-account=${user.id}; max-age=${MULTIVERSE_TOKEN_COOKIE_EXPIRE_TIME}; path=/;`;
    document.cookie = `shapevr-product=${user.product}; max-age=${MULTIVERSE_TOKEN_COOKIE_EXPIRE_TIME}; path=/;`;
    document.cookie = `shapevr-token=${user.token}; max-age=${MULTIVERSE_TOKEN_COOKIE_EXPIRE_TIME}; path=/;`;

    if(isDev()) {
        try {
            const addr = `${getApiUrl().replace(/^http/,"ws")}/channel`
            g_web_socket = new WebSocket(addr)
            g_web_socket.addEventListener('open', (ev) => {
                console.log(ev)
            })
            g_web_socket.addEventListener('message', (ev) => {
                //console.log(ev)
            })
            g_web_socket.addEventListener('close',(ev) => {
                console.log(ev)    
            })
            g_web_socket.addEventListener('error',(ev) => {
                console.log(ev)
            })
            g_web_socket_interval_handle = <any>setInterval(() => {
                if(g_web_socket) {
                    g_web_socket.send(JSON.stringify({
                        type: 'hello'
                    }))
                }
            }, 30000)
        } catch(err) {
            console.log(err)
        }
    }
}
export const onUnauthenticated = () => {
    document.cookie = 'auth0AccessToken=; max-age=-1; path=/;';
    document.cookie = 'cachedUserObject=; max-age=-1; path=/;';
    document.cookie = 'shapevr-account=; max-age=-1; path=/;';
    document.cookie = 'shapevr-product=; max-age=-1; path=/;';
    document.cookie = 'shapevr-token=; max-age=-1; path=/;';
    
    if(g_web_socket) {
        g_web_socket.close();
        clearInterval(g_web_socket_interval_handle);
        g_web_socket = null;
    }
}

export const getSocket = () => {
    return g_web_socket;
}



export default { get, tryGet, useGet, useGetAll, put, post, del, getAccount, getToken, getProduct, isAuthenticated, onAuthenticated, onUnauthenticated, getSocket }
