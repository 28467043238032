import React, { useEffect, useState } from 'react';
import BasicPage from '../../components/basicPage';
import { Tabs, Tab, Form, Row, Col, Alert } from 'react-bootstrap';
import { CanContainChildProduct, MultiverseMissionEventConfig, MultiverseMissionEventConfigCreateArgs, MultiverseMissionEventCreateArgs } from '../../hoc/multiverseApiProvider';
import api, { useGetAll, setDataFromUrl, useGet, TGet } from '../../utils/api';
import { Link, matchPath, useHistory } from 'react-router-dom';
import StringsControl from '../../components/stringsControl';
import { deepClone } from '../../utils/objects';

type IdMatchParams = {
    childproductid: string;
    configname: string;
}

type TEventConfigDoc = {
    id: string,
    [nm:string]: any
} & MultiverseMissionEventConfigCreateArgs

type TEventConfigQuery = CanContainChildProduct & {
    config_name?: string
};

function MissionEventConfig(props: { query: TEventConfigQuery, emptyMessage: string }) {
    const history = useHistory();

    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [err, setErr] = useState<string>("")
    const [success, setSuccess] = useState<string>("")
    const { query } = props;
    const { child_product, config_name } = query;

    const blankItem: TEventConfigDoc = {
        id: "",
        name: "",
        display_name: "",
        importance: 0,
        icon: "",
        environment: "",
        modes: [],
        any_mode: false,
        no_mode: false        
    }

    const [savedItem, setSavedItem] = useState<TEventConfigDoc | null | undefined>(undefined)
    const [item, setItem] = useState<TEventConfigDoc>({...blankItem})

    const childProductUrlPrefix = `/v2/admin/childproducts/${child_product}`;
    const getUrl = `${childProductUrlPrefix}/missions/events/config/${config_name}`;

    useEffect(() => {
        if(config_name) {
            setDataFromUrl(getUrl, setSavedItem,
                c => setItem(c ? {...c} : {...blankItem}));
        }
        else {
            setSavedItem({...blankItem});
            setItem({...blankItem});      
        }
    }, [getUrl])

    const handleAnyChange = (name: string, value: any) => {
        // @ts-ignore
        const updatedConfig: TEventConfigDoc = {...item};
        updatedConfig[name] = value;
        setItem(updatedConfig);
        setIsDirty(true);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAnyChange(e.target.name, e.target.value);
    };    

    const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAnyChange(e.target.name, e.target.checked);
    };        

    const handleStringsChange = (name: string, updatedStrings: string[]) => {
        handleAnyChange(name, updatedStrings);
    }

    const save = async (e: React.SyntheticEvent, data: TEventConfigDoc) => {
        e.preventDefault();

        const url = `${childProductUrlPrefix}/missions/events/config`;
        setLoading(true);
        try{
            let response: { msg?: string };
            if(data.id) {
                response = await api.put(url, data);
            } else {
                const { id, ...saveData } = data;
                response = await api.post(url, saveData);
            }

            if(response.msg) {
                setErr(response.msg)
            }
            else {
                setSavedItem(item);
                setIsDirty(false);
                setErr("")
            }

            
        }catch(err) {
            setErr(err.message)
        }
        setSuccess("Saved")
    }

    const remove = async (e: React.SyntheticEvent, data: TEventConfigDoc) => {
        e.preventDefault();
        if(item.id) {
            setLoading(true);
            try{
                let response: { msg?: string };
                response = await api.del(`${childProductUrlPrefix}/missions/events/config`, { id: data.id });
                if(response.msg) {
                    setErr(response.msg)
                }
                else {
                    setSavedItem({...blankItem});
                    setItem({...blankItem});
                    setIsDirty(false);
                    setErr("")
                }
            }catch(err) {
                setErr(err.message)
            }
            setSuccess("Deleted")
        }
    }

    const cancel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if(isDirty) {
            setItem(savedItem ? deepClone(savedItem) : deepClone(blankItem));
            setIsDirty(false);
        }
    }

    const goBack = (e: React.SyntheticEvent) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
        {item &&
        <Form className="edit_item">
            <Row className="pt-2">
                <Col className="col-md-3">
                    Name
                </Col>
                <Col className="col-md-9">
                    {!item.id &&
                        <Form.Control type="text" name="name" value={item.name} onChange={handleChange} />
                    }
                    {item.id && item.name
                    }
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Display Name
                </Col>
                <Col className="col-md-9">
                    <Form.Control type="text" name="display_name" value={item.display_name} onChange={handleChange} />
                </Col>
            </Row>
            <Row className={"pt-2" + (item.any_mode || item.no_mode ? " disabled-row": "")}>
                <Col className="col-md-3">
                    Importance (Ribbon Colour)
                </Col>
                <Col className="col-md-9">
                    <Form.Control type="number" name="importance" value={item.importance} onChange={handleChange} />
                </Col>
            </Row>
            <Row className={"pt-2" + (item.any_mode || item.no_mode ? " disabled-row": "")}>
                <Col className="col-md-3">
                    Icon
                </Col>
                <Col className="col-md-9">
                    <Form.Control type="text" name="icon" value={item.icon} onChange={handleChange} />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Environment
                </Col>
                <Col className="col-md-9">
                    <Form.Control type="text" name="environment" value={item.environment} onChange={handleChange} />
                </Col>
            </Row>
            <Row className={"pt-2" + (item.any_mode || item.no_mode ? " disabled-row": "")}>
                <Col className="col-md-3">
                    Modes
                </Col>
                <Col className="col-md-9">
                    <StringsControl name="modes" strings={item.modes || []} onStringsChange={handleStringsChange} />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Any mode
                </Col>
                <Col className="col-md-9">
                    <Form.Check type="checkbox" name="any_mode" checked={item.any_mode} onChange={handleCheckChange} />
                </Col>
            </Row>            
            <Row className="pt-2">
                <Col className="col-md-3">
                    No mode
                </Col>
                <Col className="col-md-9">
                    <Form.Check type="checkbox" name="no_mode" checked={item.no_mode} onChange={handleCheckChange} />
                </Col>
            </Row>            
            <Row className="pt-2">
                <Col className="col-md-3">
                </Col>
                <Col className="col-md-9 evenly-spaced-children" >
                    {isDirty &&
                        <>
                        <button onClick={e => save(e, item)} className="btn btn-primary">Save</button>
                        <button onClick={cancel} className="btn btn-secondary">Cancel</button>
                        </>}
                    {!isDirty &&
                        <button onClick={goBack} className="btn btn-secondary">Back</button>}
                    {item.id &&
                        <button onClick={e => remove(e, item)} className="btn btn-danger">Delete</button>}
                </Col>
            </Row>
            {
                err && <Row className="pt-2">
                    <Col>
                        <Alert variant="danger">{err}</Alert>
                    </Col>
                </Row>
            }
            {
                success && !err && <Row className="pt-2">
                    <Col>
                        <Alert variant="success">{success}</Alert>
                    </Col>
                </Row>
            }
        </Form>}
        {
            props.emptyMessage && !item && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

export function AdminMissionEventConfigPage(props: {}) {

    const history = useHistory();
    const match = matchPath<IdMatchParams>(history.location.pathname, {
        path: '/admin/childproducts/:childproductid/missioneventconfig/:configname?',
        exact: false
    })
    const child_product = match?.params.childproductid;
    const mission_event_config_name = match?.params.configname;

    return (
        <BasicPage title="Mission Event Config">
            <MissionEventConfig query={{child_product: child_product, config_name: mission_event_config_name}} emptyMessage="Config not found"/>
        </BasicPage>
    )    
}

export default AdminMissionEventConfigPage;