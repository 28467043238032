import { MultiverseFile, WithMultiverseApiProps } from "../hoc/multiverseApiProvider";
import * as tus from 'tus-js-client'
import { getBlobUrl } from "../config/api";
import BGTaskManager, { TBackgroundTaskArgs } from "./backgroundTasks";
import api from "./api";

export type TUploadContextOpts = {
    uri: string;
    file: File;
    alwaysBackground?: boolean;
    onProgress?: (progress: number, status: string)=>void;
};


export type TUploadVideoContextOpts = {
    uri: string;
    file: File;
    upload_id?: string;
    transcode_jobname?: string,
    alwaysBackground?: boolean;
    onProgress?: (progress: number, status: string)=>void;
};

//main video upload body that fires off a tus upload and resolves promise when full upload is complete
const vimeoUpload = (opts: TUploadVideoContextOpts, upload_link: string, task_args: TBackgroundTaskArgs): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
        var upload = new tus.Upload(opts.file, {
            uploadUrl: upload_link,
            chunkSize: 16*1024*1024,
            onError: (error) => {
                reject(error);
            },
            onSuccess: () => {
                resolve();
            },
            onProgress: (bytesSent: number, bytesTotal: number) => {
                //notify both task and caller of progress
                const progress = bytesSent / bytesTotal;
                if(task_args.onProgress) {
                    task_args.onProgress(progress, "uploading")
                }
                if(opts.onProgress) {
                    opts.onProgress(progress, "uploading")
                }
            }
        })
        upload.start()
    })
    return promise;
}

//check status of the video upload
const videoCheckStatus = async(opts: TUploadVideoContextOpts) => {
    return (await api.post<any>({
        url: `/v2/${opts.uri}/content/videoupload/status`,
        body: {
            upload_id: opts.upload_id,
            transcode_jobname: opts.transcode_jobname
        }
    }));
}

//calls check status with initial delay of 10s
const videoWaitAndCheckStatus = (opts: TUploadVideoContextOpts): Promise<any> => {
    const promise = new Promise<void>((resolve, reject) => {
        const to = setTimeout(() => {
            clearTimeout(to);
            videoCheckStatus(opts)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }, 10000)
    })
    return promise;
}

// retrieves width and height of video file

//main video upload body, wraps the firing off of a background task that does the full video
//upload process and then resolves the promise with the resulting stream url
// status can be:
// uploading, pending, transcoding, failed, complete
function videoUpload(opts: TUploadVideoContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    if(opts.onProgress) {
        opts.onProgress(0.1, "pending");
    }
    return new Promise<MultiverseFile>((resolve,reject) => {
        BGTaskManager.addBackgroundTask({
            name: file.name,
            task: async(args: TBackgroundTaskArgs) => {
                try {

                    // prepare video upload
                    let body = {
                        name: file.name,
                        size: file.size,
                        lastModified: file.lastModified,
                        width: 0,
                        height: 0,
                        duration: 0
                    }
                    // get width/height of video file
                    const url = URL.createObjectURL(file);
                    const video = document.createElement('video');
                    video.src = url;
                    // wait for metadata to be loaded
                    await new Promise<void>((resolve) => {
                        video.onloadedmetadata = () => {
                            body.width = video.videoWidth;
                            body.height = video.videoHeight;
                            body.duration = video.duration;
                            console.log("video width/height: ", body.width, body.height);
                            URL.revokeObjectURL(url); // Release the object URL
                            resolve();
                        };
                    });


                    //start with video upload begin, which may immediately return streamurl for existing video,
                    //or may return TUS upload data for the upload
                    const begin_ul_res = await api.post<any>({
                        url: `/v2/${uri}/content/videoupload/begin`,
                        body: body
                    })

                    //check for streamurl, and if not found, begin upload
                    let entity: MultiverseFile | null = begin_ul_res.entity;
                    if(!entity && begin_ul_res.upload) {
                        const vidopts = {
                            ...opts,
                            upload_id: begin_ul_res.upload.upload_id,
                        }

                        //notify task+caller of progress
                        if(args.onProgress) {
                            args.onProgress(0.25, "uploading")
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0.25, "uploading");
                        }

                        //do main video upload
                        //TODO handle streamed uploads? (so >64mb)
                        const upload_res = await api.post<any>({
                            url: `/v2/${uri}/content/videoupload/upload/${vidopts.upload_id}`,
                            body: file,
                            headers: {
                                'Content-Type': file.type,
                            }
                        })

                        vidopts.transcode_jobname = upload_res.transcode_jobname
                        if(args.onProgress) {
                            args.onProgress(0.50, upload_res.status)
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0.50, upload_res.status);
                        }
                        if(upload_res.status === "failed") {
                            reject(upload_res.error);
                        }

                        //initial check of status to get initial state
                        let status_res = await videoCheckStatus(vidopts);
                        if(args.onProgress) {
                            args.onProgress(0.75, status_res.status)
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0.75, status_res.status);
                        }
                        if(status_res.status === "failed") {
                            reject(status_res.error);
                        }

                        //loop with waiting checks of status to wait for transcoding to complete
                        while(true) {
                            status_res = await videoWaitAndCheckStatus(vidopts);
                            if(args.onProgress) {
                                args.onProgress(1.00, status_res.status)
                            }
                            if(opts.onProgress) {
                                opts.onProgress(1.00, status_res.status);
                            }
                            if(status_res.status === "complete") {
                                break;
                            }
                            if(status_res.status === "failed") {
                                reject(status_res.error);
                                break;
                            }
                        }

                        //finish the upload, which should return the streamurl
                        if(status_res.status === "complete") {
                            const end_ul_res = await api.post<any>({
                                url: `/v2/${uri}/content/videoupload/end`,
                                body: {
                                    upload_id: vidopts.upload_id,
                                    transcode_jobname: vidopts.transcode_jobname
                                }
                            })
                            entity = end_ul_res; //the result is a file entity with the 'link' property set
                        }
                    }
                    if(!entity) {
                        //throw new Error("Video upload failed");
                        reject("Video upload failed");
                    }
                    else
                        resolve(entity);

                } catch (err) {
                    reject(err);
                }
            } 
        })
    })
}

async function fileUploadTask(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    const res = await api.post<MultiverseFile>({
        url: `/v2/${uri}/content?name=${file.name}`,
        body: file,
        hideErrors: true,
        headers: {
            'Content-Type': file.type,
            'x-shapevr-name': file.name,
        }
    });
    if(!res.blob) {
        throw new Error("File upload failed");
    }
    return res;
}

function fileUpload(opts: TUploadContextOpts) : Promise<MultiverseFile> {
    const { file } = opts;
    if(!opts.alwaysBackground && opts.file.type.startsWith("image/")) {
        return fileUploadTask(opts);
    } else {
        return new Promise<MultiverseFile>((resolve,reject) => {
            BGTaskManager.addBackgroundTask({
                name: file.name,
                task: async(args: TBackgroundTaskArgs) => {
                    try {
                        if(args.onProgress) {
                            args.onProgress(100,"transcoding");
                        }
                        if(opts.onProgress) {
                            opts.onProgress(100,"transcoding");
                        }
                        resolve(await fileUploadTask(opts));
                    } catch(err) {
                        reject(err);
                    }
                }
            })   
        })
    }
}

export async function uploadContent(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { file } = opts;
    if(file.type.startsWith("video/")) {
        return await videoUpload(opts);
    } else {
        return await fileUpload(opts);
    }
}