import React from "react";
import * as vector from './vector'

export function MapImage(props: { min: number[], max: number[], width: number, height: number }) {

    const rectsize = vector.sub(props.max,props.min)
    const size = rectsize[0]

    let mapsize = 250;
    while(mapsize < size && mapsize < 32000) {
        mapsize *= 2
    }

    const scale = props.width/size

    //const centre_cell = vector.muls(vector.round(vector.divs(vector.lerp(props.min,props.max,0.5),mapsize)),mapsize)

    const min_cell = vector.muls(
                        vector.round(
                            vector.divs(
                                vector.subs(props.min,mapsize*0.5)
                                ,mapsize
                            )
                        ),
                    mapsize)
    const max_cell = vector.muls(
                        vector.round(
                            vector.divs(
                                vector.adds(props.max,mapsize*0.5)
                                ,mapsize
                            )
                        ),
                    mapsize)

    const iminx = min_cell[0];
    const iminy = min_cell[1];
    const imaxx = max_cell[0]; //Math.round((props.max[0]+mapsize)/mapsize)*mapsize;
    const imaxy = max_cell[1]; //Math.round((props.max[1]+mapsize)/mapsize)*mapsize;

    const maps: {x:number,y:number,url:string}[] = []

    for(let x = iminx; x <= imaxx; x+=mapsize) {
        for(let y = iminy; y <= imaxy; y+=mapsize) {
            maps.push({
                x,y,
                url: `https://devblobs.shapevrcloud.com/infiniverse/maps/${mapsize}/${x}_${y}.jpg`
            })
        }
    }

    return (<div style={{position:'absolute',top:0,left:0,width:props.width,height:props.height,overflow:'clip',pointerEvents:"none"}}>
        {maps.map(cell => <img 
            key={`${mapsize}_${cell.x}_${cell.y}`} 
            src={cell.url}
            style={{
                position: 'absolute',
                top: props.height-scale*(cell.y-props.min[1]+mapsize*0.5),
                left: scale*(cell.x-props.min[0]-mapsize*0.5),
                width: scale*mapsize,
                height: scale*mapsize,
                pointerEvents:"none"
            }}
        />)}
    </div>)
}