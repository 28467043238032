import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import './backgroundtaskrunner.css'
import BGTaskManager, { TBackgroundTask } from '../../utils/backgroundTasks';
import eventBus from '../../utils/eventBus';


type BackgroundTaskRunnerProps = {

};

type BackgroundTaskRunnerState = {

};

export class BackgroundTaskRunner extends React.Component<BackgroundTaskRunnerProps, BackgroundTaskRunnerState> {
    constructor(props: BackgroundTaskRunnerProps) {
        super(props);
        this.state = {
            tasks: [],
            activeTask: null,
            activeProgress: 0
        };
    }

    componentDidMount = () => {
        eventBus.on("BackgroundTaskManager.onUpdate", this.onTasksRefresh)
    }
    componentWillUnmount = () => {
        eventBus.remove("BackgroundTaskManager.onUpdate", this.onTasksRefresh)
    }


    onTasksRefresh = (data: any) => {
        this.forceUpdate();
    }

    render(): JSX.Element {
        const task_runner = BGTaskManager;

        let cn = "background-task-runner";
        if(!task_runner.activeTask && task_runner.tasks.length === 0) {
            cn = cn + " background-task-runner-idle";
        } else {
            cn = cn + " background-task-runner-active";
        }
        return (
            <div className={cn}>
                {task_runner.activeTask &&
                    <div className="task active">
                        {task_runner.activeTask.name}
                        <ProgressBar now={task_runner.activeProgress*100} animated={task_runner.activeProgress>=1}/>
                    </div>
                }
                {task_runner.tasks.map((x,idx) => 
                    <div className="task pending" key={x.name}>
                        {x.name}
                    </div>
                )}
            </div>
        )
    }
}
export default BackgroundTaskRunner;
