import React, { useEffect, useState } from 'react';
import BasicPage from '../../components/basicPage';
import { Tabs, Tab, Form, Row, Col, Alert } from 'react-bootstrap';
import { CanContainChildProduct, MultiverseMissionEventConfig, MultiverseMissionEventConfigCreateArgs, MultiverseMissionEventCreateArgs } from '../../hoc/multiverseApiProvider';
import PageTable, { PageTableCell } from '../../components/pageTable';
import api, { useGetAll, setDataFromUrl, useGet, TGet } from '../../utils/api';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { formatDateTime, removeSeconds } from '../../utils/dates';
import StringsControl from '../../components/stringsControl';
import { deepClone } from '../../utils/objects';
import DateTimePicker from 'react-datetime-picker';

const loadedDate = new Date();

type IdMatchParams = {
    childproductid: string;
    id: string;
}

type TBaseItemDoc = {
    id: string,
    [nm:string]: any
}

type TItemDoc = TBaseItemDoc & MultiverseMissionEventCreateArgs

type TEventConfigDoc = TBaseItemDoc & MultiverseMissionEventConfigCreateArgs

type TEventConfigQuery = CanContainChildProduct & {
    id?: string
};

function MissionEvent(props: { query: TEventConfigQuery, emptyMessage: string }) {
    const history = useHistory();

    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [err, setErr] = useState<string>("")
    const [success, setSuccess] = useState<string>("")
    const { query } = props;
    const { child_product, id } = query;

    const blankItem: TItemDoc = {
        id: "",
        mission_event_config: "",
        startdate: undefined,
        enddate: undefined,
        option: undefined
    }

    const [configData, setConfigData] = useState<{mission_event_configs:TEventConfigDoc[]} | null | undefined>(undefined);
    const [savedItem, setSavedItem] = useState<TItemDoc | null | undefined>(undefined)
    const [item, setItem] = useState<TItemDoc>(deepClone(blankItem))

    const childProductUrlPrefix = `/v2/admin/childproducts/${child_product}`;
    const getUrl = `${childProductUrlPrefix}/missions/event/${id}`;
    const configsUrl = `/v2/admin/childproducts/${child_product}/missions/events/configs`

    useEffect(() => {
        if(id) {
            setDataFromUrl(getUrl, setSavedItem,
                c => setItem(c ? deepClone(c) : deepClone(blankItem)));
        }
        else {
            setSavedItem({...blankItem});
            setItem({...blankItem});      
        }

        setDataFromUrl(configsUrl, setConfigData);
    }, [getUrl, configsUrl])

    const handleAnyChange = (name: string, value: any) => {
        // @ts-ignore
        const updatedItem: TItemDoc = deepClone(item);
        updatedItem[name] = value;
        setItem(updatedItem);
        setIsDirty(true);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAnyChange(e.target.name, e.target.value);
    };    

    const handleStringsChange = (name: string, updatedStrings: string[]) => {
        handleAnyChange(name, updatedStrings);
    }

    const handleDateChange = (name: string, value: Date) => {
        handleAnyChange(name, removeSeconds(value));
    }

    const save = async (e: React.SyntheticEvent, data: TItemDoc) => {
        e.preventDefault();

        const url = `${childProductUrlPrefix}/missions/event`;
        setLoading(true);
        try{
            let response: { msg?: string };
            if(data.id) {
                response = await api.put(url, data);
            } else {
                const { id, ...saveData } = data;
                response = await api.post(url, saveData);
            }

            if(response.msg) {
                setErr(response.msg)
            }
            else {
                setSavedItem(item);
                setIsDirty(false);
                setErr("")
            }

            
        }catch(err) {
            setErr(err.message)
        }
        setSuccess("Saved")
    }

    const remove = async (e: React.SyntheticEvent, data: TItemDoc) => {
        e.preventDefault();
        if(item.id) {
            setLoading(true);
            try{
                let response: { msg?: string };
                response = await api.del(`${childProductUrlPrefix}/missions/event`, { id: data.id });
                if(response.msg) {
                    setErr(response.msg)
                }
                else {
                    setSavedItem(deepClone(blankItem));
                    setItem(deepClone(blankItem));
                    setIsDirty(false);
                    setErr("")
                }
            }catch(err) {
                setErr(err.message)
            }
            setSuccess("Deleted")
        }
    }

    const cancel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if(isDirty) {
            setItem(savedItem ? deepClone(savedItem) : deepClone(blankItem));
            setIsDirty(false);
        }
    }

    const goBack = (e: React.SyntheticEvent) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
        {item &&
        <Form className="edit_item">
            {item.id &&
            <Row className="pt-2">
                <Col className="col-md-3">
                    Id
                </Col>
                <Col className="col-md-9">
                    {item.id}
                </Col>
            </Row>}
            <Row className="pt-2">
                <Col className="col-md-3">
                    Mission Type
                </Col>
                <Col className="col-md-9">
                    <Form.Control as="select" name="mission_event_config" value={item.mission_event_config} onChange={handleChange}>
                        <option value="">Select a mission type</option>
                        {configData && configData.mission_event_configs.map((config) => (
                            <option key={config.id} value={config.id}>
                                {config.display_name} ({config.name})
                            </option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Start Date (Local Time)
                </Col>
                <Col className="col-md-9">
                    <DateTimePicker
                        name="startdate"
                        value={typeof item.startdate === "string" ? new Date(item.startdate) : item.startdate}
                        minDate={loadedDate} onChange={date => handleDateChange("startdate", date)}
                        disableClock
                        clearIcon={null}
                        className="display-block date-picker start-date-picker"
                        maxDetail="minute" />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    End Date (Local Time)
                </Col>
                <Col className="col-md-9">
                    <DateTimePicker
                        name="enddate"
                        value={typeof item.enddate === "string" ? new Date(item.enddate) : item.enddate}
                        minDate={loadedDate} onChange={date => handleDateChange("enddate", date)}
                        disableClock
                        clearIcon={null}
                        className="display-block date-picker start-date-picker"
                        maxDetail="minute" />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Helicopter
                </Col>
                <Col className="col-md-9">
                    <Form.Control as="select" name="option" value={item.option} onChange={handleChange}>
                        <option value={undefined}>Select a chopper</option>
                        <option value="0">Left</option>
                        <option value="1">Right</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                </Col>
                <Col className="col-md-9 evenly-spaced-children" >
                    {isDirty &&
                        <>
                        <button onClick={e => save(e, item)} className="btn btn-primary">Save</button>
                        <button onClick={cancel} className="btn btn-secondary">Cancel</button>
                        </>}
                    {!isDirty &&
                        <button onClick={goBack} className="btn btn-secondary">Back</button>}
                    {item.id &&
                        <button onClick={e => remove(e, item)} className="btn btn-danger">Delete</button>}
                </Col>
            </Row>
            {
                err && <Row className="pt-2">
                    <Col>
                        <Alert variant="danger">{err}</Alert>
                    </Col>
                </Row>
            }
            {
                success && !err && <Row className="pt-2">
                    <Col>
                        <Alert variant="success">{success}</Alert>
                    </Col>
                </Row>
            }
        </Form>}
        {
            props.emptyMessage && !item && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

export function AdminMissionEventPage(props: {}) {

    const history = useHistory();
    const match = matchPath<IdMatchParams>(history.location.pathname, {
        path: '/admin/childproducts/:childproductid/missionevent/:id?',
        exact: false
    })
    const child_product = match?.params.childproductid;
    const id = match?.params.id;

    return (
        <BasicPage title="Mission Event">
            <MissionEvent query={{child_product: child_product, id: id}} emptyMessage="Event not found"/>
        </BasicPage>
    )    
}

export default AdminMissionEventPage;