import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';

import { LineChart, Line, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import api from '../../utils/api';
import { Col, Container, Row } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import { getBlobUrl } from '../../config/api';

type TBaseChartData = {
    timeslice: number;
    count: number;
    total: number;
    rollingAverage: number;
    [id: string]: any;
}

type TChartComponentProps = {
    apiquery: string,
    unit: string,
    processdata?: (x:TBaseChartData)=>void;
}
function ChartComponent(props: TChartComponentProps) {

    const [data, setData] = useState<TBaseChartData[]>([])
    const { unit } = props;

    //tick formatter returns a date every 24 hours and a time every 6 hours
    const tickFormatter = (value: any, index: number) => {
        const timestamp: number = value;
        const date = new Date(timestamp);

        const hour = date.getHours();
        if( hour == 0 )
        {
            console.log("Tick")
            console.log(date)
            return date.toLocaleDateString().substring(0,5);         
        }   
        else
        {
            return "";
        }
    }

    //tooltip value formatter adds the unit
    const tooltipFormatter = (value: any, name: string, props: any) => {
        return `${Math.round(value*100)/100}${unit}`
    }

    //tooltip label converts time stamp to date/time string
    const labelFormatter = (label: any, payload: any) => {
        return new Date(label).toLocaleString()
    }

    //query for data and fill in gaps/timestamps
    const loadData = async() => {
        const rsp = await api.tryGet<any>(props.apiquery)
        const data: TBaseChartData[] = rsp.results;
        let filleddata: TBaseChartData[] = []
        let lastidx = data[0].timeslice;
        data.forEach(x => {
            while(lastidx < x.timeslice) {
                filleddata.push({
                    timeslice: lastidx,
                    count: 0,
                    total: 0,
                    rollingAverage: 0
                })
                lastidx++;
            }
            filleddata.push(x);       
            lastidx = x.timeslice+1;
        })
        filleddata.forEach(x => {
            x.timestamp = x.timeslice * rsp.timeslice_length
            if(props.processdata) {
                props.processdata(x);
            }
        })

        filleddata.forEach((x,idx) => {
            let avsum = 0;
            let avweight = 0;
            for(let offset = -5; offset <= 5; offset++) {
                let offset_idx = idx + offset;
                if(offset_idx >= 0 && offset_idx < filleddata.length) {
                    const weight = 1 / (Math.abs(offset)+1);
                    avsum += weight*filleddata[offset_idx].total;
                    avweight += weight;
                }
            }
            x.rollingAverage = avsum / avweight
        })
        

        //console.log(filleddata);
        return filleddata;
    }

    //refreshes chart data
    useEffect(() => {
        loadData()
        .then(x => { console.log(x); setData(x) })
        .catch(err => console.log(err))
    }, [props.apiquery])

    return(
        <ResponsiveContainer width="99%" height={400}>
            <LineChart  data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <Line dataKey="total" stroke="#8884d8" strokeWidth="2" dot={false} isAnimationActive={false}/>
                <Line type="monotone" dataKey="rollingAverage" stroke="#d88884"  strokeWidth="2" dot={false} isAnimationActive={false}/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis dataKey="timestamp" tickFormatter={tickFormatter} minTickGap={-100} interval={23} style={{
                    fontSize: '0.5rem'
                }}/>
                <YAxis width={40} unit={props.unit} style={{
                    fontSize: '0.5rem'
                }} />
                <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter}/>
            </LineChart>
            </ResponsiveContainer> 
        )
}


type TSpenderInfo = {
    _id: string,
    total: number,
    count: number,
    user: MultiverseUser
}

type TSpendersComponentProps = {
    from: number;
    to: number;
}
function SpendersComponent(props: TSpendersComponentProps) {
    const [data, setData] = useState<TSpenderInfo[]>([])

    //query for data and fill in gaps/timestamps
    const loadData = async() => {
        const rsp = await api.tryGet<any>(`/v2/admin/stats/biggestspenders?devtransactions=true&from=${props.from}&to=${props.to}`)
        return rsp.results;
    }

    //refreshes chart data
    useEffect(() => {
        loadData()
        .then(x => { console.log(x); setData(x) })
        .catch(err => console.log(err))
    }, [])

    return <PageTable>
    <thead>
        <th></th>
        <th>Nick Name</th>
        <th>First Login</th>
        <th>Last Online</th>
        <th>Total Spent</th>
        <th>Num Purchases</th>
        <th>Av Purchase</th>
    </thead>
    <tbody>
        {data.filter(x => x.user).map((x) => (                                 
            <PageTable.Row key={x._id}>
                <PageTable.IconCell src={`${getBlobUrl()}/${x.user.iconblob}`} roundedCircle/>
                <PageTable.Cell>{x.user.nickname}</PageTable.Cell>
                <PageTable.Cell>{new Date(x.user.created).toLocaleDateString()}</PageTable.Cell>
                <PageTable.Cell>{new Date(x.user.timestamp).toLocaleDateString()}</PageTable.Cell>
                <PageTable.Cell>${(x.total-1)/100}</PageTable.Cell>
                <PageTable.Cell>{x.count}</PageTable.Cell>
                <PageTable.Cell>${((x.total-1)/(x.count * 100)).toFixed(2)}</PageTable.Cell>
            </PageTable.Row>))}
        </tbody>
    </PageTable>
}

type StatsPageProps = {

} & WithMultiverseApiProps;

type StatsPageState = {
};

class _StatsPage extends React.Component<StatsPageProps, StatsPageState> {
    constructor(props: StatsPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {

        const from = new Date(Date.now()-7*24*60*60*1000)
        from.setHours(0)
        from.setMinutes(0)
        from.setSeconds(0)
        from.setMilliseconds(0)
        console.log("From")
        console.log(from)
        const totals_query = `/v2/admin/stats/inventorytransactions/totals?from=${from.getTime()}&devtransactions=true&timeslice=${60*60*1000}`
        const infiniverse_buy_query = `/v2/admin/stats/infiniversetransactions/totals?from=${from.getTime()}&type=buy_slot&timeslice=${60*60*1000}`
        const infiniverse_sell_query = `/v2/admin/stats/infiniversetransactions/totals?from=${from.getTime()}&type=sell_slot&timeslice=${60*60*1000}`

        const mc_to_dollar = (x: TBaseChartData) => {
            if(x.count > 0) {
                x.total = (x.total - 1) / 100;
            }
        }
        const get_counts = (x: TBaseChartData) => {
            if(x.count > 0) {
                x.total = x.count;
            }
        }
        const get_means = (x: TBaseChartData) => {
            if(x.count > 0) {
                x.total = (x.total - 1) / (100 * x.count);
            }
        }
        const negate = (x: TBaseChartData) => {
            x.total = -x.total;
        }

        const now = Date.now();

        const can_see_graphs = this.props.multiverse?.user?.permissions.includes("admin_stats_graphs") || false;
        const can_see_spenders = this.props.multiverse?.user?.permissions.includes("admin_stats_spenders") || false;

        return (
            <>
                {can_see_graphs && <Container fluid>
                    <Row>
                        <Col className="text-center" xs={12} lg={6}>
                            Total Revenue / Hour
                            <ChartComponent 
                            apiquery={totals_query}
                            unit="$"
                            processdata={mc_to_dollar}
                            />
                        </Col>
                        <Col className="text-center" xs={12} lg={6}>
                            Total Transactions / Hour
                            <ChartComponent 
                            apiquery={totals_query}
                            unit=""
                            processdata={get_counts}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" xs={12} lg={6}>
                            Mean Transaction / Hour
                            <ChartComponent 
                            apiquery={totals_query}
                            unit="$"
                            processdata={get_means}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" xs={12} lg={6}>
                            Infinivese Metacoin Spend / Hour
                            <ChartComponent 
                            apiquery={infiniverse_buy_query}
                            unit="MC"
                            processdata={negate}
                            />
                        </Col>
                        <Col className="text-center" xs={12} lg={6}>
                            Infiniverse Purchase Count / Hour
                            <ChartComponent 
                            apiquery={infiniverse_buy_query}
                            unit=""
                            processdata={get_counts}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" xs={12} lg={6}>
                            Infinivese Metacoin Recoup / Hour
                            <ChartComponent 
                            apiquery={infiniverse_sell_query}
                            unit="MC"
                            />
                        </Col>
                        <Col className="text-center" xs={12} lg={6}>
                            Infiniverse Sale Count / Hour
                            <ChartComponent 
                            apiquery={infiniverse_sell_query}
                            unit=""
                            processdata={get_counts}
                            />
                        </Col>
                    </Row>     
                </Container>}
                {can_see_spenders && <Container fluid>           
                    <Row>
                        <Col className="text-center">
                            Big Spenders Past Day
                            <SpendersComponent from={now-24*60*60*1000} to={now}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Big Spenders Past 7 Days
                            <SpendersComponent from={now-7*24*60*60*1000} to={now}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Big Spenders Past 28 Days
                            <SpendersComponent from={now-28*24*60*60*1000} to={now}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            Big Spenders Past Year
                            <SpendersComponent from={now-365*24*60*60*1000} to={now}/>
                        </Col>
                    </Row>
                </Container>}
            </>
        )
    }
}
export const StatsPage = withMultiverseApi(_StatsPage);

export default StatsPage;
