import React, { FunctionComponent } from 'react';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

type IsAuthenticatedProps = WithMultiverseApiProps;
const _IsAuthenticated:FunctionComponent<IsAuthenticatedProps> = (props) => {
  const { multiverse: { isAuthenticated, isLoading }, children } = props;
  return (isAuthenticated && !isLoading) ? (
    <>
      {children}
    </>
  ) : null;
};

export const IsAuthenticated = withMultiverseApi(_IsAuthenticated);

// Apply the HOC to the IsAuthenticated Component
// This results in a component that has no props
export default IsAuthenticated;
