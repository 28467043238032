import React, { CSSProperties } from 'react';
import { Alert, Button, Row, Col, Image, Container } from 'react-bootstrap';
import { MultiverseEvent, isLockedEvent } from '../../hoc/multiverseApiProvider';
import { useEntity,  postEntity, useDomainEntity } from '../../utils/entitycache';

function BarWithButton(props: {text: string, buttonText?: string, onClick?: ()=>void}) {
    return <Container fluid>
        <Row className="align-items-center">
            <Col>{props.text}</Col>
            {props.buttonText && <Col xs="auto"><Button variant="outline-info" onClick={props.onClick}>{props.buttonText}</Button></Col>}
        </Row>
    </Container>
}

const ERROR_STYLE: CSSProperties = {
    borderStyle:"solid",
    borderColor:"red",
    borderWidth:5
}

export function PublishBar(props: { targeturl: string, requirePublished?: boolean }) {
    const event = useEntity<MultiverseEvent>(props.targeturl)
    const domain = useDomainEntity(props.targeturl)
    if(!event || !domain) {
        return <></>
    }

    const onTogglePublishedClicked = async() => {
        postEntity(props.targeturl, {
            published: !event.published
        })
    }

    if(domain.domainaccess !== "public") {
        return <Row className="mt-2">
            <Col>
                <Alert className="mb-0" variant="error"><BarWithButton text="For your event to be accesible by other players your Metaverse must be set to public in the Manage section"/></Alert>
            </Col>
        </Row>        
    }

    const locked = isLockedEvent(event)

    return <> 
    {event && !locked && <Row className="mt-2">
        <Col>
            {!event.published && <Alert style={props.requirePublished && ERROR_STYLE || undefined} className="m-0" variant="warning"><BarWithButton text="This event is currently unpublished, so is not visible to players in Multiverse" buttonText="Publish" onClick={onTogglePublishedClicked}/></Alert>}
            {event.published && <Alert className="m-0" variant="info"><BarWithButton text="This event has been published and is visible to players in Multiverse" buttonText="Unpublish" onClick={onTogglePublishedClicked}/></Alert>}
        </Col>
    </Row>}
    </>
}
