import React, { FunctionComponent } from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, FormControl, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import PageTable from '../../components/pageTable';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseLocation, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { getIconSrc } from '../../utils/entitycache';

type LocationsPageProps = WithMultiverseApiProps;
type LocationsPageState = {
    isLoading: boolean;
    hasError: boolean;
    locations: MultiverseLocation[];
};

const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

class LocationsPage extends React.Component<LocationsPageProps, LocationsPageState> {
    constructor(props: LocationsPageProps) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            locations: []
        };
    }

    async componentDidMount(): Promise<void> {
        await this.refreshList();
    }

    refreshList = async () => {
        try {
            //show loading spinner
            this.setState({ isLoading: true });

            //load locations
            //for now apply explicit filter for none-legacy locations
            const { multiverse: { get, getCurrentDomainId } } = this.props;
            let locations = await get<MultiverseLocation[]>(`/v2/domains/${getCurrentDomainId()}/locations`);
            locations = locations.filter(a => a.type === "location");

            //store locations
            this.setState({ locations });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    renderLoadingSpinner() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    renderNoLocations() {
        return (
            <>
                <Row>
                    <Col className="text-center pb-3">
                        This domain currently contains no locations.
                    </Col>
                </Row>
            </>
        );
    }

    getLocationUri = (x: MultiverseLocation) => {
        const { multiverse: { getCurrentDomainId } } = this.props;

        //calculate path to location accounting for legacy types
        let domain_path = "";
        if(x.type === "location") {
            console.log(x.path);
            const domain_match = x.path.match(/^.*?\/locations\/(.*)$/);
            console.log(domain_match);
            if(!domain_match) {
                return "error";
            }
            domain_path = domain_match[1];            
        } else {
            domain_path = x.name.replace(/ /g, "_").toLocaleLowerCase();
        }

        return `${getCurrentDomainId()}/locations/${domain_path}`
    }

    onDeleteDomainClick = (domain: MultiverseLocation) => {
        /*this.setState({
            showDeleteDialog: true,
            domainToDelete: domain,
            deleteDomainValidationText: ""
        })*/
    }

    onManageDomainClick = async (domain: MultiverseLocation) => {
        /*const { multiverse: { openDomainPath } } = this.props;
        await openDomainPath(domain.id, "/manage");*/
    }

    canDelete = (x: MultiverseLocation): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return x.owner == user.id;
    }

    canManage = (x: MultiverseLocation): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return x.owner == user.id;
    }

    canDoAnything = (x: MultiverseLocation): boolean => {
        return this.canDelete(x) || this.canManage(x);
    }

    renderLocations = () => {
        const { domain } = this.props;

        return (
            <>
                <Row className="pt-2">
                    <Col>
                        <PageTable>
                            <tbody>
                                {this.state.locations.map((x) => {
                                    return (
                                        <PageTable.Row key={x.id}>
                                            <PageTable.IconCell src={getIconSrc(x)} roundedCircle/> 
                                            <PageTable.InfoCell title={x.nickname} subtitle={<Link to={x.path}>{x.path.substr(9)}</Link>}/>
                                            <PageTable.DropDownCell disabled={!this.canDoAnything(x)}>
                                                {this.canManage(x) && (<Dropdown.Item onClick={() => this.onManageDomainClick(x)}>Manage</Dropdown.Item>)}
                                                {this.canDelete(x) && (<Dropdown.Item onClick={() => this.onDeleteDomainClick(x)}>Delete</Dropdown.Item>)}
                                            </PageTable.DropDownCell>
                                        </PageTable.Row>
                                    )
                                })}
                            </tbody>
                        </PageTable>

                    </Col>
                </Row>
            </>
        )
    }

    renderPage() {
        if (this.state.isLoading)
            return this.renderLoadingSpinner();
        else if (!this.state.locations) {
            return (<pre>error</pre>)
        }
        else if (this.state.locations.length > 0)
            return this.renderLocations();
        else
            return this.renderNoLocations();
    }

    render() {
        return (
            <>
                <BasicPage title="Locations">
                    {this.renderPage()}
                </BasicPage>
            </>);
    }
}

export default withMultiverseApi(LocationsPage);
