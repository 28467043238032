import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, FormControlProps, Modal, Row, Spinner, Image } from 'react-bootstrap';
import { MultiversePlan, MultiversePlanTemplate } from '../../hoc/multiverseApiProvider';
import api from '../../utils/api';
import DialogBox from '../dialogBox';

import './templateselectdialog.css'


const stripPlanType = (plan: string) => {
    const idx = plan.indexOf('-')
    return idx >= 0 ? plan.substr(0,idx) : plan;
}
export const getTemplateIconUrl = (template: string) => {
    const match = template.match(/^.*\/locations\/(.*)/)
    if(!match) {
        return ""
    }
    return `https://devblobs.shapevrcloud.com/templateicons/${match[1]}.jpg`
}
const plan2int = (plan?: string) => {
    if(!plan) {
        return 0;
    }
    plan = stripPlanType(plan)
    switch(plan) {
        case "free":
            return 0;
        case "premium":
            return 1;
        case "professional":
            return 2;
        case "business":
            return 3;
        case "enterprise":
            return 4;
        default:
            return 0;
    }
}

export type TTemplate = {
    plan: string,
    icon: string
} & MultiversePlanTemplate

function TemplateSelectGridItem(props: { disabled?: boolean, template: TTemplate, isselected?: boolean, onClick?: ()=>void }) {

    let cn = "template-select-item square"
    if(props.isselected) {
        cn += " tsi-selected"
    }
    if(props.disabled) {
        cn += " tsi-disabled"
    }

    return (
    <div className={cn} onClick={props.disabled ? undefined : props.onClick}>
        <div className="square_content">
            <div className="tsi-content">
                <div className = "tsi-image">
                    <Image src={props.template.icon}/>
                    <Image src={`https://devblobs.shapevrcloud.com/templateicons/ti-${props.template.plan}.png`}/>
                </div>
                <div className = "tsi-text">
                    {props.template.name}
                </div>
            </div>
        </div>
    </div>
    )
}

export type TTemplateSelectGridProps = { 
    plan?: string, 
    selected?: TTemplate, 
    variantsOf?: string | null,
    onSelect?: (s: TTemplate)=>void 
}
export function TemplateSelectGrid(props:TTemplateSelectGridProps) {
    const [templates, setTemplates] = useState<TTemplate[]>([])
    
    const loadPlans = async() => {
        const template: any = {}
        const plans = await api.get<{plans:MultiversePlan[]}>(`/v2/domains/getwebcreateplans`)
        let filter = ""
        if(props.variantsOf) {
            let base_name: string;
            const us_idx = props.variantsOf.indexOf("_");
            if(us_idx >= 0) {
                base_name = props.variantsOf.substr(0, us_idx)
            } else {
                base_name = props.variantsOf
            }

            //handle old badly named templates!
            if(base_name.endsWith('brick')) base_name = base_name.substr(0,base_name.length-5)
            if(base_name.endsWith('wood')) base_name = base_name.substr(0,base_name.length-4)

            filter = base_name    
        }
        plans.plans.forEach(x => {
            x.templates.filter(x => x.template.startsWith(filter)).forEach(y => {
                if(!template[y.template]) {
                    template[y.template] = {
                        ...y,
                        plan: stripPlanType(x.type),
                        icon: getTemplateIconUrl(y.template)
                    }
                }
            })
        })   

        console.log(template)
        setTemplates(Object.values(template))
    }

    useEffect(() => {
        loadPlans()
    }, [])

    const onItemClick = (item: TTemplate) => {
        if(props.onSelect) {
            props.onSelect(item)
        }
    }

    const intplan = props.plan ? plan2int(props.plan): 100;

    return (
        <>
        {templates.length == 0 && <Container fluid>
            <Row className="justify-content-center">
                <Col xs="auto">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>}
    <div className="template-select-grid">
        {templates.map(x => (
        <TemplateSelectGridItem 
            key={x.template} 
            template={x} 
            isselected={props.selected && props.selected.template === x.template}
            disabled={intplan<plan2int(x.plan)}
            onClick={()=>onItemClick(x)}
            />
        ))}

    </div>
    </>
    )
}

export function TemplateSelectDialog(props: TTemplateSelectGridProps & { 
    show?: boolean, 
    onConfirm?:(t: TTemplate)=>void,
    onCancel?:()=>void,
    onUpgrade?:()=>void }) {

    const [selected,setSelected] = useState<TTemplate | undefined>(undefined)

    const onConfirm = (button: 0 | 1 | undefined) => {
        if(button === 0) {
            if(props.onConfirm) {
                props.onConfirm(selected!)
            }
        } else {
            if(props.onUpgrade) {
                props.onUpgrade()
            }
        }
    }

    const canUpgrade = () => {
        if(!props.onUpgrade) {
            return false;
        }
        if(!props.plan) {
            return false;
        }
        if(plan2int(props.plan) >= 4) {
            return false;
        }
        return true;
    }


    return (
    <DialogBox 
        title="Templates" 
        show={props.show}
        button0Text="Ok"
        button1Text={canUpgrade() ? "Upgrade" : undefined}
        cancelButtonText='Cancel'
        closeButton
        onConfirm={onConfirm}
        onCancel={props.onCancel}
        disableConfirm0={selected === undefined}
        >
        <TemplateSelectGrid {...props} selected={selected} onSelect={(t)=>setSelected(t)} />
    </DialogBox>
    )
}