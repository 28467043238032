import React from "react"
import { Modal, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import ImageDropper from "./imagedropper";
import ValidatedField from "../../components/validatedField";
type CreateAdvertModalProps = {
    show: boolean;
    onHide: () => void;
    isLoading: boolean;
    onCreateAdvert: (name: string, image: File) => void;
}

export default function CreateAdvertModal(props: CreateAdvertModalProps): JSX.Element {
    const [value, setValue] = React.useState<File | null>(null)
    const [isImageValid, setIsValid] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>("")

    return <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Create Infiniverse Advertisement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4 style={{textAlign:"center"}} className="mt-1">Upload your billboard image!</h4>
            <ImageDropper
                value={value}
                onChange={(file, isValid)=>{
                    setValue(file)
                    setIsValid(isValid)
                }}
                disabled={props.isLoading}
                aspectRatio="1920 / 1080"
                aspectRatioWarning={<><strong>Warning:</strong> Images which are not 1920:1080 will be cropped</>}
                maxBytes={1000000}
                maxBytesError={<><strong>Error:</strong> Max file size exceeded</>}
            />
            { props.isLoading && <Container fluid className="center">
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>}
            <h4 style={{textAlign:"center"}} className="mt-4">Name your advertisment!</h4>
            <ValidatedField 
                value={name}
                disabled={props.isLoading}
                onChange={name => setName(name)}
                showErrorWhenEmpty
            />

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                Cancel
            </Button>
            <Button variant="primary" disabled={!isImageValid || value === null || name === "" || props.isLoading} onClick={()=>props.onCreateAdvert(name, value!)}>
                Create
            </Button>
        </Modal.Footer>
    </Modal>
}