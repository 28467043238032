import React, { useState } from "react"
import { useEffect } from "react"
import { Alert } from "react-bootstrap"


export function CopyrightNotice(props: { targeturl: string }) {

    const [showCopyright, setShowCopyright] = useState(false)

    useEffect(() => {
        setShowCopyright(localStorage.getItem(`.showcopyright.${props.targeturl}`) == null);
    }, [])

    const onCloseCopyright = () => {
        localStorage.setItem(`.showcopyright.${props.targeturl}`,"done")
        setShowCopyright(false)
    }

    return <Alert className="mt-2 mb-0" variant="info" dismissible show={showCopyright} onClose={() => onCloseCopyright()}>
        Please note that uploading of copyrighted material without permission is illegal, and may result in your Metaverse being removed. 
        Always ensure you have permission to upload content and that it meets all guidelines in our terms and conditions.
    </Alert>
}