import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseFile, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import api, { useGet } from '../../utils/api';
import "./restapi.css"
import { getBlobUrl } from '../../config/api';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

type TAutomatedTestPerfRecord = {
    name: string;
    chart: number;
    values: number[];
}
type TAutomatedTestPerfResult = {
    platform : string;
    build : string;
    location : string;
    uri: string;
    position : string;
    ffrlevel : number;
    per_frame_records: TAutomatedTestPerfRecord[];
    timestamp:Date;
    [key: string]: any;
}

const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#8A2BE2", "#FF7F50", "#E9967A"]

function ATGRow(props: TAutomatedTestPerfResult) {

    const num_points = props.per_frame_records[0].values.length;

    //create array of empty objects of size num_points
    const chart_points: any[][] = [Array(num_points).fill(undefined).map(() => ({})) ,Array(num_points).fill(undefined).map(() => ({}))]

    //fill in the points array with the data
    props.per_frame_records.forEach((x) => {
        x.values.forEach((y, i) => {
            if(x.chart && x.chart > 1) return
            chart_points[x.chart||0][i][x.name] = y
        })
    })

    return (
        <>
        <Row>
            <Col>{props.build} / {props.timestamp}</Col>
        </Row>
        <Row>
                {
                    chart_points.map((x,i) => 
                        <Col>

                                <LineChart  width={1000} height={500} data={x}>
                                    { Object.keys(x[0]).map((name,li) => <Line name={name} dataKey={name} stroke={colors[li%colors.length]} strokeWidth="2" dot={false} isAnimationActive={false}/>) }
                                    <Tooltip />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis/>
                                    <YAxis type="number" domain={[0, i == 0 ? 60 : 10]} allowDataOverflow={true}/>
                                    <Legend layout="vertical" verticalAlign="middle" align="right" />
                                </LineChart>

                        </Col>
                    )
                }
        </Row>
        </>
    )  
}

export function AutoTestGroupRows(props: {data: any}) {
    const results = useGet<TAutomatedTestPerfResult[]>(`/v2/autotest/tests?user=${props.data.user}&platform=${props.data.platform}&location=${props.data.location}&position=${props.data.position}`)  
    return (<>
        <Row><Col><h3>{results? results[0].uri : ""}</h3></Col></Row>
        <Row><Col><h4>{props.data.platform} / {props.data.position}</h4></Col></Row>
        {
            results && results.map((x) => <ATGRow {...x}/>)
        }
    </>) 
}

type TAutomatedTestGetHistoryResponse = {
    [id:string]: TATHPosition
}
type TATHPosition = {
    position: string,
    totals: TATHPositionTest[]
}
type TATHPositionTest = {
    timestamp: Date,
    build: string,
    totals: { [id:string]: TATHPositionResult}
}
type TATHPositionResult = {
    min: number,
    max: number,
    average: number,
    median: number 
}

function ATHValueCell(props: { test?: TATHPositionTest, valueName: string, good?: number, bad?: number}) {
    if (!props.test) return <PageTableCell></PageTableCell>

    const value = props.test.totals[props.valueName]
    if (!value) return <PageTableCell></PageTableCell>

    const good = props.good || 28
    const bad = props.bad || 28
    
    //choose pale green, pale orange or pale red based on the median
    let color = "#aaffaa"
    if (value.median > good) color = "#ffbb77"
    if (value.median > bad) color = "#ffaaaa"

    return <PageTableCell style={{background:color}}>{value.average.toFixed(2)} ({value.max.toFixed(2)})</PageTableCell>

}


function ATHPositionRow(props: {data: TATHPosition}) {

    return <Container fluid>
        <Row><Col><strong>{props.data.position}</strong></Col></Row>
        <Row><Col>
        <PageTable>
            <thead>
                <PageTableRow>
                    <th style={{width:100}}>Build</th>
                    <th style={{width:100}}>Time</th>
                    <th style={{width:100}}>GPU (ms)</th>
                    <th style={{width:100}}>Update (ms)</th>
                    <th style={{width:100}}>Render (ms)</th>
                    <th style={{width:100}}>Frame (ms)</th>
                    <th style={{width:100}}>Lights</th>
                    <th style={{width:100}}>Vertices</th>
                </PageTableRow>
            </thead>
            <tbody>
                {props.data.totals.map((x:any) => <PageTableRow>
                    <PageTableCell>{x.build}</PageTableCell>
                    <PageTableCell>{x.timestamp}</PageTableCell>
                    <ATHValueCell test={x} valueName="GPU" good={15} bad={17}/>
                    <ATHValueCell test={x} valueName="All Updates" good={6} bad={8}/>
                    <ATHValueCell test={x} valueName="End Of Frame" good={10} bad={11}/>
                    <ATHValueCell test={x} valueName="Frame" good={28.5} bad={28.5}/>
                    <ATHValueCell test={x} valueName="All Lights" good={10} bad={20}/>
                    <ATHValueCell test={x} valueName="Vertices" good={75000} bad={100000}/>
                </PageTableRow>)}
            </tbody>
        </PageTable>
        </Col></Row>
    </Container>
}

export function AutoTestTotalsRow(props: {data: any, statsuser?: string|null}) {
    const statsuser = props.statsuser || "6542948f734384a9fe69b168";
    const results = useGet<TAutomatedTestGetHistoryResponse>(props.data.id && `/v2/autotest/historicalstats?user=${statsuser}&platform=android&location=${props.data.id}`)
    console.log(results)

    return (
        <Row>
            <Col className="restapi-documentfields-col">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Android Perf Test Stats
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" className="restapi-documents-col">                       
                            <>
                                {results && Object.keys(results).map((x) => <ATHPositionRow data={results[x]}/>)}  
                            </>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>)    
}