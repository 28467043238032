import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const PageNotFound: FunctionComponent = () => (
  <div>
    <h2>Page not found</h2>
    <Link to="/">Return to home page</Link>
  </div>
);
export default PageNotFound;
