import { Modal, ModalBody, ModalFooter, Button } from "react-bootstrap"
import React from "react";
import ModalHeader from "react-bootstrap/ModalHeader";

type SimpleModalProps = {
    text: null|string;
    header?: string;
    onHide: ()=>void;
}

function SimpleModal (props:SimpleModalProps): JSX.Element {
    return <Modal show={!!props.text} onHide={props.onHide}>
        {props.header && <ModalHeader>
            {props.header}
        </ModalHeader>}
        <ModalBody>
            {props.text}
        </ModalBody>
        <ModalFooter>
            <Button variant="primary" onClick={()=>props.onHide()}>
                    OK
            </Button>
        </ModalFooter>
    </Modal>
}

export default SimpleModal;