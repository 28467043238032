import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../basicPage';
import { Accordion, Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { GiFireShield } from 'react-icons/gi';
import "./restapi.css"

type RestApiFieldType = "objectid" | "string" | "number" | "date" | "object" | "boolean";

export type RestApiFieldDefinition = {
    type?: RestApiFieldType
    required?: boolean,
    visible?: boolean,
    search?: string
}
export type RestApiDocumentDefinition = {
    [key: string]: RestApiFieldDefinition | RestApiFieldType;
}
export type RestApiSpecResponse = {
    type: string;
    document: RestApiDocumentDefinition;
}

export type RestApiDocumentData = {
    [key: string]: any;
}

type RestApiCollectionProps = {
    uri: string;
    fields: { [key:string]: RestApiFieldDefinition };
} & WithMultiverseApiProps & RouteComponentProps;

type RestApiCollectionState = {
    documents: RestApiDocumentData[];
    sort: string;
    first: number;
    count: number;
    total: number;
    order: number;
    queryText: string;
    fields: { [key:string]: RestApiFieldDefinition };
    lastSearch: string;
};

class _RestApiCollection extends React.Component<RestApiCollectionProps, RestApiCollectionState> {
    constructor(props: RestApiCollectionProps) {
        super(props);
        this.state = {
            documents: [],
            fields: {...props.fields},
            sort: "timestamp",
            first: 0,
            count: 30,
            total: 0,
            queryText: "",
            lastSearch: "$notsearched",
            order: -1
        };
    }

    componentDidMount = () => {
        this.readUrlArgs();
    }
    componentDidUpdate = () => {
        this.readUrlArgs();
    }

    //checks for updated search uri and parses/acts on it if changed
    readUrlArgs = async() => {
        if(this.state.lastSearch === document.location.search) {
            return;
        }
        const url = new URL(document.location.href);
        console.log("Reading url args");
        console.log(url);
       
        //read basic params
        const sort = url.searchParams.get("sort") || "timestamp";
        const first = parseInt(url.searchParams.get("first") || "0");
        const count = parseInt(url.searchParams.get("count") || "30");
        const order = parseInt(url.searchParams.get("order") || "-1");
        let query_str = url.searchParams.get("query") || undefined;

        //take copy of fields in state
        const fields = { ...this.state.fields };

        //request and merge field definitions from the server with those provided to the component
        const spec = await this.props.multiverse.get<RestApiSpecResponse>(`${this.props.uri}/adminspec`);
        Object.keys(spec.document).forEach( (field_name) => {
            let spec_field = spec.document[field_name];
            if(typeof(spec_field) === 'string') {
                spec_field = { type: spec_field };
            }           
            let curr_field = fields[field_name];
            if(!curr_field) {
                fields[field_name] = spec_field;
            }
        });

        //check for arg that specifies required fields to override defaults
        {
            const req_text = url.searchParams.get("required");
            if(req_text) {
                Object.keys(fields).forEach(field_name => {
                    fields[field_name].required = false;
                });            
                req_text.split(",").forEach(x => {
                    if(fields[x]) {
                        fields[x].required = true;
                    }
                })
            }
        }

        //check for arg that specifies search fields to override defaults
        {
            const search_text = url.searchParams.get("search");
            if(search_text) {
                Object.keys(fields).forEach(field_name => {
                    fields[field_name].search = undefined;
                });            
                search_text.split(",").forEach(x => {
                    if(x.length > 0)
                    {
                        const [name,val] = x.split(":");
                        fields[name].search = val;
                    }
                })
            }
        }

        //check for arg that specifies visible fields to override defaults
        {
            const req_text = url.searchParams.get("visible");
            if(req_text) {
                Object.keys(fields).forEach(field_name => {
                    fields[field_name].visible = false;
                });            
                req_text.split(",").forEach(x => {
                    if(fields[x]) {
                        fields[x].visible = true;
                    }
                })
            }
        }

        //if query supplied format at JS object then evaluate using eval
        let queryText = "";
        if(query_str && query_str.length > 0) {
            try {
                queryText = decodeURIComponent(query_str);
            } catch(err) {
                console.log(err);
            }
        }

        //store updated state then reload
        this.setState({
            lastSearch: document.location.search,
            sort,
            first,
            count,
            order,
            fields,
            queryText
        }, () => { this.reload(); })

    }

    //attempts to generate mongo db query from the query text
    evalQuery = () => {
        let { queryText } = this.state;

        if(queryText && queryText.length > 0) {

            //first attempt to evaluate as a JS object, and if valid, assume text is explicit mongo query
            try {
                let qt = queryText;
                if(!qt.startsWith("{")) qt = "{" + qt + "}";
                const evaluated = eval("("+qt+")");
                if(typeof(evaluated) === 'object') {
                    return eval("("+qt+")");
                }
            } catch(err) {

            }   
            
            //failing that, if it's just plain text, generate query that searches for the text in name/nickname/uri fields
            try {
                if(!queryText.match(/[\:\{\}\"\']/)) {
                    const evaluated: any = {
                        $or: [
                            { _id: { $oid: queryText } },
                            { name: { $regex: `.*${queryText}.*`, $options: 'i' } },
                            { nickname: { $regex: `.*${queryText}.*`, $options: 'i' } },
                            { uri: { $regex: `.*${queryText}.*`, $options: 'i' } },
                            { path: { $regex: `.*${queryText}.*`, $options: 'i' } }
                        ]
                    }

                    const postcode = queryText.match(/([NS])(\d+)\-([EW])(\d+)\-(\d+)\-(\d+)/)
                    if(postcode) {
                        const celly = (postcode[1] === 'N' ? 1 : -1) * parseInt(postcode[2]) + 2147483647;
                        const cellx = (postcode[3] === 'E' ? 1 : -1) * parseInt(postcode[4]) + 2147483647;
                        const subdistrictid = parseInt(postcode[5])
                        const buildingid = parseInt(postcode[6])
                        const buildingquery = { cellx, celly, subdistrictid, buildingid }
                        console.log(buildingquery)
                        evaluated.$or.push(buildingquery)
                    }

                    

                    return evaluated;
                }
            } catch(err) {

            }
        }
        return null;
    }

    //returns true if query is valid, false if not, by trying to evaluate it
    validateQuery = () => {
        return this.evalQuery() != null;
    }

    //submits request for updated documents
    reload = async() => {
        const { fields } = this.state;
        let query_args: any[] = [];

        //evaluate the mongo db query specified by the query argument
        const query = this.evalQuery();
        if(query) {
            query_args.push(query);
        }
        
        //generate queries for required/searched fields
        let req_query: any = {};
        let search_query: any = {}
        Object.keys(fields).forEach(field_name => {
            const field = fields[field_name];
            if(field.required) {
                req_query[field_name] = { $ne: null }
            }
            if(field.search && field.search.length > 0) {
                search_query[field_name] = field.search
            }
        })
        if(Object.keys(req_query).length > 0) {
            query_args.push(req_query);
        }
        if(Object.keys(search_query).length > 0) {
            query_args.push(search_query);
        }        

        //generate single mongo db query that is the AND of all the above queries combined
        const query_uri = query_args.length > 0 ? encodeURIComponent(JSON.stringify({
            $and: query_args
        })) : "";

        //submit request and store new documents
        const { total, documents } = await this.props.multiverse.get<{total: number, documents: RestApiDocumentData[]}>(
            `${this.props.uri}?first=${this.state.first}&count=${this.state.count}&sort=${this.state.sort}&order=${this.state.order}&query=${query_uri}`)
        this.setState({
            documents,
            total
        })       
    }

    //renders content of the cell for a field
    renderFieldValue = (doc: RestApiDocumentData, field_name: string) => {
        const field_def = this.state.fields[field_name];
        let field_val = doc[field_name];
        const resolved_field_val = doc["_"+field_name];
        if(resolved_field_val) {
            field_val = `${resolved_field_val} (${field_val})`;
        }
        if(field_val === undefined) {
            return "";
        } else if(field_def.type === "object") {
            return JSON.stringify(field_val);
        } else if(field_def.type === "date") {
            return new Date(field_val).toUTCString();
        } else {
            return `${field_val}`;
        }
    }

    //renders row for a document
    renderDocument = (doc: RestApiDocumentData) => {
        const { fields } = this.state;
        return (<PageTableRow key={doc._id}>
            <PageTableCell>
                <Button variant="primary" onClick={() => {
                    this.props.history.push(`${document.location.pathname}/${doc.id}`);
                }}/>
            </PageTableCell>
            {Object.keys(fields).filter(x => fields[x].visible).map(field_name => 
            {
                return (
                <PageTableCell key={field_name}>
                    {this.renderFieldValue(doc,field_name)}
                </PageTableCell>)
            })}
        </PageTableRow>)
    }

    //renders the name and tick boxes in the fields list
    renderFieldOptions = (field_name: string) => {
        const field = this.state.fields[field_name];
        return <tr key={field_name}>
            <td>{field_name}</td>
            <td><input type="checkbox" checked={field.visible || false} onChange={(x) => this.setFieldVisible(field_name,x.target.checked)}/></td>
            <td><input type="checkbox" checked={field.required || false} onChange={(x) => this.setFieldRequired(field_name,x.target.checked)}/></td>
        </tr>
    }

    //updates field visible setting
    setFieldVisible = (field_name: string, val: boolean) => {
        const { fields } = this.state;      
        fields[field_name].visible = val;

        this.setState({
            fields
        }, this.replaceCurrentUri);
    }

    //builds uri for current page with search params for current state
    getAndInitUri = () => {
        const { fields, first, count, queryText, sort, order } = this.state;      
        const url = new URL(document.location.href);
        url.searchParams.set("required", Object.keys(fields).filter(x => fields[x].required).join(","));
        url.searchParams.set("visible", Object.keys(fields).filter(x => fields[x].visible).join(","));
        url.searchParams.set("first", first.toString());
        url.searchParams.set("count", count.toString());
        url.searchParams.set("order", order.toString());
        url.searchParams.set("query", encodeURIComponent(queryText));
        url.searchParams.set("sort", sort);
        return url;
    }

    //pushes current search args to history so they work with back button
    pushCurrentUriAndReload = () => {
        const new_href = this.getAndInitUri().href;
        if(new_href !== document.location.href) {
            window.history.pushState({}, "", new_href);
        }
        this.reload();        
    }

    //just replaces current uri with new one with correct search args
    //used when setting visibility, which doesn't require a reload and doesn't want
    //to add history changes, but does want to get captured in uri
    replaceCurrentUri = () => {
        const new_href = this.getAndInitUri().href;
        if(new_href !== document.location.href) {
            window.history.replaceState({}, "", new_href);
            this.setState({
                lastSearch: document.location.search
            })
        }     
    }

    //sets new field required and reloads document so url updates (and it works in back button)
    setFieldRequired = (field_name: string, val: boolean) => {
        const { fields } = this.state;      
        fields[field_name].required = val;    

        this.setState({
            first: 0,
            fields
        }, this.pushCurrentUriAndReload);
    }

    setSort = (field_name: string) => {
        if(this.state.sort === field_name) {
            this.setState({
                order: -this.state.order
            }, this.pushCurrentUriAndReload);
        } else {
            this.setState({
                order: 1,
                sort: field_name
            }, this.pushCurrentUriAndReload);
        }
    }

    setQuery = (new_text: string) => {
        this.setState({
            queryText: new_text
        })
    }

    applyQuery = () => {
        this.setState({
            first: 0
        }, this.pushCurrentUriAndReload);
    }

    renderHeader = (field_name: string) => {
        return (
            <th key={field_name}>
                <Button variant="link" onClick={() => this.setSort(field_name)}>
                    {field_name === this.state.sort ? <strong>{field_name}</strong> : <>{field_name}</>}
                    {field_name === this.state.sort && (this.state.order === 1 ? " [a]" : " [d]")}
                </Button>
            </th>
        )
    }

    renderSetFirstButton = (text: string, new_first: number) => {
        return <Button 
            variant="link" 
            disabled={new_first < 0 || new_first >= this.state.total}
            onClick={() => {
                this.setState({
                    first: new_first
                }, this.pushCurrentUriAndReload);            
        }}>{text}</Button>
    }

    render(): JSX.Element {        
        const { fields } = this.state;
        return (
            <>
            <Container className="restapi" fluid>
                <Row className="restapi-search-row">
                    <Col xs="3">Search</Col>
                    <Col>
                    <input 
                        value={this.state.queryText} 
                        spellCheck={false}
                        onChange={(x) => this.setQuery(x.target.value)}
                        onKeyUp={(x) => { if(x.key == "Enter") this.applyQuery(); }}/>
                    </Col>
                    <Col xs="3"><Button disabled={!this.validateQuery()} onClick={this.applyQuery}>Go</Button></Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.first+1} - {this.state.first + this.state.documents.length} / {this.state.total}
                    </Col>
                    <Col className="text-right">
                        {this.renderSetFirstButton("First", 0)}
                        {this.renderSetFirstButton("Prev", Math.max(this.state.first - this.state.count,0))}
                        {this.renderSetFirstButton("Next", this.state.first + this.state.count)}
                    </Col>
                </Row>
                <Row>
                    <Col className="restapi-fields-col" xs="6">
                        <Table style={{tableLayout:"fixed"}}>
                            <tbody>                               
                                <tr>
                                    <th>Field</th>
                                    <th>Viz</th>
                                    <th>Req</th>
                                    {/*<th>Search</th>*/}
                                </tr>
                                {Object.keys(fields).sort().map(field_name => this.renderFieldOptions(field_name))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col className="restapi-documents-col">
                        <PageTable>
                            <tbody>
                                <PageTableRow>
                                    <th></th>
                                    {Object.keys(fields).filter(x => fields[x].visible).map(x => this.renderHeader(x))}
                                </PageTableRow>
                                {this.state.documents.map(x => this.renderDocument(x))}
                            </tbody>
                        </PageTable>
                    </Col>
                </Row>
            </Container>
            </>



        )
    }
}
export const RestApiCollection = withRouter(withMultiverseApi(_RestApiCollection));

export default RestApiCollection;
