import React, { useState } from "react";
import { Accordion, Card, Container, Row, Col, Image, Button } from "react-bootstrap";
import { MultiverseLocation, MultiverseRoom, MultiverseDomain, MultiversePlan } from "../../hoc/multiverseApiProvider";
import { TTemplate, TemplateSelectDialog, getTemplateIconUrl } from "../../components/templateSelectDialog";
import api from "../../utils/api";
import { useEntity, onEntityDirty } from "../../utils/entitycache";
import { useHistory } from "react-router-dom";
import { useRelease } from "./entityHeaderCard";


export function LocationSettingsCard(props: { targeturl: string }) { 
    const domainuri = props.targeturl.substr(0,props.targeturl.indexOf("/"));
    const release = useRelease();
    const releaseuri = `${props.targeturl}/releases/win64_${release}`
    const planuri = `${domainuri}/plan`

    const domain = useEntity<MultiverseDomain>(domainuri)
    const location = useEntity<MultiverseLocation>(props.targeturl)
    const room = useEntity<MultiverseRoom>(releaseuri)
    const template = useEntity<MultiverseLocation>(room?.template)
    const plan = useEntity<MultiversePlan>(`${domainuri}/plan`)
    const history = useHistory();

    const [showTemplateDialog,setShowTemplateDialog] = useState<boolean>(false)    

    const onSetTemplateClicked = () => {
        setShowTemplateDialog(true)
    }
    const onCancelSetTemplate = () => {
        setShowTemplateDialog(false)
    }
    const onConfirmSetTemplate = async(template: TTemplate) => {
        setShowTemplateDialog(false)
        await api.post(`/v2/domains/${location?.domain}/locations/${location?.id}/template`, {
            template: template.template
        })
        onEntityDirty(props.targeturl)
        onEntityDirty(releaseuri)

    }
    const onUpgradeSetTemplate = () => {
        history.push(`/domains/${domainuri}/upgrade`)
    }

    //for now, bodge this to not existing until we work out how to filter properly
    if(!template || template.name.includes("apartment") || template.name.includes("penthouse")) {
        return <></>
    }

    return (<>
        <TemplateSelectDialog 
            show={showTemplateDialog} 
            plan={plan?.type}
            variantsOf={room?.template}
            onConfirm={onConfirmSetTemplate}
            onCancel={onCancelSetTemplate}
            onUpgrade={onUpgradeSetTemplate}/>
        <Accordion defaultActiveKey="0">
            <Card key="settings_header" className="mt-2">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    Settings
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                    <Container>
                        <Row className='mb-4'>
                            <Col>
                                <div><strong>Template</strong></div>
                                <div>{template?.name}</div>
                                <Button onClick={onSetTemplateClicked}>Change</Button>
                            </Col>
                            <Col xs="auto">
                                <Image className='lp-template-image' src={getTemplateIconUrl(room?.template || "")}/>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>)
}
