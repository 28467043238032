import React, { CSSProperties, FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const STYLE:CSSProperties = {
  fontSize: '50%',
};

const Footer:FunctionComponent = () => (
  <footer className="border-top font-weight-lighter" style={STYLE}>

      ©
      {new Date().getFullYear()}
      {' '}
      Future Tech Labs Ltd, a UK registered company with ID #1048590<br></br>
      <Link to="/privacy">Privacy Policy</Link>

  </footer>
);

export default Footer;
