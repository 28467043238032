import React, { CSSProperties } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api, { FetchOptions } from '../../utils/api';

const LOADING_DIV_STYLE: CSSProperties = {
    margin: 0,
    position: "absolute",
    top: "50%",
    width: "100%",
    transform: "translate(0,-50%)",
    textAlign: "center"
}

type LinkAccountsPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type LinkAccountsPageState = {
    isLoading: boolean;
    hasError: boolean;
    showConfirmDialog: boolean;
    linkUserId: string;
    linkSecret: string;
    linkUserName: string;
    linkingActive: boolean;
    showFinishedDialog: boolean;
    finishedTitle: string;
    finishedMessage: string;
};

class LinkAccountsPage extends React.Component<LinkAccountsPageProps, LinkAccountsPageState> {
    constructor(props: LinkAccountsPageProps) {
        super(props);
        this.state = {
            isLoading: false,
            hasError: false,
            showConfirmDialog: false,
            linkUserId: "",
            linkSecret: "",
            linkUserName: "",
            linkingActive: false,
            showFinishedDialog: false,
            finishedTitle: "",
            finishedMessage: ""
        };
    }

    //mounting reads the url and decides if this page should be the pre-link confirm page or the post-link success page
    componentDidMount = async() => {
        
        try {
            //read url
            const url = new URL(window.location.href);

            //first check for a 'finished' paramater
            const finished = url.searchParams.get("finished");
            if(finished==="1") {
                //got finished==="1", indicating we've been directed here after succesful account link / reauthentication, so show success message
                this.setState({
                    showConfirmDialog: false,
                    showFinishedDialog: true,
                    finishedTitle: "Linking Succeeded",
                    finishedMessage: "Account linked! You can now manage and view your Multiverse account using multiverseonline.io."
                })
            }
            else {
                //not finished, so this page should show link confirmation dialog with parameters read from url
                const user = url.searchParams.get("user")
                const secret = url.searchParams.get("secret")
                const name = url.searchParams.get("name") || "unknown";
                if(!user || !secret) {
                    throw new Error("Link accounts page should always get user and secret");
                }
                this.setState({
                    linkUserId: user,
                    linkSecret: secret,
                    linkUserName: name,
                    showConfirmDialog: true
                })
            }
           
        } catch(err) {
            //page has error
            this.setState({hasError: true})
        }

        //finish loading
        this.setState({isLoading: false})
    }

    //prelink confirmation dialog
    renderConfirmDialog() {
        const { multiverse: { user } } = this.props;
        const { linkUserName, linkingActive, showConfirmDialog } = this.state;

        return (
            <Modal show={showConfirmDialog} onHide={this.handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Link Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{visibility:linkingActive ? "hidden" : "unset"}}>
                        <p>Are you sure you want to permanently link this account (<strong>{user?.name}</strong>) with the account <strong>{linkUserName}</strong>?</p>
                        <p>Linking will allow you to manage <strong>{linkUserName}</strong>, modify your profile and view/access Multiverse content all on multiverseonline.io.</p>
                    </div>

                    {linkingActive && <div style={LOADING_DIV_STYLE}>
                        <Spinner animation="border" role="status" />
                    </div>}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleConfirm}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={this.handleCancel}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        ) 
    }

    //post link attempt info dialog (shows message defined by finishedTitle and finishedMessage with an Ok button)
    renderFinishedDialog() {
        const { multiverse: { user } } = this.props;
        const { showFinishedDialog, finishedMessage, finishedTitle } = this.state;

        return (
            <Modal show={showFinishedDialog} onHide={this.handleFinished}>
                <Modal.Header closeButton>
                    <Modal.Title>{finishedTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {finishedMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleFinished}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        ) 
    }

    //main linking function triggered by clicking 'Ok' from confirm dialog
    handleConfirm = async() => {
        const { multiverse: { reauthenticate }, history } = this.props;
        const { linkUserId, linkSecret } = this.state;

        //record that now linking
        this.setState({
            linkingActive: true
        })

        try {
            //attempt the account link
            const post_opts: FetchOptions = {
                url: "/v2/acceptlinkaccount",
                body: {
                    user: linkUserId, 
                    secret: linkSecret
                },
                hideErrors: true            
            }
            const res = await api.post(post_opts)

            //once done (assuming no exception) attempt to reauthenticate, as the current account will no longer exist having been merged with the other
            await reauthenticate();

            //after reauth, jump to the 'finished' version of this page to show confirm dialog. we have to do it this way rather than
            //just showing the dialog directly, as the reauthenticate triggeres a redirect/remount of current url
            history.push("/linkaccounts?finished=1")

        } catch(err) {
            
            //got an error so show a corresponding error message
            if(err.message === "Error: User already merged") {
                this.setState({
                    showConfirmDialog: false,
                    showFinishedDialog: true,
                    finishedTitle: "Linking Failed",
                    finishedMessage: "Uh Oh! One of your accounts was already linked. If you think this is incorrect, email support@ftl.ltd."
                })    
            } else if(err.message === "Error: Merge request not found") {
                this.setState({
                    showConfirmDialog: false,
                    showFinishedDialog: true,
                    finishedTitle: "Linking Failed",
                    finishedMessage: "Uh Oh! It looks like you tried to use an invalid (or already used) link. If you think this is incorrect, email support@ftl.ltd."
                })    
            } else {
                this.setState({
                    showConfirmDialog: false,
                    showFinishedDialog: true,
                    finishedTitle: "Linking Failed",
                    finishedMessage: `Uh Oh! An unknown error occured linking your accounts. For more help, email support@ftl.ltd, providing the error code: ${err}.`
                })
            }
            

        }
    }

    //cancel clicked from confirm dialog - just show message
    handleCancel = () => {
        this.setState({
            showConfirmDialog: false,
            showFinishedDialog: true,
            finishedTitle: "Linking Cancelled",
            finishedMessage: "No problem! You can still use your multiverseonline.io account, and link it later on from this site or from the Multiverse app."
        })
    }

    //finished dialog 'Ok' button clicked - redirect to home
    handleFinished = () => {
        const { history } = this.props;
        history.push("/")
    }

    render(): JSX.Element {
        return (
            <>
            {this.renderConfirmDialog()}
            {this.renderFinishedDialog()}
            <BasicPage></BasicPage>
            </>
        )
    }
}

export default withRouter(withMultiverseApi(LinkAccountsPage));