import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain } from '../../hoc/multiverseApiProvider';
import { getBlobUrl, getApiUrl } from '../../config/api';
import PageTable from '../pageTable';
import { Dropdown, Button, Accordion, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { getIconSrc, useEntity } from '../../utils/entitycache';
import api from '../../utils/api';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';

type TCommonProps = {
    emptyMessage?: string
    showHeader?: boolean;
    additionalColumnNames?: string[]
    additionalColumnValues?: (e: MultiverseBaseEntity)=>any[]
}

export type TRenderEntityTableProps = {
    domainUri: string,
    entities: MultiverseBaseEntity[],
    editable?: boolean, 
    onManage?: (x: MultiverseBaseEntity)=>void,
    onDelete?: (x: MultiverseBaseEntity)=>void,  
    onCopy?: (x: MultiverseBaseEntity)=>void
} & TCommonProps
export function RenderDomainEntityTable(props: TRenderEntityTableProps) {
    
    const canDeleteEntity = (entity: MultiverseBaseEntity) => {
        if(entity.type === "location") {
            if(props.entities.length <= 1) {
                return false;
            }        
            if(entity.name === "home") {
                return false;
            }
            if(entity.name.match(/^infiniverse_store_[a-f\d]{24}$/)) {
                return false;
            }
            if(entity.name.match(/^infiniverse_[a-f\d]{24}_\d+$/)) {
                return false;
            }
        }
        return true;
    }

    const show_edit = props.editable && (props.onManage || props.onDelete);

    const onManage = (x: MultiverseBaseEntity) => {
        if(props.onManage) {
            props.onManage(x)
        }
    }
    const onDelete = (x: MultiverseBaseEntity) => {
        if(props.onDelete) {
            props.onDelete(x)
        }
    }
    const onCopy = (x: MultiverseBaseEntity) => {
        if(props.onCopy) {
            props.onCopy(x)
        }
    }

    return (
    <PageTable className="mb-0">
        <tbody>
            {
                props.entities.length > 0 && props.showHeader && (
                    <PageTable.Row key="header">
                        <th></th>
                        <th>Name</th>
                        {props.additionalColumnNames && props.additionalColumnValues && props.additionalColumnNames.map(x => (
                            <th key={x}>{x}</th>
                        ))}
                        {show_edit && <th></th>}
                    </PageTable.Row>
                )
            }
            {props.entities.map((x) => {
                return (
                    <PageTable.Row key={x.id}>
                        <PageTable.IconCell src={getIconSrc(x)} roundedCircle/> 
                        <PageTable.InfoCell title={x.nickname} subtitle={<Link to={`/domains/${props.domainUri}/${x.type}s/${x.name}`}>{`${props.domainUri}/${x.type}s/${x.name}`}</Link>}/>
                        {props.additionalColumnValues && props.additionalColumnNames && props.additionalColumnValues(x)}
                        {show_edit && <PageTable.DropDownCell>
                            {props.onManage && <Dropdown.Item onClick={() => onManage(x)}>Manage</Dropdown.Item>}
                            {props.onCopy && <Dropdown.Item onClick={() => onCopy(x)}>Copy</Dropdown.Item>}
                            {props.onDelete && <Dropdown.Item disabled={!canDeleteEntity(x)} onClick={() => onDelete(x)}>Delete</Dropdown.Item>}
                        </PageTable.DropDownCell>}
                    </PageTable.Row>
                )
            })}
            {
                props.emptyMessage && props.entities.length === 0 && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
            }
        </tbody>
    </PageTable>)
}

export type TDomainEntityTableProps = {
    targeturl: string;
    type: string;
    canCopy?: boolean;
    query?: Record<string,string>;
    filter?: (entity: MultiverseBaseEntity)=>boolean;    
} & TCommonProps
export function DomainEntityTable(props: TDomainEntityTableProps) {
    const domain = useEntity<MultiverseDomain>(props.targeturl)
    const [entities,setEntities] = useState<MultiverseBaseEntity[]>([])
    const [entityToDelete,setEntityToDelete] = useState<MultiverseBaseEntity | null>(null)
    const history = useHistory()
    const editable = (domain && domain.domainPermissions && domain.domainPermissions.includes("manage_domain")) || false

    const loadEntities = async() => {
        try {
            const search_params = new URLSearchParams(props.query)
            setEntities(await api.get<MultiverseBaseEntity[]>(`/v2/domains/${props.targeturl}/${props.type}s?${search_params.toString()}`))
        } catch(err) {
            console.log(err)
            setEntities([])
        }
    }

    useEffect(() => {
        loadEntities()
    }, [props.targeturl,props.type])

    const canCreate = domain && domain.domainPermissions && domain.domainPermissions.includes(`create_${props.type}s`);

    const renderCreateButton = () => {
        return canCreate && domain && (
            <div className="p-2" style={{width:"100%", height:"100%",textAlign:"center"}}>
                <Link to={`/domains/${domain.uri}/create${props.type}`}>
                    <Button variant="outline-primary">
                        Create New
                    </Button>
                </Link>
            </div>
        )
    }

    const onDeleteClick = (entity: MultiverseBaseEntity) => {
        setEntityToDelete(entity)
    }

    const onManageClick = (entity: MultiverseBaseEntity) => {
        history.push(`/domains/${props.targeturl}/${props.type}s/${entity.name}`)
    }

    const onCopyClick = (entity: MultiverseBaseEntity) => {
        if(canCreate && domain) {
            history.push(`/domains/${domain.uri}/create${props.type}?copy=${entity.id}`)
        }
    }

    const deleteEntity = async() => {
        if(entityToDelete) {
            await api.del(`/v2/domains/${entityToDelete.domain}/${props.type}s/${entityToDelete.id}`)
            await loadEntities();
            setEntityToDelete(null)
        }
    } 

    const renderDeleteEntityDialog = () => {
        return (
        <DialogBoxWithSpinner 
            show={entityToDelete != null}
            title={`Delete ${entityToDelete?.nickname}`}
            onCancel={()=>setEntityToDelete(null)}
            onConfirm={deleteEntity}
            button0Text="Ok"
            cancelButtonText="Cancel"
            >
            {
                entityToDelete && <>
                    Are you sure you want to <strong>permanently</strong> delete {entityToDelete.nickname}?
                </>
            }
        </DialogBoxWithSpinner>)
    }

    const renderEntities = () => {
 
        return (
            <>
                {renderDeleteEntityDialog()}
                <RenderDomainEntityTable {...props} 
                    editable={editable} 
                    domainUri={domain?.uri || ""} 
                    entities={entities} 
                    onManage={onManageClick} 
                    onDelete={onDeleteClick}
                    onCopy={canCreate && props.canCopy ? onCopyClick : undefined}/>
            </>
        )
    }
 
    return (
        <>
            {renderCreateButton()}                    
            {renderEntities()}
        </>
    )
}

export function DomainEntityCard(props: { title: string } & TDomainEntityTableProps) {
    return (
    <Accordion defaultActiveKey="0">
        <Card className="mt-2">
            <Accordion.Toggle as={Card.Header} eventKey="0">
                {props.title}
            </Accordion.Toggle>
            <Accordion.Collapse as={Card.Body} eventKey="0">
                <DomainEntityTable {...props}/>
            </Accordion.Collapse>
        </Card>
    </Accordion>)    
}