import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap';
import ValidatedField from '../validatedField';
import './contactus.css'

export type ContactUsRequest = {
    defaultSubject?: "Sales" | "Support" | "Billing" | "Other";
    defaultDescription?: string;
}

type ContactUsDialogProps = {

} & WithMultiverseApiProps;

type ContactUsDialogState = {
    currentRequest?: ContactUsRequest;
    visible: boolean;
    email: string;
    name: string;
    subject: "Sales" | "Support" | "Billing" | "Other";
    description: string;
    done: boolean;
};

class _ContactUsDialog extends React.Component<ContactUsDialogProps, ContactUsDialogState> {
    constructor(props: ContactUsDialogProps) {
        super(props);
        this.state = {
            currentRequest: undefined,
            visible: false,
            email: "",
            name: "",
            subject: "Other",
            description: "",
            done: true
        };
    }

    componentDidMount() {

    }
    componentDidUpdate() {
        if(this.state.currentRequest !== this.props.contactUsRequest) {
            console.log("ContactUs request changed");
            console.log(this.props.contactUsRequest);
            this.setState({
                currentRequest: this.props.contactUsRequest,
                email: this.props.multiverse.user?.email || "",
                name: this.props.multiverse.user?.name || "",
                subject: this.props.contactUsRequest?.defaultSubject || "Other",
                description: this.props.contactUsRequest?.defaultDescription || "",
                done: false
            })
        }
    }

    onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore value is from drop down we know has correct value
        this.setState({subject:event.currentTarget.value});
    }

    onConfirm = async (button: any) => {
        const { multiverse: { post, closeContactUs }} = this.props;
        this.setState({done: true});
        await post(`/v2/contactus`, {
            email: this.state.email,
            name: this.state.name,
            subject: this.state.subject,
            description: this.state.description
        });
        closeContactUs();
    }

    render(): JSX.Element {
        return (
            <DialogBoxWithSpinner 
                className="contact-us-dialog"
                title="Contact Us" 
                show={this.state.currentRequest !== undefined} 
                onCancel={this.props.multiverse.closeContactUs}
                closeButton={true}
                button0Text="Send"
                onConfirm={this.onConfirm}>
                
                {!this.state.done && <Form>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <ValidatedField regex={/^.+@.+\..+$/} value={this.state.email} onChange={(val)=>{this.setState({email: val})}}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <ValidatedField regex={/.*/} value={this.state.name} onChange={(val)=>{this.setState({name: val})}}/>
                    </Form.Group>                    
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        
                        <Form.Control as="select" value={this.state.subject} onChange={this.onSubjectChange}>
                            <option>Sales</option>
                            <option>Support</option>
                            <option>Billing</option>
                            <option>Other</option>
                        </Form.Control>
                    </Form.Group>          
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={5} value={this.state.description} onChange={(val)=>{this.setState({description:val.currentTarget.value})}}/>
                    </Form.Group>
                    
                </Form>}

            </DialogBoxWithSpinner>
        )
    }
}
export const ContactUsDialog = withMultiverseApi(_ContactUsDialog);

export default ContactUsDialog;
