import React, { CSSProperties, FunctionComponent } from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';
import { Subtract } from 'utility-types';

type ValidatedFieldProps = {
    value: string;
    regex?: RegExp;
    showErrorWhenEmpty?: boolean;
    errorColor?: string;
    onChange?: (val: string, valid: boolean) => void;
    onConfirm?: (val: string, valid: boolean) => void;
    onCancel?: () => void;
    onValidate?: (val: string) => boolean;
    className?: string;
    style?: CSSProperties;
} & Subtract<FormControlProps, { onChange?: any }>;

type ValidatedFieldState = {
};

//wraps an input field that is validated with a regular expression, goes red if invalid
//and calls an onChange callback which includes whether the current value is valid
class ValidatedField extends React.Component<ValidatedFieldProps, ValidatedFieldState> {
    textInputRef = React.createRef<HTMLInputElement>();

    
    constructor(props: ValidatedFieldProps) {
        super(props);
        this.state = {};
        
    }

    _onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        const valid = this._validate(e.target.value);
        if(onChange) {
            onChange(e.target.value, valid);
        }
    };   

    _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { onConfirm, onCancel } = this.props;
        if(e.key == 'Enter') {
            if(this.textInputRef.current)
            {
                const val = this.textInputRef.current.value;
                const valid = this._validate(val);
                if(onConfirm) {
                    onConfirm(val,valid);
                }
            }
        } else if(e.key == 'Escape') {
            if(onCancel) {
                onCancel();
            }
        }
    };   

    _onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { onCancel } = this.props;
        if(onCancel) {
            onCancel();
        }
    }
 
    _validate = (val: string): boolean => {
        if(this.props.onValidate && !this.props.onValidate(val)) {
            return false;
        }
        if(this.props.regex && !val.match(this.props.regex)) {
            return false;
        }
        return true;
    }

    getTextInput = () => {
        return this.textInputRef.current;
    }


    render(): JSX.Element {
        
        const { value, showErrorWhenEmpty, errorColor } = this.props;
        const isValid = this._validate(value);
        const showError = !isValid && (showErrorWhenEmpty || value.length > 0);
        const errcol = errorColor || "#F0C0C0";

        let style: React.CSSProperties = {...this.props.style};
        if(showError) {
            style.backgroundColor = errcol;
        }

        return (
            <>
                <FormControl 
                    ref={this.textInputRef} 
                    className={this.props.className} 
                    value={this.props.value} 
                    onChange={this._onValueChange} 
                    onKeyUp={this._onKeyUp}
                    onBlur={this._onBlur}
                    style = {style}
                    disabled={this.props.disabled}
                    />
            </>
        )
    }
}

export default ValidatedField;

//helpers for common use cases 

type ValidatedFieldWithPredefinedRegExProps = Subtract<ValidatedFieldProps,{regex?:any}>;

export const VALID_DOMAIN_NAME = /^[A-Za-z0-9\s\']*$/;
export const VALID_DOMAIN_URI = /^[a-z][a-z0-9\-\.]*$/;
export const VALID_LOCATION_URI = /^[a-z][a-z0-9\-\.]*\/locations\/.+$/;
export const VALID_ENTITY_URI = /^[a-z][a-z0-9\-\.]*\/\w+s\/.+$/;
export const VALID_DOMAIN_OR_LOCATION_URI = /^[a-z][a-z0-9\-\.]*(\/locations\/.+)?$/;
export const VALID_DOMAIN_OR_ENTITY_URI = /^[a-z][a-z0-9\-\.]*(\/\w+s\/.+)?$/;

export const DomainNameField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_DOMAIN_NAME}
      />
    );
};
export const DomainUriField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_DOMAIN_URI}
      />
    );
};
export const LocationUriField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_LOCATION_URI}
      />
    );
};
export const EntityUriField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_ENTITY_URI}
      />
    );
};
export const DomainOrLocationUriField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_DOMAIN_OR_LOCATION_URI}
      />
    );
};
export const DomainOrEntityUriField:FunctionComponent<ValidatedFieldWithPredefinedRegExProps> = (props) => {
    return (
      <ValidatedField
        {...props}
        regex={VALID_DOMAIN_OR_ENTITY_URI}
      />
    );
};
