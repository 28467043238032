import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseLocation, MultiverseRoom, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import api from '../../utils/api';
import { Button, Form } from 'react-bootstrap';
import { DomainNameField } from '../../components/validatedField';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { TemplateSelectDialog, TemplateSelectGrid, TTemplate } from '../../components/templateSelectDialog';

type TTemplateInfo = {
    name: string;
    template: string;
}
type TTemplateResponse = {
    plan: string;
    templates: TTemplateInfo[];
}

type CreateLocationPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type CreateLocationPageState = {
    existingLocations: MultiverseLocation[];
    name: string;
    isLoading: boolean;
    isSaving: boolean;
    valid: boolean;
    selectedTemplate?: TTemplate
};

class _CreateLocationPage extends React.Component<CreateLocationPageProps, CreateLocationPageState> {
    constructor(props: CreateLocationPageProps) {
        super(props);
        this.state = {
            name: "",
            existingLocations: [],
            isSaving: false,
            isLoading: false,
            valid: false
        };
    }

    componentDidMount = async() => {
        const { domain } = this.props;
        if(!domain) {
            throw new Error("Invalid domain");
        }

        this.setState({
            isLoading: true
        })
        
        const existingLocations = (await api.get<MultiverseLocation[]>(`/v2/domains/${domain.uri}/locations`)).filter(a => a.type === "location");
        this.setState({
            existingLocations,
            isLoading: false
        })
    }

    onNameChange = (name: string, valid: boolean) => {
        this.setState({name, valid});
    }

    onNameValidate = (val: string) => {
        if(this.state.existingLocations.find((loc)=>loc.nickname === val)) {
            return false;
        }

        const name = this.calculateName();
        if(this.state.existingLocations.find((loc)=>loc.name === name)) {
            return false;
        }

        return true;
    }

    calculateName = () => {
        return this.state.name.replace(/\s/g,"_").replace(/[^\w\s]/g, "").toLocaleLowerCase()
    }

    calculateURL = () => {
        return `${this.props.domain?.uri}/locations/${this.calculateName()}`
    }

    onCreateClicked = async() => {
        if(!this.state.selectedTemplate) {
            throw new Error("No template")
        }

        this.setState({
            isSaving: true
        })

        const res = await api.post<{location: MultiverseLocation, room: MultiverseRoom}>(`/v2/domains/${this.props.domain!.uri}/locations`, {
            name: this.calculateName(),
            nickname: this.state.name,
            template: this.state.selectedTemplate.template
        })

        console.log(res);

        this.props.history.push(`/domains/${this.props.domain!.uri}/locations/${res.location.name}`)

    }

    render(): JSX.Element {
        return (
            <BasicPage isLoading={this.state.isLoading} title="Create New Location">
                <Form>
                    <Form.Group controlId="nameInputControl">
                        <Form.Label>Name</Form.Label>
                        <DomainNameField showErrorWhenEmpty onChange={this.onNameChange} value={this.state.name} onValidate={this.onNameValidate}/>
                    </Form.Group>              
                    <Form.Group controlId="URLControl">
                        <Form.Label>Metaverse URL</Form.Label>
                        <Form.Control type="input" readOnly value={this.calculateURL()}/>
                    </Form.Group>  
                    <Form.Group controlId="domainAccessControl">
                        <Form.Label>Choose Your Template:</Form.Label>                    
                        <TemplateSelectGrid plan={this.props.domain?.plan} selected={this.state.selectedTemplate} onSelect={x=>this.setState({selectedTemplate:x})}/>
                    </Form.Group>                  
                    <Button className="mt-2" style={{width:"100%"}} onClick={this.onCreateClicked} disabled={this.state.isSaving || !this.state.valid || !this.state.selectedTemplate}>Go!</Button>
                </Form>

            </BasicPage>
        )
    }
}
export const CreateLocationPage = withRouter(withMultiverseApi(_CreateLocationPage));

export default CreateLocationPage;
