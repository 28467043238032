import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { TItemInfo } from '../../utils/driveapi';
import AspectRatioBox from '../aspectRatioBox';
import DialogBox from "../dialogBox"
import "./drivebrowser.css"

function camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

export type TDriveBrowserInfoDialogProps = {
    item: TItemInfo | null;
    path: string;
    onCancel?: ()=>void;
}
export default function DriveBrowserInfoDialog(props: TDriveBrowserInfoDialogProps) {
    function getLocationString() {
        if(props.item) {
            if(props.path.startsWith("/google")) {
                return "Google Drive";
            } else if(props.path.startsWith("/vimeo")) {
                return "Vimeo";
            } else {
                return "Multiverse Uploads";
            }
        } else {
            return "";
        }
    }
    
    function getTypeString() {
        if(props.item) {
            if(props.item.mimeType)
            {
                if(props.item.mimeType.startsWith("image/")) {
                    return "Image";
                } else if(props.item.mimeType.startsWith("video/")) {
                    return "Video";
                } else if(props.item.mimeType === "application/x-mpegurl" || props.item.mimeType === "application/vnd.apple.mpegurl") {
                    return "Adaptive Video";
                }
            }
            return props.item.type[0].toLocaleUpperCase() + props.item.type.slice(1);

        }
        return "Unknown";
    }
    function getSizeString() {
        if(props.item && props.item.size) {
            return (props.item.size / (1024*1024)).toFixed(2) + " MB";
        } else {
            return "";
        }
    }

    function PropRow(props: {name: string, val: any}) {
        return (
        <Row className="dbid-property-row">
            <Col className="dbid-property-name">{props.name}</Col>
            <Col className="dbid-property-value">{props.val}</Col>
        </Row>)
    }
    
    return <DialogBox 
        className="drive-browser-info-dialog"
        show={props.item != null} 
        title={`${props.item?.name}`}
        onCancel={props.onCancel}
        closeButton>
            <Container fluid>
                <Row className="dbid-thumbnail-row">
                    <Col>
                        <AspectRatioBox aspect={1.777778}>
                            <Image src={props.item?.thumbnailUri}/>
                        </AspectRatioBox>
                    </Col>
                </Row>
                <PropRow name="Type" val={getTypeString()}/>
                <PropRow name="Location" val={getLocationString()}/>
                {props.item?.size && <PropRow name="Size" val={getSizeString()}/>}
                {props.item?.size && <PropRow name="Storage used" val={(props.path.startsWith("/uploads") || props.path.startsWith("/videos") || props.path.startsWith("/metaverses")) ? getSizeString() : "-"}/>}
                {props.item?.width && <PropRow name="Width" val={props.item.width}/>}
                {props.item?.height && <PropRow name="Height" val={props.item.height}/>}
                {props.item?.url && <PropRow name="URL" val={<a href={props.item.url}>{props.item.url}</a>}/>}
            </Container>
    </DialogBox>
}

