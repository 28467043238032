import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseLocation, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import './doorSelector.css'
import { Button, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import ValidatedField from '../../components/validatedField';

type DoorSelectorProps = {
    name?: string;
    description?: string;
    default?: string;
    value: string;
    showSpinner?: boolean;
    onValueChanged?: (val: string) => void;
    onClearClicked?: () => void;
    locations: MultiverseLocation[];
} & WithMultiverseApiProps;

type DoorSelectorState = {
    loadedVal?: string;
    currentType: string;
    currentLocation: string;
    currentBrowser: string;
    currentOculusApp: string;
    currentCustomVal: string;
};

class _DoorSelector extends React.Component<DoorSelectorProps, DoorSelectorState> {
    constructor(props: DoorSelectorProps) {
        super(props);
        this.state = {
            loadedVal: undefined,
            currentType: "",
            currentLocation: "",
            currentBrowser: "",
            currentOculusApp: "",
            currentCustomVal: ""
        };
    }

    getPropValue = () => {
        if(this.props.value && this.props.value != "") {
            return this.props.value;
        } else if(this.props.default && this.props.default != "") {
            return this.props.default;
        } else {
            return ""
        }
    }

    componentDidMount() {
        this.readValue();
        console.log({props: this.props})
    }
    componentDidUpdate() {
        if(this.state.loadedVal !== this.getPropValue()) {
            this.readValue();
        }
    }

    readValue() {
        let value = this.getPropValue();

        let method = "";
        let target = "";
        if (value) {
            const match = value.match(/^\[(\w*)\]\s*(.*)/);
            if (match) {
                method = match[1];
                target = match[2];
            }
            else {
                method = "custom";
                target = value;
            }
        } 

        if(method === "multiverse" && value.includes("/locations/")) {
            method = "custom"
        }

        console.log({value, method, target})

        if(method === "multiverse") {
            this.setState({
                loadedVal: value,
                currentType: "multiverse",
                currentLocation: target
            })
        } else if(method === "oculus") {
            this.setState({
                loadedVal: value,
                currentType: "oculus",
                currentOculusApp: target
            })
        } else if(method === "browser") {
            this.setState({
                loadedVal: value,
                currentType: "browser",
                currentBrowser: target
            }) 
        } else if(method === "locked") {
            this.setState({
                loadedVal: value,
                currentType: "locked"
            }) 
        } else {
            this.setState({
                loadedVal: value,
                currentType: "custom",
                currentCustomVal: target
            }) 
        }
    }
   

    onTypeChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
       this.setState({
           currentType: ev.currentTarget.value
       })
    }
    onTargetChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    }

    renderLocationSelect = () => {
        return (
            <Form.Group className="location-input" as={Row} controlId="locationInputControl">
                <Form.Label className="d-none d-lg-block" column >Location</Form.Label>
                <Col xs="12">
                    <Form.Control as="select" value={this.state.currentLocation} onChange={(e)=>this.setState({currentLocation:e.currentTarget.value})}>
                        {this.props.locations.map((x) => <option value={x.name}>{x.nickname}</option>)}         
                    </Form.Control>
                </Col>
            </Form.Group>)
    }

    renderBrowserSelect = () => {
        return (
            <Form.Group className="browser-input" as={Row} controlId="browserInputControl">
                <Form.Label className="d-none d-lg-block" column >(eg http://mysite.com)</Form.Label>
                <Col xs="12">
                    <ValidatedField  value={this.state.currentBrowser} onChange={(v)=>this.setState({currentBrowser:v})} regex={/.*/} />
                </Col>
            </Form.Group>)
    }

    renderOculusSelect = () => {
        return (
            <Form.Group className="oculus-input" as={Row} controlId="oculusInputControl">
                <Form.Label className="d-none d-lg-block" column >(eg 1026944133401643)</Form.Label>
                <Col xs="12">
                    <ValidatedField  value={this.state.currentOculusApp} onChange={(v)=>this.setState({currentOculusApp:v})} regex={/^\d+$/} />
                </Col>
            </Form.Group>)
    }

    renderCustomSelect = () => {
        return (
            <Form.Group className="browser-input" as={Row} controlId="browserInputControl">
                <Form.Label className="d-none d-lg-block" column >(eg ftl.multiverseonline.io/locations/lobby)</Form.Label>
                <Col xs="12">
                    <ValidatedField  value={this.state.currentCustomVal} onChange={(v)=>this.setState({currentCustomVal:v})} regex={/.*/} />
                </Col>
            </Form.Group>)
    }

    calcValue = () => {
        const { currentType } = this.state;
        switch(currentType) {
            case "multiverse":
                let loc = this.state.currentLocation;
                if(loc == "" && this.props.locations.length > 0) {
                    loc = this.props.locations[0].name;
                }
                return `[multiverse] ${loc}`;
            case "browser":
                return `[browser] ${this.state.currentBrowser}`;
            case "oculus":
                return `[oculus] ${this.state.currentOculusApp}`;
            case "locked":
                return "[locked]";
            case "custom":
                return `${this.state.currentCustomVal}`;
            default: 
                return "";
        }        
    }

    onSaveClicked = () => {
        if(this.props.onValueChanged) {
            const val = this.calcValue();
            this.props.onValueChanged(val);
        }
    }

    onClearClicked = () => {
        if(this.props.onClearClicked) {
            this.props.onClearClicked();
        }
    }

    render(): JSX.Element {
        const { name, description, value, showSpinner } = this.props;
        const { currentType } = this.state;

        let cn = "door-selector";
        cn += ` door-selector-type-${currentType}`

        if(showSpinner) {
            cn += " door-selector-showspinner";
        } else {
            cn += " door-selector-hidespinner";
        }

        const renderTooltip = (props: any) => (
            <Tooltip id="button-tooltip" {...props}>
                {description}
            </Tooltip>
        );

        return (
            <div className={cn}>
                {name && <p className="name">
                    <strong>{name}</strong>
                    <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderTooltip}>
                        <span> <u>(?)</u></span>
                    </OverlayTrigger>
                </p>}
                <div className="aspect-ratio-sizer">
                    <svg viewBox={`0 0 1 1`}></svg>

                    <div className="spinner-wrapper">
                        <Spinner animation="border" role="status" />
                    </div>
                    
                    <Form className="form" as={Container} fluid>
                        <Form.Group as={Row} controlId="linkTypeInputControl">
                            <Form.Label column className="d-none d-md-unset" >Type</Form.Label>
                            <Col xs="12">
                                <Form.Control as="select" value={currentType} onChange={this.onTypeChanged}>
                                    <option value="multiverse">Location</option>
                                    <option value="browser">Oculus Browser URL</option>
                                    <option value="oculus">Oculus App Launch</option>
                                    <option value="locked">Locked</option>
                                    <option value="custom">Metaverse URL</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        {currentType === "multiverse" && this.renderLocationSelect()}
                        {currentType === "browser" && this.renderBrowserSelect()}
                        {currentType === "oculus" && this.renderOculusSelect()}
                        {currentType === "custom" && this.renderCustomSelect()}
                        <Row>
                            <Col className="text-right">
                                <Button className="save-button" variant="primary" disabled={this.calcValue() === value} onClick={this.onSaveClicked}>Save</Button>
                                <Button className="save-button" variant="primary" onClick={this.onClearClicked}>Clear</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        )
    }
}
export const DoorSelector = withMultiverseApi(_DoorSelector);

export default DoorSelector;
