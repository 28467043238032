import { FileDrop } from 'react-file-drop';
import ContentBox from '../contentBox';
import '../discoveryContentCard/discoverycontentcard.css'
import { BsFillCaretDownFill } from 'react-icons/bs';
import IsDeveloper, { IsNotDeveloper } from '../isDeveloper';
import { useEntity, onEntityUpdated, postEntity, onEntityDirty } from '../../utils/entitycache';
import { Subtract } from 'utility-types';
import React, { useRef, useState, CSSProperties } from 'react';
import AspectRatioBox from '../aspectRatioBox';
import { OverlayTrigger, Dropdown, Tooltip, Spinner } from 'react-bootstrap';
import { getBlobUrl } from '../../config/api';
import { MultiverseBaseEntity } from '../../hoc/multiverseApiProvider';
import api from '../../utils/api';

function ImageBoxDropDown(props: { value?: string, onUploadClicked: ()=>void, onClearClicked: ()=>void}) {
    return (
    <Dropdown className="options-dropdown" dir="right">
        <Dropdown.Toggle className="togglebutton" variant="light" id="options-dropdown">
            <BsFillCaretDownFill size="20px" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.onUploadClicked()}>Upload File</Dropdown.Item>
            <Dropdown.Item disabled={!props.value || props.value===""} onClick={() => props.onClearClicked()}>Clear</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>)
}

export function RenderImageUploadBox(props: {requireContent?: boolean, className?: string, style?:CSSProperties, aspect: number, bold?: boolean, title: string, description: string, default?: string, blob?: string, onSave: (files?: FileList) => Promise<void>}) {
    const fileInputRef = useRef<HTMLInputElement|null>(null)
    const [loading,setLoading] = useState<boolean>(false)

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            upload(files);
        }
    }

    const onDropFiles = (files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
        if (files) {
            upload(files);
        }
    }

    const upload = async(files: FileList) => {
        setLoading(true);
        try {
            await props.onSave(files);
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }

    const clear = async() => {
        setLoading(true);
        try {
            await props.onSave();
        } catch(err) {
            console.log(err);
        }
        setLoading(false);
    }


    const renderTooltip = (ttprops: any) => (
        <Tooltip id="button-tooltip" {...ttprops}>
            {props.description}
        </Tooltip>
    );

    let className = "dcc-content-box dcc-image-box";
    if(props.className) {
        className += " " + props.className;
    }
    if(props.requireContent && !props.blob) {
        className += " dcc-error"
    }

    return (
    <div className={className} style={props.style}>
        <div className="dcc-title">
        {props.bold && <strong>{props.title}</strong>}
        {!props.bold && <span>{props.title}</span>}
        <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderTooltip}>
            <span> <u>(?)</u></span>
        </OverlayTrigger>
        </div>
        <AspectRatioBox aspect={props.aspect}>
            <FileDrop
                onDrop={onDropFiles}>
                <input
                    onChange={onFileInputChange}
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                />
                {loading && <div className="spinner-container">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>}

                {(props.blob || props.default) && <ContentBox className="content" src={props.blob ? `${getBlobUrl()}/${props.blob}` : props.default}/>}                
                {!(props.blob || props.default) && <div className="dcc-image-box-description">{props.description}</div>}
            </FileDrop>
            <ImageBoxDropDown value={props.blob} onUploadClicked={() => fileInputRef.current?.click()} onClearClicked={()=>clear()}/>
        </AspectRatioBox>
    </div>
    )
}

export function EntityImageUploadBox(props: {requireContent?: boolean, className?: string, style?:CSSProperties, aspect: number, bold?: boolean, title: string, description: string, default?: string, targeturl: string, contentname: string }) {
    const entity = useEntity<MultiverseBaseEntity>(props.targeturl);
    
    const field_val = entity && (entity as any)[`${props.contentname}blob`]

    const onSave = async(val?: FileList) => {
        if(val) {
            const res = await api.post<MultiverseBaseEntity>({
                url: `/v2/domains/${props.targeturl}/${props.contentname}`,
                body: val[0],
                headers: {
                    'Content-Type': val[0].type,
                    'x-shapevr-name': val[0].name,
                }
            })
            onEntityDirty(props.targeturl)
        } else {
            const res = await api.del<MultiverseBaseEntity>(`/v2/domains/${props.targeturl}/${props.contentname}`)
            onEntityDirty(props.targeturl)
        }
    }

    return <RenderImageUploadBox
        {...props}
        blob={field_val}
        onSave={onSave}
    />

}