import React from 'react';
import { Button, Card, Col, Container, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

import {BsPersonPlus, BsPersonFill} from 'react-icons/bs'

type ProfileIconProps = {
    user?: string
    showName?: boolean;
    name?: string;
    onClick?: ()=>void;
} & WithMultiverseApiProps;

type ProfileIconState = {
    user?: MultiverseUser;
    name: string;
};
 
class _ProfileIcon extends React.Component<ProfileIconProps, ProfileIconState> {
    constructor(props: ProfileIconProps) {
        super(props);
        this.state = {
            name: props.name || ""
        }
    }

    componentDidMount = async () => {
        const { user, name, multiverse } = this.props;

        let userEntity: MultiverseUser | undefined;
        if(!user) {
            userEntity = multiverse.user;
        } else {
            userEntity = await multiverse.get<MultiverseUser>(`/v2/users/${user}`)
        }

        this.setState({
            user: userEntity,
            name: name || userEntity?.nickname || ""
        })
    }

    render() {
        const { user, name } = this.state;

        return (
        <>
        <Button className="profile-icon" onClick={this.props.onClick}>
        { (user && user.iconblob && user.iconblob.length > 0) ? <Image className="icon-image" src={`${getBlobUrl()}/${user.iconblob}`} roundedCircle></Image> : <BsPersonFill className="icon-image p-1"/>}
        { this.props.showName && <div className="profile-icon-username">{name}</div> }
        </Button>
        </>)
    }
}
export const ProfileIcon = withMultiverseApi(_ProfileIcon);

export default ProfileIcon;
