import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import driveapi, { TItemInfo } from '../../utils/driveapi';
import { BsChevronRight, BsArrowUpShort, BsUpload } from 'react-icons/bs'
import { RiFolderUploadLine } from 'react-icons/ri'
import { Button, Col, Container, Dropdown, ProgressBar, Row } from 'react-bootstrap';
import './drivebrowser.css'
import { CircularButton, CloseButton, DeleteButton, InfoButton, NavigateUpButton, PreviewButton, UploadButton, VerticalMenuButton } from '../circularButton';

type DriveBrowserHeaderProps = {
    path: string;
    selected?: TItemInfo;
    onChangePathClicked?: (newpath: string)=>void;

    onOpenClicked?: ()=>void;
    onUploadClicked?: ()=>void;
    onInfoClicked?: ()=>void;
    onDeleteClicked?: ()=>void;
    onCloseClicked?: ()=>void;

    showOpen?: boolean;
    showUpload?: boolean;
    showInfo?: boolean;
    showDelete?: boolean;    
    showStorage?: boolean;
    showClose?: boolean;

    disableOpen?: boolean;
    disableUpload?: boolean;
    disableInfo?: boolean;
    disableDelete?: boolean;    
    disableClose?: boolean;
};

type DriveBrowserHeaderState = {
    loadingPath: string;    
    pathItems: TItemInfo[];
};

const ROOT: TItemInfo = {
    name: "Drives",
    id: "",
    type: "directory"
}

class _DriveBrowserHeader extends React.Component<DriveBrowserHeaderProps, DriveBrowserHeaderState> {
    constructor(props: DriveBrowserHeaderProps) {
        super(props);
        this.state = {
            loadingPath: "",
            pathItems: [] 
        };
    }

    componentDidMount = () => {
        this.setState({
            loadingPath: this.props.path
        }, this.reload)
    }
    componentDidUpdate = () => {
        if(this.state.loadingPath != this.props.path) {
            this.setState({
                loadingPath: this.props.path
            }, this.reload)
        }
    }

    reload = async() => {
        if(this.state.loadingPath == "") {
            this.setState({
                pathItems: [ROOT]
            })            
        } else {
            const pathItems = [ROOT, ...await driveapi.getFullPathMeta(this.state.loadingPath)];
            this.setState({
                pathItems
            })
        }
    }

    onFolderButtonClicked = (item: TItemInfo, index: number) => {
        const path = this.props.path.split("/").slice(0, index+1).join("/");
        if(this.props.onChangePathClicked) {
            this.props.onChangePathClicked(path);
        }
    }

    onUpButtonClicked = () => {
        const last_slash = this.props.path.lastIndexOf('/');
        if(last_slash > 1) {
            if(this.props.onChangePathClicked) {
                this.props.onChangePathClicked(this.props.path.substr(0, last_slash));
            }
        } else {
            if(this.props.onChangePathClicked) {
                this.props.onChangePathClicked("")
            }
        }

    }

    getItemName = (item: TItemInfo, index: number) => {
        if(index === 1) {
            if(this.props.path.startsWith("/uploads")) {
                return "Uploads";
            }
            if(this.props.path.startsWith("/metaverses")) {
                return "Metaverses";
            }
        }
        return item.name;
    }

    renderFolderButton = (item: TItemInfo, index: number) => {
        return <Button 
            className="no-outline-button drive-browser-transparent-button dbh-name-button" 
            variant='light'
            onClick={() => this.onFolderButtonClicked(item, index)}>
                {this.getItemName(item,index)}
        </Button>
    }

    renderPathItems = (maxItems: number) => {
        const items = this.state.pathItems.length <= maxItems ? this.state.pathItems : this.state.pathItems.slice(-maxItems);
        return items.map((item,idx) => 
        <>
            {idx > 0 && <BsChevronRight className="dbh-path-separator"/>}
            {this.renderFolderButton(item, idx)}
        </> )
    }


    renderToolButtonIcons = () => {
        const divider = (this.props.showOpen || this.props.showDelete || this.props.showStorage) && (this.props.showUpload || this.props.showInfo)
        return <>
            {this.props.showOpen && <PreviewButton disabled={this.props.disableOpen} onClick={this.props.onOpenClicked}/>}            
            {this.props.showDelete && <DeleteButton disabled={this.props.disableDelete} onClick={this.props.onDeleteClicked}/>}
            {divider && <div className="dbh-toolbar-separator"/>}
            {this.props.showUpload && <UploadButton disabled={this.props.disableUpload} onClick={this.props.onUploadClicked}/>}            
            {this.props.showInfo && <InfoButton disabled={this.props.disableInfo} onClick={this.props.onInfoClicked}/>}      
            {this.props.showClose && <CloseButton disabled={this.props.disableClose} onClick={this.props.onCloseClicked}/>}
        </>
    }

    renderToolButtons = () => {
        return <>
            <div>
                {this.renderToolButtonIcons()}
            </div>
        </>
    }

    render = () => {
        return (
            <Container fluid className='drive-browser-header' onClick={(ev: React.MouseEvent)=>{ev.stopPropagation()}}>
                <Row>
                    <Col xs="auto">
                        <NavigateUpButton disabled={this.props.path.length <= 1} className="no-outline-button drive-browser-transparent-button dbh-up-button" onClick={this.onUpButtonClicked}/>
                    </Col>
                    <Col className="text-left">           
                        <div className="d-sm-none">
                            {this.renderPathItems(1)}
                        </div>
                        <div className="d-none d-sm-inline-block" >
                            {this.renderPathItems(10)}
                        </div>         
                    </Col>
                    <Col xs="auto" className="dbh-tool-buttons">
                        {this.renderToolButtons()}
                    </Col>
                </Row>

            </Container> 
        )
    }
}
export const DriveBrowserHeader = _DriveBrowserHeader;

export default DriveBrowserHeader;
