import { loadStripe } from '@stripe/stripe-js';
import { isProd } from '../config/api';

export async function createMultiverseStripeClient() {
    const key = isProd() ?
        'pk_live_51IDVIVFlTMkvVg5gHI7yVgwRzcLkvqk8nx1CUFJNWMfNeGv0guFw9eTBmloVbHFmIF4Ny2ghgEwVGwMPWQ4SrCrS00nPGLDC6k' :
        'pk_test_51Ize8uE7zAnWmDOC8HCBKKq1RU03nYucYvoKErFta1OkFMlG5PRP6gLc5xB6Z8lekNYfrH7eoX76SPeHDrseCQZL00b55FM9zt';
    const stripe = await loadStripe(key);
    return stripe;            
}
