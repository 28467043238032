import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType, CSSProperties, FC, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import DialogBoxWithSpinner from '../components/dialogBoxWithSpinner';
import { MultiverseApiContext, WithMultiverseApiProps } from '../hoc/multiverseApiProvider';
import LoggingInLoader from '../hoc/multiverseApiProvider/loggingInLoader';
import withMultiverseApi from '../hoc/multiverseApiProvider/withMultiverseApi';

export type PrivateRouteProps<T> = {component: T} & RouteProps;

const PrivateRoute = <T extends React.ComponentType>(props:PrivateRouteProps<T>):JSX.Element => {
  const { component, ...rest } = props;
  return (
    <Route
    exact
      {...rest}
      component={withAuthenticationRequired( component, {
          onRedirecting: () => <div/>
      })}
    /> 
  );
};
export default PrivateRoute;
