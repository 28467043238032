import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseUser } from '../../hoc/multiverseApiProvider';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import { RestApiDocumentData } from '.';
import PageTable from '../pageTable';
import "./restapi.css"
import RawAudioPlayer from './rawAudioPlayer';

export function WitRequestRow( props: { data: RestApiDocumentData } ) {

    type WitRequestData = {
        id: string,
        headers?: { [key:string]: string },
        log?: {
            pre_query: {
                rooms: ({
                    location_entity: MultiverseLocation;
                    room_entity: MultiverseRoom;
                    score: number;
                })[],
                people: ({
                    location_entity?: MultiverseLocation,
                    room_entity?: MultiverseLocation
                } & MultiverseUser)[],
                friends: ({
                    location_entity?: MultiverseLocation,
                    room_entity?: MultiverseLocation
                } & MultiverseUser)[],
                domains: MultiverseDomain[]

            }
            potentials: ({ _rating: number } & MultiverseBaseEntity)[],
            db_potentials: ({ _rating: number } & MultiverseBaseEntity)[],
            prior_potentials: ({ _rating: number } & MultiverseBaseEntity)[],
            filtered_potentials: ({ _rating: number } & MultiverseBaseEntity)[],
            reduced_destination: string,
            textindex_destination: string,
            vis_query: any,
            prior_matches: {
                input: string,
                output: string,
                score: number,
                uses: number
            }[]
        },
        response?: {
            multiverse?: {
                actions: {
                    intent: string;
                    target?: MultiverseBaseEntity,
                    scpre: number,
                    description: string
                }[],
                witintent: string,
                wittext: string,
                destination: string
            }
        },
        useraction?: {
            actionindex: number
        }
    }

    const request: WitRequestData = props.data as WitRequestData;
    console.log(request);

    return (
        <>
            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Request Summary
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">  
                                {request.log ? (
                                <PageTable>
                                    <tbody>
                                        <tr>
                                            <td>Audio</td>
                                            <td><RawAudioPlayer src={`/v2/wit/audio/${request.id}`}/></td>
                                        </tr>
                                        <tr>
                                            <td>Text</td>
                                            <td>{<strong><em>"{request.response?.multiverse?.wittext}"</em></strong>}</td>
                                        </tr>
                                        <tr>
                                            <td>Wit Destination</td>
                                            <td>{request.response?.multiverse?.destination}</td>
                                        </tr>
                                        <tr>
                                            <td>Wit Intent</td>
                                            <td>{request.response?.multiverse?.witintent}</td>
                                        </tr>
                                        <tr>
                                            <td>Reduced Destination</td>
                                            <td>{request.log.reduced_destination}</td>
                                        </tr>
                                        <tr>
                                            <td>Text Index Destination</td>
                                            <td>{request.log.textindex_destination}</td>
                                        </tr>
                                        <tr>
                                            <td>Viz Query</td>
                                            <td>{JSON.stringify(request.log.vis_query)}</td>
                                        </tr>
                                        {(request.response?.multiverse?.actions || []).map( (x,idx) => (
                                            <tr key={idx}>
                                                <td>Action {idx}</td>
                                                <td>{x.description}</td>
                                            </tr>
                                        ))
                                        }
                                        <tr>
                                            <td>Action Taken</td>
                                            <td>{request.useraction ? (request.response?.multiverse?.actions[request.useraction.actionindex]?.description ) : "None" }</td>
                                        </tr>
                                    </tbody>
                                </PageTable>) : <div>No Headers</div>}           
                            </Accordion.Collapse>
                        </Card> 
                    </Accordion>
                </Col>
            </Row>

            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Prior Matches
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">  
                                <>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Input</th>
                                                <th>Output</th>
                                                <th>Score</th>
                                                <th>Uses</th>
                                            </tr>
                                            {(request.log?.prior_matches || []).map(x => (
                                                <tr key={x.input+x.output}>
                                                    <td>{x.input}</td>
                                                    <td>{x.output}</td>
                                                    <td>{x.score}</td>
                                                    <td>{x.uses}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                </>     
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>

            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Potentials
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">  
                                <>
                                    <div>Filtered</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Path</th>
                                                <th>Rating</th>
                                            </tr>
                                            {(request.log?.filtered_potentials || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.path.replace("/domains/","")}</td>
                                                    <td>{x._rating}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                    <div>Prior</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Path</th>
                                                <th>Rating</th>
                                            </tr>
                                            {(request.log?.prior_potentials || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.path.replace("/domains/","")}</td>
                                                    <td>{x._rating}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                    <div>Text Index</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Path</th>
                                                <th>Rating</th>
                                            </tr>
                                           {(request.log?.db_potentials || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.path.replace("/domains/","")}</td>
                                                    <td>{x._rating}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                    <div>Unfiltered</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Path</th>
                                                <th>Rating</th>
                                            </tr>
                                            {(request.log?.potentials || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.path.replace("/domains/","")}</td>
                                                    <td>{x._rating}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                </>    
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>

            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Headers
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">  
                                {request.headers ? (
                                <PageTable>
                                    <tbody>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                        {Object.keys(request.headers).map(x => (
                                            <tr key={x}>
                                                <td>{x}</td>
                                                <td>{request.headers![x]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </PageTable>) : <div>No Headers</div>}           
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>

            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Pre Query
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">  
                                <>
                                    <div>Rooms</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Path</th>
                                            </tr>
                                            {(request.log?.pre_query.rooms || []).map(x => (
                                                <tr key={x.location_entity.id}>
                                                    <td>{x.location_entity.id}</td>
                                                    <td>{x.location_entity.nickname}</td>
                                                    <td>{x.location_entity.path.replace("/domains/","")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>

                                    <div>Friends</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Location Nickname</th>
                                                <th>Location Path</th>
                                            </tr>
                                            {(request.log?.pre_query.friends || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.location_entity?.nickname || ""}</td>
                                                    <td>{x.location_entity?.path?.replace("/domains/","") || ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                    
                                    <div>People</div>
                                    <PageTable>
                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nickname</th>
                                                <th>Location Nickname</th>
                                                <th>Location Path</th>
                                            </tr>
                                            {(request.log?.pre_query.people || []).map(x => (
                                                <tr key={x.id}>
                                                    <td>{x.id}</td>
                                                    <td>{x.nickname}</td>
                                                    <td>{x.location_entity?.nickname || ""}</td>
                                                    <td>{x.location_entity?.path?.replace("/domains/","") || ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </PageTable>
                                </>     
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </>)    
}