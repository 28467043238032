import React, { useState, useEffect } from "react"
import { TVector2 } from "./maptypes"
import { MapLoader, TLoadingCell } from "./maploader"


export const useMapLoader = (callback?:(cell:TLoadingCell|null)=>void) => {
    const maploader = React.useRef(new MapLoader())
    const callbackref = React.useRef(callback)

    const [counter,set_counter] = useState(0)
    useEffect(()=>{
        maploader.current.start((cell) => {
            set_counter(Date.now())
            if(callbackref.current) {
                callbackref.current(cell)
            }
        })
        return ()=>maploader.current.stop()
    },[])   

    useEffect(() => {
        callbackref.current = callback;
    }, [callback])

    return maploader.current
}

export const calc_world2screen = (world_ppos: TVector2, width: number, height: number, originX: number, originY: number, scale: number) => {
    return [(world_ppos[0]-originX)*scale+width/2,height-((world_ppos[1]-originY)*scale+height/2)]
}
export const calc_screen2world = (screen_pos: TVector2, width: number, height: number, originX: number, originY: number, scale: number) => {
    return [(screen_pos[0]-width/2)/scale+originX,((height-screen_pos[1])-height/2)/scale+originY]
}