import React from 'react';
import BasicPage from '../../components/basicPage';
import { useCurrentDomainUri } from '../../utils/entitycache';
import { DomainEntityTable } from '../../components/domainEntityTable';
import { Tabs, Tab } from 'react-bootstrap';
import { MultiverseBaseEntity, MultiverseEvent } from '../../hoc/multiverseApiProvider';
import { PageTableCell } from '../../components/pageTable';
import InfiniverseMap from '../../components/map';

export function MapPage(props: {}) {
    const targeturl = useCurrentDomainUri();

    return (
        <BasicPage title="Map">
            <InfiniverseMap/>
        </BasicPage>
    )    
}
export default MapPage;