import { MapLoader, TLoadingCell } from "./maploader";
import { MapCanvas } from "./mapcanvas";
import { calc_world2screen } from "./maphelpers";
import * as vector from "./vector"
import { TVector2 } from "./maptypes";


export class MapCanvasWindow {

    map: MapLoader;
    granularity: number;
    canvases: { [id: string]: MapCanvas }

    constructor(map: MapLoader, granularity: number) {
        this.map = map;
        this.granularity = granularity;
        this.canvases = {}
    }

    onCellLoaded(cell: TLoadingCell) {
        if(cell.state == 'loaded' && cell.data) {
            const poly = cell.data.district.boundingpoly;
            const min = [...poly[0]];
            const max = [...min];
            for(let i = 1; i < poly.length; i++) {
                min[0] = Math.min(min[0],poly[i][0]);
                min[1] = Math.min(min[1],poly[i][1]);
                max[0] = Math.max(max[0],poly[i][0]);
                max[1] = Math.max(max[1],poly[i][1]);
            }
            const iminx = Math.round((min[0]-this.granularity)/this.granularity-1)*this.granularity;
            const iminy = Math.round((min[1]-this.granularity)/this.granularity-1)*this.granularity;
            const imaxx = Math.round((max[0]+this.granularity)/this.granularity+1)*this.granularity;
            const imaxy = Math.round((max[1]+this.granularity)/this.granularity+1)*this.granularity;

            for(let x = iminx; x < imaxx; x+=this.granularity) {
                for(let y = iminy; y < imaxy; y+=this.granularity) {
                    const id = `${x}_${y}`;
                    let canvas = this.canvases[id];
                    if(canvas) {
                        console.log(`Redraw ${id}`)
                        canvas.addCell(cell);
                        canvas.redraw();
                    }
                }
            }        
        }
    }

    redrawAll(target: HTMLCanvasElement, minx: number, miny: number, maxx: number, maxy: number) {
        const iminx = Math.round((minx-this.granularity)/this.granularity-1)*this.granularity;
        const iminy = Math.round((miny-this.granularity)/this.granularity-1)*this.granularity;
        const imaxx = Math.round((maxx+this.granularity)/this.granularity+1)*this.granularity;
        const imaxy = Math.round((maxy+this.granularity)/this.granularity+1)*this.granularity;

        Object.values(this.canvases).forEach(x => x.needed = false);

        const context = target.getContext('2d');  
        const xscale = target.width / (maxx-minx);
        const yscale = target.height / (maxy-miny);

        let drawn = 0;
        let count = 0;

        for(let x = iminx; x < imaxx; x+=this.granularity) {
            for(let y = iminy; y < imaxy; y+=this.granularity) {
                const id = `${x}_${y}`;
                let canvas = this.canvases[id];
                if(!canvas) {
                    canvas = new MapCanvas(this.map, [x, y], this.granularity);
                    canvas.rebuildAndRedraw();
                    this.canvases[id] = canvas;
                }
                canvas.needed = true;

                count++;
                if(context) {
                    //console.log({x,y,xscale,yscale})
                    const a = calc_world2screen(canvas.getMin(), target.width, target.height, (minx+maxx)*0.5, (miny+maxy)*0.5, xscale);
                    const b = calc_world2screen(canvas.getMax(), target.width, target.height, (minx+maxx)*0.5, (miny+maxy)*0.5, xscale);
                    //console.log({cmx:canvas.minx,cmy:canvas.miny,dx,dy})
                    const min = vector.min(a,b)
                    const max = vector.max(a,b)
                    const size = vector.sub(max,min);

                    context.drawImage(canvas.canvas, min[0], min[1], size[0], size[1])
                    drawn++;
                }
            }
        }
        
        Object.values(this.canvases).forEach(x => {
            if(!x.needed) {
                x.destroy();
                delete this.canvases[ `${x.centre[0]}_${x.centre[1]}`]
            }
        });

        //console.log(`Drawn ${drawn} of ${count}`)

    }

    getFeaturesAtPoint(world_pos: TVector2) {
        const canvases = Object.values(this.canvases)
        for(let i = 0; i < canvases.length; i++) {
            const features = canvases[i].getFeaturesAtPoint(world_pos);
            if(features) {
                return features;
            }
        }
        return null;
    }

}