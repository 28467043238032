import React, { RefObject } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';

import * as webrtc from '../../webrtc/webrtc/adapter-latest'
import { WebRTCAdaptor } from '../../webrtc/antmedia/webrtc_adaptor'
import { Button, Col, Container, Row, Image, Dropdown } from 'react-bootstrap';
import { BsCameraVideo, BsMic } from 'react-icons/bs';
import { AiOutlineStop } from 'react-icons/ai'
import { MdScreenShare } from 'react-icons/md'
import { TiMediaRecordOutline, TiMediaRecord, TiTick } from 'react-icons/ti'
import PreviewBackground from '../../assets/classroom.jpg'

type MultiversePublishConfig = {
    websocket: string;
    streamid: string;
    streamurl: string;
    browserurl: string;
}

type StreamPageProps = {

} & WithMultiverseApiProps;

type StreamPageState = {
    adaptor: WebRTCAdaptor | null,
    isLoading: boolean,
    isPublishing: boolean;
    error: string | null,
    publishConfig: MultiversePublishConfig | null,
    screenShareSupported: boolean;
    isScreenSharing: boolean;
    inputDevices: InputDeviceInfo[];
    audioDevice: InputDeviceInfo | null;
    videoDevice: InputDeviceInfo | null;
};

class _StreamPage extends React.Component<StreamPageProps, StreamPageState> {

    iFrameRef: RefObject<HTMLIFrameElement>;

    constructor(props: StreamPageProps) {
        super(props);
        this.state = {
            error: null,
            adaptor: null,
            isLoading: true,
            isPublishing: false,
            publishConfig: null,
            screenShareSupported: false,
            isScreenSharing: false,
            inputDevices: [],
            audioDevice: null,
            videoDevice: null
        };
        this.iFrameRef = React.createRef<HTMLIFrameElement>();
    }

    componentDidMount = async () => {

        const { multiverse: { get } } = this.props;

        try {
            const publish_info = await get<MultiversePublishConfig>({
                url: "/v2/stream/camera/publishconfig",
                hideErrors: true
            });
            this.setState({
                publishConfig: publish_info
            })

            this.initWebRTCAdaptor(false, false);
        } catch (err) {
            this.setState({
                error: `${err.message}`
            })
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    componentDidUpdate = (prevProps: StreamPageProps, prevState: StreamPageState) => {
        const { publishConfig } = this.state;
        if (publishConfig && !prevState.publishConfig) {
            this.initWebRTCAdaptor(false, false);
        }
    }

    videoDeviceDropdownItem = (x: InputDeviceInfo | null) => {
        if (!x) {
            const selected = this.state.videoDevice == null;
            return (
                <Dropdown.Item onClick={() => this.onCameraItemClick(null)}>
                    {selected ? <TiTick size="30" /> : <div className="d-inline-block p-0 m-0" style={{ width: 30 }} />}
                No Camera
                </Dropdown.Item>);
        } else {
            const selected = (this.state.videoDevice && this.state.videoDevice.deviceId === x.deviceId);
            return (
                <Dropdown.Item onClick={() => this.onCameraItemClick(x)}>
                    {selected ? <TiTick size="30" /> : <div className="d-inline-block p-0 m-0" style={{ width: 30 }} />}
                    {x.label}
                </Dropdown.Item>);
        }
    }

    audioDeviceDropdownItem = (x: InputDeviceInfo | null) => {
        if (!x) {
            const selected = this.state.audioDevice == null;
            return (
                <Dropdown.Item onClick={() => this.onAudioItemClick(null)}>
                    {selected ? <TiTick size="30" /> : <div className="d-inline-block p-0 m-0" style={{ width: 30 }} />}
                No Microphone
                </Dropdown.Item>);
        } else {
            const selected = (this.state.audioDevice && this.state.audioDevice.deviceId === x.deviceId);
            return (
                <Dropdown.Item onClick={() => this.onAudioItemClick(x)}>
                    {selected ? <TiTick size="30" /> : <div className="d-inline-block p-0 m-0" style={{ width: 30 }} />}
                    {x.label}
                </Dropdown.Item>);
        }
    }

    renderMainPage = () => {
        const { isLoading, publishConfig: publishInfo, isPublishing, isScreenSharing, screenShareSupported, inputDevices, error } = this.state;

        return (<>
            <Row className="pt-4">
                <Col>
                    <p>Click <em>Start</em> to begin streaming straight to VR. Access it within Multiverse using the <strong>My Stream</strong> button under <strong>My Content</strong>.</p>
                    <p>Please note this feature is in <strong>beta</strong> and not fully tested. We are currently aware of streaming issues from recent versions of Chrome and Safari</p>
                    </Col>
            </Row>
            <Row className="pt-2">
                <Col style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    <div className="d-flex flex-column" style={{ position: 'relative' }}>
                        <video className="local-video" id="localVideo" autoPlay muted playsInline>

                        </video>
                        <Container className="local-video-controls" fluid>
                            <Row>
                                <Col className="pl-1 col-9" style={{ textAlign: "left" }}>
                                    <Dropdown className="d-inline-block" drop='right'>
                                        <Dropdown.Toggle variant="light" className="header-button" id="dropdown-camera">
                                            <BsCameraVideo className="icon-image p-1" />
                                            <AiOutlineStop className="icon-image-overlay p-1" visibility='hidden' />
                                        </Dropdown.Toggle >
                                        <Dropdown.Menu>
                                            {this.videoDeviceDropdownItem(null)}
                                            {inputDevices.filter(x => x.kind === "videoinput").map(x => this.videoDeviceDropdownItem(x))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="d-none d-sm-inline-block ml-1">
                                        Camera<br></br>
                                    </div>

                                    <Dropdown className="d-inline-block" drop='right'>
                                        <Dropdown.Toggle variant="light" className="header-button ml-3" id="dropdown-camera">
                                            <BsMic className="icon-image p-1" />
                                            <AiOutlineStop className="icon-image-overlay p-1" visibility='hidden' />
                                        </Dropdown.Toggle >
                                        <Dropdown.Menu>
                                            {this.audioDeviceDropdownItem(null)}
                                            {inputDevices.filter(x => x.kind === "audioinput").map(x => this.audioDeviceDropdownItem(x))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="d-none d-sm-inline-block ml-1">
                                        Audio<br></br>
                                    </div>

                                    <Button variant={isScreenSharing ? "primary" : "light"} className="header-button ml-3" disabled={!screenShareSupported} onClick={this.onScreenShareClick}>
                                        <MdScreenShare className="icon-image p-1" />
                                    </Button>
                                    <div className="d-none d-sm-inline-block ml-1">
                                        Screen<br></br>
                                    </div>
                                </Col>
                                <Col className="pr-2 col-3" style={{ textAlign: 'right' }}>
                                    <Button variant={isPublishing ? "primary" : "light"} className="header-button" onClick={this.onPublishClick}>
                                        <TiMediaRecordOutline className="icon-image-overlay p-1" />
                                        {/*<TiMediaRecord className="icon-image p-1" />*/}
                                    </Button>
                                    <div className="d-none d-sm-inline-block ml-1">
                                        {isPublishing ? "Stop" : "Start"}<br></br>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Col>
            </Row>

            <Row className="pt-4">
                <Col>
                    Use this live preview to see what your stream looks like for people watching in Multiverse.
                    </Col>
            </Row>
            <Row className="pt-2">
                <Col style={{ position: 'relative' }}>
                    <div className="p-0 m-0" style={{ position: 'relative' }}>

                        <Image src={PreviewBackground} style={{ maxWidth: "100%" }} />
                        <iframe ref={this.iFrameRef} className="stream-preview-iframe" src={(isPublishing && publishInfo) ? publishInfo.browserurl : ""} scrolling='no'>

                        </iframe>
                    </div>
                </Col>

            </Row>
        </>
        )
    }

    renderErrorPage = () => {
        const { isLoading, publishConfig: publishInfo, isPublishing, isScreenSharing, screenShareSupported, inputDevices, error } = this.state;
        return (<>
            <Row className="pt-4">
                <Col className="text-center">
                    {(error === "stream permission missing") ? (<>
                        <h2>Streaming Is Not Enabled For This Account</h2>
                        <p>Currently the ability to stream from your web cam or desktop live to VR is a feature restricted to certain users and enterprise customers. If you would like to 
                            discuss enterprise domains or request a demo, feel free to contact us at <a href="mailto:sales@ftl.ltd">sales@ftl.ltd</a></p>
                        <p>If you're already a customer and think you should have access, please contact us at <a href="mailto:support@ftl.ltd">support@ftl.ltd</a> for support.</p>

                    </>) : (<>
                        <h2>Uh Oh!</h2>
                        An unhandled error occured. If you can't work out the problem, please report this message: <strong>{error}</strong> to our support team at <a href="mailto:support@ftl.ltd">support@ftl.ltd</a> and we'll do our best to help out.
                    </>)}
                </Col>
            </Row>
        </>)
    }

    render(): JSX.Element {

        const { isLoading, publishConfig: publishInfo, isPublishing, isScreenSharing, screenShareSupported, inputDevices, error } = this.state;

        return (
            <BasicPage className={isLoading ? "stream-page-loading" : ""} style={{ maxWidth: 697 }}>
                {error === null && this.renderMainPage()}
                {error !== null && this.renderErrorPage()}
            </BasicPage>
        )
    }

    onPublishClick = () => {
        const { adaptor, publishConfig, isPublishing } = this.state;
        if (adaptor && publishConfig) {
            if (isPublishing) {
                adaptor.stop(publishConfig.streamid);
            } else {
                adaptor.publish(publishConfig.streamid, "");
            }
        }
    }

    onScreenShareClick = () => {
        const { adaptor, publishConfig, isScreenSharing, videoDevice } = this.state;
        if (adaptor && publishConfig) {
            if (isScreenSharing) {
                adaptor.switchVideoCameraCapture(publishConfig.streamid, videoDevice ? videoDevice.deviceId : null);
                this.setState({ isScreenSharing: false });
            } else {
                adaptor.switchDesktopCapture(publishConfig.streamid);
                this.setState({ isScreenSharing: true });
            }
        }
    }

    onCameraItemClick = (device: InputDeviceInfo | null) => {
        const { adaptor, publishConfig, isScreenSharing, videoDevice } = this.state;
        if (adaptor && publishConfig) {
            this.setState({
                videoDevice: device
            })
            if (!isScreenSharing) {
                adaptor.switchVideoCameraCapture(publishConfig.streamid, device ? device.deviceId : null);
            }
        }
    }

    onAudioItemClick = (device: InputDeviceInfo | null) => {
        const { adaptor, publishConfig, isScreenSharing, videoDevice } = this.state;
        if (adaptor && publishConfig) {
            this.setState({
                audioDevice: device
            })

            adaptor.switchAudioInputSource(publishConfig.streamid, device ? device.deviceId : null);
        }
    }

    initWebRTCAdaptor = (publishImmediately: boolean, autoRepublishEnabled: boolean) => {
        const { publishConfig } = this.state;
        if (!publishConfig) {
            throw new Error("Attempt to init WebRTC adaptor before publish config received")
        }

        console.log("initWebRTCAdaptor");

        var sdpConstraints = {
            offerToReceiveAudio: false,
            offerToReceiveVideo: false
        };

        var mediaConstraints = {
            video: true,
            audio: true
        };
        var pc_config = {
            'iceServers': [{
                'urls': 'stun:stun1.l.google.com:19302'
            }]
        };

        const streamId = publishConfig.streamid;
        const token = "";

        const webRTCAdaptor = new WebRTCAdaptor({
            websocket_url: publishConfig.websocket,
            mediaConstraints: mediaConstraints,
            peerconnection_config: pc_config,
            sdp_constraints: sdpConstraints,
            localVideoId: "localVideo",
            debug: false,
            bandwidth: 2000,
            callback: (info: string, obj: any) => {
                if (info == "initialized") {
                    //console.log("initialized");
                    //start_publish_button.disabled = false;
                    //stop_publish_button.disabled = true;
                    if (publishImmediately) {
                        webRTCAdaptor.publish(streamId, token)
                    }
                } else if (info == "publish_started") {
                    //stream is being published
                    console.log("publish started");
                    this.setState({
                        isPublishing: true
                    })
                    /*start_publish_button.disabled = true;
                    stop_publish_button.disabled = false;
                    startAnimation();
                    if (autoRepublishEnabled && autoRepublishIntervalJob == null) 
                    {
                        autoRepublishIntervalJob = setInterval(() => {
                            checkAndRepublishIfRequired();
                        }, 3000);
                    }*/
                    webRTCAdaptor.enableStats(obj.streamId);
                    //enableAudioLevel();
                } else if (info == "publish_finished") {
                    //stream is being finished
                    console.log("publish finished");
                    this.setState({
                        isPublishing: false
                    })
                    //start_publish_button.disabled = false;
                    //stop_publish_button.disabled = true;
                    //$("#stats_panel").hide();
                }
                else if (info == "browser_screen_share_supported") {
                    //$(".video-source").prop("disabled", false);
                    console.log("browser screen share supported");
                    this.setState({
                        screenShareSupported: true
                    })
                    //browser_screen_share_doesnt_support.style.display = "none";
                }
                else if (info == "screen_share_stopped") {
                    //choose the first video source. It may not be correct for all cases. 
                    //$(".video-source").first().prop("checked", true);	
                    console.log("screen share stopped");
                    this.setState({
                        isScreenSharing: false
                    })
                }
                else if (info == "closed") {
                    //console.log("Connection closed");
                    if (typeof obj != "undefined") {
                        console.log("Connecton closed: " + JSON.stringify(obj));
                    }
                }
                else if (info == "pong") {
                    //ping/pong message are sent to and received from server to make the connection alive all the time
                    //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
                }
                else if (info == "refreshConnection") {
                    //checkAndRepublishIfRequired();
                }
                else if (info == "ice_connection_state_changed") {
                    console.log("iceConnectionState Changed: ", JSON.stringify(obj));
                }
                else if (info == "updated_stats") {
                    //obj is the PeerStats which has fields
                    //averageOutgoingBitrate - kbits/sec
                    //currentOutgoingBitrate - kbits/sec
                    /*console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec"
                        + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec"
                        + " video source width: " + obj.resWidth + " video source height: " + obj.resHeight
                        + "frame width: " + obj.frameWidth + " frame height: " + obj.frameHeight
                        + " video packetLost: " + obj.videoPacketsLost + " audio packetsLost: " + obj.audioPacketsLost
                        + " video RTT: " + obj.videoRoundTripTime + " audio RTT: " + obj.audioRoundTripTime
                        + " video jitter: " + obj.videoJitter + " audio jitter: " + obj.audioJitter);*/
                }
                else if (info == "data_received") {
                    console.log("Data received: " + obj.event.data + " type: " + obj.event.type + " for stream: " + obj.streamId);
                    //$("#dataMessagesTextarea").append("Received: " + obj.event.data + "\r\n");
                }
                else if (info == "available_devices") {
                    console.log("Available devices");
                    console.log(obj);

                    const inputDevices: InputDeviceInfo[] = obj;
                    let videoDevice: InputDeviceInfo | null = null;
                    let audioDevice: InputDeviceInfo | null = null;
                    inputDevices.forEach(x => {
                        if (!videoDevice && x.kind === 'videoinput') { videoDevice = x; }
                        if (!audioDevice && x.kind === 'audioinput') { audioDevice = x; }
                    })

                    this.setState({
                        inputDevices,
                        videoDevice,
                        audioDevice
                    })

                    /*
                    var videoHtmlContent = "";
                    var audioHtmlContent = "";
                    var devices = new Array();
        	
                    var i = 0;
                    obj.forEach(function(device) {
                        var label = device.label;
                        var deviceId = device.deviceId;
                        var devices = new Array();

                        devices.forEach(function(same){
                            if (same == device.label){
                                i += 1;
                                label = device.label + " - " + i
                                deviceId = device.deviceId + i
                            }
                        })
                        if (device.kind == "videoinput") {
                            videoHtmlContent += getCameraRadioButton(label, device.deviceId);
                        }
                        else if (device.kind == "audioinput"){
                            audioHtmlContent += getAudioRadioButton(label, device.deviceId);
                        }
                        devices.push(device.label)
                    }); 
                    $('[name="videoSource"]').remove();
                    $('[name="audioDeviceSource"]').remove();

                    videoHtmlContent += getScreenButton();
                    videoHtmlContent += getScreenWithCamButton();

                    $(videoHtmlContent).insertAfter(".video-source-legend");
                    $(".video-source").first().prop("checked", true);	
                	
                    $(audioHtmlContent).insertAfter(".audio-source-legend");
                    $(".audio-source").first().prop("checked", true);	

                	
                    if (document.querySelector('input[name="videoSource"]')) {
                        document.querySelectorAll('input[name="videoSource"]').forEach((elem) => {
                            elem.addEventListener("change", function(event) {
                            var item = event.target;
                            switchVideoMode(item)
                            selectedRadio = item.value;
                            });
                            });
                    }
                    if (document.querySelector('input[name="audioSource"]')) {
                        document.querySelectorAll('input[name="audioSource"]').forEach((elem) => {
                            elem.addEventListener("change", function(event) {
                            var item = event.target;
                            switchAudioMode(item)
                            selectedAudio = item.value;
                            });
                            });
                    }
                    $(":radio[value=" + selectedRadio + "]").prop("checked", true);
                    $(":radio[value=" + selectedAudio + "]").prop("checked", true);
                    */
                }
                else {
                    console.log(info + " notification received");
                }

            },
            callbackError: function (error: any, message: string) {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + JSON.stringify(error));
                var errorMessage = JSON.stringify(error);
                if (typeof message != "undefined") {
                    errorMessage = message;
                }
                var errorMessage = JSON.stringify(error);
                if (error.indexOf("NotFoundError") != -1) {
                    errorMessage = "Camera or Mic are not found or not allowed in your device";
                }
                else if (error.indexOf("NotReadableError") != -1 || error.indexOf("TrackStartError") != -1) {
                    errorMessage = "Camera or Mic is being used by some other process that does not let read the devices";
                }
                else if (error.indexOf("OverconstrainedError") != -1 || error.indexOf("ConstraintNotSatisfiedError") != -1) {
                    errorMessage = "There is no device found that fits your video and audio constraints. You may change video and audio constraints"
                }
                else if (error.indexOf("NotAllowedError") != -1 || error.indexOf("PermissionDeniedError") != -1) {
                    errorMessage = "You are not allowed to access camera and mic.";
                }
                else if (error.indexOf("TypeError") != -1) {
                    errorMessage = "Video/Audio is required";
                }
                else if (error.indexOf("getUserMediaIsNotAllowed") != -1) {
                    errorMessage = "You are not allowed to reach devices from an insecure origin, please enable ssl";
                }
                else if (error.indexOf("ScreenSharePermissionDenied") != -1) {
                    errorMessage = "You are not allowed to access screen share";
                }
                else if (error.indexOf("WebSocketNotConnected") != -1) {
                    errorMessage = "WebSocket Connection is disconnected.";
                }
                console.log(errorMessage);

                //throw new Error(errorMessage)
            }
        });
        this.setState({
            adaptor: webRTCAdaptor
        })
        //webRTCAdaptor.switchDesktopCapture("");
    }
}
export const StreamPage = withMultiverseApi(_StreamPage);
export default StreamPage;
