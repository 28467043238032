import React, { useState, useEffect } from "react"
import { useInterval } from "../../utils/hooks"
import api from "../../utils/api"
import DialogBox from "../../components/dialogBox"
import { FormGroup, Form, Spinner } from "react-bootstrap"

export type TOculusEvent = {
    oculusid: string
    start: Date,
    end: Date,
    title: string,
    published: boolean,
    subscribers: number 
}
export function OculusEventSelectDialog(props:{ show: boolean, title: string, onConfirm: (e: TOculusEvent)=>void, onCancel: ()=>void, expectTitle?: string, expectStart?: Date, expectEnd?: Date }) {
    const [value,setValue] = useState<string>("")
    const [dirty,setDirty] = useState<number>(0)
    const [event,setEvent] = useState<any>(null)
    const [loading,setLoading] = useState<boolean>(false)
    const [error,setError] = useState<string>()
    const [ignoreErrors,setIgnoreErrors] = useState<boolean>(false)

    useEffect(() => {
        setValue("")
        setDirty(0)
        setEvent(0)
        setLoading(false)
        setError(undefined)
        setIgnoreErrors(false)
    }, [props.show])

    useEffect(() => {
        setDirty(Date.now())
    }, [value])

    useInterval(() => {
        if(dirty && (Date.now()-dirty) >= 1000 && value.length > 0) {
            setDirty(0)
            setLoading(true)
            api.get<any>(`/v2/admin/oculusevents/${value}`)
            .then((x) => {
                if(props.expectTitle && x.title.toLocaleLowerCase() !== props.expectTitle.toLocaleLowerCase()) {
                    setError(`Oculus event title doesn't match expected title: (${props.expectTitle})`)
                    setEvent(null)
                }
                if(props.expectStart && Math.abs(new Date(x.start).getTime()-props.expectStart.getTime()) > 60000) {
                    setError(`Oculus event start doesn't match expected start: (${props.expectStart.toLocaleString()})`)
                    setEvent(null)
                }
                if(props.expectEnd && Math.abs(new Date(x.end).getTime()-props.expectEnd.getTime()) > 60000) {
                    setError(`Oculus event end doesn't match expected end: (${props.expectEnd.toLocaleString()})`)
                    setEvent(null)
                }

                setEvent(x)
            })  
            .catch((err) => {
                console.log(err)
                setEvent(null)
                setError("Event not found")
            })
            .then(() => {
                setLoading(false)
            })
        }
    }, 500)

    return <DialogBox {...props} onConfirm={()=>props.onConfirm(event)} disableConfirm={event==null||dirty!=0||loading||(error!=undefined&&!ignoreErrors)} button0Text="Ok" cancelButtonText="Cancel" >
        <Form>
            <FormGroup>
                <Form.Label>Oculus Event Id</Form.Label>
                <div style={{position:'relative'}}>
                    <Form.Control value={value} onChange={(ev) => setValue(ev.currentTarget.value)}/>
                    {loading && <Spinner animation='border' style={{position:'absolute',top:6,right:6,width:25,height:25}}/>}
                </div>
            </FormGroup>
            <FormGroup>
                <Form.Check type='checkbox' label='Ignore Errors' checked={ignoreErrors} onChange={(ev) => setIgnoreErrors(ev.currentTarget.checked)}/>
            </FormGroup>
        </Form>
        {event && <div>
            <div>Title: {event.title}</div>
            <div>Start: {new Date(event.start).toLocaleString()}</div>
            <div>End: {new Date(event.end).toLocaleString()}</div>
            <div>Subscribers: {event.subscribers}</div>
        </div>}
        {error && <div>
            {error}
        </div>}
    </DialogBox>
}
export default OculusEventSelectDialog