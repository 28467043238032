import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import { RestApiDocumentData, RestApiSpecResponse } from '.';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import { RestApiFieldDefinition } from './restApiCollection';
import { getBlobUrl } from '../../config/api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import api from '../../utils/api';
import "./restapi.css"
import { LocationUriDialog } from '../locationUrlDialog';

function Offers(x: any, data:any) {
    if(x.refuseoffers) {
        return "refuse";
    }
    let count = 0;
    let max = 0;
    if(data.slotoffers) {
        data.slotoffers.forEach((slotoffer: any) => {
            if(x.slot === slotoffer.slot) {
                if(slotoffer.escrowoffers) {
                    count = slotoffer.escrowoffers.length
                    slotoffer.escrowoffers.forEach((escrow:any) => {
                        if(escrow.amount > max) max = escrow.amount;
                    })
                }
                // include possible logged-in user's offer too!
                if(slotoffer.userescrowoffer) {
                    count++;
                    if(slotoffer.userescrowoffer.amount > max) max = slotoffer.userescrowoffer.amount;
                }
            }
        })
    }
    if(count > 0)
    {
        return `${count} (h: ${max})`
    }
    return "";
}

export function SlotsRow(props: { data: RestApiDocumentData, onChange: ()=>void }) {
    const { data } = props;

    const renderSlots = (x: any) => (
        <PageTable.Row key={x.slot}>
            <PageTable.IconCell src={x.iconurl}/>
            <PageTableCell>{x.slot}</PageTableCell>
            <PageTableCell>{x.owner}{x._owner ? ` (${x._owner.nickname})` : ""} </PageTableCell>
            <PageTableCell>{x.boughtfor}</PageTableCell>
            <PageTableCell>{(x.marketsale && x.marketsale > 0) ? x.marketsale : ""}</PageTableCell>
            <PageTableCell>{Offers(x, data)}</PageTableCell>
            <PageTableCell>{x.destination}</PageTableCell>
            <PageTableCell>{x.destdoorid}</PageTableCell>
        </PageTable.Row>
    )

    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Slots
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        {(data.slots && data.slots.length > 0) ? (
                        <PageTable>
                            <thead>
                                <PageTableRow>
                                    <th style={{width:50}}>Icon</th>
                                    <th style={{width:40}}>Slot</th>
                                    <th style={{width:200}}>Owner</th>
                                    <th style={{width:60}}>Bought For</th>
                                    <th style={{width:70}}>On Sale</th>
                                    <th style={{width:100}}>Offers</th>
                                    <th style={{width:600}}>Destination</th>
                                    <th>Door Id</th>
                                </PageTableRow>
                            </thead>
                            <tbody>
                                {data.slots.map((x:any) => renderSlots(x))}
                            </tbody>
                        </PageTable>) : <div>No Slots Found</div>}
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}


function StorefrontContent(props: { data: RestApiDocumentData, onChange: ()=>void }) {

    const [showlocselect,setShowlocselect] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)


    const onAssign = () => {
        setShowlocselect(true)
    }

    const onSelectLocation = (uri?: string) => {
        setShowlocselect(false)
        if(uri) {
            applyUri(uri)
        }
    }

    const applyUri = async(uri: string) => {
        setLoading(true)
        await api.post(`/v2/admin/buildings/${props.data._id}/assignstore`, {
            destination: uri
        })
        setLoading(false)
        props.onChange()
    }

    const messageAndButton = () => {
        if(!props.data["storefront_slot"]) {
            return <div>
                <LocationUriDialog show={showlocselect} onComplete={onSelectLocation}/>
                <div>Store front doesn't currently exist</div>
                <Button variant="primary" onClick={() => onAssign()}>Create+Assign Storefront</Button>
            </div> 
        } else if(!props.data["storefront_slot"]["owner"]) {
            return <div>
                <LocationUriDialog show={showlocselect} onComplete={onSelectLocation}/>
                <div>Store front exists but is not owned</div>
                <Button variant="primary" onClick={() => onAssign()}>Assign Storefront</Button>
            </div>
        } else {
            return <div>
                <LocationUriDialog show={showlocselect} onComplete={onSelectLocation}/>
                <div>Store front is owned and links to {props.data["storefront_slot"]["destination"]}</div>
                <Button variant="primary" onClick={() => onAssign()}>Re-assign Storefront</Button>
            </div>        
        } 
    }

    if(loading) {
        return <div>
            Loading
        </div>
    } else {
        return (
        <div>
            Estimated value: {props.data.buystore}MC ({props.data.buystore/100}$)
            {messageAndButton()}
        </div>)
    }
}

function StorefrontRow(props: { data: RestApiDocumentData, onChange: ()=>void }) {

    if(!props.data["storefront"]) {
        return <></>;
    }
        
    return (
        <Row>
            <Col className="restapi-documentfields-col">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Storefront
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">                       
                            <StorefrontContent {...props}/>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>)      

}

function HeaderRow(props: { data: RestApiDocumentData, onChange: ()=>void }) {

    const calcPostCode = () => {
        let x = props.data.cellx-2147483647;
        let y = props.data.celly-2147483647;
        const xprefix = x < 0 ? 'W' : 'E'
        const yprefix = y < 0 ? 'S' : 'N'
        x = Math.abs(x)
        y = Math.abs(y)
        return `${yprefix}${y}-${xprefix}${x}-${props.data.subdistrictid}-${props.data.buildingid}`
    }

    return (
    <Row className="restapi-entityheader">
        <Col>
            <Container fluid>
                <Row><Col><h3 className="mb-0">{props.data.name}</h3></Col></Row>
                <Row><Col>{calcPostCode()}</Col></Row>
            </Container>
        </Col>
    </Row>) 
}

function ButtonsRow(props: { data: RestApiDocumentData, onChange: ()=>void } ) {

    const onClick = () => {
        api.post(`/v2/admin/buildings/${props.data._id}/teleport`, {})
    }

    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Controls
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">                       
                        <Button variant="primary" onClick={onClick}>Teleport Outside</Button>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)  
}

export function BuildingRows(props: { data: RestApiDocumentData, onChange: ()=>void } ) {
    return <>
        <HeaderRow {...props}/>
        <ButtonsRow {...props}/>
        <StorefrontRow {...props}/>
        <SlotsRow {...props}/>
    </>
}