import React, { FunctionComponent } from 'react';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

type IsNotAuthenticatedProps = WithMultiverseApiProps;
const IsNotAuthenticated:FunctionComponent<IsNotAuthenticatedProps> = (props) => {
  const { multiverse: { isAuthenticated, isLoading }, children } = props;
  return (!isAuthenticated && !isLoading) ? (
    <>
      {children}
    </>
  ) : null;
};

export default withMultiverseApi(IsNotAuthenticated);
