import React from 'react';
import { Modal } from 'react-bootstrap';
import { ItemInfo } from './item';
import DriveBrowserBody from '../../components/driveBrowser/driveBrowserBody';
import './browsedrivedialog.css'

type BrowseDriveDialogProps = {
    show: boolean;
    onClickItem?: (directory: string, item: ItemInfo)=>void;
    onClose?: ()=>void;
};

type BrowseDriveDialogState = {
    location: string;
};
class _BrowseDriveDialog extends React.Component<BrowseDriveDialogProps, BrowseDriveDialogState> {

    constructor(props: BrowseDriveDialogProps) {
        super(props);

        this.state = {
            location: ""
        };
    }

    _handleCancel = () => {
        if(this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <Modal className="browse-drive-dialog" show={this.props.show} onHide={this._handleCancel} style={{padding: 0}} >
                <Modal.Body>
                    <DriveBrowserBody 
                        path={this.state.location}
                        onChangePath={(np) => this.setState({location: np})}
                        showDrivesList
                        showClose
                        onClose={this._handleCancel}
                        onSelectItem={this.props.onClickItem}
                    />
                </Modal.Body>     

            </Modal>
        );
    }
}
const BrowseDriveDialog = _BrowseDriveDialog;
export default BrowseDriveDialog;
 