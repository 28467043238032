import React, { useRef } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseFile, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { FileDrop } from 'react-file-drop'
import './contentSelector.css'
import { Button, Dropdown, DropdownButton, Image, OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';
import { BsPlus, BsFillCaretDownFill, BsExclamationCircle } from 'react-icons/bs';
import { getApiUrl, getBlobUrl } from '../../config/api';
import videojs from 'video.js'
import VideoPlayer from './videoplayer';
import { warn } from 'console';
import * as tus from 'tus-js-client'
import { uploadContent } from '../../utils/uploadContent';
import api from '../../utils/api';
import ContentBox from '../../components/contentBox';
import IsDeveloper, { IsNotDeveloper } from '../../components/isDeveloper';
import isNotDeveloper from '../../components/isNotDeveloper';

type ContentSelectorProps = {
    name?: string;
    description?: string;
    defaultValue?: string;
    aspect?: string;
    onValueChanged?: (newValue: string) => void;
    onBrowseClicked?: () => void;
    onClearClicked?: () => void;
    onYoutubeClicked?: () => void;
    onVideoLinkClicked?: () => void;
    value: string;
    showSpinner?: boolean;
    disableImages?: boolean;
    disableVideos?: boolean;
    disableBrowser?: boolean;
    supportYoutube?: boolean;
    supportVideoLink?: boolean;
    domain?: MultiverseDomain | null
};

type ContentSelectorState = {
    uploading: boolean,
    loading: boolean,
    warning?: string;
    uploadProgress: number;
    uploadStatus?: string;
};

class _ContentSelector extends React.Component<ContentSelectorProps, ContentSelectorState> {
    fileInputRef = React.createRef<HTMLInputElement>();;

    constructor(props: ContentSelectorProps) {
        super(props);
        this.state = {
            uploading: false,
            loading: false,
            uploadProgress: -1
        };
    }

    onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            this.uploadFile(files);
        }
    }

    onDropFiles = (files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
        if (files) {
            this.uploadFile(files);
        }
    }

    uploadFile = async (files: FileList) => {
        const { domain, onValueChanged } = this.props;
       
        this.setState({ uploading: true, uploadProgress: 0, uploadStatus: undefined });

        try {
            const entity = await uploadContent({
                file: files[0],
                uri: `domains/${domain?.uri}`,
                onProgress: (uploadProgress: number, uploadStatus: string) => {
                    this.setState({ uploadProgress, uploadStatus });                    
                }
            })
            if(onValueChanged) {
                if(entity.blob) {
                    onValueChanged(`${getBlobUrl()}/${entity.blob}`);
                } else if(entity.link) {
                    onValueChanged(entity.link);
                } else {
                    onValueChanged("");
                }
            }
        } catch(err) {
            console.log(err);
        }

        this.setState({ uploading: false, uploadProgress: 0, uploadStatus: undefined });
    }

    onBrowseClicked = () => {
        if (this.props.onBrowseClicked) {
            this.props.onBrowseClicked();
        }

    }

    onClearClicked = () => {
        if (this.props.onClearClicked) {
            this.props.onClearClicked()
        }
    }

    render(): JSX.Element {
        const { name, description, aspect, value, disableImages, disableVideos, disableBrowser, defaultValue } = this.props;
        const { loading, uploading, uploadStatus } = this.state;
        let warning = this.state.warning;

        const asmatch = aspect?.match(/(\d+)\:(\d+)/);
        let aspect_x = 1;
        let aspect_y = 1;
        if (asmatch) {
            aspect_x = parseInt(asmatch[1])
            aspect_y = parseInt(asmatch[2])
        }

        const maxWidth = 800 * aspect_x / aspect_y;

        //calculate content
        let contentSrc: string | null = null;
        if(value && value !== "") {
            contentSrc = value;
        } else if(defaultValue && defaultValue !== "") {
            contentSrc = defaultValue;
        }
        if (contentSrc && contentSrc.startsWith("https://stream.shapevr.io")) {
            contentSrc = contentSrc.replace("playdelayed", "play")
        }


        const showProgress = uploading && uploadStatus;
        const showSpinner = loading || (uploading && !uploadStatus) || this.props.showSpinner;

        const showContent = contentSrc != null;

        const showInfo = !showContent;

        /*
        if(showImage && disableImages) {
            warning = "Images are not supported on your current plan. These may not display correctly in Multiverse";
        }
        if(showVideo && disableVideos) {
            warning = "Videos are not supported on your current plan. These may not display correctly in Multiverse";
        }
        if(showIFrame && disableBrowser) {
            warning = "Web sites and streams are not supported on your current plan. These may not display correctly in Multiverse";
        }
        */

        const showWarning = warning != undefined;


        let cn = "content-selector";
        if (showContent) {
            cn += " content-selector-showcontent"
        } else {
            cn += " content-selector-hidecontent"
        }
        if (showInfo) {
            cn += " content-selector-showinfo"
        } else {
            cn += " content-selector-hideinfo"
        }
        if (showSpinner) {
            cn += " content-selector-showspinner"
        } else {
            cn += " content-selector-hidespinner"
        }
        if (showProgress) {
            cn += " content-selector-showprogress"
        } else {
            cn += " content-selector-hideprogress"
        }
        if (showWarning) {
            cn += " content-selector-showwarning"
        } else {
            cn += " content-selector-hidewarning"
        }

        const renderTooltip = (props: any) => (
            <Tooltip id="button-tooltip" {...props}>
                {description} ({aspect})
            </Tooltip>
        );

        const getWarning = (container: string, src: string, type: string) => {
            if(warning) {
                return warning;
            }
            if(container === "image" && disableImages) {
                return "Videos are not supported on your current plan. These may not display correctly in Multiverse";
            }
            if(container === "video" && disableVideos) {
                return "Videos are not supported on your current plan. These may not display correctly in Multiverse";
            }
            if(container === "iframe" && disableBrowser) {
                return "Web sites and streams are not supported on your current plan. These may not display correctly in Multiverse";
            }
            return null;
        }

        const getUploadStatusLabel = () => {
            if(this.state.uploadStatus === "uploading") {
                return ""
            } else if(this.state.uploadStatus === "pending") {
                return "Pending"
            } else if(this.state.uploadStatus === "transcode_starting") {
                return "Queued";
            } else if(this.state.uploadStatus === "transcoding") {
                return "Processing";
            } else {
                return "";
            }
        }

        const onVideoLinkClicked = () => {
            if(this.props.onVideoLinkClicked) {
                this.props.onVideoLinkClicked()
            }
        }
        const onYoutubeClicked = () => {
            if(this.props.onYoutubeClicked) {
                this.props.onYoutubeClicked()
            }
        }

        return (
            <div className={cn} style={{ maxWidth }}>
                {name && <p className="name">
                    <strong>{name}</strong>
                    <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderTooltip}>
                        <span> <u>(?)</u></span>
                    </OverlayTrigger>
                </p>}
                <div className="aspect-ratio-sizer">
                    <svg viewBox={`0 0 ${aspect_x} ${aspect_y}`}></svg>
                    <FileDrop
                        onDrop={this.onDropFiles}>
                        <input
                            onChange={this.onFileInputChange}
                            ref={this.fileInputRef}
                            type="file"
                            accept="video/*,image/*"
                        />


                        <Spinner animation="border" role="status" />

                        <div className="progress-bar-wrapper">
                            <ProgressBar now={this.state.uploadProgress*100} animated={this.state.uploadStatus!=="uploading"} label={getUploadStatusLabel()} />
                        </div>

                        {description && <p className="description">{description}</p>}

                        {contentSrc && <ContentBox className="content" src={contentSrc} controls getWarning={getWarning}/>}

                        <Dropdown className="options-dropdown" dir="right">
                            <Dropdown.Toggle className="togglebutton" variant="light" id="options-dropdown">
                                <BsFillCaretDownFill size="20px" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.fileInputRef.current?.click()}>Upload File</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.onBrowseClicked()}>Browse Content</Dropdown.Item>
                                <IsDeveloper>
                                    <Dropdown.Item onClick={() => onVideoLinkClicked()}>HLS Video Link (Pro+)</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onYoutubeClicked()}>You Tube Video (Premium+, Max 1)</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onYoutubeClicked()}>You Tube Live Stream (Premium+ / Quest Only, Max 1)</Dropdown.Item>
                                </IsDeveloper>
                                <IsNotDeveloper>
                                    <Dropdown.Item disabled={!this.props.supportVideoLink} onClick={() => onVideoLinkClicked()}>HLS Video Link (Pro+)</Dropdown.Item>
                                    <Dropdown.Item disabled={!this.props.supportYoutube} onClick={() => onYoutubeClicked()}>You Tube (Premium+, Max 1)</Dropdown.Item>
                                    <Dropdown.Item disabled={!this.props.supportYoutube} onClick={() => onYoutubeClicked()}>You Live Stream (Premium+ / Quest Only, Max 1)</Dropdown.Item>
                                </IsNotDeveloper>
                                <Dropdown.Item disabled={value===""} onClick={() => this.onClearClicked()}>Clear</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="add-dropdown">
                            <Dropdown.Toggle className="togglebutton" variant="light" id="add-dropdown">
                                <BsPlus size="30px" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.fileInputRef.current?.click()}>Upload File</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.onBrowseClicked()}>Browse Content</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </FileDrop>
                </div>


            </div>
        )
    }
}
export const ContentSelector = _ContentSelector;

export default ContentSelector;
