import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row, Spinner } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import TextareaAutosize from 'react-textarea-autosize';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type AdminRedeemCodePageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type AdminRedeemCodePageState = {
    circulateparams: CirculateParams,
    circulateresult: string[] | null,
    circulatingcodes: boolean,
    genparams: GenerateParams,
    genresult: GenerateResult | null,
    generatingcodes: boolean,
    overview: OverviewRow[] | null
};

type CirculateParams = {
    redeemusage: string,
    numcodes: number,
}
type GenerateParams = {
    redeemusage: string,
    numcodes: number,
    startdate: Date,
    enddate: Date
}
type GenerateResult = {
    msg: string,
    success: boolean,
}

type OverviewRow = {
    usage: string,
    total: number,
    circulated: number,
    redeemed: number
}

class _AdminRedeemCodePage extends React.Component<AdminRedeemCodePageProps, AdminRedeemCodePageState> {
    constructor(props: AdminRedeemCodePageProps) {
        super(props);
        this.state = {
            circulateparams: {redeemusage:"test", numcodes:0},
            circulatingcodes: false,
            circulateresult: null,
            genparams: {redeemusage:"test", numcodes:0, startdate: this.defaultNow(), enddate: this.defaultNow()},
            generatingcodes: false,
            genresult: null,
            overview: null
        };
    }

    async componentDidMount(): Promise<void> {
        await this.refreshOverview();
    }

    async refreshOverview(): Promise<void> {
        const ov = await this.props.multiverse.get<OverviewRow[]>("/v2/admin/redeemcodes/overview");
        this.setState({
            overview: ov
        })
    }

    defaultNow = () => {
        let now = new Date();
        now.setUTCHours(0);
        now.setUTCMinutes(0);
        now.setUTCSeconds(0);
        now.setUTCMilliseconds(0);
        return now;
    }

    onUsageDetailsClick = async (usage: string) => {
        console.log(`View details: ${usage}`)
        this.props.history.push(`/admin/redeemcodes/listcodes/${usage}`)
    }

    // circulation
    onCirculateNumCodesChange = (v: string) => {
        const params = this.state.circulateparams;
        if(params) {
            this.setState({
                circulateparams: {
                    ...params,
                    numcodes: parseInt(v)
                }
            })            
        }
    }
    onCirculateRedeemUsageChange = (v: string) => {
        const params = this.state.genparams;
        if(params) {
            this.setState({
                circulateparams: {
                    ...params,
                    redeemusage: v
                }
            })            
        }
    }
    onCirculateClick = async () => {
        this.setState({
            circulatingcodes: true,
            circulateresult: null
        })

        try {
            const result = await this.props.multiverse.get<string[]>(`/v2/admin/redeemcodes/circulate?redeemusage=${this.state.circulateparams.redeemusage}&numcodes=${this.state.circulateparams.numcodes}`, )
            this.setState({
                circulatingcodes: false,
                circulateparams: {redeemusage: this.state.genparams.redeemusage, numcodes:0},
                circulateresult: result
            })
        } catch {
            this.setState({
                circulatingcodes: false,
                circulateparams: {redeemusage: this.state.genparams.redeemusage, numcodes:0},
                circulateresult: ["error"]
            })
        }

        await this.refreshOverview();
    }

    // generation
    onGenerateNumCodesChange = (v: string) => {
        const params = this.state.genparams;
        if(params) {
            this.setState({
                genparams: {
                    ...params,
                    numcodes: parseInt(v)
                }
            })            
        }
    }
    onGenerateRedeemUsageChange = (v: string) => {
        const params = this.state.genparams;
        if(params) {
            this.setState({
                genparams: {
                    ...params,
                    redeemusage: v
                }
            })            
        }
    }
    onGenerateStartDateChange = (v: string) => {
        console.log(v);
        const params = this.state.genparams;
        let enddate = params.enddate;
        let startdate = new Date(v);
        if( enddate < startdate) {
            enddate = startdate
        }
        if(params) {
            this.setState({
                genparams: {
                    ...params,
                    startdate: startdate,
                    enddate: enddate
                }
            })            
        }
    }
    onGenerateEndDateChange = (v: string) => {
        const params = this.state.genparams;
        if(params) {
            let enddate = new Date(v);
            if( enddate < params.startdate) {
                enddate = params.startdate
            }
            this.setState({
                genparams: {
                    ...params,
                    enddate: enddate
                }
            })            
        }
    }
    onGenerateClick = async () => {
        this.setState({
            generatingcodes: true,
            genresult: null
        })

        try {
            const result = await this.props.multiverse.post<GenerateResult>("/v2/admin/redeemcodes/generate", this.state.genparams)
            this.setState({
                generatingcodes: false,
                genparams: {redeemusage: this.state.genparams.redeemusage, numcodes:0, startdate: this.defaultNow(), enddate: this.defaultNow()},
                genresult: result
            })
        } catch {
            this.setState({
                generatingcodes: false,
                genparams: {redeemusage: this.state.genparams.redeemusage, numcodes:0, startdate: this.defaultNow(), enddate: this.defaultNow()},
                genresult: { msg: "error", success: false }
            })
        }

        await this.refreshOverview();
    }



    render(): JSX.Element {
        return (
            <AdminPage>
                <Row><Col className="pl-0"><h4>Redeem Codes</h4></Col></Row>
                <br/><br/>
                <Container>
                <Row><Col><h4>Overview</h4></Col></Row>
                <Row><PageTable>
                    <thead><PageTable.Row>
                        <PageTable.Cell>Redeem Codes Usage</PageTable.Cell>
                        <PageTable.Cell>Total Number of Codes</PageTable.Cell>
                        <PageTable.Cell>Number of Circulated Codes</PageTable.Cell>
                        <PageTable.Cell>Number of Redeemed Codes</PageTable.Cell>
                    </PageTable.Row></thead>
                    <tbody>
                        {this.state.overview?.map((x) => {
                            return (
                                <PageTable.Row key={x.usage}>
                                    <PageTable.Cell>{x.usage}</PageTable.Cell>
                                    <PageTable.Cell>{x.total}</PageTable.Cell>
                                    <PageTable.Cell>{x.circulated}</PageTable.Cell>
                                    <PageTable.Cell>{x.redeemed}</PageTable.Cell>
                                    <PageTable.Cell><Button variant='outline-secondary' onClick={async () => this.onUsageDetailsClick(x.usage)}>View Details</Button></PageTable.Cell>
                                </PageTable.Row>
                            )
                        })}
                    </tbody>
                </PageTable></Row>
                </Container>                                

                <br/><br/>

                <Card className="mt-2">
                    <Card.Header><h4>Circulate Redeem Codes</h4></Card.Header>
                    <Card.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>Redeem Codes Usage</Form.Label>
                            <Col sm={5}>
                                <Form.Control type="string" value={this.state.circulateparams?.redeemusage} onChange={(e) =>this.onCirculateRedeemUsageChange(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>Number to Circulate</Form.Label>
                            <Col sm={5}>
                                <Form.Control type="number" value={this.state.circulateparams?.numcodes} onChange={(e) =>this.onCirculateNumCodesChange(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="justify-content-end pr-3">
                            {this.state.circulatingcodes && <Spinner animation="border" role="status" className="mr-2"/>}
                            {!this.state.circulatingcodes &&<Button variant='outline-primary' onClick={this.onCirculateClick}>Circulate</Button>}
                        </Form.Group>
                    </Form>
                    {this.state.circulateresult && <Form.Group><div>
                        <Form.Label><b>Circulate Redeem Codes {this.state.circulateresult.length} results:</b><br/></Form.Label>
                        <div>
                        <Button variant='outline-secondary' onClick={()=> {
                            if(this.state.circulateresult)
                                navigator.clipboard.writeText(this.state.circulateresult.join("\n"))
                            }}>Copy to Clipboard</Button>
                        <TextareaAutosize
                            style={{fontFamily: "'Courier New', Courier, monospace", width:"100%"}}
                            disabled={true}
                            maxRows={20}
                            value={this.state.circulateresult.join("\n")}
                        />
                        </div>
                    </div></Form.Group>}
                    </Card.Body>
                </Card>
                <br/><br/><br/><br/><div/>


                <Card className="mt-2">
                    <Card.Header><h4>Generation</h4></Card.Header>
                    <Card.Body>

                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>Redeem Codes Usage</Form.Label>
                            <Col sm={5}>
                            <Form.Control type="string" value={this.state.genparams?.redeemusage} onChange={(e) =>this.onGenerateRedeemUsageChange(e.target.value)}/>                              
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>Number to Generate</Form.Label>
                            <Col sm={5}>
                            <Form.Control type="number" value={this.state.genparams?.numcodes} onChange={(e) =>this.onGenerateNumCodesChange(e.target.value)}/>                              
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>Start Date</Form.Label>
                            <Col sm={5}>
                            <Form.Control type="date" value={this.state.genparams?.startdate.toISOString().split('T')[0] } onChange={(e) =>this.onGenerateStartDateChange(e.target.value)}/>                              
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                            <Form.Label column sm={3}>End Date</Form.Label>
                            <Col sm={5}>
                            <Form.Control type="date" value={this.state.genparams?.enddate.toISOString().split('T')[0] } onChange={(e) =>this.onGenerateEndDateChange(e.target.value)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="justify-content-end pr-3">  
                        {this.state.generatingcodes && <Spinner animation="border" role="status" className="mr-2"/>}
                        {!this.state.generatingcodes &&<Button variant='outline-primary' onClick={this.onGenerateClick}>Generate</Button>}
                        </Form.Group>
                    
                        {this.state.genresult && <Form.Group as={Row}>
                            <Form.Label>Generate result: {this.state.genresult.msg}</Form.Label>
                        </Form.Group>}
                    </Card.Body>
                </Card>

            </AdminPage>
        )
    }
}

export const AdminRedeemCodePage = withRouter(withMultiverseApi(_AdminRedeemCodePage));

export default AdminRedeemCodePage;

