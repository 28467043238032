import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseDomain, MultiverseLocation, WithMultiverseApiProps, InfiniverseAdvert } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import "./adminapprovalpage.css"
import api from '../../utils/api';
import { Col, Row, Image, Container, Button, FormControl, Accordion, Card, Alert } from 'react-bootstrap';
import { StyleVar } from '../locationPage';
import PageTable from '../../components/pageTable';

//            const variables_request = api.tryGet<StyleVar[]>(`/v2/domains/${location.domain}/locations/${location.id}/styles/variables?release=${release}`);

function ContentItem(props: {variable: StyleVar})
{ 
    return <div className="aap-variable">
        <strong>{props.variable.id}</strong>
        <Image src={props.variable.value}/>
    </div>
}

function ApprovalRow(props: {location: any}) {

    const {location} = props;
    const [variables, setVariables] = useState<StyleVar[]>([])
    const [domain, setDomain] = useState<any>()
    const [owner, setOwner] = useState<any>()
    const [text, setText] = useState<string>("")
    const [done, setDone] = useState<boolean>(false)


    const loadVariables = async() => {
        const res = (await api.tryGet<StyleVar[]>(`/v2/domains/${location.domain}/locations/${location._id}/styles/variables?release=dev`)) || []
        const res2 = (await api.tryGet<StyleVar[]>(`/v2/domains/${location.domain}/locations/${location._id}/styles/variables?release=prod`)) || []
        setVariables([...res,...res2].filter(x => x.id.startsWith("infiniverse_")));

        setOwner(await api.tryGet(`/v2/admin/users/${location.owner}`))
        setDomain(await api.tryGet(`/v2/admin/domains/${location.domain}`))
    }

    useEffect(() => {
        loadVariables()
        .catch(err => console.log(err))

    }, [location])

    const onApprove = async() => {
        await api.post(`/v2/admin/approvals/${location._id}/approve`)
        setDone(true)
    }

    const onReject = async() => {
        await api.post(`/v2/admin/approvals/${location._id}/reject`, {message: text})
        setDone(true)
    }

    if(done) {
        return <></>
    }

    return <Row  className="aap-location">
        <Col>
            <h3>{props.location.nickname}</h3>
            {domain && <h4>{`${domain.uri}/locations/${location.name}`}</h4>}
            {owner && <h4>{`${owner.name} (${owner.email})`}</h4>}
            <Container fluid>
                <Row>
                    {variables.map(x => <ContentItem key={x.id} variable={x}/>)}
                </Row>
                <Row className="mt-1">
                    <Col xs="auto">
                        <Button variant="success" onClick={onApprove}>Approve</Button>
                        <Button variant="danger" onClick={onReject}>Reject</Button>
                    </Col>
                    <Col>
                        <FormControl type="input" value={text} onChange={(ev) => setText(ev.target.value)}/>
                    </Col>
                </Row>

            </Container>


        </Col>
    </Row>
}


function AdvertApprovalRow(props: InfiniverseAdvert) {
        const [text, setText] = useState<string>("")
        const [done, setDone] = useState<boolean>(false)
        const [loading, setLoading] = useState<boolean>(false)
        const [err, setErr] = useState<string>("")

        const onApprove = async() => {
            setLoading(true)
            try{
                await api.post(`/v2/admin/adverts/${props._id}/approve`);
            }catch(err) {
                setErr(err.message)
            }
            setDone(true)
        }

        const onReject = async() => {
            setLoading(true)
            try{
                await api.post(`/v2/admin/adverts/${props._id}/reject`, {rejectionreason: text});
            }catch(err) {
                setErr(err.message)
            }
            setDone(true)
        }

        if(done) {
            if(err) {
                return <Row className="aap-advert">
                    <Col>
                        <Alert variant="danger">{err}</Alert>
                    </Col>
                </Row>
            }
            return <></>
        }
        return <Row className="aap-advert">
            <Col>
                <h3>{props.name}</h3>
                <Container fluid>
                    <Row>
                        <Col><img src={props.iconurl}/></Col>
                        <Col>
                            <strong>Created at: </strong>{props.timestamp}
                            <br/>
                            <strong>Ordered Impressions: </strong>{props.pendingimpressions}
                        </Col>
                    </Row>
                    
                <Row className="mt-1">
                    <Col xs="auto">
                        <Button variant="success" onClick={onApprove} disabled={loading}>Approve</Button>
                        <Button variant="danger" onClick={onReject} disabled={!text || loading }>Reject</Button>
                    </Col>
                    <Col>
                        <FormControl type="input" value={text} disabled={loading} onChange={(ev) => setText(ev.target.value)}/>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            Show Raw Data
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <pre>{JSON.stringify(props.raw, null, 2)}</pre>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                        </Accordion>
                    </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    }

type AdminApprovalPageProps = {} & WithMultiverseApiProps;

type AdminApprovalPageState = {
    approvals: MultiverseLocation[],
    count: number,
    adverts: InfiniverseAdvert[],
    advertCount: number
};

class _AdminApprovalPage extends React.Component<AdminApprovalPageProps, AdminApprovalPageState> {
    constructor(props: AdminApprovalPageProps) {
        super(props);
        this.state = {
            approvals: [],
            count: 0,
            adverts: [],
            advertCount: 0
        };
    }

    async componentDidMount(): Promise<void> {
        const [approvals, adverts] = await Promise.all([
            await api.get<MultiverseLocation[]>("/v2/admin/approvals/pending"),
            await api.get<InfiniverseAdvert[]>("/v2/admin/adverts/pending")
        ])
        this.setState({
            approvals: approvals.slice(0,100),
            adverts: adverts.slice(0,100),
            count: approvals.length,
            advertCount: adverts.length
        })
    }

    render(): JSX.Element {
        return (
            <BasicPage className="approval-page">
                {this.state.count > 0 ? (
                <>
                <h2>Showing approvals 1-{this.state.approvals.length} of {this.state.count}</h2>
                {this.state.approvals.map(x => <ApprovalRow key={x.id} location={x}/>)}
                </>) : (
                <>
                <h2>No store fronts are pending approval</h2>
                </>
                )}
                {this.state.advertCount > 0 ? (
                <>
                <h2>Showing adverts 1-{this.state.adverts.length} of {this.state.advertCount}</h2>
                {this.state.adverts.map(x => <AdvertApprovalRow key={x._id} {...x}/>)}
                </>) : (
                <>
                <h2>No adverts are pending approval</h2>
                </>
                )}
            </BasicPage>
        )
    }
}
export const AdminApprovalPage = withMultiverseApi(_AdminApprovalPage);

export default AdminApprovalPage;
