import React, { CSSProperties, FunctionComponent } from 'react';

type SquareImageProps = {
  src: string,
  roundedCircle?:boolean,
  style?: CSSProperties,
};

const SquareImage:FunctionComponent<SquareImageProps> = ({ src, roundedCircle, style }:SquareImageProps) => (
  <div
    style={{
      backgroundImage: `url("${src}")`,
      backgroundOrigin: 'center',
      backgroundSize: 'cover',
      display: 'inline-block',
      ...(roundedCircle ? { borderRadius: '50%' } : {}),
      ...style,
    }}
  />
);
export default SquareImage;
