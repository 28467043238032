import React, { useEffect } from "react"
import { Modal, Button, Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js';
import { InfiniverseAdvert } from "../../hoc/multiverseApiProvider";
import { createMultiverseStripeClient } from "../../utils/createMultiverseStripeClient";
import { loadStripe, StripeCardElement } from "@stripe/stripe-js";
import { post, get } from "../../utils/api";
import ValidatedField from "../../components/validatedField";

type PurchaseAdvertImpressionsModalProps = {
    show: boolean;
    advert: InfiniverseAdvert,
    onHide: (paymentMethodResponse?:PaymentMethodResponse) => void;
}


type InfiniverseAdvertImpressionCosts = {
    currency: string;
    costPerUnit: number,
    unitSize: number,
}

type PaymentIntent = {
    id: string;
    client_secret: string;
}

type PaymentMethodResponse = { status: "ok", captured: boolean} | { status: 'requires_action', next_action: any }

const MAX_IMPRESSIONS = 1000000

export default function PurchaseAdvertImpressionsModal(props: PurchaseAdvertImpressionsModalProps): JSX.Element | null {
    const [paymentIntent, setPaymentIntent] = React.useState<PaymentIntent | null>(null);
    const [card, setCard] = React.useState<StripeCardElement | null>(null)
    const [error, setError] = React.useState<string | null>(null)
    const [price, setPrice] = React.useState<InfiniverseAdvertImpressionCosts | null>(null)
    const [quantity, setQuantity] = React.useState<number>(0)
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [isLoadingIntent, setIsLoadingIntent] = React.useState<boolean>(false)
    const stripeClient = React.useMemo(createMultiverseStripeClient, [])

    const formatter = price && new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: price?.currency
    });
    const isQuanitiesValid = quantity > 0 && !!price && quantity % price.unitSize === 0 && quantity <= MAX_IMPRESSIONS
    const submitDisabled = !paymentIntent || !props.advert || !card || !stripeClient || isSubmitting || !price || !isQuanitiesValid || isLoadingIntent

    useEffect(() => {
        if (!props.advert) return
        if (!isQuanitiesValid) return
        setIsLoadingIntent(true)
        post({
            url: `/v2/adverts/purchaseimpressions`,
            body: {
                advertid: props.advert._id,
                quantity,
                returnurl: window.location.href,
            }
        }).then(resp => {
            setPaymentIntent((resp as any))
            setError(null)
            setIsLoadingIntent(false)
        }).catch(err => {
            setError(err.message)
            setIsLoadingIntent(false)
            setPaymentIntent(null)
        })

    }, [props.advert, quantity])

    useEffect(() => {
        get<{ price: InfiniverseAdvertImpressionCosts }>({
            url: `/v2/adverts/price`,
        }).then(({ price }) => {
            setPrice(price)
        })
    }, [])

    const onSubmit = async () => {
        setIsSubmitting(true)
        try {
            const stripe = await stripeClient;
            if (!stripe) return
            if (!card) return
            const response = await stripe.createPaymentMethod({
                type: 'card',
                card,
            })
            if (!response.paymentMethod) {
                if (response.error?.type === "validation_error") {
                    return; // Stripe UI will show this error
                }
                if (response.error) {
                    throw new Error(response.error.message)
                }
                throw new Error("Could not process payment, please try again later")
            }
            const payment: PaymentMethodResponse = await post({
                url: `/v2/adverts/purchaseimpressions/paymentmethod`,
                body: {
                    paymentmethodid: response.paymentMethod.id,
                    clientsecret: paymentIntent!.id,
                }
            });
            if (payment.status === 'requires_action') {
                const actionResult = await stripe.confirmCardPayment(paymentIntent!.client_secret, {
                    payment_method: response.paymentMethod.id
                });
                if (actionResult.error) {
                    throw new Error(actionResult.error.message)
                }
                const postActionResult: PaymentMethodResponse = await post({
                    url: `/v2/adverts/purchaseimpressions/paymentmethod/postaction`,
                    body: {
                        paymentmethodid: response.paymentMethod.id,
                        clientsecret: paymentIntent!.id,
                    }
                });
                props.onHide(postActionResult)
            }else{
                props.onHide(payment)
            }
            setError(null)
        } catch (e) {
            setError(e.message)
        } finally {
            setIsSubmitting(false)
        }
    }

    const clampedQuantity = price ? Math.min(Math.floor(quantity / price.unitSize) * price.unitSize, MAX_IMPRESSIONS) : 0;


    return <Modal show={props.show} onHide={props.onHide} key={props.advert?._id} backdrop={isSubmitting ? "static" : undefined}>
        {props.advert && <> <Modal.Header closeButton>
            <Modal.Title>Purchase Impressions for {props.advert.name}</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                {price && <>
                    <p>
                        You are about to purchase impressions for {props.advert.name}.
                    Impressions cost {formatter!.format(price?.costPerUnit / 100)} per {price?.unitSize} impressions.
                </p>

                    <h5>Number of Impressions</h5>
                    <input className="form-control"
                        type="number"
                        min="0"
                        max={MAX_IMPRESSIONS}
                        step={price.unitSize}
                        value={quantity}
                        onChange={e => setQuantity(parseInt(e.target.value))}
                        onBlur={e => setQuantity(clampedQuantity)}
                    />
                    <p className={isNaN(quantity) ? "invisible" : ""}>
                        <strong>Cost:</strong> {formatter!.format(price?.costPerUnit / 100 * clampedQuantity / price?.unitSize)}
                    </p>
                </>
                }

                <Elements stripe={stripeClient}>
                    <div style={{ minHeight: '2em' }}>
                        {paymentIntent && <CardForm clientSecret={paymentIntent.client_secret} onChange={setCard} />}
                    </div>
                </Elements>
                <Alert variant="danger" className="mt-2" style={{ display: error ? "block" : "none" }}>
                    {error}
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>props.onHide()} disabled={isSubmitting}>
                    Cancel
            </Button>
                <Button variant="primary" disabled={submitDisabled} onClick={onSubmit}>
                    <Spinner className={(!isSubmitting ? 'invisible' : '')} animation="border" size="sm" />
                    <span className={isSubmitting ? 'invisible' : ''}>Purchase</span>
                </Button>
            </Modal.Footer></>}
    </Modal>
}

type CardFormProps = {
    clientSecret: string | null;
    onChange: (e: StripeCardElement | null) => void;
}
function CardForm({ clientSecret, onChange }: CardFormProps): JSX.Element {
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        if (!stripe || !elements) return
        const card = elements.getElement(CardElement);
        onChange(card)
    }, [elements, stripe])
    return <>
        <CardElement />
    </>
}
