import React from 'react';
import BasicPage from '../../components/basicPage';
import { useCurrentDomainUri } from '../../utils/entitycache';
import { DomainEntityTable } from '../../components/domainEntityTable';
import { Tabs, Tab } from 'react-bootstrap';
import { MultiverseBaseEntity, MultiverseEvent } from '../../hoc/multiverseApiProvider';
import { PageTableCell } from '../../components/pageTable';

export function PlotsPage(props: {}) {
    const targeturl = useCurrentDomainUri();

    const COLUMNS: any[] = [/*"Start","End","Published"*/]

    const getColumnValues = (x: MultiverseBaseEntity) => {
        const event = x as MultiverseEvent;
        return [
            /*<PageTableCell key="start">{new Date(event.startdate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="end">{new Date(event.enddate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="published">{event.published ? "Yes" : "No"}</PageTableCell>*/
        ];
    }

    return (
        <BasicPage title="Plots">
                <DomainEntityTable targeturl={targeturl} type="plot" emptyMessage="There are no plots" showHeader additionalColumnNames={COLUMNS} additionalColumnValues={getColumnValues}/>
        </BasicPage>
    )    
}
export default PlotsPage;