import React, { FunctionComponent } from 'react';
import { DropdownButton, Table, TableProps, Image, ImageProps } from 'react-bootstrap';
import { BsList } from 'react-icons/bs';

type PageTableProps = {
    className?: string;
} & TableProps;

type PageTableState = {
};

export const PageTableRow:FunctionComponent<React.HTMLProps<HTMLTableRowElement>> = (props) => (
    <tr {...props}>

    </tr>
);

export const PageTableCell:FunctionComponent<React.HTMLProps<HTMLTableCellElement>> = (props) => (
    <td {...props}>
        
    </td>
);

type PageTableIconCellProps = {
    src: string;
    rounded?: boolean;
    roundedCircle?: boolean;
    thumbnail?: boolean;
} & React.HTMLProps<HTMLTableCellElement>

const PageTableIconCell:FunctionComponent<PageTableIconCellProps> = (props) => {
    const { src, rounded, roundedCircle, thumbnail, ...cell_props } = props;
    return (
    <PageTableCell {...cell_props} className={[props.className, "icon-cell"].join(" ")}>
        <Image src={src} rounded={rounded} roundedCircle={roundedCircle} thumbnail={thumbnail}/>
    </PageTableCell>)
};

const PageTableInfoCell:FunctionComponent<{title: any, subtitle?: any} & React.HTMLProps<HTMLTableCellElement>> = (props) => {
    const { title, subtitle, ...cell_props } = props;
    return (
    <PageTableCell {...cell_props} className={[props.className, "info-cell"].join(" ")}>
        {title}
        {subtitle && (<>
            <br></br>
            {subtitle}    
        </>)}
    </PageTableCell>);
};

const PageTableDropDownCell:FunctionComponent<{disabled?: boolean} & React.HTMLProps<HTMLTableCellElement>> = (props) => {
    const {disabled, ...cell_props} = props;
    return (
    <PageTableCell {...cell_props} className={[props.className, "button-cell"].join(" ")}>
        <DropdownButton disabled={disabled} id="dropdown-basic-button" title={<BsList color="black" size="1.5em" />} className="dropdown-hide-arrow" onClick={(e: any) => e.stopPropagation()}>
            {props.children}
        </DropdownButton>        
    </PageTableCell>)
};

class PageTable extends React.Component<PageTableProps, PageTableState> {

    static Row = PageTableRow;
    static Cell = PageTableCell;
    static DropDownCell = PageTableDropDownCell;
    static IconCell = PageTableIconCell;
    static InfoCell = PageTableInfoCell;

    constructor(props: PageTableProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {
        let cn = "page-table " + (this.props.className || "");
        return (
            <Table {...this.props} striped bordered hover className={cn}>
                {this.props.children}
            </Table>
        )
    }
}

export default PageTable;
