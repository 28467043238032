import React, { CSSProperties } from 'react';
import {  Button, Col, Container, ContainerProps, Row, Spinner } from 'react-bootstrap';
import "./basicpage.css"

export type BasicPageProps = {
    title?: string;
    isLoading?: boolean;
    style?: CSSProperties
    className?: string;
    titleButton?: any;
} & ContainerProps;

export type BasicPageState = {

};

class BasicPage extends React.Component<BasicPageProps, BasicPageState> {
    constructor(props: BasicPageProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    renderLoading() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderChildren() {
        const { children, isLoading } = this.props;
        if (isLoading)
            return this.renderLoading();
        else
            return children;
    }

    render() {
        const { className, title, style, titleButton, fluid} = this.props;

        let fullClassName = "basic-page pt-2";
        if(className) {
            fullClassName = `${fullClassName} ${className}`;
        }

        return (
            <Container className={fullClassName} style={style} fluid={fluid}>
                {title && <Row className="bp-title-row pt-2">
                    <Col className="text-center bp-title-col">
                        <h2 className="bp-title-header">{title}</h2>
                        <div className="bp-title-button">{titleButton}</div>
                    </Col>
                </Row>}

                {this.renderChildren()}

            </Container>
            );
    }
}

export default BasicPage;
