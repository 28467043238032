import React, { CSSProperties } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import driveapi, { TItemInfo, TItemListResults } from '../../utils/driveapi';
import { BsChevronRight, BsArrowUpShort, BsUpload } from 'react-icons/bs'
import { RiFolderUploadLine } from 'react-icons/ri'
import { Button, Col, Container, Dropdown, ProgressBar, Row, Spinner } from 'react-bootstrap';
import './drivebrowser.css'
import { CircularButton, DeleteButton, InfoButton, PreviewButton, UploadButton, VerticalMenuButton } from '../circularButton';
import eventBus from '../../utils/eventBus';
import DriveBrowserHeader from './driveBrowserHeader'
import DriveBrowserInfoDialog from './driveBrowserInfoDialog'
import DriveBrowserPreviewDialog from './driveBrowserPreviewDialog'
import DriveBrowserDeleteDialog from './driveBrowserDeleteDialog'
import { DriveBrowserScrollableGrid } from './driveBrowserGrid'
import { FileDrop } from 'react-file-drop';
import { uploadContent } from '../../utils/uploadContent';
import api from '../../utils/api';
import DialogBox from '../dialogBox';

const isHoverableDevice = window.matchMedia(
    '(hover: hover) and (pointer: fine)'
  ).matches

type DriveBrowserBodyProps = {
    path: string;
    onChangePath?: (newPath: string)=>void;
    onSelectItem?: (path: string, item: TItemInfo)=>void;
    onClose?: ()=>void;
    showDrivesList?: boolean;
    showClose?: boolean;
};

type DriveBrowserBodyState = {
    currentPath: string;
    isLoading: boolean;
    listResults: TItemListResults;
    hasMore: boolean;
    selectedItem: TItemInfo | null;
    infoItem: TItemInfo | null;
    previewItem: TItemInfo | null;
    deleteItem: TItemInfo | null;
    storageUsed: number;
    storageAvailable: number;
    errorMsg: string | null;
};

class _DriveBrowserBody extends React.Component<DriveBrowserBodyProps, DriveBrowserBodyState> {
    fileInputRef = React.createRef<HTMLInputElement>();;

    constructor(props: DriveBrowserBodyProps) {
        super(props);
        this.state = {
            currentPath: "",
            isLoading: true,
            listResults: { items: [] },
            hasMore: true,
            selectedItem: null,
            infoItem: null,
            previewItem: null,
            deleteItem: null,
            storageUsed: 0,
            storageAvailable: 0,
            errorMsg: null
        };
    }

    componentDidMount = () => {
        this.reload();

        eventBus.on("BackgroundTaskManager.onTaskFinished", this.onBackgroundTaskFinished);
    }

    componentWillUnmount = () => {
        eventBus.remove("BackgroundTaskManager.onTaskFinished", this.onBackgroundTaskFinished);
    }

    componentDidUpdate = () => {
        if(this.props.path != this.state.currentPath) {
            this.reload();
        }
    }

    //refresh current list of items when a background task completes in case it updates this folder
    onBackgroundTaskFinished = ()=> {
        this.reFetchItems();
    }

    //stores updated path, then clears fetches items for new path
    reload = () => {
        if(this.props.path !== "" || this.props.showDrivesList) {
            this.setState({
                currentPath: this.props.path,
                isLoading: true,
                listResults: { items: [] },
                hasMore: true,
            }, async() => {
                await this.fetchItems();
                this.setState({
                    isLoading: false
                })          
            })
        }
    }

    //fetches next set of items
    fetchItems = async (): Promise<void> => {
        const { currentPath, listResults } = this.state;
        
        if(currentPath !== "" || this.props.showDrivesList) {
            try {
                //fetch
                console.log(`fetchItems`);
                console.log(this.state);
                const result = await driveapi.listPath(currentPath, 200, listResults.nextPageToken);
    
                //append to current results
                this.setState({
                    listResults: {
                        nextPageToken: result.nextPageToken,
                        items: [...listResults.items, ...result.items]
                    },
                    hasMore: result.nextPageToken != undefined
                });

                //fire off but no need to wait for storage refresh
                this.loadStorage();

            } catch (err) {
                console.log("Fetch error");
                console.log(err);
            }
        }

    };

    //refreshes entire list by getting page big enough to contain all current items plus some more
    //used when background task reports new files
    reFetchItems = async (): Promise<void> => {
        const { currentPath, listResults } = this.state;

        if(currentPath !== "" || this.props.showDrivesList) {
            try {
                //calculate fetch uri
                console.log(`reFetchItems`);
                console.log(this.state);
                const result = await driveapi.listPath(currentPath, listResults.items.length + 200);
    
                //append to current results
                this.setState({
                    listResults: {
                        nextPageToken: result.nextPageToken,
                        items: [...result.items]
                    },
                    hasMore: result.nextPageToken != undefined
                });

                //fire off but no need to wait for storage refresh
                this.loadStorage();

            } catch (err) {
                console.log("Fetch error");
                console.log(err);
            }
        }

    };

    onItemOpen = (item: TItemInfo) => {
        const { onChangePath, onSelectItem } = this.props;
        const { currentPath } = this.state;

        if (item.type !== "file" && item.type !== "link") {
            if(onChangePath) {
                onChangePath(currentPath + "/" + item.id);
            }
        } else if(onSelectItem) {
            onSelectItem(currentPath, item);
        } else {
            this.onItemPreview(item)
        }        
    }

    onItemPreview = (item: TItemInfo) => {
        this.setState({
            previewItem: item
        })        
    }

    onItemSelect = (item: TItemInfo) => {
        this.setState({
            selectedItem: item
        })    
    }

    onItemDelete = (item: TItemInfo) => {
        this.setState({
            deleteItem: item
        })
    }

    onItemInfo = (item: TItemInfo) => {
        console.log("onItemInfo");
        console.log(item);
        this.setState({
            infoItem: item
        })
    }

    onDropFiles = (files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
        if(files) {
            for(let i = 0; i < files.length; i++) {
                uploadContent({
                    uri: `/users/${api.getAccount()}`,
                    file: files[i],
                    alwaysBackground: true
                }).catch((e)=>this.setState({errorMsg: e as string || "An error occurred"}));
            }
        }
    }

    onChangeFolderClicked = (newpath: string) => {
        const { onChangePath } = this.props;
        if(onChangePath) {
            onChangePath(newpath);
        }
    }

    onUploadClicked = () => {
        this.fileInputRef.current?.click();
    }

    renderRowsLoading = () => {
        return (
            <>
                <Row key="loading" style={{flexGrow: 1, flexShrink:1, position: "relative"}}>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    renderRowsFiles = () => {
        const { listResults, hasMore, currentPath, selectedItem } = this.state;

        const itemProps = {
            showMenu: !isHoverableDevice,
            showDelete: true,
            showInfo: true,
            disableDelete: !currentPath.startsWith("/uploads") && !currentPath.startsWith("/videos"),
            disableInfo: false
        }

        return <Row key="files" style={{flexGrow: 1, flexShrink:1, position: "relative"}}>
            <DriveBrowserScrollableGrid 
                touchScreen={!isHoverableDevice}
                items={listResults.items}
                path={currentPath}
                selectedItem={selectedItem || null}
                onItemOpen={this.onItemOpen}
                onItemSelect={this.onItemSelect}
                onItemDelete={this.onItemDelete}
                onItemInfo={this.onItemInfo}
                next={this.fetchItems}
                hasMore={hasMore}
            />
        </Row>
    }

    renderRowsNoFiles = () => {
        const { currentPath } = this.state;
        const isWritableFolder = currentPath.startsWith("/uploads") || currentPath.startsWith("/videos");

        return (
            <>
                <Row key="nofiles" style={{flexGrow: 1, flexShrink:1, position: "relative"}}>
                    <Col className="text-center pb-3">
                        {isWritableFolder ? 
                            <>Upload files here to use in Multiverse</> :
                            <>There are no files or folders in here that can be used in Multiverse</>
                        }
                        <br></br>
                    </Col>
                </Row>
            </>
        );
    }

    renderRows = () => {
        const { listResults, isLoading } = this.state;
        if (isLoading)
            return this.renderRowsLoading();
        else if (listResults.items.length > 0)
            return this.renderRowsFiles();
        else
            return this.renderRowsNoFiles();
    }

    renderStorageInfo = () => {
        const NORMAL_STYLE:CSSProperties = {}
        const FULL_STYLE:CSSProperties = { backgroundColor: "red" }

        let className = "dbh-storage";
        if(this.state.storageAvailable > 0 && this.state.storageUsed > this.state.storageAvailable) {
            className += " dbh-storage-full";
        }

        return (
        <div className={className}>
            <div>Multiverse Storage: {(this.state.storageUsed/(1024*1024*1024)).toFixed(2)} GB of {(this.state.storageAvailable/(1024*1024*1024)).toFixed(2)} GB used</div>
            <ProgressBar 
                now={this.state.storageAvailable > 0 ? 100*this.state.storageUsed/this.state.storageAvailable : 0}
            />    
        </div>)
    }

    renderContainer = () => {
        const { currentPath, selectedItem } = this.state;

        const isWritableFolder = currentPath.startsWith("/uploads") || currentPath.startsWith("/videos");
        const isDeletableFolder = currentPath.startsWith("/uploads") || currentPath.startsWith("/photos") || currentPath.startsWith("/metaverses") || currentPath.startsWith("/videos");

        const displayProps = {
            showInfo: isHoverableDevice,
            showStorage: isHoverableDevice,
            showDelete: isHoverableDevice && selectedItem != null,
            showOpen: isHoverableDevice && selectedItem != null,
            showUpload: true,
            showClose: this.props.showClose,
            disableUpload: !isWritableFolder,
            disableDelete: !isWritableFolder && !isDeletableFolder,
            disableOpen: !(selectedItem != null && selectedItem.type !== "directory"),
            disableInfo: selectedItem == null,
            disableClose: false,
        }

        return (
            <Container fluid className="dbb-main-container" onClick={()=>this.setState({selectedItem: null})}>
                <Row key="header">
                    <Col className="p-0">
                        <DriveBrowserHeader 
                            path={currentPath} 
                            {...displayProps}
                            onChangePathClicked={this.onChangeFolderClicked}
                            onInfoClicked={()=>{if(selectedItem) this.onItemInfo(selectedItem)}}
                            onOpenClicked={()=>{if(selectedItem) this.onItemPreview(selectedItem)}}
                            onDeleteClicked={()=>{if(selectedItem) this.onItemDelete(selectedItem)}}
                            onUploadClicked={this.onUploadClicked}
                            onCloseClicked={this.props.onClose}
                        />
                    </Col>
                </Row>
                {this.renderRows()}

                {(currentPath.startsWith("/uploads") || currentPath.startsWith("/metaverses") || currentPath.startsWith("/videos")) && 
                <Row key="storage">
                    <Col style={{textAlign:"right"}}>
                        {this.renderStorageInfo()}
                    </Col>
                </Row>}

            </Container>
        );        
    }

    getCurrentContentUri = () => {
        const match = this.props.path.match(/\/metaverses\|\w*\/(\w*).*/);
        if(match) {
            return  `/domains/${match[1]}`
        } else {
            return  `/users/${api.getAccount()}`;
        }
    }

    onFileUpload = (files: FileList | null) => {
        if(files) {
            for(let i = 0; i < files.length; i++) {
                uploadContent({
                    uri: this.getCurrentContentUri(),
                    file: files[i],
                    alwaysBackground: true
                }).catch((e)=>this.setState({errorMsg: e as string || "An error occurred"}));
            }
        }
    }

    onFileDelete = async() => {
        const { deleteItem } = this.state;
        if(deleteItem) {
            const res = await api.del<{deleted: number}>(`/v2${this.getCurrentContentUri()}/content/${deleteItem.id}`);
            await this.reFetchItems();
            this.setState({deleteItem: null});
        }
    }

    loadStorage = async() => {
        const res = await api.get<{used: number, available: number}>(`/v2${this.getCurrentContentUri()}/content/storage`);
        console.log(res)
        this.setState({
            storageUsed: res.used,
            storageAvailable: res.available
        });
    }

    render = () => {
        const { currentPath, infoItem, previewItem, deleteItem, errorMsg } = this.state;

        return (
            <div className="drive-browser-body" style={{position: "relative", width: "100%", height: "100%"}}>
                <DriveBrowserInfoDialog item={infoItem} onCancel={()=>this.setState({infoItem: null})} path={currentPath}/>
                <DriveBrowserPreviewDialog item={previewItem} onCancel={()=>this.setState({previewItem: null})} path={currentPath}/>
                <DriveBrowserDeleteDialog item={deleteItem} onCancel={()=>this.setState({deleteItem: null})} onConfirm={this.onFileDelete} path={currentPath}/>
                <DialogBox show={errorMsg != null} title="Upload Error" onCancel={()=>this.setState({errorMsg: null})} closeButton>
                    <p>{errorMsg?.toString}</p>
                </DialogBox>
                {currentPath.startsWith("/uploads|") ? 
                    (<FileDrop onDrop={this.onDropFiles} >
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            accept="video/*,image/*"
                            onChange={(ev)=>this.onFileUpload(ev.target.files)}
                        />
                        {this.renderContainer()}
                    </FileDrop>) :
                    this.renderContainer()
                }
                {currentPath.startsWith("/videos|") ? 
                    (<FileDrop onDrop={this.onDropFiles} >
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            accept="video/*"
                            onChange={(ev)=>this.onFileUpload(ev.target.files)}
                        />
                        {this.renderContainer()}
                    </FileDrop>) :
                    this.renderContainer()
                }
            </div>
        )
    }
}
export const DriveBrowserBody = _DriveBrowserBody;

export default DriveBrowserBody;
