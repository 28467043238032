import React from 'react';
import {  Button, Card, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap';
import BasicPage from '../../components/basicPage';
import { getApiUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, MultiverseUseInviteResult, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import TestBanner from '../../assets/lobby_2048x512.jpg';
import OculusLogo from '../../assets/oculus-logo-transparent.png';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DomainFrontPage from '../../components/domainFrontPage'
import DialogBox from '../../components/dialogBox';
import api, { FetchOptions } from '../../utils/api';

type AcceptMergeRequestPageProps = {
} & WithMultiverseApiProps & RouteComponentProps;

type AcceptMergeRequestPageState = {
    isLoading: boolean;
    error: boolean;
    success: boolean;
};

class AcceptMergeRequestPage extends React.Component<AcceptMergeRequestPageProps, AcceptMergeRequestPageState> {
    constructor(props: AcceptMergeRequestPageProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: false,
            success: false
        };
    }

    componentDidMount = async() => {

        try {

            const url = new URL(window.location.href);
            const secret = url.searchParams.get("secret")
            if(!secret) {
                throw new Error("Merge request page should always have a and secret");
            }

            const fetch_args: FetchOptions = {
                url:  `/v2/me/usermerge/accept`,
                body : {
                    secret
                },
                hideErrors: true
            }
            await api.post<any>(fetch_args);
            this.setState({
                success: true
            })
        } catch(err) {
            this.setState({
                error: true
            })
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    onErrorConfirm = () => {
        const { history } = this.props;
        history.push("/");
    }

    onSuccessConfirm = async() => {
        await this.props.multiverse.logout();
        window.location.href = "/";
    }

    render() {
        const { isLoading, error, success } = this.state;
        return (
            <>
            <DialogBox title="Account Merge Failed" show={error} button0Text="Ok" onConfirm={this.onErrorConfirm}>            
<p>The link you're trying to use for merging seems to be incorrect. Commonly, this happens due to a typo in the URL or when trying to merge with an account that has a different email address than intended.</p>
<p><strong>We suggest you log out from multiverseonline.io and click on the link you were provided once more.</strong></p>
            </DialogBox>
            <DialogBox title="Account Merge Succeeded" show={success} button0Text="Ok" onConfirm={this.onErrorConfirm}>
<p>Congratulations! All your accounts have been successfully merged. You can now access all your Metaverses using the email address {this.props.multiverse.user?.email}.</p>
<p><strong>Reminder: To experience the fully merged version, you might need to log out and then log back into multiverseonline.io.</strong></p>
            </DialogBox>
            <BasicPage isLoading={isLoading}>
            </BasicPage>
            </>);
    }

}

export default withRouter(withMultiverseApi(AcceptMergeRequestPage));
