import Rollbar from 'rollbar';

/* eslint-disable */
var _rollbarConfig = {
    enabled: (process.env.NODE_ENV === 'production'),
    accessToken: "6e8850cb874c48f0aff802358d7deb34",
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureUsername: true,
    payload: {
        environment: process.env.NODE_ENV
    }
};
export default Rollbar.init(_rollbarConfig)