import React, { useState, useContext } from "react"
import { useEffect } from "react"
import { Alert } from "react-bootstrap"
import { useEntity } from "../../utils/entitycache"
import { MultiverseEvent, useMultiverseContext } from "../../hoc/multiverseApiProvider"


export function EventLockedBar(props: { targeturl: string }) {
    const context = useMultiverseContext()
    const event = useEntity<MultiverseEvent>(props.targeturl)

    return <>
     {event && event.oculuseventid && event.oculuseventid.length > 0 && <Alert className="mt-2 mb-0" variant="warning">
        This event has been linked to an Oculus event, so some fields can no longer be modified. If you need
        to cancel it, please contact FTL.
    </Alert>}
    {event && event.oculussubmission === "submitted" && <Alert className="mt-2 mb-0" variant="info">
        This event has been submitted to FTL for promotion as an Oculus event and is pending approval by the content
        team. If we need to contact you about your event we'll email you at {context.multiverse.user?.email}
    </Alert>}
    {event && event.oculussubmission === "refused" && <Alert className="mt-2 mb-0" variant="info">
        This event was submitted to FTL for promotion as an Oculus event but we were unable to accept it this time. However 
        users will still be able to see it in the discovery menu from within Multiverse!
    </Alert>}
    </>
}