import React, { useState } from "react"
import { useEffect } from "react"
import { Alert } from "react-bootstrap"


export function ReadonlyNotice(props: { type: string, readonly_message?: string }) {

    return <Alert className="mt-2 mb-0" variant="info">
        {props.readonly_message || `This ${props.type} is readonly. To change it, contact FTL`}
    </Alert>
}