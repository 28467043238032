import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import rollbar from '../../utils/setUpRollbar';

type ConnectToOculusButtonState = {
  isLoading: boolean,
  error?: string,
  interval?: number,
};

type CreateMergeTokenResponse = {
  deeplink: string,
};

const INTERVAL_TIME = 1500;

class Index extends React.Component<WithMultiverseApiProps, ConnectToOculusButtonState> {

  constructor(props:WithMultiverseApiProps) {
    super(props);
    this.state = { isLoading: false };
  }

  componentWillUnmount(): void {
    const { interval } = this.state;
    if (interval) {
      window.clearInterval(interval);
    }
  }

  //poll user waits for a standard authenticated request (updateUser) to fail, indicating
  //the current account has become invalid and is probably merged. once done, we re-auth
  //with the multiverse servers
  pollUser = async ():Promise<void> => {
    const { interval } = this.state;
    const { multiverse: { updateUser, reauthenticate } } = this.props;
    console.log("Polling user after attempt to account merge");

    //clear this timeout
    window.clearTimeout(interval);
    this.setState( {interval: undefined});
 
    //attempt to refresh user profile data. success means merge hasn't completed, so
    //just re-trigger the delayed poll and return
    try {
      await updateUser();      
      this.startPollUser();
      return;
    } catch(e) {
      console.log(`updateUser exception ${e} fired, indicating user merge has finished`)
    }

    //once done attempt to reauthenticate
    try {
      console.log("Requesting reauthenticate");
      await reauthenticate();
    } catch (e) {
      console.log(`Failed to reauthenticate with error ${e}`);
    }
  };

  //creates the poll user timeout and stores it in state
  startPollUser = () => {
    const interval = window.setTimeout(() => this.pollUser(), INTERVAL_TIME);
    this.setState({ 
      interval: interval
    });
    console.log(`pollUser timeout set, interval id ${interval}`);
  }

  //on click gets deep link, sets up tab and starts the polling process to detect merge completion
  onClick = async ():Promise<void> => {
    const { multiverse: { post, requestOculusLaunch } } = this.props;
    await requestOculusLaunch({
        domain: "",
        location: "",
        title: "Launch Multiverse",
        prompt: "confirm"
    });
  };

  render = ():JSX.Element => {
    const { isLoading } = this.state;
    return isLoading ? (
      <div>
        <span>Connection to Oculus in progress! Please use the new oculus.com tab to run Multiverse and complete the connection.</span>
      </div>
    ) : (
      <div>
        <span><Button onClick={this.onClick} variant="link" className="p-0">Click Here</Button> to link your Oculus Account</span>
      </div>
    );
  };
}

export default withMultiverseApi(Index);
