import React, { FunctionComponent } from 'react';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

type IsDeveloperProps = WithMultiverseApiProps;

const _IsDeveloper:FunctionComponent<IsDeveloperProps> = (props) => {
  const { multiverse: { user, isLoading }, children } = props;

  return (user && user.permissions.includes("developer") && !isLoading) ? (
    <>
      {children}
    </>
  ) : null;
};
export const IsDeveloper = withMultiverseApi(_IsDeveloper);

const _IsNotDeveloper:FunctionComponent<IsDeveloperProps> = (props) => {
    const { multiverse: { user, isLoading }, children } = props;
  
    return ( user && !user.permissions.includes("developer") && !isLoading) ? (
      <>
        {children}
      </>
    ) : null;
  };
  export const IsNotDeveloper = withMultiverseApi(_IsNotDeveloper);

  const _IsTester:FunctionComponent<IsDeveloperProps> = (props) => {
    const { multiverse: { user, isLoading }, children } = props;
  
    return (user && user.permissions.includes("tester") && !isLoading) ? (
      <>
        {children}
      </>
    ) : null;
  };
  export const IsTester = withMultiverseApi(_IsTester);
  
  const _IsNotTester:FunctionComponent<IsDeveloperProps> = (props) => {
      const { multiverse: { user, isLoading }, children } = props;
    
      return ( user && !user.permissions.includes("tester") && !isLoading) ? (
        <>
          {children}
        </>
      ) : null;
    };
    export const IsNotTester = withMultiverseApi(_IsNotTester);
  
    const _IsDeveloperOrTester:FunctionComponent<IsDeveloperProps> = (props) => {
      const { multiverse: { user, isLoading }, children } = props;
    
      return (user && (user.permissions.includes("tester") || user.permissions.includes("developer")) && !isLoading) ? (
        <>
          {children}
        </>
      ) : null;
    };
    export const IsDeveloperOrTester = withMultiverseApi(_IsDeveloperOrTester);
    
    const _IsNotDeveloperOrTester:FunctionComponent<IsDeveloperProps> = (props) => {
        const { multiverse: { user, isLoading }, children } = props;
      
        return ( user && !(user.permissions.includes("tester") || user.permissions.includes("developer")) && !isLoading) ? (
          <>
            {children}
          </>
        ) : null;
      };
      export const IsNotDeveloperOrTester = withMultiverseApi(_IsNotDeveloperOrTester);
      

export default IsDeveloper;
