//imports
import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers"
import { BsArrowLeft, BsArrowLeftShort, BsArrowUpShort, BsEye, BsInfo, BsThreeDotsVertical, BsTrash, BsUpload, BsX } from 'react-icons/bs'
import './circular-button.css'
import { TiTick } from "react-icons/ti";

//handy type defintion to wrap up the replace+bsprefix bits of bootstrap
type BootstrapComponentProps<As extends React.ElementType, P> = ReplaceProps<As, BsPrefixProps<As> & P>

//basic circular version of bootstrap button button
type NewCircularButtonProps = {
 
} & ButtonProps
type CircularButtonProperties<As extends React.ElementType = 'button'> = BootstrapComponentProps<As, NewCircularButtonProps>

export function CircularButton<As extends React.ElementType = 'button'>(props: CircularButtonProperties<As>) {
    return <Button 
        {...props}  
        variant={props.variant || "light"} 
        className={"mv-circular-button " + (props.disabled ? "mv-circular-button-disabled " : "") + (props.className || "")}
        />
}

type CircularButtonNoChildrenProperties<As extends React.ElementType = 'button'> = CircularButtonProperties<As> & {children?: never }


export function InfoButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsInfo/>
    </CircularButton>
    )
}

export function UploadButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsUpload/>
    </CircularButton>
    )
}

export function NavigateUpButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsArrowUpShort style={{transform:"scale(1.3)"}}/>
    </CircularButton>
    )
}

export function DeleteButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsTrash/>
    </CircularButton>
    )
}

export function PreviewButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsEye/>
    </CircularButton>
    )
}

export function VerticalMenuButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsThreeDotsVertical/>
    </CircularButton>
    )
}

export function OpenFolderButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsThreeDotsVertical/>
    </CircularButton>
    )
}

export function BackButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsArrowLeftShort style={{transform:"scale(1.3)"}}/>
    </CircularButton>
    )
}

export function CloseButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <BsX style={{transform:"scale(1.3)"}}/>
    </CircularButton>
    )
}

export function TickButton<As extends React.ElementType = 'button'>(props: CircularButtonNoChildrenProperties<As>) {
    return (
    <CircularButton {...props}>
        <TiTick style={{transform:"scale(1.3)"}}/>
    </CircularButton>
    )
}
