import React from 'react';
import BasicPage from '../../components/basicPage';
import AdvertsTable from './advertstable';
import "./advertspage.css"
import IsDeveloper from '../../components/isDeveloper';

type AdvertsPageProps = {};
type AdvertsPageState = {
    isLoading: boolean;
};

class AdvertsPage extends React.Component<AdvertsPageProps, AdvertsPageState> {
    constructor(props: AdvertsPageProps) {
        super(props);
    }
    render() {
        return (
            <IsDeveloper>
                <BasicPage className="adverts-page" title="Infiniverse Advertising" >
                    <AdvertsTable group />
                </BasicPage>
            </IsDeveloper>
        );
    }
}

export default AdvertsPage;
