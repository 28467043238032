import React, { useState } from 'react';
import BasicPage from '../../components/basicPage';
import { useCurrentDomainUri } from '../../utils/entitycache';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { DomainNameField } from '../../components/validatedField';
import { useInterval, useUrlSearchParams } from '../../utils/hooks';
import api from '../../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import { MultiverseEvent } from '../../hoc/multiverseApiProvider';

export function CreateEventPage(props: {}) {
    const targeturl = useCurrentDomainUri();
    const [name,setName] = useState<string>("")
    const [valid,setValid] = useState<boolean>(false)
    const [isSaving,setIsSaving] = useState<boolean>(false)
    const [urlDirty,setUrlDirty] = useState<number>(0)
    const [url,setUrl] = useState<string>("")
    const [urlName,setUrlName] = useState<string>("")
    const history = useHistory()
    const params = useUrlSearchParams()

    const onNameChange = (value: string, valid: boolean) => {
        setName(value)
        setValid(valid)
        setUrlDirty(Date.now())
    }

    useInterval(() => {
        if(urlDirty && (Date.now()-urlDirty) > 1000) {
            let testname = name.toLocaleLowerCase();
            testname = testname.replace(/[^a-z\d]/g,"_")
            testname = testname.trim()
            if(name.length > 0) {
                api.get<{name: string}>(`/v2/domains/${targeturl}/suggestname?name=${testname}&type=event`)
                .then((x) => {
                    setUrlName(x.name)
                    setUrl(`${targeturl}/events/${x.name}`)
                    setUrlDirty(0)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
    }, 1000)

    const onCreateClicked = () => {
        console.log(params)
        setIsSaving(true)
        api.post<MultiverseEvent>(`/v2/domains/${targeturl}/events`, {
            name: urlName,
            nickname: name,
            copy: params.get("copy") || undefined
        })
        .then((x) => {
            history.push(`/domains/${targeturl}/events/${x.name}`)
        })
        .catch((err) => {
            console.log(err)
            setIsSaving(false)
        })
    }

    return (
        <BasicPage title="Name Your Event!">
            <Row>
                <Col className="justify-content-center mt-2">
                    <div style={{textAlign:"center"}}>Note: Your event name can be changed later, but the URL cannot, so choose carefully!</div>
                </Col>
            </Row>    
            <Row className="justify-content-center mt-4">
                <Col style={{maxWidth:400}}>
                    <DomainNameField showErrorWhenEmpty onChange={onNameChange} value={name}/>
                    <Button className="mt-2" style={{width:"100%"}} onClick={onCreateClicked} disabled={isSaving || urlDirty !== 0 || url.length === 0}>Go!</Button>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col>
                    {urlDirty === 0 && url.length > 0 && <div style={{textAlign:"center", overflowWrap:"anywhere"}}>URL: {url}</div>}
                    {urlDirty !== 0 && <div style={{textAlign:"center", overflowWrap:"anywhere"}}><Spinner animation="border"></Spinner></div>}
                </Col>
            </Row>

        </BasicPage>
    )    
}
export default CreateEventPage;