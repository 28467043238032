import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useEntity, getIconSrc, TMinimalEntity } from '../../utils/entitycache';
import { Image, Container, Row, Col } from 'react-bootstrap';
import api from '../../utils/api';
import { getBlobUrl } from '../../config/api';
import { MultiverseBaseEntity } from '../../hoc/multiverseApiProvider';
import './entityminiinfobox.css'

export function EntityMiniInfoBox(props: {uri?: string}) {
    const entity = useEntity<MultiverseBaseEntity>(props.uri)
    if(!entity) {
        return <></>
    }

    return (
    <Container fluid className="entity-mini-info-box">
        <Row>
            <Col xs="auto">
                <Image src={getIconSrc(entity)} rounded roundedCircle/>
            </Col>
            <Col>
                <span>{entity.nickname}</span>
            </Col>
        </Row>
    </Container>
    )
}