import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseFile, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import api from '../../utils/api';
import "./restapi.css"
import { getBlobUrl } from '../../config/api';


export function UserFilesRow(props: {userid: string, onvisit: (id:string)=>void}) {
    const [load,setLoad] = useState(false)
    const [domains, setDomainss] = useState<(MultiverseFile & {_id:string})[] | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/users/${props.userid}/files`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        if(load) {
            loadData()
            .then(x => setDomainss(x))
            .catch(err => console.log(err))
        }

    }, [props.userid,load])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Files
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="restapi-documents-col">                       
                        <>
                            {!load && <Button onClick={()=>setLoad(true)}>Load</Button>}
                            {domains && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th></th>
                                        <th></th>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Size</th>
                                     </PageTableRow>
                                </thead>
                                <tbody>
                                    {domains.map(x => (
                                        <PageTableRow key={x._id}>
                                            <PageTableCell><Button variant="primary" onClick={() => { props.onvisit(x._id) }}/></PageTableCell>
                                            <PageTable.IconCell src={`${getBlobUrl()}/${x.iconblob}`}/>
                                            <PageTableCell>{x._id}</PageTableCell>
                                            <PageTableCell>{x.name}</PageTableCell>
                                            <PageTableCell>{x.mimetype}</PageTableCell>
                                            <PageTableCell>{x.size}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}
