const PROD_API = 'https://api.multiverseonline.io';

const DEV_ENV = 'dev';
const PROD_ENV = 'prod';

var environment: Environment | undefined;

export const getEnvironment = () => {
    if(environment === undefined)
    {
        environment = getEnvironmentByHost(window.location.origin, window.location.host);
    }

    return environment;
}

function getEnvironmentByHost(origin: string, host: string): Environment {  
    var name: string | undefined;
    var apiUrl: string | undefined;

    if (origin.includes('localhost')) {
        name = DEV_ENV;
        apiUrl = 'http://localhost:3000';
    }
    else if(host.indexOf("multiverseonline") >= 0) {
        name = PROD_ENV;
        apiUrl = PROD_API;
    }
    else {
        const DASH_FRONTEND = "-frontend";
        var dashFrontEndPos = host.indexOf(DASH_FRONTEND);
        if(dashFrontEndPos >= 0) {
            name = host.substring(0, dashFrontEndPos);
            apiUrl = `https://${host.replace(DASH_FRONTEND, "")}`;
        }       

        const UGLY_FRONTEND_URL_PREFIX = "frontend-dot-multiverse-";
        if(host.startsWith(UGLY_FRONTEND_URL_PREFIX)) {
            const nextDashPos = host.indexOf("-", UGLY_FRONTEND_URL_PREFIX.length);
            if(nextDashPos >= 0) {
                name = host.substring(UGLY_FRONTEND_URL_PREFIX.length, nextDashPos);
                return {
                    name: name,
                    apiUrl: `https://${host.replace("frontend-", "default-")}`,
                    blobsUrl: `https://storage.googleapis.com/multiverse-${name}-blobs/blobs`
                }
            }
        }
    }

    if(name === undefined || apiUrl == undefined) throw `Unable to determine environment from host ${host}`;
    return {
        name: name,
        apiUrl: apiUrl,
        blobsUrl: `https://${name}blobs.shapevrcloud.com/blobs`
    }
}

export const isDev = () => getEnvironment().name === DEV_ENV;
export const isProd = () => process.env.NODE_ENV === 'production' && getEnvironment().name === PROD_ENV;

export const getApiUrl = () => {
    const env = getEnvironment();

    //can only use prod in prod environment
    if (env.name === PROD_ENV && process.env.NODE_ENV !== 'production') {
        throw new Error('Wanted to use production api, but is not in production mode');
    }

    return env.apiUrl;
};

export const getBlobUrl = () => {
    return getEnvironment().blobsUrl;
};

interface Environment {
    name: string;
    apiUrl: string;
    blobsUrl: string;
}
