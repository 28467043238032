import React, { CSSProperties, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { getApiUrl, getBlobUrl } from '../config/api';
import { MultiverseDomain, MultiverseLocation, WithMultiverseApiProps } from '../hoc/multiverseApiProvider';
import withMultiverseApi from '../hoc/multiverseApiProvider/withMultiverseApi';
import DialogBoxWithSpinner from '../components/dialogBoxWithSpinner';

const CHECKBOX_STYLE: CSSProperties = {
    fontSize: "small"
}

type TermsCheckProps = {

} & WithMultiverseApiProps;

type TermsCheckState = {
    acceptBoxChecked: boolean;
    updatesBoxChecked: boolean;
};
 
class TermsCheck extends React.Component<TermsCheckProps, TermsCheckState> {
    constructor(props: TermsCheckProps) {
        super(props);
        this.state = {
            acceptBoxChecked: false,
            updatesBoxChecked: false
        }
    }

    componentDidMount = async () => {

    }

    render() {
        const { multiverse: { user } } = this.props;
        const {acceptBoxChecked, updatesBoxChecked} = this.state;

        if(user && !user.acceptedtandcs) {
            return (
            <DialogBoxWithSpinner title="Terms And Conditions" show button0Text="Accept" disableConfirm={!acceptBoxChecked} onConfirm={this.onConfirm}>
                <iframe src="/privacy.txt" style={{width:"100%", height: "250px"}}/>
                <Form className="pt-2">
                    <Form.Check checked={acceptBoxChecked} onChange={this.onAcceptChanged} style={CHECKBOX_STYLE} type='checkbox' id='accept-box' label='I accept the Multiverse Terms and Conditions'/>
                    <Form.Check checked={updatesBoxChecked} onChange={this.onUpdatesChanged} style={CHECKBOX_STYLE} type='checkbox' id='accept-box' label='I would like to receive occasional news about Multiverse updates and events'/>
                </Form>
    
            </DialogBoxWithSpinner>)
        } else {
            return (<>{this.props.children}</>)
        }
    }

    onAcceptChanged = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            acceptBoxChecked: ev.target.checked
        });
    }
    onUpdatesChanged = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            updatesBoxChecked: ev.target.checked
        });
    }

    onConfirm = async(button?: number) => {
        const { multiverse:{post, updateUser}} = this.props;
        const {acceptBoxChecked, updatesBoxChecked} = this.state;
        if(acceptBoxChecked) {
            await post("/v2/settermsandconditions", { acceptedtandcs: acceptBoxChecked, receiveupdates: updatesBoxChecked });
            await updateUser();
        }
    }
}

export default withMultiverseApi(TermsCheck);
