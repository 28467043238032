import React from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css';

type VideoPlayerProps = {
    className?: string
} & videojs.PlayerOptions

export default class VideoPlayer extends React.Component<VideoPlayerProps> {
    videoNode: HTMLVideoElement | null = null;
    player: videojs.Player | null = null;


    componentDidMount() {
        // instantiate Video.js
        if (!this.videoNode) {
            throw new Error("No video node");
        }
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
            console.log('onPlayerReady')
        });
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        let cn = "data-vjs-player";
        if (this.props.className) {
            cn = cn + " " + this.props.className;
        }
        return (
            <div className={cn}>
                <video ref={node => this.videoNode = node} className="video-js"></video>
            </div>
        )
    }
}