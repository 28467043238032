import React, { FunctionComponent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Domain, clientId } from './config/auth0';
import MultiverseApiProvider from './hoc/multiverseApiProvider';

import AppRouter from './router';
import './utils/setUpRollbar';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './router/authWithHistory';
import { Elements } from '@stripe/react-stripe-js';
import { createMultiverseStripeClient } from './utils/createMultiverseStripeClient';


const App: FunctionComponent = () => {

    //enable to print stuff about every domain change!
    /*
    const full_location = new URL(window.location.href);
    console.log(full_location);
    console.log(full_location.pathname);
    console.log(full_location.search);
    const stateb64 = full_location.searchParams.get("state");
    if(stateb64) {
        const state = btoa(stateb64);
        console.log(stateb64);
        console.log(state);
    }*/

    return (
        <>
            <BrowserRouter>
                <Auth0ProviderWithHistory
                    domain={auth0Domain}
                    clientId={clientId}
                    redirectUri={`${window.location.origin}`}>
                    <MultiverseApiProvider>
                        <AppRouter />
                    </MultiverseApiProvider>
                </Auth0ProviderWithHistory>
            </BrowserRouter>
        </>
    );
}

export default App;
