import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseFile, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../../components/pageTable';
import api from '../../utils/api';
import { getBlobUrl } from '../../config/api';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import AdminPage from '../../components/adminPage';
import { stringify } from 'querystring';



interface IRedeemCode {
    uniquecode: string,
    usage: string,
    circulated: boolean,
    timestamp: Date,
    startdate: Date,
    enddate: Date,
    redeemdate?: Date,
    //redeemuser?: ObjectId
}



type AdminListRedeemCodePageProps = {

} & WithMultiverseApiProps & RouteComponentProps;;

type AdminListRedeemCodePageState = {
    usage: string,
    codeslist: any[]
};

class _AdminListRedeemCodePage extends React.Component<AdminListRedeemCodePageProps, AdminListRedeemCodePageState> {
    constructor(props: AdminListRedeemCodePageProps) {
        super(props);
        this.state = {
            usage: "",
            codeslist: []
        };
    }

    async componentDidMount(): Promise<void> {
        try {
            //match against url to pull out domain + location
            const match = matchPath<{ usage: string }>(this.props.history.location.pathname, {
                path: '/admin/redeemcodes/listcodes/:usage',
            })
            if (!match) {
                throw new Error("Invalid path");
            }

            const query = `/v2/admin/redeemcodes/listcodes/${match.params.usage}`
            const codes = await this.props.multiverse.get<any[]>(query);
            console.log(codes)
            this.setState({usage:match.params.usage, codeslist: codes})

        } catch(err) {
            console.log(err)
//            this.setState({resultText: "error", redeemingState:RedeemingState.redeemFail }) //err as string} )
        }
    }

    RedeemCodesRow() {
        const usage = this.state.usage;
        const codeslist = this.state.codeslist;
        return (
        <Row>
            <Col className="restapi-documentfields-col">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Redeem Codes for <b>{usage}</b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">                       
                            <>
                                {<PageTable>
                                    <thead>
                                        <PageTableRow>
                                            <th style={{width:'15%'}}>Unique Code</th>
                                            <th style={{width:'10%'}}>Circulated</th>
                                            <th>Redeemed On</th>
                                            <th>Redeemed By</th>
                                            <th>Building Slot</th>
                                            <th>user id</th>
                                         </PageTableRow>
                                    </thead>
                                    <tbody>
                                        {codeslist.map(x => (
                                            <PageTableRow key={x.uniquecode}>
                                                <PageTableCell>{x.uniquecode}</PageTableCell>
                                                <PageTableCell>{x.circulated?"Yes":""}</PageTableCell>
                                                <PageTableCell>{x.redeemdate}</PageTableCell>
                                                <PageTableCell>{x.username}</PageTableCell>
                                                <PageTableCell>{x.buildingslot?x.buildingslot+1:""}-{x.buildingname}</PageTableCell>
                                                <PageTableCell>{x.redeemuser} </PageTableCell>
                                            </PageTableRow>
                                        ))}
                                    </tbody>
                                </PageTable>}
                            </>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </Col>
        </Row>)    
    }
    


    render(): JSX.Element {
        return (
            <AdminPage>
                {this.RedeemCodesRow()}
            </AdminPage>
        )
    }
}

export const AdminListRedeemCodePage = withRouter(withMultiverseApi(_AdminListRedeemCodePage));

export default AdminListRedeemCodePage;
