import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { authenticatedFetch } from '../../utils/api';
import { Button } from 'react-bootstrap';


type RawAudioPlayerProps = {
    src: string;
};

type RawAudioPlayerState = {
    audioContext: AudioContext | null;
    contentType: string | null;
    buffer: ArrayBuffer | null;
    samples: Float32Array | null;
    audioBuffer: AudioBuffer | null;
    bufferSource: AudioBufferSourceNode | null;
};

class _RawAudioPlayer extends React.Component<RawAudioPlayerProps, RawAudioPlayerState> {
    constructor(props: RawAudioPlayerProps) {
        super(props);
        this.state = {
            audioContext: null,
            contentType: null,
            buffer: null,
            samples: null,
            audioBuffer: null,
            bufferSource: null
        };
    }

    async componentDidMount() {


        const audioContext = new AudioContext();

        const rsp = await authenticatedFetch({url: this.props.src});

        const contentType = rsp.headers.get("content-type");

        const buffer = await rsp.arrayBuffer();

        const dv = new DataView(buffer);

        const samples = new Float32Array(buffer.byteLength/2);
        for(let i = 0; i < buffer.byteLength; i+=2) {
            const i16 = dv.getInt16(i, true);
            samples[i/2] = i16 / 32768;
        }   

        const audioBuffer = audioContext.createBuffer(1, samples.length, 48000);
        audioBuffer.copyToChannel(samples, 0);

        //bufferSource.start();

        this.setState({
            audioContext,
            contentType,
            buffer,
            samples,
            audioBuffer
        })

    }

    async componentWillUnmount() {
        if(this.state.audioContext) {
            this.state.audioContext.close();
        }
    }

    play = () => {
        const { bufferSource, audioContext, audioBuffer } = this.state;
        if(audioContext) {
            if(bufferSource) {
                bufferSource.stop()
                bufferSource.disconnect()
            } 
    
            const newBufferSource = audioContext.createBufferSource();
            newBufferSource.buffer = audioBuffer;
            newBufferSource.connect(audioContext.destination);   
            newBufferSource.start()

            this.setState({
                bufferSource: newBufferSource
            })
        }
 

    }

    render(): JSX.Element {
        return (
            <div>
                <Button 
                    disabled={this.state.audioBuffer == null}
                    className="no-outline-button"
                    variant="outline-secondary"
                    onClick={this.play}
                    style={{
                        padding: 0,
                        margin: 0,
                        width: "100%",
                        fontSize: 12
                    }}>Play</Button>
            </div>
        )
    }
}
export const RawAudioPlayer = _RawAudioPlayer;

export default RawAudioPlayer;
