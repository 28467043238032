import React, { CSSProperties, FunctionComponent } from 'react';
import { Spinner } from 'react-bootstrap';

const STYLE:CSSProperties = {
  display: 'inline-block',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  fontSize: '2.5rem',
};

const LoggingInLoader:FunctionComponent = () => (
  <div style={STYLE}>
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

export default LoggingInLoader;
