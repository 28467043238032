import React from 'react';
import { Button, Col, Container, FormControl, Modal, Row, Spinner, Badge, Alert } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import PageTable from '../../components/pageTable';
import { InfiniverseAdvert, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import "./advertspage.css"
import CreateAdvertModal from './createadvertmodal';
import PurchaseImpressionsModal from './purchaseimpressionsmodal';
import SimpleModal from '../../components/simpleModal';

type InfiniverseAdvertsResponse = {
    adverts: InfiniverseAdvert[];
}

type Filter = "none";

type AdvertsTableProps = {
    filter?: Filter
    group?: boolean
} & WithMultiverseApiProps & RouteComponentProps;

type AdvertsTableAdvert = InfiniverseAdvert & {
    isLoading?: boolean;
}

type AdvertsTableState = {
    isLoading: boolean;
    hasError: boolean;
    adverts: AdvertsTableAdvert[];
    pollInterval: undefined | number;
    showCreateDialog: boolean;
    showPurchaseDialog: null | InfiniverseAdvert;
    messageModalText: null | string;
};

const POLL_SPEED = 3000; // The time between polls for adverts pending approval

class _AdvertsTable extends React.Component<AdvertsTableProps, AdvertsTableState> {

    constructor(props: AdvertsTableProps) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            adverts: [],
            pollInterval: undefined,
            showCreateDialog: false,
            showPurchaseDialog: null,
            messageModalText: null,
        };
    }

    async componentDidMount(): Promise<void> {
        // TODO: (Kai 25/02/22) Ensure that if the users looses internet connection they don't get spammed with errors
        await this.refreshList();
        /*
        const pollInterval = window.setInterval(() => {
            this.pollPendingAdverts();
        }, POLL_SPEED);
        this.setState({pollInterval})
        */
    }

    componentWillUnmount(): void {
        const { pollInterval } = this.state;
        if (pollInterval) {
            window.clearInterval(pollInterval);
        }
    } 

    pollPendingAdverts() {
        this.state.adverts
            .filter(x => x.pendingimpressions > 0)
            .filter(x => !x.isLoading)
            .forEach(x => this.refreshAdvert(x._id));
    }
    

    filterAdvertList = (adverts: InfiniverseAdvert[], filter?: Filter): InfiniverseAdvert[] => {
        return adverts;
    }

    shouldShowPurchaseButton(x: AdvertsTableAdvert) {
        return !x.pendingimpressions && !x.rejectionreason
    }

    refreshList = async () => {

        try {
            //switch to loading
            this.setState({ isLoading: true });

            //get and store adverts list
            let adverts = this.filterAdvertList(await this.fetchAdverts(), this.props.filter);
            this.setState({ adverts });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    fetchAdverts = async (): Promise<InfiniverseAdvert[]> => {
        const { multiverse: { get } } = this.props;
        const res = await get<InfiniverseAdvertsResponse>('/v2/adverts')
        return res.adverts
    }

    renderrows_noadverts() {
        return <>
            <Row className="pt-2">
                <Col className="text-center pb-2">
                    You've not created any Infiniverse adverts yet.
                           <br></br>
                </Col>
            </Row>
            {this.createNewAdvertButton()}
        </>

    }


    renderTableContainerRow = (adverts: AdvertsTableAdvert[]) => {
        return (
            adverts.map((x) => {
                const purchaseButton = ()=>(<>
                { this.shouldShowPurchaseButton(x) && <Button 
                    variant="link" 
                    className="p-0 mt-1 purchase-impressions"
                    onClick={()=>this.onPurchaseClick(x._id)}>
                        Purchase impressions
                    </Button>
                }
                {
                    x.paymentfailure &&  <Alert variant="warning" className="mb-0">Payment failed: {x.paymentfailure}</Alert>
                }
                </>)
                return (
                    <PageTable.Row 
                        key={x._id} 
                        className={x.isLoading ? 'loading' : ''}
                    >
                        <PageTable.IconCell className="dp-icon-cell" src={x.iconurl} />
                        <PageTable.Cell className="dp-name-cell">
                            <div>
                            {x.name}
                            </div>
                            <Badge className="m-0 p-0 mb-3">{this.getStatus(x)}</Badge>
                            {x.isLoading && <Spinner className="ml-2" animation="border" variant="primary" size="sm"/>}
                            <div className="d-sm-none">
                                {this.formatImpressions(x)}
                                {purchaseButton()}
                            </div>
                        </PageTable.Cell>
                        <PageTable.Cell className="d-none d-sm-table-cell" >
                            {this.formatImpressions(x, "No impressions yet")}
                            {purchaseButton()}
                        </PageTable.Cell>
                    </PageTable.Row>
                )
            })
        )
    }

    concatTableContainerRow = (tables: JSX.Element[], adverts: InfiniverseAdvert[]) => {
        if (adverts.length > 0) {
            if (tables.length > 0) {
                tables.push(<PageTable.Row style={{ height: 15 }} />)
            }
            this.renderTableContainerRow(adverts).forEach(x => tables.push(x));
        }
    }

    private formatImpressions(x: InfiniverseAdvert, fallback:React.ReactNode = null): React.ReactNode {
        if(x.rejectionreason){
            return <small>
                Unfortunately your advert has been rejected, with the message "{x.rejectionreason}"
                <br/>
                Please contact support for more information.
            </small>
        }
        if(!x.approved && x.pendingimpressions) return <>
        {x.pendingimpressions} 
            <small> impressions ordered.
            <br/>You will not be charged until after your order has been approved</small>
        </>;
        if (x.totalimpressions === 0) return fallback;
        return <>
            {`${x.remainingimpressions} / ${x.totalimpressions} `}
            <small>remaining impressions</small>
        </>
    }

    renderrows_adverts() {
        let tables: JSX.Element[] = this.renderTableContainerRow(this.state.adverts);

        return (
            <>
                <Row className="p-0">
                    <Col>
                        <PageTable className="m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Advert</th>
                                    <th className="d-none d-sm-table-cell">Remaining Impressions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tables}
                            </tbody>
                        </PageTable>
                    </Col>
                </Row>
                {this.createNewAdvertButton()}
            </>
        )
    }
    createNewAdvertButton(): JSX.Element {
        return <Row className="pt-2 pb-2">
            <Col className="text-center">
                <Button onClick={() => this.setState({ showCreateDialog: true })} variant="outline-primary">Create New Infinverse Billboard</Button>
            </Col>
        </Row>
    }

    render_rows() {
        if (!this.state.adverts)
            return (<pre>error</pre>)
        else if (this.state.adverts.length > 0)
            return this.renderrows_adverts();
        else
            return this.renderrows_noadverts();
    }

    renderLoading() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </Container>
        );
    }

    onCreateAdvert = async (name:string, image:File) => {
        const { multiverse: { post, showErrorToast } } = this.props;
        try{
            this.setState({ isLoading: true });
            await post<InfiniverseAdvert>({
                    url:`/v2/adverts?name=${name}`,
                    body: image,
                    headers: {
                        'Content-Type': image.type,
                        'x-shapevr-name': name,
                    }
             })
        }catch(e){
            showErrorToast(e);
        }finally{
            await this.refreshList();
            this.setState({ isLoading: false,  showCreateDialog: false });
        }
    }

    async onPurchaseClick(_id: string): Promise<void> {
       this.setState({
              showPurchaseDialog: this.state.adverts.find(x=>x._id === _id) || null,
       })
    }

    getStatus(x: InfiniverseAdvert): string {
        if(!!x.paymentfailure && x.approved) return "Approved (Payment failed)";
        if(!!x.paymentfailure) return "Payment failed";
        if(!x.approved && x.rejectionreason) return "Failed Approval"
        if (x.pendingimpressions > 0 && !x.approved) return "Waiting for Approval";
        if (x.remainingimpressions > 0) return "Active";
        if (x.totalimpressions > 0) return "No Remaining Impressions";
        return "Inactive";
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                <CreateAdvertModal
                    show={this.state.showCreateDialog}
                    isLoading={this.state.isLoading}
                    onHide={() => this.setState({ showCreateDialog: false })}
                    onCreateAdvert={this.onCreateAdvert}
                />
                <PurchaseImpressionsModal
                    key={this.state.showPurchaseDialog?._id}
                    show={!!this.state.showPurchaseDialog}
                    advert={this.state.showPurchaseDialog!}
                    onHide={(paymentMethodResponse) => {
                        this.refreshAdvert(this.state.showPurchaseDialog!._id);
                        this.setState({ showPurchaseDialog: null })
                        if(paymentMethodResponse?.status === 'ok' && paymentMethodResponse.captured){
                            this.setState({ messageModalText: 'Impressions purchased successfully!' })
                        }else if(paymentMethodResponse?.status === 'ok' && !paymentMethodResponse.captured){
                            this.setState({ messageModalText: 'Impressions ordered successfully. Billboards must be manually approved before appearing in the Infiniverse. You will not be charged until your advert has been approved.' })
                        }
                    }}
                />
                <SimpleModal 
                    text={this.state.messageModalText} 
                    onHide={()=>this.setState({messageModalText: null})} 
                    header="Success!"
                />
                <Container fluid className="p-0">
                    {!isLoading && this.render_rows()}
                    {isLoading && this.renderLoading()}
                </Container>
            </>);
    }
    async refreshAdvert(_id: string) {
        this.setState({
            adverts: this.state.adverts.map(x => {
                if (x._id === _id) {
                    return {
                        ...x,
                        isLoading: true,
                    }
                }
                return x;
            })
        })
        try {
            const advert = await this.props.multiverse.get<InfiniverseAdvert>({
                url: `/v2/adverts/${_id}`
            });
            this.setState({
                adverts: this.state.adverts.map(x => {
                    if (x._id === _id) {
                        return advert
                    }
                    return x;
                })
            })
        } catch (e) {
            this.props.multiverse.showErrorToast(e);
        } finally {
            this.setState({
                adverts: this.state.adverts.map(x => {
                    if (x._id === _id) {
                        return {
                            ...x,
                            isLoading: false,
                        }
                    }
                    return x;
                })
            })
        }
    }
}

export const AdvertsTable = withRouter(withMultiverseApi(_AdvertsTable));
export default AdvertsTable;


