import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import { RestApiDocumentData, RestApiSpecResponse } from '.';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import { RestApiFieldDefinition } from './restApiCollection';
import { getBlobUrl } from '../../config/api';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import api from '../../utils/api';
import { TCustomStyleInfo, TSpec, TVariableValue } from '../../utils/types';
import "./restapi.css"
import ReactJson from 'react-json-view';
import RawAudioPlayer from './rawAudioPlayer';
import { BuildingRows, SlotsRow } from './buildings';
import { WitRequestRow } from './wit';
import { StylesRows } from './styles';
import { UserInfiniverseSlotsRow, UserInfiniverseTransactionsRow, UserInventoryRow, UserEscrowsRow, UserInventoryTransactionsRow, UserUnconsumedIAPRow, InfiniverseSlotsRow } from './inventory';
import { PERMISSIONS, UserAccessToken } from './permissions';
import { DomainLocationsRow, UserDomainsRow } from './domains';
import { UserFilesRow } from './files';
import { LocationUriDialog } from '../locationUrlDialog';
import { FindUserDialog } from '../findUserDialog';
import { AutoTestGroupRows, AutoTestTotalsRow } from './autoTestGroup';

type UrlParts = {
    child_product?: string,
    child_product_submenu?: string
}

type RestApiDocumentProps = {
    uri?: string;
    children: any;
} & WithMultiverseApiProps & RouteComponentProps;

type RestApiDocumentState = {
    fields: { [key:string]: RestApiFieldDefinition };
    data: RestApiDocumentData;
    release: string;
    onSelectUserCallback: ((user: MultiverseUser|null)=>void) | null;
};

class _RestApiDocument extends React.Component<RestApiDocumentProps, RestApiDocumentState> {
    constructor(props: RestApiDocumentProps) {
        super(props);
        this.state = {
            fields: { },
            data: {},
            release: "prod",
            onSelectUserCallback: null
        };
    }

    async componentDidMount(): Promise<void> {

        const uri = this.props.uri || document.location.pathname;
        const uri_match = uri.match(/^.*(\/admin\/\w*)\/(.*)/);
        if(!uri_match) {
            throw new Error("Bad uri");
        }
        const base_uri = "/v2"+uri_match[1];
        const id = uri_match[2];

        const spec = await this.props.multiverse.get<RestApiSpecResponse>(`${base_uri}/adminspec`);
        const fields: any = {};
        Object.keys(spec.document).forEach( (field_name) => {
            let spec_field = spec.document[field_name];
            if(typeof(spec_field) === 'string') {
                spec_field = { type: spec_field };
            }           
            fields[field_name] = spec_field;
        });

        const data = await this.props.multiverse.get<any>(`${base_uri}/${id}`);
        this.setState({
            fields, 
            data
        })
    }

    async reloadDocument() {
        const uri = this.props.uri || document.location.pathname;
        const uri_match = uri.match(/^.*(\/admin\/\w*)\/(.*)/);
        if(!uri_match) {
            throw new Error("Bad uri");
        }        
        const base_uri = "/v2"+uri_match[1];
        const id = uri_match[2];
        const data = await this.props.multiverse.get<any>(`${base_uri}/${id}`);
        this.setState({
            data
        })
    } 

    renderFieldValue = (field_name: string) => {        
        const field_def = this.state.fields[field_name];
        let field_val = this.state.data[field_name];
        const resolved_field_val = this.state.data["_"+field_name];
        if(resolved_field_val) {
            field_val = `${resolved_field_val} (${field_val})`;
        }
        if(field_val === undefined) {
            if(field_def.type === "boolean") {
                return "false";
            } else {
                return "";
            }
        } else if(field_def.type === "object") {
            return JSON.stringify(field_val);
        } else if(field_def.type === "date") {
            return new Date(field_val).toUTCString();
        } else {
            return `${field_val}`;
        }
    }

    renderField = (field_name: string) => {
        return (
            <PageTable.Row key={field_name}>
                <PageTableCell>{field_name}</PageTableCell>
                <PageTableCell>{this.renderFieldValue(field_name)}</PageTableCell>
            </PageTable.Row>
        )
    }

    onUserPermissionToggle = async(permission_name: string, val: boolean) => {
        try {
            const new_data = {...this.state.data};    

            let permissions: string[] = new_data.permissions || [];
            permissions = permissions.filter(x => x !== permission_name);      
            if(val) {
                permissions.push(permission_name);
            }
            new_data.permissions = permissions;

            this.setState({
                data: new_data
            })

            await this.props.multiverse.post(`/v2/admin/users/${new_data["id"]}/permissions`, {
                permissions
            })

            await this.reloadDocument();
        } catch(err) {
            console.log(err);
        }
    }

    onUserPropertyToggle = async(bool_val_name: string, val: boolean) => {
        try {
            const new_data = {...this.state.data};    

            new_data[bool_val_name] = val;

            this.setState({
                data: new_data
            })

            const ud: any = {}
            ud[bool_val_name] = val
            await this.props.multiverse.post(`/v2/admin/users/${new_data["id"]}`, ud)

            await this.reloadDocument();
        } catch(err) {
            console.log(err);
        }
    }

    onUserDeleteClicked = async() => {
        if(window.confirm(`Are you sure you wish to delete ${this.state.data["name"]} and everything they own?`)) {
            await this.props.multiverse.del(`/v2/admin/users/${this.state.data["id"]}`)
            this.props.history.push("/admin/users");
        }
    }
    onUserUnlinkClicked = async() => {
        if(window.confirm(`Are you sure you wish to unlink ${this.state.data["name"]} from their auth 0 account?`)) {
            await this.props.multiverse.post(`/v2/admin/users/${this.state.data["id"]}/unlink`)
            this.props.history.push("/admin/users");
        }
    }
    onDomainDeleteClicked = async() => {
        if(window.confirm(`Are you sure you wish to delete ${this.state.data["name"]} and everything it contains?`)) {
            await this.props.multiverse.del(`/v2/admin/domains/${this.state.data["id"]}`)
            this.props.history.push("/admin/domains");
        }
    }

    onDomainTrialResetClicked = async() => {
        await this.props.multiverse.post(`/v2/admin/domains/${this.state.data["id"]}/trialreset`)
        await this.reloadDocument();
    }

    onDomainSetCustomPlanClicked = async() => {
        if(window.confirm(`Are you sure you wish to switch ${this.state.data["name"]} to the enterprise custom plan?`)) {
            await this.props.multiverse.post(`/v2/admin/domains/${this.state.data["id"]}/setcustomplan`)
            await this.reloadDocument();
        }
    }

    onDomainSetBusinessTrialClicked = async() => {
        if(window.confirm(`Are you sure you wish to switch ${this.state.data["name"]} to the business trial plan?`)) {
            await this.props.multiverse.post(`/v2/admin/domains/${this.state.data["id"]}/settrialplan`)
            await this.reloadDocument();
        }
    }

    onChangeOwnerClicked = async() => {
        this.setState({
            onSelectUserCallback: (user) => {
                if(user && window.confirm(`Are you sure you wish to change owner ship of ${this.state.data["name"]} to ${user.nickname}/${user.email}`)) {
                    //await this.props.multiverse.post(`/v2/admin/domains/${this.state.data["id"]}/settrialplan`)
                    //await this.reloadDocument();
                    this.changeOwnerTo(user)
                }                
            }
        })
    }

    onRegeneratePlotClicked = async() => {
        await api.post(`/v2/admin/plots/${this.state.data["id"]}/regenerate`)
    }

    onAdministerMissionEventsClicked = async(child_product: string) => {
        console.log(`Administer mission event for product: ${child_product}`)
        this.props.history.push(`/admin/childproducts/${child_product}/missionevents`)
    }

    onAdministerMissionConfigsClicked = async(child_product: string) => {
        console.log(`Administer mission configs for product: ${child_product}`)
        this.props.history.push(`/admin/childproducts/${child_product}/missionconfigs`)
    }    

    onAdministerMissionsClicked = async(child_product: string) => {
        console.log(`Administer missions for product: ${child_product}`)
        this.props.history.push(`/admin/childproducts/${child_product}/missions`)
    }    

    onAdministerAvatarsClicked = async(child_product: string) => {
        console.log(`Administer avatars for product: ${child_product}`)
        this.props.history.push(`/admin/childproducts/${child_product}/avatars`)
    }

    changeOwnerTo = async(user:MultiverseUser) => {
        await this.props.multiverse.post(`/v2/admin/setowner`, { id: this.state.data["id"], owner: user.id })
        await this.reloadDocument();
    }

    onSelectUser = (user: MultiverseUser | null) => {
        this.setState({onSelectUserCallback: null})
        if(this.state.onSelectUserCallback) {
            this.state.onSelectUserCallback(user);
        }
    }

    togglePermissionDisabled = (permission_name: string) => {
        if(PERMISSIONS[permission_name].startsWith("[Deprecated]")) {
            return true;
        } else if(this.state.data.permissions && this.state.data.permissions.includes("superuser")) {
            return !this.props.multiverse.user?.permissions.includes("superuser"); 
        } else if(permission_name === "superuser") {
            return !this.props.multiverse.user?.permissions.includes("superuser");
        } else {
            return false;
        }
    }

    renderEntityHeaderRow = () => {
        if(this.state.data.type) {
            return (
            <Row className="restapi-entityheader">
                <Col className="restapi-entityicon" >
                    <Image src={`${getBlobUrl()}/${this.state.data.iconblob}`}/>
                </Col>
                <Col>
                    <Container fluid>
                        <Row><Col><h3 className="mb-0">{this.state.data.name}</h3></Col></Row>
                        <Row><Col>{this.state.data.type} @ {this.state.data.path}</Col></Row>
                    </Container>
                </Col>
            </Row>)
        } else {
            return undefined;
        }
    }

    renderUserPermissionRow = (permission_name: string) => {
        return <Row key={permission_name} className="restapi-document-userpermissions">
            <Col>
                <input 
                    type="checkbox" className="mr-2" 
                    disabled={this.togglePermissionDisabled(permission_name)}
                    checked={this.state.data.permissions && this.state.data.permissions.includes(permission_name)}
                    onChange={(ev) => this.onUserPermissionToggle(permission_name, ev.target.checked)}/>
                {`${PERMISSIONS[permission_name]} (${permission_name})`}
            </Col>

        </Row>
    }

    renderUserToggleRow = (label: string, bool_val_name: string) => {
        return <Row key={bool_val_name} className="restapi-document-userpermissions">
            <Col>
                <input 
                    type="checkbox" className="mr-2" 
                    disabled={false}
                    checked={this.state.data[bool_val_name]}
                    onChange={(ev) => this.onUserPropertyToggle(bool_val_name, ev.target.checked)}/>
                {`${label} (${bool_val_name})`}
            </Col>

        </Row>
    }

    renderUserTokenRow = () => {
        return <Row key="user_token" className="restapi-document-userpermissions">
            <Col>
                <UserAccessToken userid={this.state.data["id"]}/>
            </Col>

        </Row>
    }

    renderUserPermissionsRow = () => {
        if(this.state.data["type"] === "user") {
            return (
            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            User Permissions
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Container fluid>
                                    {Object.keys(PERMISSIONS).map(x => this.renderUserPermissionRow(x))}
                                    {this.renderUserToggleRow("Show developer icon","showdeveloper")}
                                    {this.renderUserToggleRow("Show rep icon","showrepresentative")}
                                    {this.renderUserToggleRow("Globally ignored","globalignore")}
                                    {this.state.data["permissions"].includes("can_use_access_token") && this.renderUserTokenRow()}
                                </Container>                                
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>)
        } else {
            return undefined;
        }
    }

    renderUserUnconsumedIAPsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserUnconsumedIAPRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }

    renderUserDomainsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserDomainsRow userid={this.state.data["id"]} onvisit={(id) => {this.props.history.push(`/admin/domains/${id}`)}}/>)
        } else {
            return undefined;
        }
    }

    renderUserFilesRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserFilesRow userid={this.state.data["id"]} onvisit={(id) => {this.props.history.push(`/admin/files/${id}`)}}/>)
        } else {
            return undefined;
        }
    }

    renderDomainLocationsRow = () => {
        if(this.state.data["type"] === "domain") {
            return (<DomainLocationsRow domainid={this.state.data["id"]} onvisit={(id) => {this.props.history.push(`/admin/locations/${id}`)}}/>)
        } else {
            return undefined;
        }
    }

    renderUserInventoryRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserInventoryRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }
    

    renderUserEscrowsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserEscrowsRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }

    renderUserInventoryTransactionsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserInventoryTransactionsRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }

    renderUserInfiniverseTransactionsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserInfiniverseTransactionsRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }

    renderUserInfiniverseSlotsRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserInfiniverseSlotsRow userid={this.state.data["id"]}/>)
        } else {
            return undefined;
        }
    }

    renderUserInfiniverseStoresRow = () => {
        if(this.state.data["type"] === "user") {
            return (<UserInfiniverseSlotsRow userid={this.state.data["id"]} uri={`/v2/admin/users/${this.state.data["id"]}/infiniverse/stores`} title="User Infiniverse Stores"/>)
        } else {
            return undefined;
        }
    }

    onUserDumpStripeDataClicked = async() => {

        const txt = JSON.stringify(await this.props.multiverse.get<any>(`/v2/admin/users/${this.state.data["id"]}/stripefull`), null, 4);
        var blob = new Blob([txt], {type: "text/json;charset=utf-8"});
        saveAs(blob, `stripe_${this.state.data["id"]}.json`);
    }

    renderUserOperationsRow = () => {
        if(this.state.data["type"] === "user") {
            return (
            <Row>
                <Col className="restapi-document-operations">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            User Operations
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Button variant="outline-primary" onClick={()=>this.onUserDeleteClicked()}>Delete User</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-primary" onClick={()=>this.onUserDumpStripeDataClicked()}>Download Stripe Data</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-primary" onClick={()=>this.onUserUnlinkClicked()}>Unlink Account</Button>
                                        </Col>
                                    </Row>
                                </Container>                                
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>)
        } else {
            return undefined;
        }        
    }

    renderFileBlobRow = () => {
        if(this.state.data["type"] === "file" && (
            this.state.data["mimetype"]?.startsWith("image/") ||
            this.state.data["name"]?.endsWith(".jpeg") ||
            this.state.data["name"]?.endsWith(".jpg") ||
            this.state.data["name"]?.endsWith(".png") )) {
            return (
            <Row>
                <Col className="restapi-documentfields-col">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            Image
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Image src={`${getBlobUrl()}/${this.state.data["blob"]}`} style={{width:"100%"}}/>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>)
        } else {
            return undefined;
        }        
    }

    renderOperationsRow = () => {

        const domain_buttons = this.state.data["type"] === "domain";
        const owner_button = ['domain','location','event'].includes(this.state.data["type"]);
        const regenerate_button = ['plot'].includes(this.state.data["type"]);

        if(domain_buttons || owner_button || regenerate_button) {

            const istrial = this.state.data["plan"]?.includes("trial");
            const isfree = this.state.data["plan"] == "free" || istrial;

            const change_owner_enabled = this.props.multiverse.user!.permissions.includes("admin_write_entities") && (this.state.data["type"] !== "domain" || isfree || this.state.data["plan"] == "enterprise-custom");


            return (
            <Row>
                <Col className="restapi-document-operations">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            Operations
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            {domain_buttons && <Button variant="outline-primary" disabled={!isfree} onClick={()=>this.onDomainDeleteClicked()}>Delete Domain</Button>}
                                            {domain_buttons && <Button variant="outline-primary" disabled={!istrial} onClick={()=>this.onDomainTrialResetClicked()}>Reset trial period</Button>}
                                            {domain_buttons && <Button variant="outline-primary" disabled={!isfree} onClick={()=>this.onDomainSetCustomPlanClicked()}>Switch to custom plan</Button>}
                                            {domain_buttons && <Button variant="outline-primary" disabled={!isfree} onClick={()=>this.onDomainSetBusinessTrialClicked()}>Switch to business trial</Button>}
                                            {owner_button && <Button variant="outline-primary" disabled={!change_owner_enabled} onClick={()=>this.onChangeOwnerClicked()}>Change owner</Button>}
                                            {regenerate_button && <Button variant="outline-primary" onClick={()=>this.onRegeneratePlotClicked()}>Regenerate</Button>}
                                        </Col>
                                    </Row>
                                </Container>                                
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>)
        } else {
            return undefined;
        }        
    }

    renderDomainInfiniverseStoresRow = () => {
        if(this.state.data["type"] === "domain") {
            return (<UserInfiniverseSlotsRow userid={this.state.data["id"]} uri={`/v2/admin/domains/${this.state.data["id"]}/infiniverse/stores`} title="Infiniverse Stores"/>)
        } else {
            return undefined;
        }
    }

    renderStylesRows = () => {
        const type = this.state.data["type"];
        const id = this.state.data["id"];
        const domain = this.state.data["domain"]
        if(!id || !type || !domain) {
            return;
        }

        let styles_uri: string;
        if(type === "location") {
            styles_uri = `/v2/domains/${this.state.data["domain"]}/locations/${id}/styles`;
        } else if(type === "event") {
            styles_uri = `/v2/domains/${this.state.data["domain"]}/events/${id}/styles`;
        } else if(type === "domain") {
            styles_uri = `/v2/domains/${this.state.data["domain"]}/styles`;
        } else {
            return;
        }

        return <StylesRows stylesUri={styles_uri} release={this.state.release}/>
        
        
    }

    renderLocationSlotsRow = () => {
        if(this.state.data["type"] === "location" && this.state.data["slots"]) {
            return (<InfiniverseSlotsRow title="Slots pointing at this location" slots={this.state.data["slots"]} onvisit={(slot) => {
                this.props.history.push(`/admin/buildings/${slot.building._id}`)
            }}/>)
        } else {
            return undefined;
        }
        
    }
 
    onReleaseChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            release: ev.currentTarget.value
        }, () => {})
    }

    renderDevMessage = () => {
        if(document.location.pathname.includes("/locations/")) {
            return (
            <Row className="restapi-devmessage">
                <Form as={Col}>
                    <Form.Control as="select" value={this.state.release} onChange={this.onReleaseChange}>
                        {/*<option value="personal">Personal</option>*/}
                        <option value="dev">Work In Progress (dev)</option>
                        {/*<option value="private">Private</option>*/}
                        <option value="prod">Published (prod)</option>
                    </Form.Control>   
                </Form>

            </Row>)
        } else {
            return undefined;
        }
    }

    renderWitRequestRows = () => {
        return (document.location.pathname.includes("/witrequests") && <WitRequestRow data={this.state.data}/>)
    }

    renderBuildingRows = () => {
        if(document.location.pathname.includes("/buildings")) {
            return <BuildingRows data={this.state.data} onChange={()=>this.reloadDocument()}/>
        } else {
            return undefined;
        }
    }

    renderProductMenuRow = () => {
        const { childProduct } = this.props;

        const url = this.parseUrl();
        if(url.child_product) {
            const missions_enabled = this.props.multiverse.user!.permissions.includes("admin_missions");
            return (
            <Row>
                <Col className="restapi-document-operations">
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            {(childProduct ? `${childProduct.name} ` : "") + "Product Menu"}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Container fluid>
                                    <Row>
                                        <Col className="evenly-spaced-children">
                                            {<Button variant="outline-primary" disabled={!missions_enabled || !url.child_product} onClick={()=>this.onAdministerMissionEventsClicked(url.child_product!)}>Mission Events</Button>}
                                            {<Button variant="outline-primary" disabled={!missions_enabled || !url.child_product} onClick={()=>this.onAdministerMissionConfigsClicked(url.child_product!)}>Mission Configs</Button>}
                                            {<Button variant="outline-primary" disabled={!missions_enabled || !url.child_product} onClick={()=>this.onAdministerMissionsClicked(url.child_product!)}>Missions</Button>}
                                            {<Button variant="outline-primary" disabled={!url.child_product} onClick={()=>this.onAdministerAvatarsClicked(url.child_product!)}>Avatars</Button>}
                                        </Col>
                                    </Row>
                                </Container>                                
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>)
        } else {
            return undefined;
        }
    }

    renderAutoTestRow = () => {
        if(document.location.pathname.includes("/autotests")) {
             return <AutoTestGroupRows data={this.state.data}/>
        } else {
            return undefined;
        }
    }

    renderPerLocationAutoTestRow = () => {
        if(document.location.pathname.includes("/locations")) {
            const statsuser = (new URLSearchParams(window.location.search)).get("statsuser");
            return <AutoTestTotalsRow data={this.state.data} statsuser={statsuser}/>
        } else {
            return undefined;
        }       
    }

    parseUrl(): UrlParts {
        const url = document.location.pathname;
        const child_product_id_regex = /\/childproducts\/([0-9a-fA-F]+)\/?([^\/]*)?/;
        const child_product_id_match = url.match(child_product_id_regex);
        return {
            child_product: child_product_id_match && child_product_id_match.length > 1 ? child_product_id_match[1] : undefined,
            child_product_submenu: child_product_id_match && child_product_id_match.length > 2 ? child_product_id_match[2] : undefined
        }
    }

    render(): JSX.Element {
        const uri = this.props.uri || document.location.pathname;
        const uri_match = uri.match(/^.*(\/admin\/\w*)\/(.*)/);
        if(!uri_match) {
            throw new Error("Bad uri");
        }
        const base_uri = "/v2"+uri_match[1];
        const id = uri_match[2];

        return (
            <>
            <FindUserDialog show={this.state.onSelectUserCallback != null} onComplete={this.onSelectUser}/>
            <Container className="restapi-document" fluid>
                {this.renderDevMessage()}
                {this.renderEntityHeaderRow()}
                {this.renderUserOperationsRow()}
                {this.renderUserPermissionsRow()}
                {this.renderUserUnconsumedIAPsRow()}
                {this.renderUserDomainsRow()}
                {this.renderUserFilesRow()}
                {this.renderUserInventoryRow()}
                {this.renderUserEscrowsRow()}
                {this.renderUserInventoryTransactionsRow()}
                {this.renderUserInfiniverseTransactionsRow()}
                {this.renderUserInfiniverseStoresRow()}
                {this.renderUserInfiniverseSlotsRow()}
                {this.renderDomainLocationsRow()}
                {this.renderFileBlobRow()}
                {this.renderOperationsRow()}
                {this.renderDomainInfiniverseStoresRow()}
                {this.renderPerLocationAutoTestRow()}
                {this.renderLocationSlotsRow()}
                {this.renderStylesRows()}
                {this.renderWitRequestRows()}
                {this.renderBuildingRows()}
                {this.renderProductMenuRow()}
                {this.renderAutoTestRow()}
                <Row>
                    <Col className="restapi-documentfields-col">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                All Fields
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <PageTable>
                                    <tbody>
                                        {Object.keys(this.state.fields).sort().map(x => this.renderField(x))}
                                    </tbody>
                                </PageTable>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col className="restapi-documentfields-col">
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                Raw data
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <ReactJson src={this.state.data} collapsed/>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}
export const RestApiDocument = withRouter(withMultiverseApi(_RestApiDocument));

export default RestApiDocument;
