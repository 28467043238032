import React, { Component, CSSProperties } from 'react';
import cookies from 'browser-cookies';
import Button from 'react-bootstrap/Button';

type CookieBannerProps = {style?:CSSProperties};
type CookieBannerState = {
  consented: boolean
};

const STYLE:CSSProperties = {
  background: 'white',
  width: '30em',
  maxWidth: '96vw',
  zIndex: 1,
  position: 'fixed',
  padding: '1em',
  bottom: 10,
  right: 10,
  border: '1px solid gray',
  borderRadius: 3,
};

export default class CookieBanner extends Component<CookieBannerProps, CookieBannerState> {
  constructor(props:CookieBannerProps) {
    super(props);
    this.state = {
      consented: !!cookies.get('CookieConsent'),
    };
  }

  onClick = ():void => {
    cookies.set('CookieConsent', 'true');
    this.setState({ consented: true });
  };

  render = ():JSX.Element|null => {
    const { consented } = this.state;
    const { style } = this.props;
    if (consented) return null;
    return (
      <div style={{ ...STYLE, ...style }}>
        We use cookies to provide and improve our services. By using our site you consent to cookies.
        <Button onClick={this.onClick} variant="link">I understand.</Button>
      </div>
    );
  };
}
