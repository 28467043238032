import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseUser, WithMultiverseApiProps, MultiverseBaseEntity } from '../../hoc/multiverseApiProvider';
import api from '../../utils/api';
import { Col, Container, Row, Card, Image, Button } from 'react-bootstrap';
import PageTable from '../../components/pageTable';
import { getBlobUrl, getApiUrl } from '../../config/api';
import HeaderCard from '../../components/headerCard';
import { LocationUriDialog } from '../../components/locationUrlDialog';
import { getIconSrc } from '../../utils/entitycache';

type TSpenderInfo = {
    _id: string,
    total: number,
    count: number,
    user: MultiverseUser
}

/*type TSpendersComponentProps = {
    from: number;
    to: number;
}
function SpendersComponent(props: TSpendersComponentProps) {
    const [data, setData] = useState<TSpenderInfo[]>([])

    //query for data and fill in gaps/timestamps
    const loadData = async() => {
        const rsp = await api.tryGet<any>(`/v2/admin/stats/biggestspenders?devtransactions=true&from=${props.from}&to=${props.to}`)
        return rsp.results;
    }

    //refreshes chart data
    useEffect(() => {
        loadData()
        .then(x => { console.log(x); setData(x) })
        .catch(err => console.log(err))
    }, [])

    return <PageTable>
    <thead>
        <th></th>
        <th>Nick Name</th>
        <th>First Login</th>
        <th>Last Online</th>
        <th>Total Spent</th>
        <th>Num Purchases</th>
        <th>Av Purchase</th>
    </thead>
    <tbody>
        {data.filter(x => x.user).map((x) => (                                 
            <PageTable.Row key={x._id}>
                <PageTable.IconCell src={`${getBlobUrl()}/${x.user.iconblob}`} roundedCircle/>
                <PageTable.Cell>{x.user.nickname}</PageTable.Cell>
                <PageTable.Cell>{new Date(x.user.created).toLocaleDateString()}</PageTable.Cell>
                <PageTable.Cell>{new Date(x.user.timestamp).toLocaleDateString()}</PageTable.Cell>
                <PageTable.Cell>${(x.total-1)/100}</PageTable.Cell>
                <PageTable.Cell>{x.count}</PageTable.Cell>
                <PageTable.Cell>${((x.total-1)/(x.count * 100)).toFixed(2)}</PageTable.Cell>
            </PageTable.Row>))}
        </tbody>
    </PageTable>
}*/



type TFearturedItemComponentProps = {
    featuredid: string,
    onRemoveClicked: ()=>void
}
function FeaturedItemComponent(props: TFearturedItemComponentProps) {
    const [data, setData] = useState<{uri?: string} & MultiverseBaseEntity | null>(null)
    const [user, setUser] = useState<MultiverseBaseEntity | null>(null)

    useEffect(() => {
        api.get<MultiverseBaseEntity>(`/v2/admin/featured/${props.featuredid}`)
        .then(x => { 
            setData(x); 
            return api.get<MultiverseBaseEntity>(`/v2/users/${x.owner}`)
        })
        .then(x => { 
            setUser(x); 
        })     
        .catch((err) => console.log(err))
    }, [])    

    const banner = () => {
        if(!data) {
            return
        }
        if(data.discoverybannerblob) {
            return `${getBlobUrl()}/${data.discoverybannerblob}`
        }
        if(data.bannerblob) {
            return `${getBlobUrl()}/${data.bannerblob}`
        }
        if(data.type === "location") {
            return `${getApiUrl()}/v2/domains/${data.domain}/locations/${data.id}/banner`
        }
        if(data.type === "event") {
            return `${getApiUrl()}/v2/domains/${data.domain}/events/${data.id}/banner`
        }
        if(data.type === "domain") {
            return `${getApiUrl()}/v2/domains/${data.id}/banner`
        }
        return ""
    }
    const icon = () => {
        if(!data) {
            return
        }
        if(data.discoveryiconblob) {
            return `${getBlobUrl()}/${data.discoveryiconblob}`
        }
        return getIconSrc(data)
    }

    const info = () => {
        if(!data) {
            return;
        }
        if(data.type === "location") {
            return <div>
                Location / {data.nickname} / {data.uri}
            </div>
        }
        if(data.type === "event") {
            return <div>
                Event / {data.nickname} / {data.uri}
            </div>
        }
        if(data.type === "domain") {
            return <div>
                Metaverse / {data.name} / {data.uri}
            </div>
        }
        return <div>
            {data.type} / {data.nickname}
        </div>
    }

    return <div className="mt-2">
        {data && (
            <HeaderCard
            title={ (data.type === "domain" ? data.name : data.nickname) || ""}
            iconUrl={icon()}
            bannerUrl={banner()}>
                <div>{user ? user.nickname : ""}</div>
                <div>{data.uri ? data.uri : ""}</div>
                <Button className="mt-2" variant="secondary" onClick={()=>props.onRemoveClicked()}>Unfeature</Button>
            </HeaderCard>
        )}
    </div>
}

type TFeaturedListComponentProps = {

}
function FeaturedListComponent(props: TFeaturedListComponentProps) {
    const [data, setData] = useState<string[]>([])
    const [adding, setAdding] = useState<boolean>(false)

    useEffect(() => {
        api.get<{featured:string[]}>("/v2/admin/featured")
        .then(x => { console.log(x); setData(x.featured); })
        .catch((err) => console.log(err))
    }, [])    

    const removeItem = (idx: number) => {
        const new_data = data.slice(0,idx).concat(data.slice(idx+1))
        api.post<{featured:string[]}>("/v2/admin/featured", {featured: new_data})
        .then(x => { console.log(x); setData(x.featured); })
        .catch((err) => console.log(err))
    }

    const addFeature = () => {
        setAdding(true)
    }

    const onAddFeature = (val?: string, id?: string) => {
        setAdding(false)

        if(id) {
            if(!data.includes(id)) {
                const new_data = [...data,id]                
                api.post<{featured:string[]}>("/v2/admin/featured", {featured: new_data})
                .then(x => { console.log(x); setData(x.featured); })
                .catch((err) => console.log(err))               
            }
        }
    }

    return (
    <Container fluid>
        {data.map((x,idx) => (
            <Row key={x}>
                <Col><FeaturedItemComponent featuredid={x} onRemoveClicked={() => removeItem(idx)}/></Col>
            </Row>
        ))}
        <Row className="mt-4">
            <Col className="text-center">
                <LocationUriDialog show={adding} onComplete={onAddFeature} allowDomains/>
                <Button onClick={() => addFeature()}>Feature New Item</Button>
            </Col>
        </Row>
    </Container>)
}

type AdminFeaturedPageProps = {

} & WithMultiverseApiProps;

type AdminFeaturedPageState = {
};

class _AdminFeaturedPage extends React.Component<AdminFeaturedPageProps, AdminFeaturedPageState> {
    constructor(props: AdminFeaturedPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {

        return (
            <Container>
                <Row>
                    <Col>
                        <FeaturedListComponent/>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export const AdminFeaturedPage = withMultiverseApi(_AdminFeaturedPage);

export default AdminFeaturedPage;
