import React, { useState, useEffect } from "react";
import { Accordion, Card, Container, Row, Col, Image, Button } from "react-bootstrap";
import { MultiverseLocation, MultiverseRoom, MultiverseDomain, MultiversePlan, MultiverseEvent, MultiverseFile } from "../../hoc/multiverseApiProvider";
import { TTemplate, TemplateSelectDialog, getTemplateIconUrl } from "../../components/templateSelectDialog";
import api from "../../utils/api";
import { useEntity, onEntityDirty } from "../../utils/entitycache";
import { useHistory } from "react-router-dom";
import { Spec, StyleVar, SpecVar, SpecLayout } from "../locationPage";
import DoorSelector from "../locationPage/doorSelector";
import ContentSelector from "../locationPage/contentSelector";
import BrowseDriveDialog from "../locationPage/browseDriveDialog";
import { SelectVideoLinkDialog } from "../locationPage/selectVideoLinkDialog";
import { SelectYoutubeVideoDialog } from "../locationPage/selectYoutubeVideoDialog";
import { ItemInfo } from "../locationPage/item";
import { getBlobUrl } from "../../config/api";
import { useRelease } from "./entityHeaderCard";


export function SpecEditCards(props: { targeturl: string }) { 
    const slash_idx = props.targeturl.indexOf("/");
    const release = useRelease();
    
    //figure out domain uri + load domain
    let domainuri: string;
    if(slash_idx === -1) {
        domainuri = props.targeturl;
    } else {
        domainuri = props.targeturl.substr(0,props.targeturl.indexOf("/"));
    }
    const domain = useEntity<MultiverseDomain>(domainuri)

    //if event, figure out event ui + load event
    let eventuri: string | null = null;
    if(props.targeturl.includes("/events/")) {
        eventuri = props.targeturl;
    }
    const event = useEntity<MultiverseEvent>(eventuri)

    //if location, or event with a location uri, load location
    let locationuri: string | null = null;
    if(props.targeturl.includes("/locations/")) {
        locationuri = props.targeturl;
    } else if(event && event.locationuri) {
        locationuri = event.locationuri
    }
    const location = useEntity<MultiverseLocation>(locationuri)

    //if we've got a location uri from previous step, load the release
    let releaseuri: string | null = null;
    if(locationuri) {
        releaseuri = `${locationuri}/releases/win64_${release}`
    }
    const room = useEntity<MultiverseRoom>(releaseuri)

    //load domain plan
    const plan = useEntity<MultiversePlan>(`${domainuri}/plan`)

    //load release template
    const template = useEntity<MultiverseLocation>(room?.template)

    const history = useHistory();

    //get all locations
    const location_options: MultiverseLocation[] = api.useGet<MultiverseLocation[]>(domain && `/v2/domains/${domain.id}/locations`) || [];

    //request style data for all components
    const dspec = api.useGet<Spec>(domain && `/v2/domains/${domain.id}/styles/spec?release=${release}`);
    const lspec = api.useGet<Spec>(location && `/v2/domains/${location.domain}/locations/${location.id}/styles/spec?release=${release}`);
    const espec = api.useGet<Spec>(event && `/v2/domains/${event.domain}/events/${event.id}/styles/spec?release=${release}`);
    const tspec = api.useGet<Spec>(template && `/v2/domains/${template.domain}/locations/${template.id}/styles/spec?release=${release}`);
    const dvariables = api.useGet<StyleVar[]>(domain && `/v2/domains/${domain.id}/styles/variables?release=${release}`);
    const lvariables = api.useGet<StyleVar[]>(location && `/v2/domains/${location.domain}/locations/${location.id}/styles/variables?release=${release}`);
    const evariables = api.useGet<StyleVar[]>(event && `/v2/domains/${event.domain}/events/${event.id}/styles/variables?release=${release}`);

    //helpers to combine style data
    const addValues = (all_values: { [id: string]: string }, variables: StyleVar[] | null ) => {
        if(variables) {
            variables.forEach(element => {
                all_values[element.id] = element.value;
            });
        }
    }
    const addSpecs = (all_specs: { [id: string]: SpecVar }, spec: Spec | null) => {
        if(spec) {
            spec.variables.forEach(element => {
                all_specs[element.id] = element;
            });                
        }
    }

    //state + effects for combining all values+variables once loaded
    const [specs,setSpecs] = useState<{ [id: string]: SpecVar }>({})
    const [values,setValues] = useState<{ [id: string]: string }>({})
    useEffect(() => {
        const all_specs: { [id: string]: SpecVar } = {};
        if(dspec !== undefined && lspec !== undefined && espec !== undefined && tspec !== undefined) {
            console.log("Setting variables")
            addSpecs(all_specs, dspec)
            addSpecs(all_specs, lspec)
            addSpecs(all_specs, tspec)
            addSpecs(all_specs, espec)
            setSpecs(all_specs)
            console.log(all_specs)
        } else {
            console.log("Not setting variables yet")
        }
    }, [dspec,lspec,espec,tspec])
    useEffect(() => {
        const all_values: { [id: string]: string } = {}
        if(dvariables !== undefined && lvariables !== undefined && evariables !== undefined) {
            console.log("Setting values")
            addValues(all_values, dvariables)
            addValues(all_values, lvariables)
            addValues(all_values, evariables)
            setValues(all_values)
            console.log(all_values)
        }
    }, [dvariables,lvariables,evariables])
   
        
    const [loadingValue,setLoadingValue] = useState<string>()
    const [browseForValue,setBrowseForValue] = useState<string>()
    const [setVideoLinkForValue,setSetVideoLinkForValue] = useState<string>()
    const [setYouTubeForValue,setSetYouTubeForValue] = useState<string>()

    //called by content selector when browse is clicked. stores value being browsed,
    //which causes browse dialog to show
    const onBrowseClicked = (specvar: SpecVar) => {
        setBrowseForValue(specvar.id)
    }

    //called by content selector when clear button is clicked
    const onClearClicked = (specvar: SpecVar) => {
        updateValue(specvar.id, undefined, true)
    }

    //called by a selector for a pure change in value without any fancy stuff
    const onValueChanged = (specvar: SpecVar, val: string) => {
        updateValue(specvar.id, val, true)
    }

    //called by selector for showing video link box
    const onSetVideoLink = (specvar: SpecVar) => {
        setSetVideoLinkForValue(specvar.id)
    }

    //called by selector for showing you tube box
    const onSetYoutube = (specvar: SpecVar) => {
        setSetYouTubeForValue(specvar.id)
    }

    //renders the selector for a given variable
    const renderSpecVar = (x: SpecVar, other_values_in_group: string[] = []) => {
        const val = values[x.id] || "";
        const type = x.type || "content";
        if(type === "content") {

            const any_are_you_tube = anyAreYouTube(other_values_in_group);
            const enable_you_tube = !any_are_you_tube && (plan ? plan.canUseYoutube : false);

            return (
                <ContentSelector 
                    key={x.id} 
                    value={val} 
                    defaultValue={x.default} 
                    name={x.name} 
                    description={x.description} 
                    aspect={x.aspect} 
                    onValueChanged={(v)=>onValueChanged(x,v)}
                    onBrowseClicked={()=>onBrowseClicked(x)}
                    onClearClicked={()=>onClearClicked(x)}
                    onVideoLinkClicked={()=>onSetVideoLink(x)}
                    onYoutubeClicked={()=>onSetYoutube(x)}
                    supportVideoLink={plan ? plan.canUseVideoLink : false}
                    // can't select YouTube if already using it elsewhere in group
                    supportYoutube={enable_you_tube}
                    showSpinner={loadingValue === x.id}
                    disableImages={false}
                    disableVideos={plan ? !plan.canUseVimeo : true}
                    disableBrowser={plan ? !plan.canStream : true}
                    domain={domain}
                />
            ) 
        } else if(type === "location") {
            return (
                <DoorSelector
                    key={x.id} 
                    value={val} 
                    default={x.default} 
                    name={x.name} 
                    description={x.description} 
                    onValueChanged={(v)=>onValueChanged(x,v)}
                    onClearClicked={()=>onClearClicked(x)}
                    showSpinner={loadingValue === x.id}
                    locations={location_options}
                />
            )
        } else {
            throw new Error("Invalid type");
        }
    }

    //renders a column from the spec layout
    const renderSpecCol = (x: SpecLayout) => {
        if(x.type === "header") {
            return (<h4>{x.text}</h4>)
        } else if(x.type === "content") {
            const spec_var = specs[x.id!];
            if(spec_var) {
                const other_values = Object.entries(values)
                    .filter(([key]) => key !== x.id && specs[key])
                    .map(([_,value]) => value)
                
                return renderSpecVar(spec_var, other_values);
            } else {
                return (<h4>Error - unknown var {x.id}</h4>)
            }
        } else {
            return (<h4>Error - unknown type {x.type}</h4>)
        }
    }

    //renders a row from the spec layout
    const renderSpecRow = (spec: SpecLayout[], idx: number) => {
        let cn = ""
        if(spec.length === 1) {
            cn = "col-12"
        } else if(spec.length === 2) {
            cn = "col-12 col-sm-6"
        } else if(spec.length === 3) {
            cn = "col-12 col-sm-4"
        } else if(spec.length === 4) {
            cn = "col-12 col-sm-6 col-lg-3"
        }

        return (
        <Row key={`specrow_${idx}`} className="mb-1">
            {spec.map(x => (
                <Col key={x.id} className={cn}>
                    {renderSpecCol(x)}
                </Col>))
            }
        </Row>)
    }

    //renders a spec in a form
    const renderSpec = (spec: Spec) => {

        let results: JSX.Element[] = [];

        let start = 0;

        for(let i = 1; i < spec.layout.length; i++) {
            if(spec.layout[i][0].type === "header") {
                results.push(
                <Accordion defaultActiveKey="0">
                    <Card key={`specheader_${start}`} className="mt-2">
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            {spec.layout[start][0].text}
                        </Accordion.Toggle>
                        <Accordion.Collapse as={Card.Body} eventKey="0">
                            <Container>
                                {spec.layout.slice(start+1,i).map((x,idx) => renderSpecRow(x,idx))}
                            </Container>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                );
                start = i;
            }
        }

        results.push(
        <Accordion defaultActiveKey="0">
            <Card key={`specheader_${start}`} className="mt-2">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    {spec.layout[start][0].text}
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                    <Container>
                        {spec.layout.slice(start+1).map((x,idx) => renderSpecRow(x,idx))}
                    </Container>
                </Accordion.Collapse>
            </Card>
        </Accordion>);

        return results;
    }

    //stores updated value then optionally saves updated style
    const updateValue = async(id: string, value?: string, save?: boolean) => {
        try {
            setLoadingValue(id);

            if(save) {
                if(value != null) {
                    await api.post(`/v2/domains/${props.targeturl}/styles/variables`, {
                        release: release,
                        variables: [
                            { id, value }
                        ]
                    })
                } else {
                    await api.del(`/v2/domains/${props.targeturl}/styles/variables`, {
                        release: release,
                        ids: [ id ]
                    })
                }
            }

            const new_values = {...values};
            if(value) {
                new_values[id] = value;
            } else {
                delete new_values[id];
            }
            setValues(new_values)

        } catch(err) {

        } finally {
            setLoadingValue(undefined);
        }
    };   

    //browse dialog close clicked, clears browseForValue so dialog stops showing
    const onBrowseDialogClose = () => {
        setBrowseForValue(undefined);
    }

    //brwose dialog selected a piece of content to place in currently editing slot
    const onBrowseDialogSelect = async(directory: string, item: ItemInfo) => {
        //store value locally, then clear browseForValue so the dialog disappears
        const valueId = browseForValue;
        setBrowseForValue(undefined);

        //check it's a valid value
        if(valueId) {
            try {
                //set that we're loading this value, which causes the spinner to show correctly
                setLoadingValue(valueId);

                //check type
                if(item.type === "link") {
                    //links can just immediately update/save the value
                    await updateValue(valueId, item.url || "", true)
                } else if(item.type === "file") {
                    //files need to get the entity using the drives api then update/save the value
                    const path = encodeURIComponent(`${directory}/${item.id}`);
                    let req_uri = `/v2/drives/files/entity?path=${path}`;
                    const entity = await api.get<MultiverseFile>(req_uri);   
                    await updateValue(valueId, `${getBlobUrl()}/${entity.blob}`, true)  
                }           
            } catch(err) {
                console.log(err)
            } finally {
                setLoadingValue(undefined);
            }
        }
    }

    const anyAreYouTube = (urls: string[]) => {
        return urls.filter(u => u).some(u => isYouTube(u));
    }

    const isYouTube = (url: string) => {
        const lower = url.toLowerCase().trim();
        if(!lower.startsWith("http")) {
            return false;
        }
        return lower.includes("/youtube.com/") ||
            lower.includes("/www.youtube.com/") ||
            lower.includes("/youtu.be/") ||
            lower.includes("/www.youtu.be/");
    }

    const onSetYouTubeValueComplete = (val?: string) => {
        if(val) {
            val = val
                .replace(/youtube\.com\/live\//i, "youtube.com/watch?v=")
                .replace(/youtu\.be\//i, "youtube.com/watch?v=")
                .replace(/https:\/\/youtube.com/i, "https://www.youtube.com");
            updateValue(setYouTubeForValue || "", val, true)
        }
        setSetYouTubeForValue(undefined)

    }
    const onSetVideoLinkValueComplete = (val?: string) => {
        if(val) {
            updateValue(setVideoLinkForValue || "", val, true)
        }
        setSetVideoLinkForValue(undefined)
    }

    return (<>
        <BrowseDriveDialog show={browseForValue != null} onClose={onBrowseDialogClose} onClickItem={onBrowseDialogSelect}/>
        <SelectVideoLinkDialog show={setVideoLinkForValue != null} onComplete={onSetVideoLinkValueComplete}/>
        <SelectYoutubeVideoDialog show={setYouTubeForValue != null} onComplete={onSetYouTubeValueComplete}/>
        {dspec && renderSpec(dspec)}
        {lspec && renderSpec(lspec)}
        {tspec && renderSpec(tspec)}
        {espec && renderSpec(espec)}
    </>)
}
