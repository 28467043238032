import React from 'react';
import { Button, Card, Col, Container, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

import {BsPersonPlus, BsPersonFill} from 'react-icons/bs'

export interface IGetMemberSummaryResults {
    users: { id: string, nickname: string, iconblob: string }[];
    count: number;
    canAdd: boolean;
}

type MemberBoxProps = {
    domainUri: string;
    onAddMemberClicked?: ()=>void;
} & WithMultiverseApiProps;

type MemberBoxState = {
    memberSummary?: IGetMemberSummaryResults
};
 
class _MemberBox extends React.Component<MemberBoxProps, MemberBoxState> {
    constructor(props: MemberBoxProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {
        const { multiverse: {get}, domainUri} = this.props;
        try {
            const summary = await get<IGetMemberSummaryResults>(`/v2/domains/${domainUri}/membersummary`);
            this.setState({
                memberSummary: summary
            })
        } catch(err) {

        }
    }

    render() {
        return (
        <div className="member-box">
            <span>
                {this.state.memberSummary?.users.map(x => {
                    return x.iconblob.length > 0 ? <Image key={x.id} className="member-image" src={`${getBlobUrl()}/${x.iconblob}`} roundedCircle></Image> : <BsPersonFill key={x.id} className="member-image p-1"/>
                })}
            </span>
            <span style={{position:"relative", top:"2px"}}>{this.state.memberSummary?.count}</span>
            <span>{this.state.memberSummary?.canAdd && <Button variant="light" onClick={this.onAddMemberClicked}><BsPersonPlus size="25px"/></Button>}</span>
        </div>)
    }

    onAddMemberClicked = () => {
        console.log(this.props)
        if(this.props.onAddMemberClicked) {
            console.log("Calling on add member")
            this.props.onAddMemberClicked();
        }
        
    }
}
const MemberBox = withMultiverseApi(_MemberBox);

export default MemberBox;
