import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { getDomainPlanInfo, MultiverseDomainMemberListResult, MultiversePlan, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Button, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import PermissionsInfo from '../../components/permissionsInfo'
import DialogBox from '../../components/dialogBox'
import ValidatedField from '../../components/validatedField';
import DialogBoxWithSpinner from '../../components/dialogBoxWithSpinner'
import BasicPage from '../../components/basicPage';
import PageTable from '../../components/pageTable';
import { getApiUrl } from '../../config/api';
import IsDeveloper from '../../components/isDeveloper'
import InviteMembersDialog from '../../components/inviteMembersDialog'
import TextIcon from '../../components/profile/texticon';
import { BsPersonPlus, BsPlus } from 'react-icons/bs';
import './domainmemberspage.css'
import MemberBox from '../../components/domainFrontPage/memberBox';

//no props at the moment
type DomainMembersPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

//page state
type DomainMembersPageState = {
    isLoading: boolean;
    members: MultiverseDomainMemberListResult[];
    confirmRemoveMember?: MultiverseDomainMemberListResult;
    showInviteMembers: boolean;
    editPermissionsMember?: MultiverseDomainMemberListResult;
    editPermissionsList: string[];
    showCantInviteMembers: boolean;
    plan?: MultiversePlan;
};

//styles for table cells
const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

//minimal verification for comma separated list of email addresses 
const EMAIL_LIST_REGEX = /^(?:[^\,]+\@[^\,]+\,*)+$/

//main page
class DomainMembersPage extends React.Component<DomainMembersPageProps, DomainMembersPageState> {

    //constructer starts state mostly empty, but with isLoading set to true
    constructor(props: DomainMembersPageProps) {
        super(props);
        this.state = {
            isLoading: true,
            members: [],
            showInviteMembers: false,
            showCantInviteMembers: false,
            editPermissionsMember: undefined,
            editPermissionsList: [],
            plan: undefined
        };
    }

    //mounting just gets the members list
    async componentDidMount() {
        const { multiverse: { get, getCurrentDomainId } } = this.props;
        try {
            const members = await get<MultiverseDomainMemberListResult[]>(`/v2/domains/${getCurrentDomainId()}/members`);
            const plan = await get<MultiversePlan>(`/v2/domains/${getCurrentDomainId()}/plan`);
            this.setState({
                members,
                plan
            })
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    //render loading spinner
    renderLoadingSpinner() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    //remove member clicked event, sets the current 'confirmRemoveMember' state to the member clicked,
    //which will trigger the 'remove member dialog'
    removeClicked = (x: MultiverseDomainMemberListResult) => {
        this.setState({
            confirmRemoveMember: x
        })
    }

    //invite clicked event, sets state to show the invite members dialog (initially with text invalid)
    inviteClicked = () => {
        const {domain} = this.props;
        if(!domain) {
            return;
        }
        this.setState({
            showInviteMembers: true
        })
    }

    //edit member clicked event, sets the current 'editPermissionsMember' state to the member clicked,
    //which will trigger the 'edit member dialog'
    editClicked = (x: MultiverseDomainMemberListResult) => {
        this.setState({
            editPermissionsMember: x,
            editPermissionsList: x.member.permissions
        })
    }

    rowclick = (x: MultiverseDomainMemberListResult) => {

    }

    renderMemberTable = (memberList: MultiverseDomainMemberListResult[]) => {
        const { domain } = this.props;
        if (!domain) {
            throw new Error("No Domain");
        }
        return (
            <PageTable>
            <tbody>
                {memberList.map((x) => {
                    return (
                        <PageTable.Row key={x.userentity.id} onClick={() => this.rowclick(x)}>
                            <PageTable.IconCell src={`${getApiUrl()}/v2/users/${x.userentity.id}/profilepicture`} roundedCircle/>
                            <PageTable.InfoCell title={x.userentity.nickname} subtitle={x.userentity.email || "-"}/>
                            <PageTable.Cell className="d-none d-sm-table-cell">
                                <PermissionsInfo permissions={x.member.permissions} owner={domain.owner} currentuser={x.userentity.id} />
                            </PageTable.Cell>
                            <PageTable.DropDownCell>
                                <Dropdown.Item disabled={domain.owner===x.userentity.id} onClick={() => this.removeClicked(x)} >Remove</Dropdown.Item>
                                <Dropdown.Item onClick={() => this.editClicked(x)} >Edit</Dropdown.Item>
                            </PageTable.DropDownCell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>
        )        
    }

    //render either the main domain or loading spinner
    renderRows() {
        if(this.state.isLoading) {
            return
        }

        const HIDE_BY_NAME=["MultiverseChris","GamerSan","ShapeVR Apparance","admin@multiverseonline.io"]
        const visibleMembers = this.state.members
        .filter(x => !HIDE_BY_NAME.includes(x.userentity.name))
        .sort((a,b) => a.userentity.name.localeCompare(b.userentity.name));
        const hiddenMembers = this.state.members.filter(x => HIDE_BY_NAME.includes(x.userentity.name));

        return (
            <>
            {/*
                <Row className="pt-2">
                    <Col className="text-center">
                        <Button variant="outline-primary" onClick={this.inviteClicked}>Invite New Members</Button>
                    </Col>
                </Row>
            */}
                <Row className="pt-0 justify-content-md-center">
                    <Col xs="auto">
                        <MemberBox domainUri={this.props.domain?.uri || ""} onAddMemberClicked={this.inviteClicked}/>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        {this.renderMemberTable(visibleMembers)}
                    </Col>
                </Row>
                <IsDeveloper>
                    <Row className="pt-2">
                        <Col>
                        <p>[DEV] Hidden Members</p>
                            {this.renderMemberTable(hiddenMembers)}
                        </Col>
                    </Row>
                </IsDeveloper>
            </>
        )
    }

    //invite members dialog box. has a validated input box for email list to invite
    renderInviteMemberDialogBox() {
        const { domain } = this.props;
        const { showInviteMembers } = this.state;
        return (
            <>
            <InviteMembersDialog show={showInviteMembers} onCancel={this.inviteMemberDialog_Cancel} onComplete={this.inviteMemberDialog_Complete}/>
            </>
        )
    }

    //invite dialog completion callbacks
    inviteMemberDialog_Complete = (newMembers: MultiverseDomainMemberListResult[]) => {
        this.setState({
            showInviteMembers: false,
            members: newMembers
        })
    }
    inviteMemberDialog_Cancel = () => {
        this.setState({
            showInviteMembers: false
        })
    }

    //renders the confirm dialog box, which shows a confirmation dialog with option to remove or remove+block, then
    //a spinner whilst remove is in progress
    renderRemoveMemberDialogBox() {
        const { confirmRemoveMember } = this.state;
        if (confirmRemoveMember) {
            return (
                <DialogBoxWithSpinner title={`Remove ${confirmRemoveMember.userentity.name}`} show={true} button0Text="Remove Only" button1Text="Remove And Block" cancelButtonText="Cancel" onCancel={this.removeMemberDialog_Cancel} onConfirm={this.removeMemberDialog_Confirm}>
                    Removing {confirmRemoveMember.userentity.name} will stop them accessing your domain, however they can attempt to rejoin if invited by another manager, or by using the shared link. Would you like to remove and permanently block them from rejoining (this can be undone later)?
                </DialogBoxWithSpinner>
            )
        } else {
            return (<DialogBoxWithSpinner title="" show={false}/>) //just hide, don't unmount when done, as promises need to clean up
        }
    }

    //remove dialog cancel
    removeMemberDialog_Cancel = () => {
        this.setState({
            confirmRemoveMember: undefined
        })
    }

    //attempts to remove the members, then refreshes the member list. the button is 0 if just removing, or
    //1 if removing and blocking
    removeMemberDialog_Confirm = async (button?: 0 | 1) => {
        const { multiverse: { get, del, user, refreshCurrentDomain }, domain, history } = this.props;
        const { confirmRemoveMember } = this.state;

        try {
            //validate domain and member
            if (!domain) {
                throw new Error("Invalid domain");
            }
            if (!confirmRemoveMember) {
                throw new Error("Invalid member");
            }

            //delete the member, passing the button value to indicate whether to block them, then refresh members
            const del_res = await del(`/v2/domains/${domain.id}/members/${confirmRemoveMember.userentity.id}?block=${button}`);
            const new_members = await get<MultiverseDomainMemberListResult[]>(`/v2/domains/${domain.id}/members`);
            this.setState({
                members: new_members
            })

            if(confirmRemoveMember.userentity.id === user!.id) {
                history.push(`/domains`);
            }


        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                confirmRemoveMember: undefined
            })
        }
    }

    //renders the confirm dialog box, which shows a confirmation dialog with option to remove or remove+block, then
    //a spinner whilst remove is in progress
    renderEditPermissionsMemberDialogBox() {
        const { multiverse: { user }, domain } = this.props;
        const { editPermissionsMember, editPermissionsList, plan } = this.state;
        if (editPermissionsMember) {
            const is_manager = editPermissionsList.includes("manage_domain");
            const is_host = editPermissionsList.includes("host");
            const can_edit = plan?.canManageRoles;

            const can_change_host = can_edit;
            const can_disable_manager = can_edit && (editPermissionsMember.userentity.id !== user!.id) && (editPermissionsMember.userentity.id !== domain!.owner);

            return (
                <DialogBoxWithSpinner title={`Edit ${editPermissionsMember.userentity.name}`} show={true} button0Text={can_edit ? "Save" : "Upgrade"} cancelButtonText="Cancel" onCancel={this.editPermissionsrDialog_Cancel} onConfirm={this.editPermissionsDialog_Confirm}>
                    {can_edit ?
                        <p>Use the membership options to control what a user is able to manage on the web, and what they can do in the Multiverse app.</p> :
                        <p>On your current plan, only the owner of the Metaverse can manage on modify it. To support multi-user editing of your Metaverse, upgrade to a paid plan</p> 
                    }

                    <Form.Check id='managerBox'>
                        <Form.Check.Input type='checkbox' disabled={!can_disable_manager} checked={is_manager} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => this.editPermissionDialog_TogglePermission('manage_domain', ev.target.checked)}/>
                        <Form.Check.Label className="font-weight-bold">Manager</Form.Check.Label>
                    </Form.Check>
                    A domain manager can invite/remove/block members, manage the domain properties and setup locations.

                    <Form.Check id='hostBox' className="pt-4">
                        <Form.Check.Input type='checkbox' disabled={!can_change_host}  checked={is_host} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => this.editPermissionDialog_TogglePermission('host', ev.target.checked)}/>
                        <Form.Check.Label className="font-weight-bold">Host</Form.Check.Label>
                    </Form.Check>
                    A domain host will have a 'host' badge above their head in the Multiverse app, and has special controls such as the ability to kick or mute other users.

                </DialogBoxWithSpinner>
            )
        } else {
            return (<DialogBoxWithSpinner title="" show={false}/>) //just hide, don't unmount when done, as promises need to clean up
        }
    }
 
    //handles toggling whether a given permissions should be enabled for a member by adding/removing it
    //to the permissions array. to avoid any weirdness with accidental duplication or anything, this
    //fully removes the permission in question by filtering the array, then reinserts it if requested
    editPermissionDialog_TogglePermission(permission: string, val: boolean) {
        const { editPermissionsList } = this.state;
        let new_permissions = editPermissionsList.filter(x => x != permission);
        if(val) {
            new_permissions.push(permission);
        }     
        this.setState({
            editPermissionsList: new_permissions
        })
    }

    //edit permissions cancel
    editPermissionsrDialog_Cancel = () => {
        this.setState({
            editPermissionsMember: undefined
        })
    }

    //attempts to update member permissions
    editPermissionsDialog_Confirm = async (button?: 0 | 1) => {
        const { multiverse: { get, post }, domain } = this.props;
        const { editPermissionsMember, editPermissionsList, plan } = this.state;
        const can_edit = plan?.canManageRoles;


        try {
            //validate domain and member
            if (!domain) {
                throw new Error("Invalid domain");
            }
            if (!editPermissionsMember) {
                throw new Error("Invalid member");
            }

            //if can't edit permissions, user clicked upgrade, not save
            if(!can_edit) {
                this.props.history.push(`/domains/${domain.uri}/upgrade`);
                return;       
            }
    
            //apply the permissions
            const apply_res = await post(`/v2/domains/${domain.id}/members/${editPermissionsMember.userentity.id}`, {
                permissions: editPermissionsList
            })
            const new_members = await get<MultiverseDomainMemberListResult[]>(`/v2/domains/${domain.id}/members`);
            this.setState({
                members: new_members
            })

        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                editPermissionsMember: undefined
            })
        }
    }

    //main render
    render() {
        const { isLoading } = this.state;

        return (
            <>
                {this.renderRemoveMemberDialogBox()}
                {this.renderInviteMemberDialogBox()}
                {this.renderEditPermissionsMemberDialogBox()}
                <BasicPage className="domain-members-page" isLoading={isLoading} title="Members">
                    <Container fluid>
                        {this.renderRows()}
                    </Container>
                </BasicPage>
            </>);
    }
}

export default withRouter(withMultiverseApi(DomainMembersPage));
