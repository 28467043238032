import React, { ChangeEventHandler, ReactNode } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Card, Button, Spinner } from 'react-bootstrap';
import TestBanner from '../../assets/testbanner-grenoble.jpg';
import { IoMdCamera } from 'react-icons/io'
import { RiPencilFill } from 'react-icons/ri'
import './headercard.css'
import ValidatedField, { VALID_DOMAIN_NAME } from '../validatedField';
import IsDeveloper from '../isDeveloper';

type HeaderCardProps = {
    bannerUrl?: string;
    iconUrl?: string;
    title: string;
    children?:ReactNode;
    editable?:boolean;
    devMessage?:any;
    onSetIcon?: (f: File) => void;
    onSetBanner?: (f: File) => void;
    onSetTitle?: (f: string) => void;
    iconError?: boolean;
    bannerError?: boolean;
    titleError?: boolean;
} & WithMultiverseApiProps;

type HeaderCardState = {
    editingTitle: boolean;
    editingTitleValue: string;
    savingIcon: boolean;
    savingBanner: boolean;
    savingTitle: boolean;
};

const IMAGE_TYPES = ".jpg,.jpeg,.png"

class _HeaderCard extends React.Component<HeaderCardProps, HeaderCardState> {
    iconInputRef = React.createRef<HTMLInputElement>();
    bannerInputRef = React.createRef<HTMLInputElement>();
    titleInputRef = React.createRef<ValidatedField>();


    constructor(props: HeaderCardProps) {
        super(props);
        this.state = {
            editingTitle: false,
            editingTitleValue: "",
            savingIcon: false,
            savingBanner: false,
            savingTitle: false
        };
    }

    async componentDidMount(): Promise<void> {

    }

    cameraButton = (click: ()=>void, loading: boolean) => {
        return (
            this.props.editable && <Button disabled={loading} className="hc-circlebutton hc-camerabutton" variant='secondary' onClick={click}>
                {!loading && <IoMdCamera/>}
                {loading && <Spinner animation='border'/>}
            </Button>
        )
    }

    pencilButton = (click: ()=>void) => {
        return (
            this.props.editable && <Button className="hc-circlebutton hc-pencilbutton" variant='secondary' onClick={click}>
                <RiPencilFill/>
            </Button>
        )
    }

    displayTitle = () => {
        return (
            <>
                <h3>{this.props.title}</h3>
                {this.pencilButton(this.onChangeTitleButtonClicked)}
            </>
        )
    }
    editTitle = () => {
        return (
            <ValidatedField 
                ref={this.titleInputRef} 
                className="hc-titlebox" 
                style={{display: this.state.editingTitle ? 'unset' : 'none'}} 
                value={this.state.editingTitleValue} regex={VALID_DOMAIN_NAME} 
                onChange={this.onTitleInputChange} 
                onConfirm={this.onTitleInputConfirm}
                onCancel={this.onTitleInputCancel}      
                disabled={this.state.savingTitle}          
            />
        )

    }

    onIconFileInputChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.iconInputRef.current && this.iconInputRef.current.files && this.iconInputRef.current.files.length > 0) {
            this.setState({
                savingIcon: true
            })
            try {
                if(this.props.onSetIcon) {
                    await this.props.onSetIcon(this.iconInputRef.current.files[0])
                }
            } catch(err) {
                console.log(err);
            }
            this.setState({
                savingIcon: false
            })            
        }
    }
    onBannerFileInputChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.bannerInputRef.current && this.bannerInputRef.current.files && this.bannerInputRef.current.files.length > 0) {
            this.setState({
                savingBanner: true
            })
            try {
                if(this.props.onSetBanner) {
                    await this.props.onSetBanner(this.bannerInputRef.current.files[0])
                }
            } catch(err) {
                console.log(err);
            }
            this.setState({
                savingBanner: false
            })            
        }        
    }
    onTitleInputChange = (value: string, valid: boolean) => {
        this.setState({
            editingTitleValue: value
        })
    }

    onTitleInputConfirm = async(value: string, valid: boolean) => {
        if(!valid) {
            return this.onTitleInputCancel();
        }

        this.setState({
            savingTitle: true
        })

        try {
            if(this.props.onSetTitle) {
                await this.props.onSetTitle(value)
            }
        } catch(err) {
            console.log(err);
        }

        this.setState({
            savingTitle: false,
            editingTitle: false
        })         
    }

    onTitleInputCancel = () => {
        this.setState({
            editingTitle: false
        })
    }


    onChangeBannerButtonClicked = () => {
        this.bannerInputRef.current?.click()
    }

    onChangeIconButtonClicked = () => {
        this.iconInputRef.current?.click()
    }

    onChangeTitleButtonClicked = () => {
        this.setState({
            editingTitle: true,
            editingTitleValue: this.props.title
        }, () => {
            console.log(this.titleInputRef.current?.getTextInput())
            this.titleInputRef.current?.getTextInput()?.focus();
        })
    }

    render(): JSX.Element {
        const classes = ["hc-header"];
        if(this.props.iconError) { classes.push("hc-icon-error")}
        if(this.props.bannerError) { classes.push("hc-banner-error")}
        if(this.props.titleError) { classes.push("hc-title-error")}

        return ( 
            <Card className={classes.join(" ")}>
                <Card.Body className="hc-bannerbody">
                    <div className="hc-bannerplaceholder"/>
                    <Image className="hc-banner" src={this.props.bannerUrl}/>
                    {this.cameraButton(this.onChangeBannerButtonClicked, this.state.savingBanner)}
                    <IsDeveloper>
                        {this.props.devMessage && (
                            <div className="hc-devmessage">
                                {this.props.devMessage}
                            </div>
                        )}
                    </IsDeveloper>
                </Card.Body>

                <Card.Body className="hc-iconbody">
                    <div className="hc-iconholder">
                        <Image className="hc-icon" src={this.props.iconUrl}/>
                        {this.cameraButton(this.onChangeIconButtonClicked, this.state.savingIcon)}
                    </div>
                </Card.Body>

                <Card.Body style={{ position: "relative" }} className="hc-body">
                    {!this.state.editingTitle && this.displayTitle()}
                    {this.editTitle()}
                    {this.props.children && <div>{this.props.children}</div>}
                </Card.Body>

                {this.props.editable && <input
                    className="hc-fileinput"
                    onChange={this.onIconFileInputChange}
                    ref={this.iconInputRef}
                    type="file"
                    accept={IMAGE_TYPES}
                />}

                {this.props.editable && <input
                    className="hc-fileinput"
                    onChange={this.onBannerFileInputChange}
                    ref={this.bannerInputRef}
                    type="file"
                    accept={IMAGE_TYPES}
                />}

            </Card>
        )
    }
}
export const HeaderCard = withMultiverseApi(_HeaderCard);

export default HeaderCard;
