import React, { Component, CSSProperties, FunctionComponent } from 'react';
import {
    Navbar, NavbarBrand, Image, Nav, NavDropdown, Button, Row, Col, DropdownButton, Dropdown, /* NavDropdown, Form, Button, FormControl, Col */
} from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import MiniProfile from './miniProfile';
import MulitverseDarkLogo from '../../assets/mv.png';
import HeaderDomainInfo from './headerDomainInfo'
import AuthorizedNav from '../authorizedNav';
import IsAuthenticated from '../isAuthenticated';
import { BsList, BsFillCaretDownFill, BsBoxArrowInLeft, BsCameraVideo, BsStarFill } from 'react-icons/bs'
import { IoIosGitMerge } from 'react-icons/io'
import { GiVrHeadset } from 'react-icons/gi'
import IsNotAuthenticated from '../isNotAuthenticated';
import LoginButton from '../loginButton';
import { MultiverseDomain, WithMultiverseApiProps, useMultiverseContext, MultiversePlan } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import mvicon_128 from '../../assets/mvicon_128.png'
import { DomainIcon, ProfileIcon } from '../profile';
import IsDeveloper, { IsDeveloperOrTester } from '../isDeveloper';
import LogoutButton from '../logoutButton';
import TextIcon from '../profile/texticon';
import { SiOculus } from 'react-icons/si'
import { BsCardList, BsFillEnvelopeFill } from 'react-icons/bs'
import { HasPermission } from '../hasPermission';
import { useCurrentDomainEntity, useEntity } from '../../utils/entitycache';
import { post } from '../../utils/api';


const LOGO_STYLE: CSSProperties = {
    maxHeight: '50px'
};

const STYLE = {
};

const NAVBUTTON_STYLE: CSSProperties = {
    position: 'absolute',
    left: '5px',
    top: '14px'
}


const MINIPROFILE_STYLE: CSSProperties = {
    position: 'absolute',
    right: '15px',
    top: '4px'
}

const LOGIN_STYLE: CSSProperties = {
    position: 'absolute',
    right: '15px',
    top: '4px'
}

const BRAND_STYLE: CSSProperties = {
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    textAlign: 'center',
    padding: 0
}

const CIRCLE_BUTTON: CSSProperties = {
    ...NAVBUTTON_STYLE,
    left: "15px",
    top: "8px",
    width: "50px",
    height: "50px",
    padding: "0",
    paddingTop: "5px",
    borderRadius: "25px",
    textAlign: "center",
    background: "lightgray"
}

function UserAndDomainsDropDown(props:{}) {
    const context = useMultiverseContext()
    const domain = useCurrentDomainEntity()
    const history = useHistory()
    const memberOf = context.multiverse.memberOf;
    const isemailaccount = context.multiverse.user?.auth0userid?.startsWith("auth0|");

    const onProfileIconClicked = () => {
        history.push("/profile");
    }
    
    const onDomainIconClicked = (x: MultiverseDomain) => {
        history.push(`/domains/${x.uri}`)
    }

    const onLogoutIconClicked = () => {
        context.multiverse.logout();
    }
    const onBillingIconClicked = async () => {
        const res = await post<any>(`/v2/stripe/createportalsession`, { return_url: document.location.href });
        console.log(res);
        document.location = res.url;        
    }
    const onContactUsClicked = () => {
        context.multiverse.requestContactUs({
            defaultSubject: "Support"
        });
    }
    const onMergeClicked = () => {
        context.multiverse.requestMergeAccounts({
            
        })
    }

    return (
    <Dropdown>
        <Dropdown.Toggle id="header-profile-dropdown" variant="light">
            <BsFillCaretDownFill className="icon-image"/>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight={true} className="profile-menu p-2" >

            <Dropdown.Item key="profile" as={ProfileIcon} onClick={onProfileIconClicked} showName />
            <Dropdown.Divider />
            <Dropdown.Item key="contact"  as={TextIcon} icon={BsFillEnvelopeFill} iconPadding="7px" text="Contact Us" onClick={onContactUsClicked} />
            <Dropdown.Item key="billing"  as={TextIcon} icon={BsCardList} iconPadding="7px" text="Billing" onClick={onBillingIconClicked} />
            <Dropdown.Item key="logout"  as={TextIcon} icon={BsBoxArrowInLeft} iconPadding="7px" text="Log Out" onClick={onLogoutIconClicked} />
            {isemailaccount && <Dropdown.Item key="merge"  as={TextIcon} icon={IoIosGitMerge} iconPadding="7px" text="Merge Accounts" onClick={onMergeClicked} />}
            {memberOf && memberOf.length > 0 && <Dropdown.Divider />}
            {memberOf?.map(x => <Dropdown.Item key={x.uri} as={DomainIcon} domainuri={x.uri} showName name={x.name} onClick={() => onDomainIconClicked(x)} />)}

        </Dropdown.Menu>
    </Dropdown>)
}

function RightHeader(props: {}) {
    const context = useMultiverseContext()
    const domain = useCurrentDomainEntity()
    const history = useHistory()
    const plan = useEntity<MultiversePlan>(domain && `${domain.uri}/plan`)

    const onStreamClick = () => {
        history.push(`/stream`)
    }

    
    const onLaunchInVRClick = () => {
        if(domain) {
            context.multiverse.requestOculusLaunch({
                title: `Launch ${domain.name}`,
                domain: domain.uri,
                prompt: "confirm",
                location: ""
            })
        } else {
            context.multiverse.requestOculusLaunch({
                title: `Launch Multiverse`,
                domain: "",
                prompt: "confirm",
                location: ""
            })
        }
    }

    const onProfileIconClicked = () => {
        history.push("/profile");
    }
    
    const onDomainIconClicked = (x: MultiverseDomain) => {
        history.push(`/domains/${x.uri}`)
    }

    return (
        <div className="header-right">
            <IsNotAuthenticated>
                <LoginButton />
            </IsNotAuthenticated>
            <IsAuthenticated>

                <Button variant="light" className="header-button" onClick={onLaunchInVRClick}>
                    <SiOculus className="icon-image p-1"/> 
                    {/*<BsStarFill className="star-icon"/>*/}
                </Button>

                {plan && plan.canStream && <Button variant="light" className="header-button" onClick={onStreamClick}>
                    <BsCameraVideo className="icon-image p-1"/> 
                </Button>}

                {domain ? <DomainIcon key={domain.uri} domainuri={domain.uri} onClick={() => onDomainIconClicked(domain)} /> : <ProfileIcon onClick={onProfileIconClicked} />}

                <UserAndDomainsDropDown/>

            </IsAuthenticated>
        </div>)
}

type HeaderProps = {
} & WithMultiverseApiProps & RouteComponentProps;

type HeaderState = {
};

class Header extends Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {}
    }

    renderSmallScreenNavDropDown = () => {
        const { multiverse: { memberOf, user }, domain } = this.props;
        return (
            <Dropdown className="d-inline-block d-sm-none">
                <Dropdown.Toggle id="header-main-dropdown" variant="light">
                    <BsList className="icon-image"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item key="settings" as={Link} to="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item key="domains" as={Link} to="/domains">Metaverses</Dropdown.Item>
                    <Dropdown.Item key="link" as={Link} to="/drives">Drives</Dropdown.Item>
                    <IsDeveloper>
                        <Dropdown.Item key="link" as={Link} to="/adverts">Infiniverse Advertising</Dropdown.Item>
                    </IsDeveloper>
                    {this.renderSmallScreenAdditionalOptions()}
                </Dropdown.Menu>
            </Dropdown>)
    }

    renderSmallScreenAdditionalOptions = () => {
        const { multiverse: { memberOf, user }, domain } = this.props;
        if(document.location.href.includes("/admin")) {
            return (<>
                    <NavDropdown.Divider />
                    <Dropdown.Item key="admin_dash" as={Link} to={`/admin`}>Dashboard</Dropdown.Item>
                    <Dropdown.Item key="admin_users" as={Link} to={`/admin/users`}>Users</Dropdown.Item>
            </>)
        } else if(document.location.href.includes("/domains")) {
            return (<>
                {domain && (user?.permissions?.includes("superuser") || domain.domainPermissions?.includes("manage_domain")) && (<>
                    <NavDropdown.Divider />
                    <Dropdown.Item key="domain_home" as={Link} to={`/domains/${domain.uri}`}>Home</Dropdown.Item>
                    <Dropdown.Item key="domain_manage" as={Link} to={`/domains/${domain.uri}/manage`}>Manage</Dropdown.Item>
                    <Dropdown.Item key="domain_members" as={Link} to={`/domains/${domain.uri}/members`}>Members</Dropdown.Item>
                    <HasPermission permission="create_events">
                        <Dropdown.Item key="domain_events" as={Link} to={`/domains/${domain.uri}/events`}>Events</Dropdown.Item>       
                    </HasPermission>
                    <IsDeveloper>
                        <Dropdown.Item key="domain_landmarks" as={Link} to={`/domains/${domain.uri}/landmarks`}>[D]Landmarks</Dropdown.Item>
                        <Dropdown.Item key="domain_stats" as={Link} to={`/domains/${domain.uri}/stats`}>[D]Stats</Dropdown.Item>
                        {domain.plan !== "enterprise" && <Dropdown.Item className="upgrade-link" key="domain_members" as={Link} to={`/domains/${domain.uri}/upgrade`}>[D]Upgrade</Dropdown.Item>}
                    </IsDeveloper>
                    <Dropdown.Item key="domain_plots" as={Link} to={`/domains/${domain.uri}/plots`}>Plots</Dropdown.Item>
                </>)}
            </>);
        } else {
            return <></>
        }
    }

    renderLeftHeader = () => {
        const { multiverse: { memberOf, user }, domain } = this.props;
        return (
            <div className="header-left">
                <Link to="/">
                    <Image src={mvicon_128} />
                </Link>
                <IsAuthenticated>
                    <Nav.Item key="settings" as={Link} to="/profile" className="d-none d-sm-inline-block">Profile</Nav.Item>
                    <Nav.Item key="drives" as={Link} to="/drives" className="d-none d-sm-inline-block">Drives</Nav.Item>
                    <Nav.Item key="domains" as={Link} to="/domains" className="d-none d-sm-inline-block">Metaverses</Nav.Item>
                    <IsDeveloper>
                        <Nav.Item key="adverts" as={Link} to="/adverts" className="d-none d-sm-inline-block">Infiniverse Advertising</Nav.Item>
                    </IsDeveloper>
                    {this.renderSmallScreenNavDropDown()}
                </IsAuthenticated>
            </div>
        );
    }

    renderRightHeader = () => {
        return (<RightHeader/>)
    }

    render = () => {
        const { multiverse: { memberOf, user }, domain } = this.props;
        return <>
            <Navbar id="header" className="header">
                {this.renderLeftHeader()}
                {this.renderRightHeader()}
            </Navbar>

            <div className={"navspace" + (document.location.pathname.length > 1 ? "" : " navspacehome")} />

        </>
    }
}
/*(
        <>
        <Navbar id="header" style={STYLE} collapseOnSelect expand={false}>

            <IsAuthenticated>
                <div style={MINIPROFILE_STYLE}>
                    <MiniProfile />
                </div>
            </IsAuthenticated>


            <NavbarBrand style={BRAND_STYLE}>
                <Link to="/">
                    <span className="sr-only">Multiverse</span>
                    <Image src={MulitverseDarkLogo} style={LOGO_STYLE} />
                </Link>
            </NavbarBrand>

            <IsAuthenticated>
                <NavDropdown title={<BsList color="black" size="2em" />} id="nav-profile" className="usernavdropdown" style={CIRCLE_BUTTON}>
                    <AuthorizedNav />
                </NavDropdown>

            </IsAuthenticated>

            <IsNotAuthenticated>
            <div style={LOGIN_STYLE}>
                    <LoginButton />
                </div>
            </IsNotAuthenticated>


        </Navbar>

        {(document.location.pathname.length > 1) && <div className="navspace"/> }

        </>
);*/

export default withRouter(withMultiverseApi(Header));

/*

            <Navbar.Toggle aria-controls="responsive-navbar-nav-2" style={NAVBUTTON_STYLE}/>
            <Navbar.Collapse id="responsive-navbar-nav-2">
                <Nav className="mr-auto">
                    <AuthorizedNav/>
                </Nav>
            </Navbar.Collapse>
        <Navbar.Text className="pl-3 pb-0 pt-0">
            <HeaderDomainInfo />
        </Navbar.Text>

*/