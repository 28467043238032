import React from 'react';
import { Alert, Button, Row, Col, Image, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBlobUrl } from '../../config/api';
import { BsFolder, BsFileEarmark } from 'react-icons/bs'
import './item.css'
import { MultiverseLocation, MultiverseRoom } from '../../hoc/multiverseApiProvider';
import { useEntity, onEntityUpdated } from '../../utils/entitycache';
import api from '../../utils/api';
import { useRelease } from '../entityPage/entityHeaderCard';

function BarWithButton(props: {text: string, buttonText?: string, onClick?: ()=>void}) {
    return <Container fluid>
        <Row className="align-items-center">
            <Col>{props.text}</Col>
            {props.buttonText && <Col xs="auto"><Button variant="outline-info" onClick={props.onClick}>{props.buttonText}</Button></Col>}
        </Row>
    </Container>
}


export function ApprovalBar(props: { targeturl: string }) {
    const release = useRelease();
    const releaseuri = `${props.targeturl}/releases/win64_${release}`
    const location = useEntity<MultiverseLocation>(props.targeturl)
    const room = useEntity<MultiverseRoom>(releaseuri)
    const template = useEntity<MultiverseLocation>(room?.template)

    if(!location || !template || location.approval == "approved") {
        return <></>
    }
    if(!template.name.includes("storefront") && !template.name.includes("joke")) {
        return <></>
    }

    const onRequestApprovalClicked = async() => {
        const res = await api.post<MultiverseLocation>(`/v2/domains/${location.domain}/locations/${location.id}/styles/requestapproval`)
        onEntityUpdated(props.targeturl, res);
    }


    return <Row className="mt-4">
        <Col>
            {!location.approval && <Alert variant="info"><BarWithButton text="This location has exterior content that will need submitting for approval before being shown in the Infiniverse" buttonText="Submit" onClick={onRequestApprovalClicked}/></Alert>}
            {location.approval === "pending" && <Alert variant="warning"><BarWithButton text="Approval is pending for this location's exterior to become visible in the Infiniverse"/></Alert>}
            {location.approval === "unapproved" && <Alert variant="danger">
                <BarWithButton text={`This location was not approved for display in Infiniverse. Please remove any inappropriate content before resubmitting for approval. Approval note: ${location.approvalmessage}`} 
                buttonText="Resubmit" onClick={onRequestApprovalClicked}/>
            </Alert>}
        </Col>
    </Row>
}

/*
{!props.location.approval &&  <Alert variant="info"><div>As this location has an exterior in the Infiniverse, it'll need approving before it can be visible</div><button className="ml-auto"></button></Alert>}
{props.location.approval === "unapproved" &&  <Alert variant="danger">This location was not approved for display in Infiniverse. Please remove any inappropriate content before requesting re-approval.</Alert>}
{props.location.approval === "pending" &&  <Alert variant="warning">Approval is pending for this location to be visible in Infiniverse.</Alert>}
*/