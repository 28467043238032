import React from 'react';
import {  Button, Card, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap';
import BasicPage from '../../components/basicPage';
import { getApiUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, MultiverseUseInviteResult, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import TestBanner from '../../assets/lobby_2048x512.jpg';
import OculusLogo from '../../assets/oculus-logo-transparent.png';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DomainFrontPage from '../../components/domainFrontPage'
import DialogBox from '../../components/dialogBox';
import api, { FetchOptions } from '../../utils/api';

type AcceptInvitePageProps = {
} & WithMultiverseApiProps & RouteComponentProps;

type AcceptInvitePageState = {
    isLoading: boolean;
    error: boolean;
};

class AcceptInvitePage extends React.Component<AcceptInvitePageProps, AcceptInvitePageState> {
    constructor(props: AcceptInvitePageProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: false
        };
    }

    componentDidMount = async() => {
        
        const { history } = this.props;

        try {

            const url = new URL(window.location.href);
            console.log({url})

            const domainid = url.searchParams.get("domain")
            const secret = url.searchParams.get("secret")
            if(!domainid || !secret) {
                throw new Error("Accept invite page should always have a domain id and secret");
            }

            const fetch_args: FetchOptions = {
                url:  `/v2/domains/useinvite`,
                body : {
                    domain: domainid,
                    secret
                },
                hideErrors: true
            }
            const accept_result = await api.post<MultiverseUseInviteResult>(fetch_args);
            console.log({accept_result})

            history.push(`/domains/${accept_result.domain.uri}?joined=1`)
        } catch(err) {
            this.setState({
                error: true
            })
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    onErrorConfirm = () => {
        const { history } = this.props;
        history.push("/");
    }

    render() {
        const { isLoading, error } = this.state;
        return (
            <>
            <DialogBox title="Invite Failed" show={error} button0Text="Ok" onConfirm={this.onErrorConfirm}>
                Your invite link appears to be invalid. This is most often caused by typo in the url, or the invite link being revoked by an administrator.
            </DialogBox>
            <BasicPage isLoading={isLoading}>
            </BasicPage>
            </>);
    }

}

export default withRouter(withMultiverseApi(AcceptInvitePage));
