import React, { useEffect, useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap';
import './updatebillinginfo.css'
import api from '../../utils/api';
import DialogBox from '../dialogBox';

export default function UpdateBillingInfoDialog(props: { authenticated: boolean }) {

    const [visible,setVisible] = useState(false);
    const [loading,setLoading] = useState(false);
    
    const checkBillingInfoNeeded = async() => {
        const nbi = await api.get<{required: boolean}>("/v2/stripe/needsbillinginfo");
        if(nbi.required) {
            setVisible(true)
        }
    }

    const onConfirm = async() => {
        const res = await api.post<any>(`/v2/stripe/createportalsession`, { return_url: document.location.href });
        document.location = res.url;       
    }

    useEffect(() => {
        if(props.authenticated) {
            checkBillingInfoNeeded();
        }
    }, [props.authenticated])



    return visible ? (
    <DialogBox title="Please Update Your Billing Info" show button0Text="Continue" onConfirm={onConfirm}>
        <p>As you are a paying customer, we need some updated billing info to comply with international tax regulations (your address, and if a business, a tax id). Please update them in our billing portal to continue</p>
        <p>Note: this is purely for tax reporting, and will <strong>not</strong> result in any increased charges for your subscription or be used for marketing purposes</p>
    </DialogBox>)
    : ( 
    <></>
    )
}
