import { useState, useEffect } from "react"
import api from "../../utils/api"
import HeaderCard from "../../components/headerCard"
import React from "react"
import { getApiUrl, getBlobUrl, isProd } from "../../config/api"
import { Form, Container, Row, Col, Button } from "react-bootstrap"
import eventBus from "../../utils/eventBus"
import { useEntity, onEntityUpdated, postEntity, useDomainEntity, getIconSrc } from "../../utils/entitycache"
import { MultiverseDomain, MultiversePlan, getDomainPlanInfo, MultiverseEvent, isLockedEvent } from "../../hoc/multiverseApiProvider"
import MemberBox from "../../components/domainFrontPage/memberBox"
import { EditableDomainHeader } from "./editableDomainHeader"
import './entitypage.css'

let g_release: string | null = null;
function initRelease() {
    if(!g_release) {
        g_release = localStorage.getItem(".release");
        if(!g_release) {
            g_release = isProd() ? "prod" : "dev";
        }        
        localStorage.setItem(".release", g_release);
    }
    return g_release;
}

function ReleaseSelectControl() {
    const [release,setRelease] = useState<string>(initRelease())
    
    useEffect(() => {
        if(release != g_release) {
            g_release = release;
            localStorage.setItem(".release", g_release);
            setRelease(release);
            eventBus.dispatch(".release.change", g_release)
        }
    }, [release])

    const onReleaseChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        setRelease(ev.currentTarget.value as any)
    }

    return <div className="lp-devmessage">
        <Form>
            <Form.Control as="select" value={release} onChange={onReleaseChange}>
                {/*<option value="personal">Personal</option>*/}
                <option value="dev">Work In Progress (dev)</option>
                {/*<option value="private">Private</option>*/}
                <option value="prod">Published (prod)</option>
            </Form.Control>   
        </Form>
    </div>
}

export function useRelease() {
    const [release,setRelease] = useState<string>(initRelease())

    useEffect(() => {
        const on_release_change = (val: string) => {
            setRelease(val)
        }
        eventBus.on('.release.change', on_release_change)
        return () => {
            eventBus.remove('.release.change', on_release_change)
        }
    })

    return release;
}

type THeaderEntityProps = {
    id: string,
    type: string,
    domain?: string,
    name?: string,
    nickname?: string,
    iconblob?: string,
    bannerblob?: string
}
export function EntityHeaderCard(props: { targeturl: string, requireContent?: boolean }) { 
    const slash_idx = props.targeturl.indexOf("/");
    const entity = useEntity<THeaderEntityProps>(props.targeturl)
    const domain = useDomainEntity(props.targeturl)

    const renderDevMessage = () => {
        return <ReleaseSelectControl/>
    }

    const onSetIcon = async(icon: File) => {
        const entity = await api.post<THeaderEntityProps>({
            url: `/v2/domains/${props.targeturl}/icon`,
            body: icon,
            headers: {
                'Content-Type': icon.type,
                'x-shapevr-name': icon.name,
            }
        })
        onEntityUpdated(props.targeturl, entity);
    }

    const onSetBanner = async(banner: File) => {
        const entity = await api.post<THeaderEntityProps>({
            url: `/v2/domains/${props.targeturl}/banner`,
            body: banner,
            headers: {
                'Content-Type': banner.type,
                'x-shapevr-name': banner.name,
            }
        })
        onEntityUpdated(props.targeturl, entity);
    }

    const onSetTitle = async(title: string) => {
        if(entity) {
            if(entity.type === "domain") {
                postEntity(props.targeturl, {
                    name: title
                })
            } else {
                postEntity(props.targeturl, {
                    nickname: title
                })
            }
        }
    }

    //calc if have permission to edit
    let editable = domain && domain.domainPermissions && domain.domainPermissions.includes("manage_domain");

    //lock properties if synced with oculus event
    if(isLockedEvent(entity))
        editable = false;

    //track if editable domain
    const editable_domain = editable && domain === entity;

    return <>
        {entity && <HeaderCard                     
            iconUrl= {getIconSrc(entity)}
            bannerUrl= {entity.bannerblob ? `${getBlobUrl()}/${entity.bannerblob}` : `${getApiUrl()}/v2/domains/${props.targeturl}/banner` }
            title={(entity.type === "domain" ? entity.name : entity.nickname) || ""} 
            editable={editable || false} 
            onSetBanner={onSetBanner}
            onSetIcon={onSetIcon}
            onSetTitle={onSetTitle}
            iconError={props.requireContent && !entity.iconblob}
            bannerError={props.requireContent && !entity.bannerblob}
            titleError={props.requireContent && !entity.nickname}
            devMessage={renderDevMessage()}>
            {editable_domain && <EditableDomainHeader domain={entity as MultiverseDomain}/>}
        </HeaderCard>}
    </>                                
}

export function UnauthenticatedEntityCard(props: { targeturl: string }) { 
}