import React, { useState, useRef, useEffect } from "react";
import { Accordion, Card, Container, Row, Col, Image, Button, Form, InputGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MultiverseLocation, MultiverseRoom, MultiverseDomain, MultiversePlan, MultiverseEvent, useMultiverseContext, usePermission, isLockedEvent } from "../../hoc/multiverseApiProvider";
import { TTemplate, TemplateSelectDialog, getTemplateIconUrl } from "../../components/templateSelectDialog";
import api, { useGet } from "../../utils/api";
import { useEntity, onEntityDirty, useDomainEntity, postEntity } from "../../utils/entitycache";
import { useHistory } from "react-router-dom";
import { useRelease } from "./entityHeaderCard";
import DateTimePicker from 'react-datetime-picker';
import { useInterval } from "../../utils/hooks";
import './entitypage.css'
import IsDeveloper from "../../components/isDeveloper";
import DialogBox from "../../components/dialogBox";
import { EntityImageUploadBox } from "../../components/entityImageUploadBox";
import { EntityTextAreaPropertyBox } from "../../components/entityTextAreaPropertyBox";
import OculusEventSelectDialog from "./oculusEventSelectDialog";

const LoadedDate = new Date();

export function EventSettingsCard(props: { targeturl: string, onRequireContent?: (rc:boolean)=>void }) { 
    
    const domainuri = props.targeturl.substr(0,props.targeturl.indexOf("/"));
    const release = useRelease();
    const events_admin = usePermission("admin_write_entities")

    const locations = useGet<MultiverseLocation[]>(`/v2/domains/${domainuri}/locations`) || []

    const domain = useDomainEntity(props.targeturl)
    const event = useEntity<MultiverseEvent>(props.targeturl)

    const [start,setStart] = useState<Date>()
    const [end,setEnd] = useState<Date>()
    const [datedirty,setDateDirty] = useState<number>(0)

    useEffect(() => {
        setStart(event && event.startdate ? new Date(event?.startdate) : undefined);
        setEnd(event && event.enddate ? new Date(event?.enddate) : undefined);
    }, [event])

    const onLocationChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        postEntity(props.targeturl, {
            location: ev.currentTarget.value
        })
    }

    const onStartChange = (val: Date) => {
        if(start && end) {
            setStart(val)
            if(end.getTime() < val.getTime()) {
                setEnd(new Date(val.getTime()+30*60*1000))
            }
            setDateDirty(Date.now())
        }
    }
    const onEndChange = (val: Date) => {
        setEnd(val)
        setDateDirty(Date.now())
    }

    const isDateValid = () => {
        if(!start || !end) {
            return false
        }
        if(start.getTime() < Date.now()) {
            return false
        }
        const len = end.getTime()-start.getTime();
        if(len < 0) {
            return false
        }
        if(len > (12*60*60*1000)) {
            if(!events_admin) {
                return false
            }
        }
        return true
    }

    const isDateValidForOculus = () => {
        if(!start || !end) {
            return false
        }

        if(!isDateValid()) {
            return false
        }

        const len_minutes = (end.getTime() - start.getTime()) / (60*1000)
        const long_enough = len_minutes >= 15
        if(!long_enough) {
            return false;
        }

        const in_future = start.getTime() >= (Date.now()+7*24*60*60*1000)
        if(!in_future) {
            return false
        }

        return true
    }

    const [triedOculus,setTriedOculus] = useState<boolean>(false)
 
    useInterval(() => {
        if(datedirty && (Date.now()-datedirty) > 3000 && isDateValid()) {
            setDateDirty(0)
            postEntity(props.targeturl, {
                startdate: start,
                enddate: end
            })           
        }
    }, 1000)

    const agegroup = event && event.agegroup ? event.agegroup : "all";
    const onAgeGroupChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        postEntity(props.targeturl, {
            agegroup: ev.currentTarget.value
        })
    }

    let date_container_class = "date-container"
    if(!isDateValid()) {
        date_container_class += ' invalid-date'
    }
    if(datedirty) {
        date_container_class += ' dirty-date'
    }
    if(triedOculus && !isDateValidForOculus()) {
        date_container_class += ' error-date'
    }

    const [requestFeatureText,setRequestFeatureText] = useState<any>(null)
    const [requestFeatureValid,setRequestFeatureValid] = useState<boolean>(false)

   
    const onRequestFeature = () => {
        if(event && start && end) {
            const results: { name: string, pass: boolean }[] = []

            const len_minutes = (end.getTime() - start.getTime()) / (60*1000)

            results.push({ name: "Name supplied", pass: event.nickname != null && event.nickname.length > 0 })
            results.push({ name: "Description supplied", pass: event.description != null && event.description.length > 0 })
            results.push({ name: "Icon supplied", pass: event.iconblob != null })
            results.push({ name: "Door supplied", pass: event.doorimageblob != null })
            results.push({ name: "Banner supplied", pass: event.bannerblob != null })
            results.push({ name: "Discovery icon supplied", pass: event.discoveryiconblob != null })
            results.push({ name: "Event is at least 15 minutes", pass: len_minutes >= 15})
            results.push({ name: "Event is at least 1 week from today", pass: start.getTime() >= (Date.now()+7*24*60*60*1000) })
            results.push({ name: "Event has been published", pass: event.published || false } )

            const body = <>
                <ul>
                    {results.map(x => (
                        <li key={x.name}>
                            {x.name}: <strong>{x.pass ? "Yes" : "No"}</strong>
                        </li>
                    ))}
                </ul>     
            </>

            setRequestFeatureText(body)        
            setRequestFeatureValid(results.filter(x => !x.pass).length == 0)
            setTriedOculus(true)

            if(props.onRequireContent) {
                props.onRequireContent(true)
            }
        }
    }
    const requestFeatureDialog = () => {
        return (
        <DialogBox 
            show={requestFeatureText != null} 
            title="Request Oculus Event Promotion" 
            disableConfirm={!requestFeatureValid} 
            button0Text="Ok" 
            cancelButtonText="Cancel"
            onCancel={()=>setRequestFeatureText(null)}
            onConfirm={(x)=>{setRequestFeatureText(null); postEntity(props.targeturl,{oculussubmission:"started"});}}
        >
            {requestFeatureText}
            {requestFeatureValid && "All checks passed! Click Ok to proceed to Oculus event setup next step"}
            {!requestFeatureValid && "Some checks failed - please ensure you've met all requirements before requesting promotion as an Oculus event"}
        </DialogBox>)
    }

    const renderOculusButtonTooltop = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
             Once your event is fully configured, click here to request FTL promote your event on the Oculus store
        </Tooltip>
    );

    const locked_event = isLockedEvent(event)

    const [showEventTerms,setShowEventTerms] = useState<boolean>(false)
    const showEventTermsDialog = () => {
        return (
            <DialogBox 
                show={showEventTerms} 
                title="Submit Oculus Event To FTL" 
                button0Text="Ok" 
                cancelButtonText="Cancel"
                onCancel={()=>setShowEventTerms(false)}
                onConfirm={(x)=>{setShowEventTerms(false); postEntity(props.targeturl,{oculussubmission:"submitted"});}}
            >

            Please confirm you agree to the following Terms and Conditions
                <ul>
                    <li>My event complies with app and platform guidelines</li>
                    <li>I have obtained the necessary permission for any licenced content</li>
                    <li>I have the necessary subscription level (e.g. Premium, Pro) for any specific aspects of my event e.g. livestream, videos</li>
                    <li>I understand FTL is under no obligation to use or promote my event</li>
                    <li>I understand FTL may cancel the featuring of my event at any time without having to give a reason, but that I will be given notice if possible</li>
                    <li>The room hosting my event will be set to "public"</li>
                </ul>     

            If you accept the Terms and Conditions, please select Ok to submit your event to FTL for promotion as an Oculus Event!

            </DialogBox>)
    }

    const [showOculusEvent, setShowOculusEvent] = useState<boolean>(false)
    const showOculusEventDialog = () => {
        return (
            <OculusEventSelectDialog 
                show={showOculusEvent}
                title="Assign Oculus Event Id"
                expectTitle={event?.nickname}
                expectStart={start}
                expectEnd={end}
                onConfirm={(ev)=>{
                    postEntity(props.targeturl,{oculuseventid:ev.oculusid, oculussubmission:"accepted"}); 
                    setShowOculusEvent(false); 
                }}
                onCancel={
                    ()=>setShowOculusEvent(false)
                }
            />
        )
    }

    const oculus_destination = useGet<any>(domain && event && event.location && `/v2/domains/${domain.id}/locations/${event.location}/oculusdestination`)

    return (<>
        {requestFeatureDialog()}
        {showEventTermsDialog()}
        {showOculusEventDialog()}
        <Accordion defaultActiveKey="0" className="event-settings-card">
            <Card key="settings_header" className="mt-2" style={{overflow: "unset"}}>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    Settings
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                    <Form className='px-3 pt-3'>
                        <Form.Group controlId="settings.location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="select"  disabled={locked_event} value={event?.location} onChange={onLocationChange}>
                                {locations.map((x) => <option value={x.id}>{x.nickname}</option>)}        
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="settings.start">
                            <Container fluid className={date_container_class}>
                                <Row>
                                    <Col xs="12" sm="6" className="p-0">Start<br></br>{start && end && <DateTimePicker disabled={locked_event} value={start} minDate={LoadedDate} onChange={onStartChange} disableClock clearIcon={null} className="display-block date-picker start-date-picker"/>}</Col>
                                    <Col xs="12" sm="6" className="p-0">End<br></br>{start && end && <DateTimePicker disabled={locked_event} value={end} minDate={start} onChange={onEndChange} disableClock clearIcon={null} className="display-block date-picker end-date-picker"/>}</Col>
                                </Row>
                            </Container>                          
                        </Form.Group>
     
                        <Form.Group controlId="settings.age">
                            <Form.Label>Age Group</Form.Label>
                            <Form.Control as="select"  disabled={locked_event} value={agegroup} onChange={onAgeGroupChange} >
                                <option value="all">Suitable for all ages, including children</option>
                                <option value="older">Suitable for adults and older children</option>
                                <option value="adult">Suitable for adults only</option>
                                <option value="explicit">Adults only - Explicit content</option>
                                <option value="unsure">Unsure</option>
                            </Form.Control>
                        </Form.Group>

                        {event && !event.oculussubmission && !locked_event && <Form.Group controlId="settings.oculusevent">
                            <Form.Label>Oculus Event Promotion                   
                                <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderOculusButtonTooltop}>
                                    <span> <u>(?)</u></span>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control as={Button} onClick={onRequestFeature} variant='outline-secondary'>
                                Request Promotion as an Oculus Event
                            </Form.Control>
                        </Form.Group>}

                        {event && event.oculussubmission === "started" && <Form.Group controlId="settings.oculuseventinfo">
                            <EntityImageUploadBox className="esc-oculusbanner" targeturl={props.targeturl} contentname={"oculusbanner"} aspect={16.0/9.0} title="Oculus Event Banner (2560x1440, 16:9)" 
                                description="Large banner to show in Oculus event listings (2560x1440, 16:9). Note this MUST be the correct size to qualify for Oculus promotion"/>
                            <EntityTextAreaPropertyBox className="esc-oculusnotes" targeturl={props.targeturl} fieldname="oculusnotes" title="Additional Notes" description="Any extra additional info you can provide to help us make the most of your event!"/>
                            <Form.Control as={Button} disabled={event.oculusbannerblob == null} onClick={()=>setShowEventTerms(true)} variant='outline-secondary'>
                                Submit to FTL for Oculus promotion
                            </Form.Control>
                        </Form.Group>}

                        {event && locked_event && <Form.Group controlId="settings.oculuseventaccepted">
                            <Form.Label>Oculus Event Information</Form.Label>
                            <Form.Control as={Container} fluid style={{height:"unset"}}>
                                {!event.oculuseventid && <span>Your event has been accepted by FTL for Oculus promotion and is being configured</span>}
                                {event.oculuseventid && <div>
                                    <div>Oculus event: <a href={`https://oculus.com/experiences/event/${event.oculuseventid}/`}>https://oculus.com/experiences/event/{event.oculuseventid}</a></div>
                                    <div>Approved by Oculus: {event.oculusapproved ? "Yes" : "Pending"}</div>
                                    <div>Oculus Event Subscribers: {event.subscribers}</div>                                    
                                </div>}
                            </Form.Control>
                        </Form.Group>}

                        <IsDeveloper>
                            <Form.Group controlId="settings.devoculus">
                                <Form.Label>[Dev] Oculus Event Id: {event?.oculuseventid && event.oculuseventid.length > 0 ? event?.oculuseventid : "Not set"}</Form.Label>
                                {!locked_event && <Form.Control as={Button} className="mt-1" variant='outline-secondary' onClick={()=>setShowOculusEvent(true)}>
                                    Assign Oculus Id
                                </Form.Control>}         
                                {event?.oculussubmission === 'submitted' && <Form.Control as={Button} className="mt-1" variant='outline-secondary' onClick={()=>postEntity(props.targeturl,{ oculussubmission:'refused' })}>
                                    Refuse Oculus Promotion
                                </Form.Control>}         
                                {locked_event && <Form.Control as={Button} className="mt-1" variant='outline-secondary' onClick={()=>postEntity(props.targeturl,{ oculussubmission:'started', oculuseventid:'' })}>
                                    Clear Oculus Id                                    
                                </Form.Control>}   
                                <div className="mt-2">
                                    {oculus_destination && <div>
                                        Location Oculus destination: {oculus_destination.name}, {oculus_destination.api}
                                    </div>}               
                                    {!oculus_destination && <div>
                                        Location has no Oculus destination
                                    </div>}
                                </div>   
                            </Form.Group>                        
                        </IsDeveloper>

                    </Form>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>)
}
