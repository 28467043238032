import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { getApiUrl } from '../../config/api';
import { saveAs } from 'file-saver';

type AdminMainPageProps = {

} & WithMultiverseApiProps;

type AdminMainPageState = {
    build: BuildInfo | null
    buildsaving: boolean
};

type BuildInfo = {
    name: string,
    description: string,
    warnbuildnumber: number,
    failbuildnumber: number
}

class _AdminMainPage extends React.Component<AdminMainPageProps, AdminMainPageState> {
    constructor(props: AdminMainPageProps) {
        super(props);
        this.state = {
            build: null,
            buildsaving: false
        };
    }

    async componentDidMount(): Promise<void> {
        const build = await this.props.multiverse.get<BuildInfo>("/v2/admin/build");
        this.setState({
            build
        })
    }

    onSaveBuildClick = async () => {
        this.setState({
            buildsaving: true
        })
        const build = await this.props.multiverse.post<BuildInfo>("/v2/admin/build", {
            warnbuildnumber: this.state.build?.warnbuildnumber,
            failbuildnumber: this.state.build?.failbuildnumber
        })
        this.setState({
            build,
            buildsaving: false
        })
    }

    onWarnBuildNumberChange = (v: string) => {
        const build = this.state.build;
        if(build) {
            this.setState({
                build: {
                    ...build,
                    warnbuildnumber: parseInt(v)
                }
            })            
        }
    }
    onFailBuildNumberChange = (v: string) => {
        const build = this.state.build;
        if(build) {
            this.setState({
                build: {
                    ...build,
                    failbuildnumber: parseInt(v)
                }
            })            
        }
    }

    onSaveMarketingEmailsClicked = async() => {

        const data = await this.props.multiverse.get<any[]>(`/v2/admin/marketingemails`);
        console.log(data);

        let txt = "ServerId,Name,NickName,EMail,Created,TimeStamp\n";
        data.forEach(x => {
            txt += `"${x.id}","${x.name}","${x.nickname}","${x.email}","${x.created}","${x.timestamp}"\n`
        })        

        var blob = new Blob([txt], {type: "text/plain;charset=utf-8"});
        saveAs(blob, "marketing.csv");
    }

    render(): JSX.Element {
        const { build } = this.state;
        return (
            <AdminPage>
                
                {build && <Form as={Container}>
                    <Row><Col className="pl-0"><h4>Build</h4></Col></Row>
                    <Form.Group as={Row}>
                        <Form.Label>Warn build number</Form.Label>
                        <Form.Control type="number" value={build.warnbuildnumber} onChange={(e) =>this.onWarnBuildNumberChange(e.target.value)}/>                              
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label>Error build number</Form.Label>
                        <Form.Control type="number" value={build.failbuildnumber} onChange={(e) =>this.onFailBuildNumberChange(e.target.value)}/>                              
                    </Form.Group>
                    <Form.Group as={Row} className="justify-content-end pr-3">  
                       {this.state.buildsaving && <Spinner animation="border" role="status" className="mr-2"/>}
                       <Button variant='outline-primary' onClick={this.onSaveBuildClick}>Save</Button>
                    </Form.Group>
                </Form>}

                <Container>
                    <Row><Col><h4>Misc</h4></Col></Row>
                    <Row>
                        <Col>
                            <Button onClick={() => this.onSaveMarketingEmailsClicked()}>Save Marketing Emails</Button>
                        </Col>
                    </Row>
                </Container>
            </AdminPage>
        )
    }
}
export const AdminMainPage = withMultiverseApi(_AdminMainPage);

export default AdminMainPage;
