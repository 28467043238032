import React, { useState } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseDomain, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DomainNameField, DomainUriField } from '../../components/validatedField';
import IsDeveloper from '../../components/isDeveloper';
import { useHistory } from 'react-router-dom';
import { post } from '../../utils/api';


function SetupDomainComponent(props: {}) {

    const [name,setName] = useState("")
    const [uri,setUri] = useState("")
    const [domainaccess,setDomainaccess] = useState("public")
    const [saving,setSaving] = useState(false)    
    const [stage,setStage] = useState("name")
    const [buildingaccess,setBuildingaccess] = useState(false)

    const history = useHistory();
    const search = new URLSearchParams(history.location.search)
    const plan = search.get("plan") || "free"

    const onNameChange = (name: string) => {
        setName(name)
    }
    const onUriChange = (uri: string) => {
        setUri(uri)
    }

    const onNextClicked = () => {
        if(stage === "name") {
            setStage("access")
        } else if(stage === "access") {
            onCreateClicked();
        }
    }

    const onCreateClicked = async() => {
        setSaving(true)

        try {
            const domain = await post<MultiverseDomain>("/v2/domains",{
                name, uri, plan, domainaccess, buildingaccess
            })
            history.push(`/domains/${domain.uri}`)
        } catch(err) {
            console.log(err);
        }
        setSaving(false)
    }

    if(stage === "name") {
        return (<>
            <Row>
                <Col>
                    <h4 style={{textAlign:"center"}}>Name Your Metaverse!</h4>
                </Col>
            </Row>    
            <Row className="justify-content-center">
                <Col style={{maxWidth:400}}>
                    <DomainNameField showErrorWhenEmpty onChange={onNameChange} value={name}/>
                    <Button className="mt-2" style={{width:"100%"}} onClick={onNextClicked} disabled={saving}>Next</Button>
                    <IsDeveloper>
                        <p className="mt-4 mb-0">[DEV Only <b>San</b>] Uri</p>
                        <DomainUriField onChange={onUriChange} value={uri}/>
                    </IsDeveloper>
                </Col>
            </Row>                
        </>)
    } else if(stage === "access") {
        return (<>
            <Row>
                <Col>
                    <h4 style={{textAlign:"center"}}>Who Can Access It?</h4>
                </Col>
            </Row>    
            <Row className="justify-content-center">
                <Col style={{maxWidth:400}}>
                    <Form.Control as="select" value={domainaccess}  onChange={(e)=>setDomainaccess(e.currentTarget.value)}>
                        <option value="public">Public</option>
                        <option value="invite_only">Invite Only</option>
                    </Form.Control>  
                    <Form.Check 
                        className="mt-2"
                        type="switch"
                        id="building-access-switch"
                        label="Access via Infiniverse stores"
                        checked={buildingaccess || false}
                        disabled={domainaccess==='public'}
                        onChange={(e)=>setBuildingaccess(e.currentTarget.checked)}
                    />
                    <Button className="mt-2" style={{width:"100%"}} onClick={onNextClicked} disabled={saving}>Go!</Button>
                    <div className="mt-3"><strong>What's This?</strong></div>
                    <div className="mt-2">
                        The great thing about Multiverse is sharing your experiences with each other, but who to share with??! Set your Metaverse to <strong>public</strong> to let
                        anybody who finds it join it. Set it to <strong>invite only</strong> to only allow those you add as members to join it. 
                    </div>
                    <IsDeveloper>
                        <div className="mt-2">
                            If you've had fun in Infiniverse, you'll have seen buildings with storefronts. Tick <strong>Access via Infiniverse stores</strong> to allow anybody 
                            who owns an apartment above one of your storefronts access to your Metaverse, even if it's set to <strong>invite only</strong>
                        </div>
                    </IsDeveloper>
                </Col>
            </Row>                
        </>)
    } else {
        return <></>
    }

}

type SetupDomainPageProps = {

} & WithMultiverseApiProps;

type SetupDomainPageState = {

};

class _SetupDomainPage extends React.Component<SetupDomainPageProps, SetupDomainPageState> {
    constructor(props: SetupDomainPageProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <BasicPage title="Create Metaverse">
                <SetupDomainComponent/>
            </BasicPage>
        )
    }
}
export const SetupDomainPage = withMultiverseApi(_SetupDomainPage);

export default SetupDomainPage;
