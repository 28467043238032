import React, { CSSProperties, HTMLAttributes } from 'react';

export type TAspectRatioBoxProps = {
    aspect: number
} & HTMLAttributes<HTMLDivElement>

export function AspectRatioBox(props: TAspectRatioBoxProps) {
    return(
    <div {...props}>
        <div style={{
            height: 0,
            overflow: "hidden",
            paddingTop: `${1/props.aspect*100}%`,
            background: "white",
            position: "relative"
        }}>
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
            }}>
                {props.children}
            </div>
        </div>
    </div>)
}
export default AspectRatioBox;