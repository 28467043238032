import React, { CSSProperties, ReactNode } from 'react';
import {  Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import BasicPage, { BasicPageProps } from '../basicPage';

type AdminPageProps = {
    children?: ReactNode
} & BasicPageProps & WithMultiverseApiProps;

type AdminPageState = {

};

class AdminPage extends React.Component<AdminPageProps, AdminPageState> {
    constructor(props: AdminPageProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }


    render() {

        if(this.props.multiverse.user?.permissions.includes("administrator") || this.props.multiverse.user?.permissions.includes("superuser")) {
            return <BasicPage fluid {...this.props}/>
        } else {
            return <BasicPage>Invalid Permission</BasicPage>
        }

    }
}

export default withMultiverseApi(AdminPage);
