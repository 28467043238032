

const checkdims = (a: number[], b: number[]) => {
    if(a.length != b.length) {
        throw new Error("Vector dimension mismatch")
    }
}

export const add = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] += b[i];
    }
    return res;
}

export const sub = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] -= b[i];
    }
    return res;
}

export const mul = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] *= b[i];
    }
    return res;
}

export const div = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] /= b[i];
    }
    return res;
}

export const min = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] = Math.min(res[i],b[i]);
    }
    return res;
}

export const max = (a: number[], b: number[]) => {
    checkdims(a,b);
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] = Math.max(res[i],b[i]);
    }
    return res;
}

export const adds = (a: number[], b: number) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] += b;
    }
    return res;
}

export const subs = (a: number[], b: number) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] -= b;
    }
    return res;
}

export const muls = (a: number[], b: number) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] *= b;
    }
    return res;
}

export const divs = (a: number[], b: number) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] /= b;
    }
    return res;
}

export const lerp = (a: number[], b: number[], t:number) => {
    return add(muls(a,1-t),muls(b,t));
}

export const floor = (a: number[]) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] = Math.floor(res[i]);
    }
    return res;
}

export const ceil = (a: number[]) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] = Math.ceil(res[i]);
    }
    return res;
}

export const round = (a: number[]) => {
    const res = [...a];
    for(let i = 0; i < res.length; i++) {
        res[i] = Math.round(res[i]);
    }
    return res;
}
