import { MultiverseFile } from "../hoc/multiverseApiProvider";
import api from "./api";
import { Cache } from "./cache";


export type TItemInfo = {
    id: string,
    name: string,
    type: "account" | "drive" | "directory" | "file" | "link";
    mimeType?: string;
    thumbnailUri?: string;
    width?: number;
    height?: number;
    size?: number;
    url?: string;
};

export type TItemListResults = {
    nextPageToken?: string;
    items: TItemInfo[];
}

const MetaCache = new Cache("driveapi");
const EntityCache = new Cache("driveapientity");

export const getEntity = async(path: string, reload?: boolean): Promise<MultiverseFile> => {

    if(!reload) {
        const cached = EntityCache.load<MultiverseFile>(path);
        if(cached) {
            return cached;
        }
    }

    const result = await api.get<MultiverseFile>(`/v2/drives/files/entity?path=${path}`);

    EntityCache.store(path, result);

    return result;
}

export const getMeta = async(path: string, reload?: boolean): Promise<TItemInfo> => {

    if(!reload) {
        const cached = MetaCache.load<TItemInfo>(path);
        if(cached) {
            return cached;
        }
    }

    const result = await api.get<TItemInfo>(`/v2/drives/files/meta?path=${path}`);

    MetaCache.store(path, result);

    return result;
}

export const getFullPathMeta = async(path: string, reload?: boolean): Promise<TItemInfo[]> => {

    const promises: Promise<TItemInfo>[] = [];

    let index = 1;
    while(true) {
        const next_entry = path.indexOf('/',index)
        if(next_entry < 0) {
            promises.push(getMeta(path, reload));
            break;
        } else {
            promises.push(getMeta(path.slice(0, next_entry), reload));
            index = next_entry+1;
        }
    }
    return Promise.all(promises);
}

//fetches next set of items
export const listPath = async (path: string, pageSize?: number, nextPageToken?: string): Promise<TItemListResults> => {

    //calculate fetch uri
    let req_uri = `/v2/drives/files/list?path=${encodeURIComponent(path)}`;
    if(pageSize) {
        req_uri += `&pageSize=${pageSize}`;
    }
    if (nextPageToken) {
        req_uri += `&pageToken=${nextPageToken}`;
    }

    //fetch
    const result = await api.get<TItemListResults>(req_uri);

    //store results in cache
    result.items.forEach(x => {
        MetaCache.store(`${path}/${x.id}`,x)
    })

    return result;
};

export default {
    getMeta,
    getFullPathMeta,
    listPath,
    getEntity
}
