import React from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { getApiUrl } from '../../config/api';
import BasicPage from '../../components/basicPage';
import cookies from 'browser-cookies';
import IsDeveloper from '../../components/isDeveloper';
import { BsList } from 'react-icons/bs';
import PageTable from '../../components/pageTable';
import DialogBoxWithSpinner from '../../components/dialogBoxWithSpinner'
import DialogBox from '../../components/dialogBox';

const GoogleDriveLogo = require('../../assets/socialmedia/social-1_logo-googledrive.svg') as string;
const FacebookLogo = require('../../assets/socialmedia/social-1_logo-facebook.svg') as string;
const OneDriveLogo = require('../../assets/socialmedia/social-1_logo-onedrive.svg') as string;
const VimeoLogo = require('../../assets/socialmedia/social-1_logo-vimeo.svg') as string;
const MultiverseIcon = require('../../assets/mvicon_128.png')

type DriveInfo = {
    id: string,
    name: string,
    description: string,
    type: "account" | "drive" | "directory" | "file";
    mimeType?: string;
    thumbnailUri?: string;
    width?: number;
    height?: number;
    size?: number;
}

type DriveListResults = {
    nextPageToken?: string;
    items: DriveInfo[];
}

type DrivesPageProps = WithMultiverseApiProps & RouteComponentProps;

type DrivesPageState = {
    isLoading: boolean;
    hasError: boolean;
    drives: DriveInfo[];
    showDeleteDialog: boolean;
    showVimeoDialog: boolean;
    deleteDriveId: string;
    deleteDriveName: string;
};

class DrivesPage extends React.Component<DrivesPageProps, DrivesPageState> {
    constructor(props: DrivesPageProps) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            drives: [],
            showDeleteDialog: false,
            showVimeoDialog: false,
            deleteDriveId: "",
            deleteDriveName: ""
        };
    }

    async componentDidMount(): Promise<void> {
        try {
            const { multiverse: { get } } = this.props;
            const path = "/";
            let req_uri = `/v2/drives/files/list?path=${path}&pageSize=${100}`;
            const drives = await get<DriveListResults>(req_uri);
            this.setState({ drives: drives.items.filter(x => x.type==='account') });
        } catch (e) {
            this.setState({ hasError: true });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    onCreateDriveClick = () => {

    }

    onDeleteDriveClick = (drive: DriveInfo) => {
        this.setState({
            showDeleteDialog: true,
            deleteDriveName: drive.name,
            deleteDriveId: drive.id
        })
    }

    renderrows_loading() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }



    renderrows_addbuttons() {
        const api_url = getApiUrl();
        const account = cookies.get('shapevr-account');
        const token = cookies.get('shapevr-token');
        const link_google_url = `${api_url}/v2/oauth/auth?provider=google&returnPath=${encodeURIComponent("/drives")}&account=${account}&token=${token}`;
        const link_vimeo_url = `${api_url}/v2/oauth/auth?provider=vimeo&returnPath=${encodeURIComponent("/drives")}&account=${account}&token=${token}`;
        return (<Row className="pt-2">
            <Col className="text-center">
                <Button variant="outline-primary" className="p-0 mx-2" style={{
                    width: 50,
                    height: 50,
                }} onClick={() => { window.location.href = link_google_url }}>
                    <img src={GoogleDriveLogo} width="80%" />
                </Button>

                <IsDeveloper>
                    <Button variant="outline-primary" className="p-0 mx-2" style={{
                        width: 50,
                        height: 50,
                    }} onClick={() => { this.setState({showVimeoDialog: true}) }}>
                        <img src={VimeoLogo} width="80%" />
                    </Button>
                </IsDeveloper>

                {
                    /*
                <IsDeveloper>
                <Button variant="outline-primary" className="p-0 mx-2" style={{
                    width: 50,
                    height: 50
                }}>
                    <img src={FacebookLogo} />
                </Button>
                <Button variant="outline-primary" className="p-0 mx-2" style={{
                    width: 50,
                    height: 50
                }}>
                    <img src={OneDriveLogo} width="80%" />
                </Button>
                </IsDeveloper>
                */
            }

            </Col>
        </Row>)
    }

    renderrows_nodrives() {
        return (
            <>
                <Row>
                    <Col className="text-center pb-3">
                        You currently have no drives linked to this account. Try adding one now!<br></br>
                    </Col>
                </Row>
                {this.renderrows_addbuttons()}

            </>
        );
    }

    rowclick = (x: DriveInfo) => {
        console.log(x);
        const querypath = `/${x.id}`;
        this.props.history.push(`/browsedrive?location=${encodeURIComponent(querypath)}`)
    }

    getIconSrc = (x:DriveInfo) => {        
        if(x.id.startsWith("google|")) {
            return GoogleDriveLogo;
        } else if(x.id.startsWith("vimeo|")) {
            return VimeoLogo;
        } else {
            return MultiverseIcon;
        }
    }

    renderrows_drives() {

        const api_url = getApiUrl();
        const link_google_url = `${api_url}/v2/oauth/auth?provider=google&returnPath=${encodeURIComponent("/drives")}`;

        return (
            <>
                <Row className="pt-2">
                    <Col>
                        <PageTable>
                            <tbody>
                                {this.state.drives.map((x) => {
                                    return (
                                        <PageTable.Row key={x.id} onClick={() => this.rowclick(x)}>
                                            <PageTable.IconCell src={this.getIconSrc(x)} roundedCircle/> 
                                            <PageTable.InfoCell title={x.name} subtitle={x.description}/>
                                            <PageTable.DropDownCell disabled={x.id.startsWith("photos|")}>
                                                <Dropdown.Item onClick={() => this.onDeleteDriveClick(x)}>Remove</Dropdown.Item>
                                            </PageTable.DropDownCell>
                                        </PageTable.Row>
                                    )
                                })}
                            </tbody>
                        </PageTable>

                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col className="text-center">
                        <h4>Link Another Drive</h4>
                    </Col>
                </Row>
                {this.renderrows_addbuttons()}
            </>
        )
    }

    render_rows() {
        if (this.state.drives.length > 0)
            return this.renderrows_drives();
        else
            return this.renderrows_nodrives();
    }

    handleDeleteDialogClose = () => {
        this.setState({
            showDeleteDialog: false
        })
    }
    handleDeleteDialogConfirm = async(button?:number) => {

        try {
            const { multiverse: { del, get  } } = this.props;
            await del(`/v2/drives/accounts/${this.state.deleteDriveId}`)

            const drives = await get<DriveInfo[]>('/v2/drives/accounts');
            this.setState({
                drives: drives
            })

        } catch(err) {

        } finally {
            this.setState({
                showDeleteDialog: false
            })
        }

    }

    render_delete_dialog() {
        return (
            <DialogBoxWithSpinner 
                title="Remove Drive" 
                show={this.state.showDeleteDialog} 
                onCancel={this.handleDeleteDialogClose} 
                onConfirm={this.handleDeleteDialogConfirm} 
                button0Text="Yes"
                cancelButtonText="No">
                Are you sure you want to remove the drive <strong>{this.state.deleteDriveName}</strong> from your Multiverse account?
            </DialogBoxWithSpinner>
        )
    }

    render_vimeo_info_dialog() {
        const api_url = getApiUrl();
        const account = cookies.get('shapevr-account');
        const token = cookies.get('shapevr-token');
        const link_vimeo_url = `${api_url}/v2/oauth/auth?provider=vimeo&returnPath=${encodeURIComponent("/drives")}&account=${account}&token=${token}`;
        return (
            <DialogBox
                title="Add Vimeo Account" 
                show={this.state.showVimeoDialog} 
                onCancel={() => this.setState({showVimeoDialog: false})} 
                onConfirm={() => window.location.href = link_vimeo_url} 
                button0Text="Ok"
                cancelButtonText="Cancel">
                Please note: To view Vimeo videos in multiverse, you will need a <strong>Vimeo Pro</strong> account, which allows Vimeo videos to be streamed into other products (like Multiverse!)
            </DialogBox>
        )        
    }

    render() {
        const { isLoading } = this.state;
        return (
            <>
                {this.render_delete_dialog()}
                {this.render_vimeo_info_dialog()}
                <BasicPage isLoading={isLoading} title="Drives">
                    {this.render_rows()}
                </BasicPage>
            </>);
    }
}

export default withRouter(withMultiverseApi(DrivesPage));
