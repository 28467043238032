import React from 'react';
import { Button, Card, Col, Container, Modal, Row, Spinner, Table, Image } from 'react-bootstrap';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseDomain, MultiverseLocation, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

import {BsPersonPlus, BsPersonFill} from 'react-icons/bs'
import { IconType } from 'react-icons';

type TextIconProps = {
    icon: IconType;
    text: string;
    onClick?: ()=>void;
    iconPadding?: string | number;
} & WithMultiverseApiProps;

type TextIconState = {
};
 
class _TextIcon extends React.Component<TextIconProps, TextIconState> {
    constructor(props: TextIconProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {
    }

    render() {
        const { icon, text, iconPadding } = this.props;

        const IconElement = icon;

        return (
        <>
        <Button className="profile-icon" onClick={this.props.onClick}>
        { <div className="icon-image-container"><IconElement className="icon-image" style={{padding:iconPadding}}/></div> }
        { <div className="profile-icon-username">{text}</div> }
        </Button>
        </>)
    }
}
export const TextIcon = withMultiverseApi(_TextIcon);

export default TextIcon;
