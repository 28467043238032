import React, { createRef, FunctionComponent } from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import DialogBox from '../../components/dialogBox';
import IsDeveloper from '../../components/isDeveloper';
import ValidatedField, { DomainNameField } from '../../components/validatedField';
import { getDomainPlanInfo, MultiverseDomain, MultiversePlan, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { onEntityUpdated } from '../../utils/entitycache';

type TextFieldRowProps =  {
    value: string,
    label:string,
    onChange: (value:string)=>Promise<void>    
}
type TextFieldRowState = {
    mode: 'show'|'edit'|'loading',
    internalValue: string,
}
type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

class TextFieldRow extends React.Component<TextFieldRowProps, TextFieldRowState> {
	constructor(props:TextFieldRowProps) {
		super(props);
        const { value } = props;
        this.state = {
          mode: 'show',
          internalValue: value,
        };
	}

	onClick() {
	  
    }

    updateInternalValue:React.ChangeEventHandler<FormControlElement> = (e) => {
        const internalValue = e.target.value;
        this.setState({
          internalValue,
        });
      };

    rendervalue() {
        const { label, onChange } = this.props;
        const { internalValue, mode } = this.state;
    
        if(mode == 'loading') {
            return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>)
        } else if(mode == 'show') {
            return (
                <>{internalValue} <Button variant='link'>Edit</Button></>
            )
        } else {
            return (<FormControl
                placeholder={label}
                aria-label={label}
                value={internalValue}
                onChange={this.updateInternalValue}
            />)   
        }
    }

	render() {
        const { label, onChange } = this.props;
        const { internalValue } = this.state;

		return (
            <>
                <Row>
                    <Col>{label}</Col>
                    <Col>{internalValue} <Button variant='link'>Edit</Button></Col>
                    <Col><Button variant='outline-secondary'>Save</Button></Col>
                </Row>
            </>
        )
	}
}

type ManageDomainPageProps = WithMultiverseApiProps & RouteComponentProps;
type ManageDomainState = {
    origDomain?: MultiverseDomain
    name: string
    oculusdestination: string
    oculusdestinationValid: boolean;
    nameValid: boolean
    isSaving: boolean
    sharedInviteLink?: string
    domainaccess: string;
    domainaccessValid: boolean;
    showCreateLinkPermissionBox : boolean;
    plan?: MultiversePlan;
    buildingaccess?: boolean;
};
class ManageDomainPage extends React.Component<ManageDomainPageProps, ManageDomainState> {

    private inviteLinkRef = createRef<HTMLInputElement>();

	constructor(props:ManageDomainPageProps) {
		super(props);
		this.state = {
            origDomain: undefined,
            domainaccess: "",
            domainaccessValid: false,
            name: "",
            oculusdestination: "",
            oculusdestinationValid: false,
            nameValid: false,
            isSaving: false,
            showCreateLinkPermissionBox: false,
            plan: undefined,
            buildingaccess: undefined
		};
    }
    
    onNameChange = (val: string, isvalid: boolean) => {
        this.setState({
            name: val,
            nameValid: isvalid
        });
    };

    onOculusDestinationChange = (val: string, isvalid: boolean) => {
        this.setState({
            oculusdestination: val,
            oculusdestinationValid: isvalid
        })
    }

    onDomainAccessChange = (val: string) => {
        this.setState({
            domainaccess: val,
            domainaccessValid: true
        })
    }

    onBuildingAccessChange = (val: boolean) => {
        this.setState({
            buildingaccess: val
        })
    }

	onClick() {
        
    }
    
    componentDidMount() {
        this.loadDomain();
    }

    loadDomain = async() => {
        try {
            const { multiverse: { get, getCurrentDomainId } } = this.props;
            const id = getCurrentDomainId();

            if(!id) {
                throw new Error("Manage domain page should always have a domain id");
            }
    
            const domain = await get<MultiverseDomain>(`/v2/domains/${id}`);
            if(!domain) {
                throw new Error("Failed to load ")
            }

            const linkRes = await get<{link?:string}>(`/v2/domains/${id}/invite/shared`);

            const plan = await get<MultiversePlan>(`/v2/domains/${getCurrentDomainId()}/plan`);

            this.setState({
                origDomain: domain,
                name: domain.name,
                oculusdestination: domain.oculusdestination || "",
                nameValid: true,
                oculusdestinationValid: true,
                sharedInviteLink: linkRes.link,
                domainaccess: domain.domainaccess,
                domainaccessValid: true,
                buildingaccess: domain.buildingaccess,
                plan
            });

        } catch(err) {
            console.log(err);
        }
    }


    
    renderrows_loading() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    hasChanges = () => {
        const { origDomain, name, oculusdestination, domainaccess, buildingaccess } = this.state;
        if(!origDomain) {
            throw new Error("Domain is invalid");
        }

        if(name != origDomain.name || oculusdestination != origDomain.oculusdestination || domainaccess != origDomain.domainaccess || buildingaccess != origDomain.buildingaccess) {
            return true;
        }

        return false;
    }

    canSave = () => {
        const { nameValid, oculusdestinationValid, isSaving } = this.state;
        if(!this.hasChanges()) {
            return false;
        }
        if(!nameValid || !oculusdestinationValid) {
            return false;
        }
        if(isSaving) {
            return false;
        }
        return true;
    }

    canUndo = () => {
        const { isSaving } = this.state;
        return this.hasChanges() && !isSaving;
    }

    onUpgradePlanClicked = () => {
        this.props.history.push(`/domains/${this.props.domain?.uri}/upgrade?context=upgrade`);
    }

    onChangePlanClicked = () => {
        this.props.history.push(`/domains/${this.props.domain?.uri}/upgrade?context=change`);
    }

    onDeletePlanClicked = () => {
        this.props.multiverse.requestContactUs({
            defaultSubject: "Other",
            defaultDescription: "To fully delete your site, please verify its name here and submit a contact request. Our team will confirm your request and respond shortly."
        })
    }

    onMakeEnterpriseClicked = () => {

    }

    renderCantInviteByLinkDialog = () => {
        const { showCreateLinkPermissionBox } = this.state;
        return (<DialogBox 
            title="Invite Link" 
            show={showCreateLinkPermissionBox}
            onCancel={this.cantInviteByLinkDialogCancel}
            onConfirm={this.cantInviteByLinkDialogUpgrade}
            button0Text="Upgrade"
            cancelButtonText="Cancel"
            >
            <p>Only paid metaverses can create invites. Try setting your metaverse to public, or upgrade now!</p>
        </DialogBox>)
    }
    cantInviteByLinkDialogCancel = () => {
        this.setState({
            showCreateLinkPermissionBox: false
        })
    }
    cantInviteByLinkDialogUpgrade = () => {
        const { domain } = this.props;
        this.props.history.push(`/domains/${domain?.uri}/upgrade`)
    }

    renderrows_domain() {
        const { origDomain, name, oculusdestination, sharedInviteLink, domainaccess, buildingaccess } = this.state;
        if(!origDomain) {
            throw new Error("Domain is invalid");
        }

        const renderTooltip = (props:any) => (
            <Tooltip id="button-tooltip" {...props}>
              Anybody can use this link to join your Metaverse as a paid member
            </Tooltip>
          );

          const renderBuildingAccessTip = (props:any) => (
            <Tooltip id="button-tooltip" {...props}>
                Use this option to create invite only Metaverses accesible only by owners of apartments above your storefronts
            </Tooltip>
          );

          const renderBuildingAccessText = () => (
              <>
                <span>Access via Infiniverse stores</span>
                <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderBuildingAccessTip}>
                    <span> <u>(?)</u></span>
                </OverlayTrigger>
              </>
          )

          const plan_info = getDomainPlanInfo(origDomain);
        return (
            <>
                <Row>
                    <Col className="pt-3">    
                        <Form>
                            <Form.Group controlId="nameInputControl">
                                <Form.Label>Name</Form.Label>
                                <DomainNameField showErrorWhenEmpty onChange={this.onNameChange} value={name}/>
                            </Form.Group>
                            <Form.Group controlId="uriInputControl">
                                <Form.Label>Metaverse URL</Form.Label>
                                <Form.Control readOnly value={origDomain.uri}/>                           
                            </Form.Group>
                            <IsDeveloper>
                                <Form.Group controlId="destinationInputControl">
                                    <Form.Label>[D]Oculus Destination</Form.Label>
                                    <ValidatedField value={oculusdestination} regex={/.*/} onChange={this.onOculusDestinationChange}/>                        
                                </Form.Group>
                            </IsDeveloper>
                            <Form.Group controlId="domainAccessControl">
                                <Form.Label>Access</Form.Label>
                                <Form.Control as="select" value={domainaccess}  onChange={(e)=>this.onDomainAccessChange(e.currentTarget.value)}>
                                    {/*<option value="personal">Personal</option>*/}
                                    <option value="public">Public</option>
                                    {/*<option value="private">Private</option>*/}
                                    <option value="invite_only">Invite Only</option>
                                </Form.Control>                        
                            </Form.Group> 
                            <Form.Group controlId="buildingAccessControl">
                                <Form.Check 
                                    type="switch"
                                    id="building-access-switch"
                                    label={renderBuildingAccessText()}
                                    checked={buildingaccess || false}
                                    disabled={domainaccess==='public'}
                                    onChange={(e)=>this.onBuildingAccessChange(e.currentTarget.checked)}
                                />
                            </Form.Group>                     
                            <Form.Group controlId="inviteLinkInputControl">
                                <Form.Label>
                                    Add Metaverse Member Link
                                    <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderTooltip}>
                                        <span> <u>(?)</u></span>
                                    </OverlayTrigger>     
                                </Form.Label>                          
                                <InputGroup>
                                    <Form.Control ref={this.inviteLinkRef} readOnly value={sharedInviteLink ? sharedInviteLink : "Disabled"}/>                               
                                    <DropdownButton id="invite-dropdown" title="..." className="dropdown-hide-arrow pl-1" variant="outline-primary">
                                        { sharedInviteLink ? 
                                        (<>
                                            <Dropdown.Item onClick={this.onDestroyLinkClick}>Delete</Dropdown.Item>
                                            <Dropdown.Item onClick={this.onCreateLinkClick}>Regenerate</Dropdown.Item>
                                            <Dropdown.Item onClick={this.onCopyLinkClick}>Copy To Clipboard</Dropdown.Item>
                                        </>) : 
                                        (<>
                                            <Dropdown.Item onClick={this.onCreateLinkClick}>Create</Dropdown.Item>
                                        </>)}

                                    </DropdownButton>                                 
                                </InputGroup>
                            </Form.Group>                   
                            <Form.Group controlId="planInputControl">
                                <Form.Label>Plan{plan_info.expiresText.length > 0 && <span style={{color:plan_info.expiresColor}}> ({plan_info.expiresText})</span>}</Form.Label>
                                <InputGroup>
                                    <Form.Control readOnly value={plan_info.nameText}/>                           
                                    <DropdownButton id="plan-dropdown" title="..." className="dropdown-hide-arrow pl-1" variant="outline-primary">
                                        <Dropdown.Item disabled={origDomain.plan === "enterprise"} onClick={this.onUpgradePlanClicked}><strong>Upgrade</strong></Dropdown.Item>
                                        <Dropdown.Item disabled={origDomain.plan === "enterprise"} onClick={this.onChangePlanClicked}>Change</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onDeletePlanClicked}>Delete</Dropdown.Item>
                                    </DropdownButton> 
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Row} controlId="exampleForm.ControlInput2" className="justify-content-end pr-3">                   
                                <Button variant='outline-primary' disabled={!this.canSave()} onClick={this.onSaveClick}>Save</Button>
                                <Button variant='outline-secondary' disabled={!this.canUndo()} onClick={this.onUndoClick}>Undo</Button>
                            </Form.Group>

                        </Form>

                    </Col>
                </Row>
            </>
        )
    }

    onSaveClick = async() => {
        try {
            const { multiverse: { post, refreshCurrentDomain } } = this.props;
            const { origDomain, name, oculusdestination, nameValid, domainaccess, buildingaccess  } = this.state;

            if(!origDomain) {
                throw new Error("Domain is invalid");
            }

            this.setState({
                isSaving: true
            })

            const newDomainOptions = {
                name,
                oculusdestination,
                domainaccess,
                buildingaccess
            }
            console.log(newDomainOptions)
            const savedDomain = await post<MultiverseDomain>(`/v2/domains/${origDomain.id}`, newDomainOptions);
            console.log(savedDomain)


            this.setState({
                origDomain: savedDomain,
                name: savedDomain.name,
                oculusdestination: savedDomain.oculusdestination || "",
                nameValid: true,
                oculusdestinationValid: true,
                isSaving: false,
                domainaccess: savedDomain.domainaccess,
                buildingaccess: savedDomain.buildingaccess
            })

            refreshCurrentDomain(true);

            //notify entity cache of changed entity
            onEntityUpdated(origDomain.uri, savedDomain)
        }
        catch(err) {
            console.log(err);
        }
    }

    onUndoClick = () => {
        const { origDomain } = this.state;
        if(!origDomain) {
            throw new Error("Domain is invalid");
        }

        this.setState({
            name: origDomain.name,
            nameValid: true,
            oculusdestination: origDomain.oculusdestination || "",
            oculusdestinationValid: true,
            domainaccess: origDomain.domainaccess,
            domainaccessValid: true
        })
    }

    onCreateLinkClick = async () => {
        const { multiverse: { post } } = this.props;
        const { origDomain, plan } = this.state;
        if(!origDomain) {
            throw new Error("Domain is invalid");
        }
        if(plan?.canInviteByLink) {
            const linkRes = await post<{link?:string}>(`/v2/domains/${origDomain.id}/invite/shared`);
            this.setState({
                sharedInviteLink: linkRes.link
            });
        } else {
            this.setState({
                showCreateLinkPermissionBox: true
            })
        }

    }
    onDestroyLinkClick = async () => {
        const { multiverse: { del } } = this.props;
        const { origDomain } = this.state;
        if(!origDomain) {
            throw new Error("Domain is invalid");
        }
        const linkRes = await del<{link?:string}>(`/v2/domains/${origDomain.id}/invite/shared`);
        this.setState({
            sharedInviteLink: linkRes.link
        });
    }

    onCopyLinkClick = () => {
        const input = this.inviteLinkRef.current;
        if(!input) {
            return;
        }
        input.select();
        document.execCommand("copy");
        input.selectionStart = input.selectionEnd;
        input.blur();
    }


    renderrows() {
        const { origDomain } = this.state;
        if(!origDomain) {
            return this.renderrows_loading();
        } else {
            return this.renderrows_domain();
        }
    }

	render() {
		return (
		<BasicPage title="Manage Metaverse">
            {this.renderCantInviteByLinkDialog()}
            {this.renderrows()}
		</BasicPage>);
	}
}

export default withRouter(withMultiverseApi(ManageDomainPage));
