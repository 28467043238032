import React from 'react';
import BasicPage from '../../components/basicPage';
import DomainsTable from './domainstable';
import "./domainspage.css"

type DomainsPageProps = {};
type DomainsPageState = {
    isLoading: boolean;
};
 
const TextCellStyle = {
    verticalAlign: "middle"
}
const ButtonCellStyle = {
    width: 50,
    verticalAlign: "middle"
}

class DomainsPage extends React.Component<DomainsPageProps, DomainsPageState> {
    constructor(props: DomainsPageProps) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }


    render() {
        const { isLoading } = this.state;
        return (
            <>
                <BasicPage className="domains-page" isLoading={isLoading} title="Metaverses" >
                    <DomainsTable group/>
                </BasicPage>
            </>);
    }
}

export default DomainsPage;
