import './time.css';
import React, { useEffect, useState } from 'react';
import BasicPage from '../../components/basicPage';
import { Tabs, Tab, Form, Row, Col, Alert } from 'react-bootstrap';
import { CanContainChildProduct, MultiverseMissionEventConfigCreateArgs, MultiverseMissionEventCycle, MultiverseMissionEventCycleCreateArgs, MultiverseTime } from '../../hoc/multiverseApiProvider';
import PageTable, { PageTableCell } from '../../components/pageTable';
import api, { useGetAll, setDataFromUrl, useGet, TGet } from '../../utils/api';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { formatDateTime } from '../../utils/dates';
import StringsControl from '../../components/stringsControl';
import { id } from 'date-fns/locale';
import { deepClone } from '../../utils/objects';

type IdMatchParams = {
    childproductid: string;
    id: string;
}

type TBaseItemDoc = {
    _id: string,
    [nm:string]: any
}

type TItemDoc = TBaseItemDoc & MultiverseMissionEventCycleCreateArgs

type TEventConfigDoc = TBaseItemDoc & MultiverseMissionEventConfigCreateArgs

type TEventCycleQuery = CanContainChildProduct & {
    id?: string
};

function MissionEventCycle(props: { query: TEventCycleQuery, emptyMessage: string }) {
    const history = useHistory();

    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [err, setErr] = useState<string>("")
    const [success, setSuccess] = useState<string>("")
    const { query } = props;
    const { child_product, id } = query;

    const blankItem: TItemDoc = {
        _id: "",
        mission_event_config_name: "",
        day_of_week_utc: undefined,
        start_time_utc: {
            hour: undefined,
            minute: undefined
        },
        end_time_utc: {
            hour: undefined,
            minute: undefined
        },
        option: undefined
    }

    const [configData, setConfigData] = useState<{mission_event_configs:TEventConfigDoc[]} | null | undefined>(undefined);
    const [savedItem, setSavedItem] = useState<TItemDoc | null | undefined>(undefined)
    const [item, setItem] = useState<TItemDoc>(deepClone(blankItem))

    const childProductUrlPrefix = `/v2/admin/childproducts/${child_product}`;
    const getUrl = `${childProductUrlPrefix}/missions/settings/cycle/${id}`;
    const configsUrl = `/v2/admin/childproducts/${child_product}/missions/events/configs`

    useEffect(() => {
        if(id) {
            setDataFromUrl(getUrl, setSavedItem,
                c => setItem(c ? deepClone(c) : deepClone(blankItem)));
        }
        else {
            setSavedItem(deepClone(blankItem));
            setItem(deepClone(blankItem));      
        }

        setDataFromUrl(configsUrl, setConfigData);
    }, [getUrl, configsUrl])

    const handleAnyChange = (name: string, value: any) => {
        // @ts-ignore
        const updatedCycle: TItemDoc = deepClone(item);

        // if the value contains a period, it is nested
        if(name.indexOf(".") > -1) {
            const [parent, child] = name.split(".");
            updatedCycle[parent][child] = value;
        }
        else {
            updatedCycle[name] = value;
        }

        setItem(updatedCycle);
        setIsDirty(true);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.name === "disabled") {
            alert(e.target.value);
        }
        handleAnyChange(e.target.name, e.target.value);
    };    

    const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAnyChange(e.target.name, e.target.checked);
    };        

    const prepareTimeForSave = (time?: MultiverseTime) => {
        if(time && time.hour && !time.minute) {
            time.minute = 0;
        }
    }

    const prepareItemForSave = (item: TItemDoc) => {
        prepareTimeForSave(item.start_time_utc);
        prepareTimeForSave(item.end_time_utc);
    }

    const save = async (e: React.SyntheticEvent, data: TItemDoc) => {
        e.preventDefault();

        const url = `${childProductUrlPrefix}/missions/settings/cycle${data._id ? `/${data._id}` : ""}`
        setLoading(true);
        try{
            let response: { msg?: string };
            prepareItemForSave(data);
            if(data._id) {
                response = await api.put(url, data);
            } else {
                const { _id, ...saveData } = data;
                response = await api.post(url, saveData);
            }

            if(response.msg) {
                setErr(response.msg)
            }
            else {
                setSavedItem(deepClone(item));
                setIsDirty(false);
                setErr("")
            }

            
        }catch(err) {
            setErr(err.message)
        }
        setSuccess("Saved")
    }

    const remove = async (e: React.SyntheticEvent, data: TItemDoc) => {
        e.preventDefault();
        if(data._id) {
            setLoading(true);
            try{
                let response: { msg?: string };
                response = await api.del(`${childProductUrlPrefix}/missions/settings/cycle/${data._id}`);
                if(response.msg) {
                    setErr(response.msg)
                }
                else {
                    setSavedItem(deepClone(blankItem));
                    setItem(deepClone(blankItem));
                    setIsDirty(false);
                    setErr("")
                }
            }catch(err) {
                setErr(err.message)
            }
            setSuccess("Deleted")
        }
    }

    const cancel = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if(isDirty) {
            setItem(savedItem ? deepClone(savedItem) : deepClone(blankItem));
            setIsDirty(false);
        }
    }

    const goBack = (e: React.SyntheticEvent) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
        {item &&
        <Form className={"edit_item" + (item.disabled ? ' disabled-row' : '')}>
            {item._id &&
            <Row className="pt-2">
                <Col className="col-md-3">
                    Id
                </Col>
                <Col className="col-md-9">
                    {item._id}
                </Col>
            </Row>}
            <Row className="pt-2">
                <Col className="col-md-3">
                    Mission Type
                </Col>
                <Col className="col-md-9">
                    <Form.Control as="select" name="mission_event_config_name" value={item.mission_event_config_name} onChange={handleChange}>
                        <option value="">Select a mission type</option>
                        {configData && configData.mission_event_configs.map((config) => (
                            <option key={config.name} value={config.name}>
                                {config.display_name} ({config.name})
                            </option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Day of week UTC
                </Col>
                <Col className="col-md-9">
                    <Form.Control as="select" name="day_of_week_utc" value={item.day_of_week_utc} onChange={handleChange}>
                        <option value={undefined}>Select a day</option>
                        <option value={0}>Sunday</option>
                        <option value={1}>Monday</option>
                        <option value={2}>Tuesday</option>
                        <option value={3}>Wednesday</option>
                        <option value={4}>Thursday</option>
                        <option value={5}>Friday</option>
                        <option value={6}>Saturday</option>
                    </Form.Control>                    
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Start Time UTC
                </Col>
                <Col className="col-md-9">
                    <div className="time evenly-spaced-children">
                        <Form.Control type="number" name="start_time_utc.hour" value={item.start_time_utc?.hour} onChange={handleChange} />
                        :
                        <Form.Control type="number" name="start_time_utc.minute" value={item.start_time_utc?.minute} onChange={handleChange} />
                    </div>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    End Time UTC
                </Col>
                <Col className="col-md-9">
                    <div className="time evenly-spaced-children">
                        <Form.Control type="number" name="end_time_utc.hour" value={item.end_time_utc?.hour} onChange={handleChange} />
                        :
                        <Form.Control type="number" name="end_time_utc.minute" value={item.end_time_utc?.minute} onChange={handleChange} />
                    </div>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Helicopter
                </Col>
                <Col className="col-md-9">
                    <Form.Control as="select" name="option" value={item.option} onChange={handleChange}>
                        <option value={undefined}>Select a chopper</option>
                        <option value="0">Left</option>
                        <option value="1">Right</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                    Disabled
                </Col>
                <Col className="col-md-9">
                    <Form.Check type="checkbox" name="disabled" checked={item.disabled} onChange={handleCheckChange} />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col className="col-md-3">
                </Col>
                <Col className="col-md-9 evenly-spaced-children" >
                    {isDirty &&
                        <>
                        <button onClick={e => save(e, item)} className="btn btn-primary">Save</button>
                        <button onClick={cancel} className="btn btn-secondary">Cancel</button>
                        </>}
                    {!isDirty &&
                        <button onClick={goBack} className="btn btn-secondary">Back</button>}
                    {item._id &&
                        <button onClick={e => remove(e, item)} className="btn btn-danger">Delete</button>}
                </Col>
            </Row>
            {
                err && <Row className="pt-2">
                    <Col>
                        <Alert variant="danger">{err}</Alert>
                    </Col>
                </Row>
            }
            {
                success && !err && <Row className="pt-2">
                    <Col>
                        <Alert variant="success">{success}</Alert>
                    </Col>
                </Row>
            }
        </Form>}
        {
            props.emptyMessage && !item && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

export function AdminMissionEventCyclePage(props: {}) {

    const history = useHistory();
    const match = matchPath<IdMatchParams>(history.location.pathname, {
        path: '/admin/childproducts/:childproductid/missioneventcycle/:id?',
        exact: false
    })
    const child_product = match?.params.childproductid;
    const id = match?.params.id;

    return (
        <BasicPage title="Mission Event Cycle">
            <MissionEventCycle query={{child_product: child_product, id: id}} emptyMessage="Cycle not found"/>
        </BasicPage>
    )    
}

export default AdminMissionEventCyclePage;