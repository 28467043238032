import React, { CSSProperties, FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { useWindowSize } from '../../utils/hooks';
import api, { getSocket } from '../../utils/api';
import { MapLoader } from './maploader';
import { TVector2, TPoly } from './maptypes';
import { useMapLoader, calc_world2screen, calc_screen2world } from './maphelpers';
import { MapCanvas } from './mapcanvas';
import { MapCanvasWindow } from './mapcanvaswindow';
import * as vector from './vector'
import { MapImage } from './mapimagewindow';


export type TMapProps = {

}
export const InfiniverseMap = (props: TMapProps) => {
    const div = React.useRef<HTMLDivElement>(null)
    const canvas = React.useRef<HTMLCanvasElement>(null);
    const maploader = React.useRef(new MapLoader());
    const mapcanvas = React.useRef(new MapCanvasWindow(maploader.current, 500));

    const windowSize = useWindowSize()
    const [mouseDownTime,set_mouseDownTime] = useState(0)
    const [mouseDownX,set_mouseDownX] = useState(0)
    const [mouseDownY,set_mouseDownY] = useState(0)
    const [mouseDownOX,set_mouseDownOX] = useState(0)
    const [mouseDownOY,set_mouseDownOY] = useState(0)

    const [width,setWidth] = useState(0)
    const [height,setHeight] = useState(0)

    const [originX,set_originX] = useState(0)   //centre x (metres)
    const [originY,set_originY] = useState(0)   //centre y (metres)
    const [scale,set_scale] = useState(1)       //scale (metres per pixel)
    const [counter,set_counter] = useState(0)

    const [players, set_players] = useState<{[id:string]: {x:number,y:number,z:number}}>({})

    const [hoveredFeature, set_hoveredFeature] = useState<any|null>(null)
    const [selectedFeature, set_selectedFeature] = useState<any|null>(null)

    const world2screen = (world_ppos: TVector2) => {
        return calc_world2screen(world_ppos,width,height,originX,originY,scale)
    }
    const screen2world = (screen_pos: TVector2) => {
        return calc_screen2world(screen_pos,width,height,originX,originY,scale)
    }
    const moveTo = (context: CanvasRenderingContext2D, world_pos: TVector2) => {
        const screen_pos = world2screen(world_pos)
        context.moveTo(screen_pos[0],screen_pos[1])
    }
    const lineTo = (context: CanvasRenderingContext2D, world_pos: TVector2) => {
        const screen_pos = world2screen(world_pos)
        context.lineTo(screen_pos[0],screen_pos[1])
    }
    const polyPath = (context: CanvasRenderingContext2D, poly: TPoly) => {
        if(poly.length >= 1) {
            context.beginPath();
            moveTo(context, poly[0])
            for(let i = 1; i < poly.length; i++) {
                lineTo(context, poly[i])
            }
            context.closePath()         
        }
    }

    const getCanvasMousePos = (evt: { clientX: number, clientY: number }) => {
        if(canvas.current) {
            const rect = canvas.current.getBoundingClientRect();
            return [
                ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.current.width,
                ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.current.height,
            ];
        } else {
            return [0,0]
        }
    };

    const onWebSocketEvent = (evt: Event) => {
        const data = JSON.parse((evt as MessageEvent).data)
        if(data.type === 'message' && data.channel==='livemap') {
            if(data.message.nm === 'Infiniverse Pre-BETA' || data.message.nm === 'Infiniverse') {
                if(data.message.ev === 'pos') {
                    set_players((prevState) => {
                        const newState = {...prevState};
                        console.log(data)
                        newState[data.message.ac] = {x:data.message.x,y:data.message.y,z:data.message.z};
                        console.log(newState);
                        return newState;
                    })
                } else if(data.message.ev === 'leave') {
                    set_players((prevState) => {
                        const newState = {...prevState};
                        delete newState[data.message.ac];
                        console.log(newState);
                        return newState;
                    })
                }
            }
        }

    }
    useEffect(() => {
        const socket = getSocket();
        if(socket) {
            socket.addEventListener('message', onWebSocketEvent)
            socket.send(JSON.stringify({
                type: 'subscribe',
                channel: 'livemap'
            }))
        }
        return () => {
            const socket = getSocket();
            if(socket) {
                socket.send(JSON.stringify({
                    type: 'unsubscribe',
                    channel: 'livemap'
                }))
                socket.removeEventListener('message', onWebSocketEvent);
            }
        }
    }, [])

    useLayoutEffect(() => {
        setWidth(div?.current?.offsetWidth || 0)
        setHeight(div?.current?.offsetHeight || 0)
    });

    useEffect(() => {
        maploader.current.start((cell) => {
            if(cell) {
                mapcanvas.current.onCellLoaded(cell)
                set_counter((prev)=>prev+1)
            }
        })
        return ()=> { maploader.current.stop() }
    },[])

    useEffect(() => {
        if(mapcanvas.current && scale >= 0.5)
        {
            const min = screen2world([0,0]);
            const max = screen2world([width,height])
            maploader.current.request_window(
                Math.min(min[0],max[0]),
                Math.min(min[1],max[1]),
                Math.max(min[0],max[0]),
                Math.max(min[1],max[1])
            );    
        }
    }, [width,height,scale,originX,originY])

    useEffect(() => {
        
    })

    useEffect(() => {
        if (canvas.current) {
            {
                const context = canvas.current.getContext('2d');  
                if (context) {
                    context.clearRect(0,0,width,height);
                }
            }

            if(mapcanvas.current && scale >= 0.5)
            {
                const min = screen2world([0,0]);
                const max = screen2world([width,height])
                mapcanvas.current.redrawAll(canvas.current,Math.min(min[0],max[0]),Math.min(min[1],max[1]),Math.max(min[0],max[0]),Math.max(min[1],max[1]));

                const context = canvas.current.getContext('2d');  
                if (context) {
                    Object.values(players).forEach(x => {
                        try {
                            context.lineWidth = 1;
                            context.strokeStyle = '#f00'
                            context.fillStyle = '#f00';
                            const pos = world2screen([x.x,x.z])
                            context.beginPath();
                            context.arc(pos[0],pos[1],2,0,360)
                            context.closePath()
                            context.fill()
                        } catch(err) {

                        }
                    })

                    //context.beginPath()
                    //context.arc(width*0.5,height*0.5,2,0,360)
                    //context.closePath()
                    //context.fill()


                    if(selectedFeature) {
                        context.lineWidth = 6;
                        context.strokeStyle = '#000'
                        context.fillStyle = '#fff5';
                        polyPath(context, selectedFeature.poly)
                        context.stroke()

                        context.lineWidth = 3;
                        context.strokeStyle = '#fff'
                        context.stroke()
                    }

                    if(hoveredFeature) {
                        context.lineWidth = 3;
                        context.strokeStyle = '#00f'
                        context.fillStyle = '#fff5';
                        polyPath(context, hoveredFeature.poly)
                        context.stroke()
                    }

                }
            }
        }
    })    

    const renderMapImage = () => {
        const a = screen2world([0,0]);
        const b = screen2world([width,height])
        const min = vector.min(a,b)
        const max = vector.max(a,b)
        return <MapImage min={min} max={max} width={width} height={height}/>
    }

    const onHoverFeatures = (features: any[] | null) => {
        if(!features || features.length === 0) {
            set_hoveredFeature(null);
            return;
        }

        if(!selectedFeature) {
            set_hoveredFeature(features[0])
            return;
        }

        let match = -1;
        for(let i = 0; i < features.length; i++) {
            if(selectedFeature.code.startsWith(features[i].code)) {
                match = i;
            } else {
                break;
            }
        }

        match = Math.min(match+1,features.length-1);
        set_hoveredFeature(features[match]);
        
    }
    const onClickFeatures = (features: any[] | null) => {
        console.log({
            features,
            selectedFeature
        })
        if(!features || features.length === 0) {
            set_selectedFeature(null);
            return;
        }

        if(!selectedFeature) {
            set_selectedFeature(features[0])
            return
        }

        let match = -1;
        for(let i = 0; i < features.length; i++) {
            if(selectedFeature.code.startsWith(features[i].code)) {
                match = i;
            } else {
                break;
            }
        }

        match = Math.min(match+1,features.length-1);
        set_selectedFeature(features[match]);
    }

    return (<>
        <div ref={div} style={{position:"absolute",top:70,bottom:20,left:20,right:20}}>

            {renderMapImage()}

            <canvas ref={canvas} width={width} height={height} style={{position:"absolute"}}
                onMouseDown={(ev)=>{
                    set_mouseDownTime(Date.now())
                    set_mouseDownX(ev.clientX)
                    set_mouseDownY(ev.clientY)
                    set_mouseDownOX(originX)
                    set_mouseDownOY(originY)
                }}

                onMouseMove={(ev)=>{
                    if(mouseDownTime > 0) {
                        const dx = ev.clientX - mouseDownX
                        const dy = ev.clientY - mouseDownY
                        set_originX(mouseDownOX-dx/scale)
                        set_originY(mouseDownOY+dy/scale)
                    } else {
                        const screen_mouspos = getCanvasMousePos(ev);
                        const world_mousepos = calc_screen2world(screen_mouspos,width,height,originX,originY,scale)
                        onHoverFeatures(mapcanvas.current.getFeaturesAtPoint(world_mousepos))
                    }
                }}
                onMouseUp={(ev)=>{  
                    if( (Date.now()-mouseDownTime) < 250 ) {
                        const screen_mouspos = getCanvasMousePos(ev);
                        const world_mousepos = calc_screen2world(screen_mouspos,width,height,originX,originY,scale)
                        onClickFeatures(mapcanvas.current.getFeaturesAtPoint(world_mousepos))
                    }
                    set_mouseDownTime(0)
                }}
                onWheel={(ev)=>{
                    if(canvas.current) {
                        const screen_mouspos = getCanvasMousePos(ev);
                        const old_scale = scale;
                        const old_world_mousepos = calc_screen2world(screen_mouspos,width,height,originX,originY,old_scale)
                        const new_scale = scale-ev.deltaY*scale*0.001
                        const new_world_mousepos = calc_screen2world(screen_mouspos,width,height,originX,originY,new_scale)

                        set_scale(new_scale)
                        set_originX(originX+old_world_mousepos[0]-new_world_mousepos[0])
                        set_originY(originY+old_world_mousepos[1]-new_world_mousepos[1])
                    }
                }}
                />
                
                
        </div>
    </>);    
}

export default InfiniverseMap;

/*
// canvas variables
var canvas = document.getElementById("canvas");
var ctx = canvas.getContext("2d");
var $canvas = $("#canvas");
var canvasOffset = $canvas.offset();
var offsetX = canvasOffset.left;
var offsetY = canvasOffset.top;
var scrollX = $canvas.scrollLeft();
var scrollY = $canvas.scrollTop();

// set styles
ctx.fillStyle = "skyblue";
ctx.strokeStyle = "lightgray";
ctx.lineWidth = 2;

// create a triangle and parallelogram object

var triangle = {
    points: [{
        x: 25,
        y: 100
    }, {
        x: 50,
        y: 50
    }, {
        x: 75,
        y: 100
    }],
    message: "I am a triangle"
}

var parallelogram = {
    points: [{
        x: 150,
        y: 50
    }, {
        x: 250,
        y: 50
    }, {
        x: 200,
        y: 100
    }, {
        x: 100,
        y: 100
    }],
    message: "I am a parallelogram"
}

// save the triangle and parallelogram in a shapes[] array

var shapes = [];
shapes.push(triangle);
shapes.push(parallelogram);

// function to draw (but not fill/stroke) a shape
// (needed because isPointInPath only tests the last defined path)

function define(shape) {
    var points = shape.points;
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (var i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
    }
}

// function to display a shape on the canvas (define + fill + stroke)

function draw(shape) {
    define(shape);
    ctx.fill();
    ctx.stroke();
}

// display the triangle and parallelogram
draw(triangle);
draw(parallelogram);


// called when user clicks the mouse

function handleMouseDown(e) {
    e.preventDefault();

    // get the mouse position
    var mouseX = parseInt(e.clientX - offsetX);
    var mouseY = parseInt(e.clientY - offsetY);

    // iterate each shape in the shapes array
    for (var i = 0; i < shapes.length; i++) {
        var shape = shapes[i];
        // define the current shape
        define(shape);
        // test if the mouse is in the current shape
        if (ctx.isPointInPath(mouseX, mouseY)) {
            // if inside, display the shape's message
            alert(shape.message);
        }
    }

}

// listen for mousedown events
$("#canvas").mousedown(function (e) {
    handleMouseDown(e);
});
*/