import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PageTable, { PageTableCell } from '../pageTable';

interface StringsControlProps {
  name: string;
  strings: string[];
  onStringsChange: (name: string, updatedStrings: string[]) => void;
}

const StringsControl: React.FC<StringsControlProps> = ({ name, strings, onStringsChange }) => {
  const [newValue, setNewValue] = useState("");

  const handleChangeNewValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  };

  const handleAddNewValue = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const updatedStrings = strings.concat(newValue);
    setNewValue("");
    onStringsChange(name, updatedStrings);
  };

  const handleRemoveString = (e: React.SyntheticEvent, index: number) => {
    e.preventDefault();
    const updatedStrings = strings.filter((_, i) => i !== index);
    onStringsChange(name, updatedStrings);
  };

  return (
    <PageTable className="mb-0">
      <tbody>
        <PageTable.Row>
            <PageTableCell>
                <input type="text" value={newValue} onChange={handleChangeNewValue} />
            </PageTableCell>
            <PageTableCell>
                {   
                    newValue &&
                    <Button variant='outline-primary' type='button' title={`Add ${newValue}`} onClick={handleAddNewValue}>+</Button>
                }
            </PageTableCell>
        </PageTable.Row>
        {strings.map((string, index) => (
        <PageTable.Row key={index}>
            <PageTableCell>{string}</PageTableCell>
            <PageTableCell>
                <Button variant='outline-primary' type='button' title={`Remove ${string}`} onClick={e => handleRemoveString(e, index)}>-</Button>
            </PageTableCell>
        </PageTable.Row>
        ))}
      </tbody>
    </PageTable>
  );
};

export default StringsControl;
