import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import PageTable from '../../components/pageTable';

type DomainStatsPageProps = {

} & WithMultiverseApiProps;

type DomainStatsPageState = {
    isLoading: boolean;
    stats?: StatsRes
};

type StatsSession = {
    _id: string,
    created: string,
    timestamp: string,
    user: MultiverseUser,
    product: string,
    shard: string,
    status: string,
    room: MultiverseRoom,
    name: string,
    numusers: number,
    domain: string,
    sliceuri: string,
    domainaccess: string,
    location: MultiverseLocation,
}

type StatsRes = {
    query: any,
    from: string,
    to: string,
    sessions: StatsSession[]
}

class _DomainStatsPage extends React.Component<DomainStatsPageProps, DomainStatsPageState> {
    constructor(props: DomainStatsPageProps) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    async componentDidMount(): Promise<void> {
        const { domain, multiverse: { get }} = this.props;
        try {
            if(!domain) {
                throw new Error("No domain");
            }
            const stats = await get<StatsRes>(`/v2/domains/${domain.id}/stats`);
            console.log(stats);
            this.setState({stats})
        } catch(err) {

        } finally {
            this.setState({isLoading: false});
        }
    }

    statsRow = (session: StatsSession) => {
        return (
        <PageTable.Row key={session._id}>
            <PageTable.Cell>{new Date(session.timestamp).toLocaleString()}</PageTable.Cell>
            <PageTable.Cell>{session.user.nickname}</PageTable.Cell>
            <PageTable.InfoCell title={session.location.nickname} subtitle={session.location.path}/>
            <PageTable.InfoCell title={session.room.nickname} subtitle={session.room.releasename}/>
        </PageTable.Row>)
    }

    render(): JSX.Element {
        return (
            <BasicPage title="Stats" isLoading={this.state.isLoading}>
                <PageTable>
                    <thead>
                        <PageTable.Row>
                            <th>Date</th>
                            <th>User</th>
                            <th>Location</th>
                            <th>Room</th>
                        </PageTable.Row>
                    </thead>
                    <tbody>
                        {
                            this.state.stats && this.state.stats.sessions && this.state.stats.sessions.map(x => this.statsRow(x))
                        }
                    </tbody>
                </PageTable>

            </BasicPage>
        )
    }
}
export const DomainStatsPage = withMultiverseApi(_DomainStatsPage);

export default DomainStatsPage;
