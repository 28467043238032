import './adminMissionsPage.css';
import PageTable from '../../components/pageTable';
import { Image, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { MultiverseLocation, MultiverseMission, MultiverseMissionConfig, MultiverseMissionUpdateArgs, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import React from 'react';
import BasicPage from '../../components/basicPage';
import DateTimePicker from 'react-datetime-picker';
import { formatDateTime, removeSeconds } from '../../utils/dates';

const LoadedDate = new Date();

type MissionsPageProps = {
} & WithMultiverseApiProps;

type MissionsPageState = {
    isSavingMission: boolean;
    saveMissionResult: ISaveMissionResult | null;
    isLoadingCount: number;
    hasError: boolean;
    missions: MultiverseMission[];
    missionconfigs: MultiverseMissionConfig[];
    locations: MultiverseLocation[];
    editingItem: MultiverseMission;
    isDirty: boolean;
};

interface IGetMissionsResult {
    missions: MultiverseMission[]
}

interface ISaveMissionResult {
    mission?: MultiverseMission,
    msg?: string
}

interface ICreateMissionsResult {
    missions?: MultiverseMission[],
    msg?: string
}

class _AdminMissionsPage extends React.Component<MissionsPageProps, MissionsPageState> {
    missionFocusOnEditRef = React.createRef<HTMLSelectElement>();

    constructor(props: MissionsPageProps) {
        super(props);
        this.state = {
            saveMissionResult: null,
            isSavingMission: false,
            isLoadingCount: 0,
            hasError: false,
            missions: [],
            missionconfigs: [],
            locations: [],
            editingItem: _AdminMissionsPage.createEmptyMission(),
            isDirty: false
        };

        this.onMissionSubmit = this.onMissionSubmit.bind(this);
        this.onMissionTextChange = this.onMissionTextChange.bind(this);
        this.onMissionCheckBoxChange = this.onMissionCheckBoxChange.bind(this);
    }

    static createEmptyMission(): MultiverseMission {
        return {
            mission_config: "",
            option: 0,
            startdate: new Date(),
            feature: "",
            event: "",
            importance: 0
        }
    }

    async componentDidMount(): Promise<void> {
        const { multiverse: { refreshCurrentChildProduct } } = this.props;
        await refreshCurrentChildProduct();
        await this.refreshLocations();
        await this.refreshMissionConfigs();
        await this.refreshMissions();
    }

    refreshLocations = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load locations
            const { multiverse: { getLocationsForChildProduct } } = this.props;
            const locations = await getLocationsForChildProduct();

            this.setState({ locations: locations });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }    

    refreshMissionConfigs = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load mission configs
            const { multiverse: { getMissionConfigsForChildProduct } } = this.props;
            let result = await getMissionConfigsForChildProduct();
            this.setState({ missionconfigs: result.mission_configs });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }

    refreshMissions = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load mission configs
            const { multiverse: { get, getCurrentChildProductId } } = this.props;
            let result = await get<IGetMissionsResult>(`/v2/admin/childproducts/${getCurrentChildProductId()}/missions/missions`);

            const missions = result.missions.map(m => { return { 
                ...m, feature: m.feature ?? "", event: m.event ?? "", importance: m.importance ?? 0 } });
            this.sortMissions(missions);
            this.setState({ missions: missions });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }

    onMissionStartDateChange = (date: Date) => {
        this.onMissionValueChange("startdate", removeSeconds(date));
    }

    onMissionConfigChange = (event: any) => {
        const { name, value } = event.target;
        this.onMissionValueChange(name, value, () => {
            // copy the feature from the mission config to the mission
            this.setState((prev) => {
                const config = this.state.missionconfigs.filter(c => c.id === value)[0];
                return {
                    editingItem: {
                        ...prev.editingItem,
                        feature: config.feature,
                        importance: config.importance
                    },
                    isDirty: true,
                    saveMissionResult: null
                }            
            });            
        });
    }

    onMissionTextChange = (event: any) => {
        const { name, value } = event.target;
        this.onMissionValueChange(name, value);
    }

    onMissionNumberChange = (event: any) => {
        const { name, value } = event.target;
        const numberValue = parseInt(value);
        this.onMissionValueChange(name, isNaN(numberValue) ? 0 : numberValue);
    }

    onMissionCheckBoxChange = (event: any) => {
        const { name, checked } = event.target;
        this.onMissionValueChange(name, checked);
    }

    onMissionValueChange = (name: string, value: any, callback?: (() => void) | undefined) => {
        this.setState((prev) => {
            return {
                editingItem: {
                    ...prev.editingItem,
                    [name]: value
                },
                isDirty: true,
                saveMissionResult: null
            }            
        },
        callback);    
    }

    sortMissions = (missions: MultiverseMission[]) => {
        missions.sort((a, b) => {
            if (a.startdate < b.startdate) {
                return -1;
            } else if (a.startdate > b.startdate) {
                return 1;
            } else {
              // Dates are equal, compare the option property
                return a.option - b.option;
            }
        });
    }

    updateListFromCurrentItem = () => {
        this.setState((prev) => {
            // remove current mission
            const missions = prev.missions.filter(m => m.id !== prev.editingItem.id);

            // add current mission
            missions.push({...prev.editingItem});

            this.sortMissions(missions);

            return {
                missions: missions
            }
        });
    }

    onMissionSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        this.setState({
            isSavingMission: true,
            saveMissionResult: null
        })

        try {
            const { multiverse: { put, post, getCurrentChildProductId } } = this.props;

            const { child_product, timestamp, ...item } = this.state.editingItem           
            const updateItem: MultiverseMissionUpdateArgs = {
                mission_config: item.mission_config,
                option: item.option,
                startdate: item.startdate,
                feature: item.feature,
                event: item.event,
                importance: item.importance,
                id: item.id                
            }

            let result: ISaveMissionResult;
            if(this.state.editingItem.id) {
                result = await put<ISaveMissionResult>({
                    url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/mission`,
                    body: updateItem
                })    
            }
            else {
                const createResult = await post<ICreateMissionsResult>({
                    url: `/v2/admin/childproducts/${getCurrentChildProductId()}/missions/missions`,
                    body: {
                        missions: [ updateItem ]
                    } 
                })
                result = {
                    msg: createResult.msg,
                    mission: createResult.missions && createResult.missions.length > 0 ? createResult.missions[0] : undefined
                }
            }

            if(!result.msg) {   // success
                this.setState((prevState) => ({
                    editingItem: {
                        ...prevState.editingItem,
                        id: result?.mission?.id ? result?.mission?.id : prevState.editingItem.id
                    },
                    isDirty: false
                }), this.updateListFromCurrentItem)
            }

            this.setState({
                saveMissionResult: result
            });

        } catch (e) {
            console.log(e);
            this.setState({
                saveMissionResult: { msg: "error" }
            })
        }

        this.setState({
            isSavingMission: false
        })

        await this.refreshMissions();
    }

    onNewItemClick = (e: React.SyntheticEvent) => {
        this.setState({
            editingItem: _AdminMissionsPage.createEmptyMission(),
            isDirty: false,
            saveMissionResult: null
        });        
    }

    onCancelItemClick = (e: React.SyntheticEvent) => {
        const item = this.getCurrentMissionFromList();
        if(item) {
            this.selectItem(item);
            return;
        }

        this.setState({
            editingItem: _AdminMissionsPage.createEmptyMission(),
            isDirty: false,
            saveMissionResult: null
        });
    }

    getIndexOfCurrentMissionInList(missions: MultiverseMission[]) {
        for (let i = 0; i < missions.length; i++) {
            if (missions[i].id === this.state.editingItem.id) {
                return i;
            }
        }

        return -1;
    }

    getCurrentMissionFromList() {
        const missions = this.state.missions;
        const index = this.getIndexOfCurrentMissionInList(missions);
        if(index >= 0) {
            return missions[index];
        }

        return null;
    }

    canDelete = (x: MultiverseMission): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return true;
    }

    canManage = (x: MultiverseMission): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return true;
    }

    canDoAnything = (x: MultiverseMission): boolean => {
        return this.canDelete(x) || this.canManage(x);
    }

    selectItem = (mission: MultiverseMission) => {
        this.setState({
            editingItem: { ...mission },
            isDirty: false,
            saveMissionResult: null            
        })

        this.missionFocusOnEditRef.current?.focus();
    }

    getLocation = (id?: string) => {
        const matches = this.state.locations.filter(location => location.id === id);
        if(matches.length === 0) return null;
        return matches[0];
    }

    getMissionConfig = (id: string) => {
        const matches = this.state.missionconfigs.filter(config => config.id === id);
        if(matches.length === 0) return null;
        return matches[0];
    }

    getMissionConfigDescriptionWithLocation = (config: MultiverseMissionConfig) => {
        const location = this.getLocation(config.location);
        return `${location?.nickname} - ${this.getMissionConfigDescription(config)}`;
    }

    getMissionConfigDescription = (config: MultiverseMissionConfig | null): string => {
        let config_description = `${config?.description}`;

        const attributes: string[] = [];

        if(config?.feature) {
            attributes.push(config.feature);
        }

        if(config?.mode) {
            attributes.push(config.mode);
        }

        if(config?.environment) {
            attributes.push(config.environment);
        }

        if(attributes.length > 0) {
            config_description += " - " + attributes.join(", ");
        }

        return config_description;
    }

    renderLoadingSpinner() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    renderNoMissions() {
        return (
            <>
                <Row>
                    <Col className="text-center pb-3">
                        This product currently contains no upcoming missions.
                    </Col>
                </Row>
            </>
        );
    }

    renderMissions = () => {
        const { } = this.props;

        return (
            <>
                <div className="table-responsive">
                    <PageTable>
                        <tbody>
                            <PageTable.Row key="header">
                                <th>Start Date</th>
                                <th>Location</th>
                                <th>Mission Config</th>
                                <th>Option</th>
                                <th>Event Start</th>
                                <th>Event</th>
                                <th>Importance</th>
                            </PageTable.Row>
                            {this.state.missions.map((x) => {
                                const mission_config = this.getMissionConfig(x.mission_config);
                                const location = this.getLocation(mission_config?.location);
                                return (
                                    <PageTable.Row className="clickable" key={x.id} onClick={() => this.selectItem(x)}>
                                        <PageTable.Cell>{formatDateTime(x.startdate)}</PageTable.Cell>
                                        <PageTable.InfoCell title={location?.nickname}/>
                                        <PageTable.InfoCell title={this.getMissionConfigDescription(mission_config)}/>
                                        <PageTable.Cell>{x.option}</PageTable.Cell>
                                        <PageTable.InfoCell title={x.feature}/>
                                        <PageTable.InfoCell title={x.event}/>
                                        <PageTable.InfoCell title={x.importance?.toString()}/>
                                    </PageTable.Row>
                                )
                            })}
                        </tbody>
                    </PageTable>
                </div>
            </>
        )
    }

    renderCreateOrUpdateMission = () => {
        const { saveMissionResult, editingItem, missionconfigs, isDirty } = this.state;

        return (
            <>
                <Form className="edit-item" onSubmit={this.onMissionSubmit}>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Start
                        </Col>
                        <Col className="col-md-9">
                            <DateTimePicker
                                name="startdate"
                                value={typeof editingItem.startdate === "string" ? new Date(editingItem.startdate) : editingItem.startdate}
                                minDate={LoadedDate} onChange={this.onMissionStartDateChange}
                                disableClock
                                clearIcon={null}
                                className="display-block date-picker start-date-picker"
                                maxDetail="minute" />
                        </Col>
                    </Row>                    
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Mission Config
                        </Col>
                        <Col className="col-md-9">
                            <select name="mission_config" ref={this.missionFocusOnEditRef} className="responsive-select" value={editingItem.mission_config} onChange={this.onMissionConfigChange}>                                
                                <option value="">--Select a mission config--</option>
                                {missionconfigs.map((missionConfig) => (
                                    <option key={missionConfig.id} value={missionConfig.id}>
                                        {this.getMissionConfigDescriptionWithLocation(missionConfig)}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Option
                        </Col>
                        <Col className="col-md-3">
                            <Form.Control type="number" name="option" value={editingItem.option} onChange={this.onMissionNumberChange} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Event Start
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="feature" value={editingItem.feature} onChange={this.onMissionTextChange} />
                        </Col>
                    </Row>     
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Event
                        </Col>
                        <Col className="col-md-9">
                            <Form.Control type="text" name="event" value={editingItem.event} onChange={this.onMissionTextChange} />
                        </Col>
                    </Row>                                      
                    <Row className="pt-2">
                        <Col className="col-md-3">
                            Importance
                        </Col>
                        <Col className="col-md-9">
                            <select name="importance" className="responsive-select" value={editingItem.importance} onChange={this.onMissionNumberChange}>                                
                                <option value="">--Select an importance level--</option>
                                <option key="0" value="0">0</option>
                                <option key="1" value="1">1</option>
                                <option key="2" value="2">2</option>
                                <option key="3" value="3">3</option>
                            </select>                            
                        </Col>
                    </Row>                    
                    <Row className="pt-2">
                        <Col className="col-md-12 evenly-spaced-children">
                            {!isDirty && editingItem?.id && 
                                <Button variant='outline-primary' type='button' onClick={this.onNewItemClick}>New</Button>}
                            {isDirty && (
                                <>
                                    {editingItem?.id
                                        ? <Button variant='outline-primary' type='submit'>Update</Button>
                                        : <Button variant='outline-primary' type='submit'>Create</Button>}
                                    <Button variant='outline-secondary' type='button' onClick={this.onCancelItemClick}>Cancel</Button>
                                </>)}
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col className="col-md-12">
                            {saveMissionResult && (
                                <p className={saveMissionResult.msg ? 'text-danger' : 'text-success'}>
                                    { saveMissionResult.msg ? saveMissionResult.msg : "Saved" }
                                </p>
                            )}
                        </Col>
                    </Row>                    
                </Form>
            </>
        )
    }    

    renderPage() {
        if (this.state.isLoadingCount > 0)
            return this.renderLoadingSpinner();
        else if (!this.state.missions) {
            return (<pre>error</pre>)
        }
        else if (this.state.missions.length > 0)
            return this.renderMissions();
        else
            return this.renderNoMissions();
    }

    render() {
        const { childProduct } = this.props;

        return (
            <>
                <BasicPage title={(childProduct ? `${childProduct.name} ` : "") + "Missions"}>
                    {this.renderCreateOrUpdateMission()}
                    {this.renderPage()}
                </BasicPage>
            </>);
    }
}

export const AdminMissionsPage = withMultiverseApi(_AdminMissionsPage);

export default AdminMissionsPage;
