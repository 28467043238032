import React, { FunctionComponent } from 'react';
import { Button, Col, FormControl, FormControlProps, Modal, Spinner } from 'react-bootstrap';
import DialogBox from '../dialogBox'

type DialogBoxWithSpinnerProps = {
    title: string;
    button0Text?: string;
    button1Text?: string;
    cancelButtonText?: string;
    className?: string;
    closeButton?: boolean;
    show?: boolean;
    type?: 'normal' | 'danger'
    disableConfirm?: boolean;
    onConfirm?: (button?: 0 | 1) => Promise<void>;
    onCancel?: () => void;
};

type DialogBoxWithSpinnerState = {
    isWorking: boolean
};

//simple dialog box
class DialogBoxWithSpinner extends React.Component<DialogBoxWithSpinnerProps, DialogBoxWithSpinnerState> {
    constructor(props: DialogBoxWithSpinnerProps) {
        super(props);
        this.state = {
            isWorking: false
        };
    }

    private _handleConfirm = async (button?: 0 | 1) => {
        const { onConfirm } = this.props;
        try {
            this.setState({
                isWorking: true
            })
            if (onConfirm) {
                await onConfirm(button);
            }
        }
        catch (err) {
            console.log(err); 
        }
        finally {
            this.setState({
                isWorking: false
            })
        }
    }

    render() {
        const { children } = this.props;
        const { isWorking } = this.state;

        let dialog_props: any = {
            ...this.props
        }
        dialog_props.onConfirm = this._handleConfirm;

        return (
            <DialogBox {...dialog_props}>
                <div style={{visibility: (isWorking ? "hidden" : 'visible')}}>
                    {children}
                </div>
                <div style={{visibility: (!isWorking ? "hidden" : 'visible'), position: "absolute", width: "100%", height: "100%", left: 0, top: 0}}>
                    <div style={{position: "absolute", left:"50%", top:"50%", transform:"translate(-50%,-50%)"}}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Inviting...</span>
                        </Spinner>
                    </div>
                </div>
            </DialogBox>
        )
    }

}

export default DialogBoxWithSpinner;
