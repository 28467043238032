import React, { useEffect, useState } from "react";
import { Accordion, Card, Container, Row, Col, Image, Button } from "react-bootstrap";
import { MultiverseLocation, MultiverseRoom, MultiverseDomain, MultiversePlan, InfiniverseEscrowHoldingInfo, InfiniverseSlot, MultiverseUser, InfiniverseUserEscrowInfos } from "../../hoc/multiverseApiProvider";
import { TTemplate, TemplateSelectDialog, getTemplateIconUrl } from "../../components/templateSelectDialog";
import api, { useGet } from "../../utils/api";
import { useEntity, onEntityDirty } from "../../utils/entitycache";
import { useHistory } from "react-router-dom";
import { useRelease } from "./entityHeaderCard";
import ForSaleIcon from "../../assets/ForSaleIcon.png"
import UnderOfferIcon from "../../assets/UnderOfferIcon.png"
import PageTable from "../../components/pageTable";
import { getApiUrl } from "../../config/api";
import { TickButton, CloseButton } from "../../components/circularButton";
import './entitypage.css'

export function InfiniverseCard(props: { targeturl: string, userid?: string }) {

    const domainuri = props.targeturl.substr(0,props.targeturl.indexOf("/"));
    const release = useRelease();
    const releaseuri = `${props.targeturl}/releases/win64_${release}`
    const planuri = `${domainuri}/plan`
    const history = useHistory();
    const location = useEntity<MultiverseLocation>(props.targeturl)
    const room = useEntity<MultiverseRoom>(releaseuri)
    const template = useEntity<MultiverseLocation>(room?.template)

    //for now, bodge this to just show stuff relevant to apartments
    const apt = template?.name.includes("apartment") || template?.name.includes("penthouse");

    const [slot,setSlot] = useState<InfiniverseSlot>()
    const [allowOffers, setAllowOffers] = useState<boolean>(!(slot?.refuseoffers))
    const [escrows,setEscrows] = useState<InfiniverseEscrowHoldingInfo[]>()
    const [forSale, setForSale] = useState<boolean>()
    const [requestRefresh, setRequestRefresh] = useState<boolean>()
    const [actionDisabled, setActionDisabled] = useState<boolean>()
    
    useEffect(() => {
        api.get<InfiniverseSlot>(`/v2/domains/${props.targeturl}/slot`)
        .then((res) => {
            setSlot(res)
            setAllowOffers(!res.refuseoffers)
            setForSale(res.marketsale > 0)
        })
    }, [props.targeturl, requestRefresh])

    useEffect(() => {
        console.log(slot)
        if(slot?.buildingguid) {
            api.get<InfiniverseUserEscrowInfos>(`/v2/me/escrow/filter?received=true&buildingguid=${slot?.buildingguid}&slotidx=${slot?.slot}`)
            .then((res) => {
                setEscrows(res.received)
            })
            .catch(err => console.log(err))
        }
    },[slot])

    useEffect(() => {
        api.post<InfiniverseSlot>(`/v2/domains/${props.targeturl}/allowoffers`, {allow: allowOffers})
        .then((res) => {
            setSlot(res)
        })
        .catch(err => console.log(err))
    }, [allowOffers])
    
    if(!apt) {
        return <></>
    }

    const offerAcceptClick = async (x:InfiniverseEscrowHoldingInfo) => { return await offerClick(x, true) }
    const offerDeclineClick = async (x:InfiniverseEscrowHoldingInfo) => { return await offerClick(x, false) }
    const offerClick = async (x:InfiniverseEscrowHoldingInfo, accept: boolean) => {
        setActionDisabled(true)
        try {
            const res = await api.post(`/v2/me/escrow/respond`, {escrowid: x.escrowid, acceptoffer:accept, amount:x.amount})
            // if accepting, we no longer own so navigate back to property list
            if(accept) {
                // if accepting, we no longer own so navigate back to property list
                history.push(`/domains/${domainuri}`)
            } else {
                // refresh
                setRequestRefresh(!requestRefresh)
            }
        } catch (err) {
            console.log(err); 
            // refresh
            setRequestRefresh(!requestRefresh)
        }
        setActionDisabled(false)
    }

    const isUnderOffer = escrows && escrows.length > 0;

    if(!isUnderOffer) {
        return <></>   
    }

    return (<>
        <Accordion className="infiniverse-card" defaultActiveKey="0">
            <Card className="mt-2">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                    Infiniverse Offers
                    {isUnderOffer && <Image src={UnderOfferIcon} width={32} height={32} style={{margin: '0px 10px 0px 10px'}}/>}
                    {forSale && <Image src={ForSaleIcon} width={32} height={32} style={{margin: '0px 10px 0px 10px'}}/>}
                </Accordion.Toggle>
                <Accordion.Collapse as={Card.Body} eventKey="0">
                    <Container>
                        {/*
                        <Row className='mb-2 mt-2'>
                            <Col>
                                <div><strong>Infiniverse Offers</strong></div>
                                <div><input type="checkbox" checked={allowOffers} onChange={(x) => setAllowOffers(x.target.checked)}/> Allow Offers</div>
                            </Col>
                            <Col xs="auto">
                            </Col>
                        </Row>
                        */}
                        <Row>
                            <Col>
                                { isUnderOffer && <PageTable className="my-2 small">
                                    <thead>
                                        <PageTable.Row key="header">
                                            <th></th>
                                            <th>Sender</th>
                                            <th className="d-none d-md-table-cell">Offer</th>
                                            <th className="d-none d-md-table-cell">Fees</th>
                                            <th>You Get</th>
                                            <th className="d-none d-md-table-cell">Date</th>
                                            <th className="ic-action-cell"></th>
                                        </PageTable.Row>
                                    </thead>
                                    <tbody>
                                        {escrows && escrows.map((x) => {
                                            return (
                                                <PageTable.Row key={x.escrowid}>
                                                    <PageTable.IconCell src={`${getApiUrl()}/v2/users/${x.senderid}/profilepicture`} roundedCircle/>
                                                    <PageTable.InfoCell title={x.sendername}/>
                                                    <PageTable.Cell className="d-none d-md-table-cell">{x.amount}MC</PageTable.Cell>
                                                    <PageTable.Cell className="d-none d-md-table-cell">{x.fees}MC</PageTable.Cell>
                                                    <PageTable.Cell>{x.amount-x.fees}MC</PageTable.Cell>
                                                    <PageTable.Cell className="d-none d-md-table-cell">{new Date(x.timestamp).toLocaleString()}</PageTable.Cell>
                                                    <PageTable.Cell className="ic-action-cell">
                                                        <TickButton variant="primary" disabled={actionDisabled} onClick={() => offerClick(x, true)}/>
                                                        <CloseButton variant="secondary" disabled={actionDisabled} onClick={() => offerClick(x, false)}/>
                                                    </PageTable.Cell>
                                                </PageTable.Row>
                                            )
                                        })}
                                    </tbody>
                                </PageTable> }
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </>)

}