import api, { isAuthenticated } from "./api"
import eventBus from "./eventBus"
import { useState, useEffect } from "react"
import { MultiverseBaseEntity, MultiverseDomain } from "../hoc/multiverseApiProvider"
import { matchPath, useHistory } from "react-router-dom"
import { getApiUrl, getBlobUrl } from "../config/api"

const VERBOSE=false

//most basic entity data required by cache
export type TMinimalEntity = {
    id?: string,
    type?: string
}

//list of cached entities
type TEntityCacheEntry = {
    uri: string;
    expires: number;
    loaded: TMinimalEntity | null;
}
const g_entity_cache: {[uri: string]: TEntityCacheEntry} = {}

const fullUri = (uri: string) => {
    if(!uri.startsWith("/")) {
        return isAuthenticated() ? `/v2/domains/${uri}` : `/v2/public/domains/${uri}`;
    } else {
        return isAuthenticated() ? `/v2${uri}` : `/v2/public${uri}`;
    }
}

//internal function that attempts to get a loaded entity, and fires
//off request to load it if not ready yet
function requestEntity(uri: string, force_reload?: boolean) {
    if(!g_entity_cache[uri] || force_reload) {
        if(!g_entity_cache[uri]) {
            g_entity_cache[uri] = {
                uri: uri,
                expires: Date.now(),
                loaded: null
            }
        }
        VERBOSE && console.log(`[requestEntity(${uri})] Loading entity`)
        const req_uri = fullUri(uri);
        api.get<TMinimalEntity>(req_uri).then((res) => {
            g_entity_cache[uri] = {
                uri: uri,
                expires: Date.now()+30000,
                loaded: res
            }
        })
        .catch((err) => {
            console.log(err)
        })
        .then(() => {
            VERBOSE && console.log(`[requestEntity(${uri})] Dispatching event`)
            eventBus.dispatch('entitycache', g_entity_cache[uri])
        })
        return null;
    } else if(g_entity_cache[uri].loaded) {
        VERBOSE && console.log(`[requestEntity(${uri})] Returning loaded entity`)
        return g_entity_cache[uri].loaded
    } else {
        VERBOSE && console.log(`[requestEntity(${uri})] Returning null`)
        return null
    }
}

//function to be used by systems that need to update an entity, which correctly updates the cache
export async function postEntity(uri: string, entity: any) {
    entity = await api.post<TMinimalEntity>(fullUri(uri), entity)
    onEntityUpdated(uri,entity);
}

//called internally when an entity changed, but can also be called externally by functions
//that modify an entity but not via the cache (eg icon upload). Updates the cached value
//and dispatches the update event.
export function onEntityUpdated(uri: string, entity: TMinimalEntity) {
    if(g_entity_cache[uri]) {
        g_entity_cache[uri].loaded = {
            ...g_entity_cache[uri].loaded,
            ...entity
        }
        eventBus.dispatch('entitycache', g_entity_cache[uri])
    }
}

//can be called externally to notify the system that an entity has potentially changed server side and needs reloading
export function onEntityDirty(uri: string) {
    if(g_entity_cache[uri]) {
        requestEntity(uri,true)
    }  
}

//react hook that takes an entity uri and returns an entity, internally
//managing use of the cache to retrieve it and detect updates to it
export function useEntity<T extends TMinimalEntity>(uri?: string | null): T | null {
    //console.log(`[useEntity(${uri})] Called`)
    const [entity,setEntity] = useState<T | null>(null);

    useEffect(() => {
        VERBOSE && console.log(`[useEntity(${uri})] useEffect`)
        
        //handle entity cache event to catch changes to this entity
        if(uri) {
            const on_entity_change = (cache_entry: TEntityCacheEntry) => {
                VERBOSE && console.log(`[useEntity(${uri})] on_entity_change (${cache_entry.uri})`)
                if(cache_entry.uri === uri) {
                    VERBOSE && console.log(cache_entry.loaded)
                    setEntity(cache_entry.loaded as T|null)
                }
            }

            //register interest in the entity
            eventBus.on('entitycache', on_entity_change)

            //request entity and store initial value (may be null)
            VERBOSE && console.log(`[useEntity(${uri})] requesting entity`)
            setEntity(requestEntity(uri) as T | null);

            //on closing hook, unsubscribe
            return () => {
                eventBus.remove('entitycache', on_entity_change)
            }
        } else {
            setEntity(null)
            return () => {}
        }
    }, [uri])
    return entity;
}

export function useDomainEntity(uri?: string | null): MultiverseDomain | null {
    let domain_uri = null;
    if(uri && uri.length > 0) {
        const slash_idx = uri.indexOf("/");
        if(slash_idx === -1) {
            domain_uri = uri;
        } else {
            domain_uri = uri.substr(0,slash_idx);
        }
    }
    return useEntity<MultiverseDomain>(domain_uri);
}

export function useEditable(uri?: string | null): boolean {
    const domain = useDomainEntity(uri);
    return (domain && domain.domainPermissions && domain.domainPermissions.includes("manage_domain")) || false;
}

type IdMatchParams = {
    id: string;
}
export function useCurrentDomainUri() {
    const history = useHistory();
    const match = matchPath<IdMatchParams>(history.location.pathname, {
        path: '/domains/:id',
        exact: false
    })
    const id = match?.params.id;
    return id || "";      
}

export function useCurrentDomainEntity() {
    const targeturi = useCurrentDomainUri()
    return useDomainEntity(targeturi)
}

export const getIconSrc = (x?: { type: string, id: string, domain?: string, iconblob?: string } | null) => {
    if(!x) {
        return ""
    } else if(x.iconblob) {
        return `${getBlobUrl()}/${x.iconblob}`;
    } else if(api.isAuthenticated()) {
        switch(x.type) {
            case 'user':
                return  `${getApiUrl()}/v2/users/${x.id}/icon`;
            case 'domain':
                return `${getApiUrl()}/v2/domains/${x.domain}/icon`
            default:
                return  `${getApiUrl()}/v2/domains/${x.domain}/${x.type}s/${x.id}/icon`;
        }
    } else {
        switch(x.type) {
            case 'user':
                return  `${getApiUrl()}/v2/public/users/${x.id}/icon`;
            case 'domain':
                return `${getApiUrl()}/v2/public/domains/${x.domain}/icon`
            default:
                return  `${getApiUrl()}/v2/public/domains/${x.domain}/${x.type}s/${x.id}/icon`;
        }
    }

}