import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormControlProps, Modal, Spinner } from 'react-bootstrap';
import { Subtract } from 'utility-types';
import { MultiverseLocation, MultiverseBaseEntity, MultiverseUser } from '../../hoc/multiverseApiProvider';
import api from '../../utils/api';
import { useInterval } from '../../utils/hooks';
import DialogBox from '../dialogBox';
import ValidatedField, { LocationUriField, DomainOrLocationUriField, EntityUriField, DomainOrEntityUriField } from '../validatedField';
import { EntityMiniInfoBox } from '../entityMiniInfoBox';

export function FindUserDialog(props: { show: boolean, onComplete: (val: MultiverseUser | null)=>void }) {

    const [refreshing, setRefreshing] = useState<boolean>(false)
    const [changed, setChanged] = useState<boolean>(false)
    const [user, setUser] = useState<MultiverseUser | null>(null);
    const [input,setInput] = useState<{text: string, valid: boolean}>({text: "", valid: false})

    const onChange = (text: string,valid: boolean) => {
        setInput({
            text, valid
        })
        setChanged(true)
    }

    useInterval(() => {
        refresh()
    }, 1000)


    const refresh = async() => {
        if(!refreshing && changed && input.valid && input.text.length > 0) {
            setRefreshing(true);
            setChanged(false);
            const loc = await api.tryGet<MultiverseUser>(`/v2/users/find?text=${input.text}`)
            setUser(loc);
            setRefreshing(false)
        }
    }

    const selectField = () => {
        return <ValidatedField regex={/^[\w\d\@\.]*$/} value={input.text} onChange={onChange}/>
    }

     return (
     <DialogBox 
        title="Select Multiverse User" 
        show={props.show} 
        onCancel={() => props.onComplete(null)} 
        onConfirm={() => props.onComplete(user)} 
        closeButton
        button0Text="Ok"
        cancelButtonText="Cancel"
        disableConfirm={!user || refreshing || !input.valid}
        >
            Enter a Multiverse User name or email
            {selectField()}
            {refreshing && <span>Loading...</span>}
            {!refreshing && !user && <span>User not found</span>}
            {!refreshing && user && <EntityMiniInfoBox uri={`/users/${user.id}`}/>}
    </DialogBox>)
}

