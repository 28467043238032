import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

//Extension of the default auth 0 provider that reads their 'returnTo' app state on redirect, and if found
//sends the user there rather than to the home page
const Auth0ProviderWithHistory = (props: Auth0ProviderOptions) => {

    const history = useHistory();

    const onRedirectCallback = (appState: any) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider {...props} onRedirectCallback={onRedirectCallback}>
            {props.children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
