import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';

type PermissionsInfoProps = {
    currentuser: string;
    owner: string;
    permissions: string[]
};

const PermissionsInfo:FunctionComponent<PermissionsInfoProps> = (props: PermissionsInfoProps) => {
    const { owner, permissions, currentuser } = props;

    const isOwner = currentuser === props.owner;
    const isManager = permissions.includes("manage_domain");
    const isHost = permissions.includes("host");

    let txt: string[] = [];
    if(isOwner) txt.push("Owner");
    if(isManager) txt.push("Manager");
    if(isHost) txt.push("Host");

    let asstr = txt.join(" / ")
    if(asstr === "") {
        asstr = "Member";
    }

    return (
    <>
      {asstr}
    </>
    )
};

export default PermissionsInfo;
