import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { RestApiCollection, RestApiFieldDefinition } from '../../components/restApi';

type AdminCollectionPageProps = {

} & WithMultiverseApiProps;

type AdminCollectionPageState = {
};

const fields: any = {
    id: { visible: false},
    name: { visible: true },
    timestamp: {visible: true}
}

const getDefaultFields = (): any => {
    if(document.location.pathname.includes("witrequests")) {
        return {
            text: { visible: true},
            destination: { visible: true },
            actiontaken: { visible: true },
            timestamp: {visible: false}
        }
    } else if(document.location.pathname.includes("stringmatches")) {
        return {
            input: { visible: true},
            output: { visible: true },
            score: {visible: true},
            timestamp: {visible: false}
        }
    } else {
        return {
            id: { visible: false},
            name: { visible: true },
            timestamp: {visible: false}
        }
    }
}

class _AdminCollectionPage extends React.Component<AdminCollectionPageProps, AdminCollectionPageState> {
    constructor(props: AdminCollectionPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {
        return (
            <AdminPage fluid>
                <RestApiCollection uri={`/v2${document.location.pathname}`} fields={getDefaultFields()}/>
            </AdminPage>
        )
    }
}
export const AdminCollectionPage = withMultiverseApi(_AdminCollectionPage);

export default AdminCollectionPage;
  