import { Alert } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import ConnectToOculusButton from '../connectToOculusButton';

const NotMergedAlert:FunctionComponent<WithMultiverseApiProps> = (props) => {
  const { multiverse: { user } } = props;
  if (user && !user.oculusaccount) {
    return (
      <Alert variant="warning" className="my-4">
        <ConnectToOculusButton />
      </Alert>
    );
  }
  return null;
};

export default withMultiverseApi(NotMergedAlert);
