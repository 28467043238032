import React, { ChangeEventHandler, ReactNode, useState, useEffect, useRef, CSSProperties } from 'react';
import { Card, Button, Image, Row, Col, Container, FormControl, Spinner, OverlayTrigger, Tooltip, Dropdown, Accordion } from 'react-bootstrap';
import AspectRatioBox from '../aspectRatioBox';
import { useTimeout, useInterval } from '../../utils/hooks';
import api from '../../utils/api';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { FileDrop } from 'react-file-drop';
import ContentBox from '../contentBox';
import '../discoveryContentCard/discoverycontentcard.css'
import { BsFillCaretDownFill } from 'react-icons/bs';
import IsDeveloper, { IsNotDeveloper } from '../isDeveloper';
import { useEntity, onEntityUpdated, postEntity, onEntityDirty } from '../../utils/entitycache';
import { Subtract } from 'utility-types';
import { EntityImageUploadBox } from '../entityImageUploadBox';


export function EntityTextAreaPropertyBox(props: {requireContent?: boolean,  className?: string, style?:CSSProperties, targeturl: string, fieldname: string, disabled?: boolean, bold?: boolean, title: string, description: string }) {

    const entity = useEntity<any>(props.targeturl)
    const [dirty,setDirty] = useState<number>(0)
    const [value,setValue] = useState<string>("")
    const [loading,setLoading] = useState<boolean>(false)

    useEffect(() => {
        if(entity && dirty === 0) {
            setValue(entity[props.fieldname])
        }
    },[entity])

    useInterval(() => {
        if(dirty && (Date.now()-dirty) > 1500) {
            setDirty(0)
            setLoading(true)
            postEntity(props.targeturl,{[props.fieldname]:value})
            .then(()=>{})
            .catch((err)=>console.log(err))
            .then(()=>setLoading(false))
        }
    }, 1000)


    //on change, store new value and reset ticks
    const onChange = (target: React.ChangeEvent<HTMLInputElement>) => {
        setValue(target.currentTarget.value)
        setDirty(Date.now())
    }

    const renderTooltip = (ttprops: any) => (
        <Tooltip id="button-tooltip" {...ttprops}>
            {props.description}
        </Tooltip>
    );

    
    let className = "dcc-content-box dcc-image-box";
    if(props.className) {
        className += " " + props.className;
    }
    if(props.requireContent && !value) {
        className += " dcc-error"
    }

    return (
        <div className={className} style={props.style}>
            <div className="dcc-title">
                {props.bold && <strong>{props.title}</strong>}
                {!props.bold && <span>{props.title}</span>}
                <OverlayTrigger placement="top-start" delay={{ show: 0, hide: 400 }} overlay={renderTooltip}>
                    <span> <u>(?)</u></span>
                </OverlayTrigger>
            </div>
            <div>
                <FormControl 
                    as="textarea" 
                    disabled={props.disabled || false}
                    rows={5} 
                    placeholder="Description" 
                    onChange={onChange}
                    value={value}/>

                {loading && <div className="spinner-container">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>}
            </div>
        </div>
    )
}