import React, { useEffect, useState } from 'react';
import { MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Image, Accordion, Card, Col, Container, Row, Button, Form } from 'react-bootstrap';
import PageTable, { PageTableCell, PageTableRow } from '../pageTable';
import api from '../../utils/api';
import "./restapi.css"


export function UserDomainsRow(props: {userid: string, onvisit: (id:string)=>void}) {
    const [domains, setDomainss] = useState<(MultiverseDomain & {_id:string})[] | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/users/${props.userid}/domains`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setDomainss(x))
        .catch(err => console.log(err))

    }, [props.userid])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        User Metaverses
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="restapi-documents-col">                       
                        <>
                            {domains && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th></th>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Uri</th>
                                        <th>Plan</th>
                                        <th>Personal</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {domains.map(x => (
                                        <PageTableRow key={x.uri}>
                                            <PageTableCell><Button variant="primary" onClick={() => { props.onvisit(x._id) }}/></PageTableCell>
                                            <PageTableCell>{x._id}</PageTableCell>
                                            <PageTableCell>{x.name}</PageTableCell>
                                            <PageTableCell>{x.uri}</PageTableCell>
                                            <PageTableCell>{x.plan}</PageTableCell>
                                            <PageTableCell>{x.ispersonal}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}

export function DomainLocationsRow(props: {domainid: string, onvisit: (id:string)=>void}) {
    const [locations, setLocations] = useState<(MultiverseLocation & {_id:string})[] | null>(null)

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/domains/${props.domainid}/locations`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setLocations(x))
        .catch(err => console.log(err))

    }, [props.domainid])


    return (
    <Row>
        <Col className="restapi-documentfields-col">
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        Metaverse Locations
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="restapi-documents-col">                       
                        <>
                            {locations && <PageTable>
                                <thead>
                                    <PageTableRow>
                                        <th></th>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Nickname</th>
                                        <th>Visibility</th>
                                    </PageTableRow>
                                </thead>
                                <tbody>
                                    {locations.map(x => (
                                        <PageTableRow key={x.name}>
                                            <PageTableCell><Button variant="primary" onClick={() => { props.onvisit(x._id) }}/></PageTableCell>
                                            <PageTableCell>{x._id}</PageTableCell>
                                            <PageTableCell>{x.name}</PageTableCell>
                                            <PageTableCell>{x.nickname}</PageTableCell>
                                            <PageTableCell>{x.visibility}</PageTableCell>
                                        </PageTableRow>
                                    ))}
                                </tbody>
                            </PageTable>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Col>
    </Row>)    
}
