import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import DialogBox from '../../components/dialogBox';
import ValidatedField from '../../components/validatedField';

export function SelectYoutubeVideoDialog(props: { show: boolean, onComplete: (val?: string)=>void }) {

    const [text, setText] = useState<string>("")
    const [valid, setValid] = useState<boolean>(false)

    const onChange = (newText: string,newValid: boolean) => {
        setText(newText)
        setValid(newValid)
    }

     return (
     <DialogBox 
        title="Select Youtube Video" 
        show={props.show} 
        onCancel={() => props.onComplete()} 
        onConfirm={() => props.onComplete(text)} 
        closeButton
        button0Text="Ok"
        cancelButtonText="Cancel"
        disableConfirm={!valid}
        >
            Enter a youtube video URL, eg https://www.youtube.com/watch?v=123456789
            <ValidatedField regex={/https:\/\/(www\.)?(youtube\.com\/(watch\?v=|live\/)|youtu\.be\/)[\w-]+/} value={text} onChange={onChange}/>

    </DialogBox>)
}

