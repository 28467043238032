import React, { FunctionComponent } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import DialogBox from '../../components/dialogBox';
import HeaderCard from '../../components/headerCard';
import LaunchCard from '../../components/launchCard';
import Profile from '../../components/profile';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { MultiverseUser, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { getIconSrc } from '../../utils/entitycache';
import DomainsTable from '../domainsPage/domainstable';
import "./profilesettings.css"

type ProfilePageProps = {

} & WithMultiverseApiProps;

type ProfilePageState = {
    error?: string;
};

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
    constructor(props: ProfilePageProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async() => {
        
    }

    onSetIcon = async(icon: File) => {
        const { multiverse: { user } } = this.props;
        const location = await this.props.multiverse.post<MultiverseUser>({
            url: `/v2/users/${user!.id}/icon`,
            body: icon,
            headers: {
                'Content-Type': icon.type,
                'x-shapevr-name': icon.name,
            }
        })
        await this.props.multiverse.updateUser();
    }
    onSetBanner = async(banner: File) => {
        const { multiverse: { user } } = this.props;
        const location = await this.props.multiverse.post<MultiverseUser>({
            url: `/v2/users/${user!.id}/banner`,
            body: banner,
            headers: {
                'Content-Type': banner.type,
                'x-shapevr-name': banner.name,
            }
        })
        await this.props.multiverse.updateUser();
    }
    onSetTitle = async(title: string) => {
        const { multiverse: { user } } = this.props;
        const location = await this.props.multiverse.post<MultiverseUser>(`/v2/users/${user!.id}`, {
            nickname: title
        })
        await this.props.multiverse.updateUser();
    }

    render() {
        const { multiverse: { user } } = this.props;

        return (
            <BasicPage className="profile-settings">  

                {user && <HeaderCard
                    iconUrl= {getIconSrc(user)}
                    bannerUrl= {user.bannerblob ? `${getBlobUrl()}/${user.bannerblob}` : `${getApiUrl()}/v2/users/${user.id}/banner` }
                    title={user.nickname || ""} 
                    editable={true} 
                    onSetBanner={this.onSetBanner}
                    onSetIcon={this.onSetIcon}
                    onSetTitle={this.onSetTitle}>
                    {user.oculusaccount && <span>Oculus account linked</span>}
                    {!user.oculusaccount && <span>Launch In VR to link your Oculus account</span>}
                </HeaderCard>}
                <LaunchCard/>              
                <Card className="mt-2">
                    <Card.Header>My Metaverses</Card.Header>
                    <Card.Body className="p-1">
                        <DomainsTable group/>
                    </Card.Body>
                </Card>        

            </BasicPage>
        )
    }

}

export default withMultiverseApi(ProfilePage);
