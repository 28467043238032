import { FileDrop } from "react-file-drop";
import React, { useRef, useMemo, useState } from "react";
import './imagedropper.css'
import { Dropdown, Alert } from "react-bootstrap";
import { BsFillCaretDownFill } from "react-icons/bs";

const WARNING_TOLERANCE = 0.01;

type ImageDropperProps = {
    value: File | null;
    disabled?: boolean;
    onChange: (file: File | null, isValid: boolean) => void;
    aspectRatio? : string;
    aspectRatioWarning? : React.ReactNode;
    maxBytes? : number; 
    maxBytesError? : React.ReactNode;
}

const getAspectRatioFromDataUrl = (dataURL:string):Promise<number> => new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
        console.log({w:img.width, h:img.height})
      resolve(img.width / img.height)
    }
    img.src = dataURL
  })

export default function ImageDropper(props: ImageDropperProps) {
    const { value, onChange, disabled } = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileAspectRatio, setAspectRatio] = useState<number| null>(null);

    const fileSize = useMemo(() => {
        if (value) {
            return value.size;
        }
        return null;
    }, [value]);


    const srcUrl = React.useMemo<string | undefined>(() => {
        if (value) {
            return  (URL.createObjectURL(value))
        } else {
            return (undefined)
        }
    }, [value])


    React.useEffect(()=>{
        if(!srcUrl) return;
        getAspectRatioFromDataUrl(srcUrl).then((ratio:number) => {
            return setAspectRatio(ratio);
        })
    }, [srcUrl]);
    
    const aspectRatioValue = props.aspectRatio ? calculateRatioValue(props.aspectRatio) : undefined;

    const aspectRatioDifference = aspectRatioValue && fileAspectRatio ? Math.abs(aspectRatioValue - fileAspectRatio) : undefined;
    const showAspectRatioWarning = (props.aspectRatioWarning && aspectRatioDifference) ? aspectRatioDifference > WARNING_TOLERANCE : false;

    const overSizeLimit = (props.maxBytes && fileSize) ? fileSize > props.maxBytes : false;

    return <>
        <input 
            ref={fileInputRef}
            className="d-none"
            type="file"
            disabled={disabled}
            onChange={(e: any) => {
                if (e.target.files) {
                    onChange(e.target.files[0], props.maxBytes === undefined || e.target.files[0].size < props.maxBytes);
                }
            }} />
        <FileDrop
            className={`file-drop image-dropper ${disabled ? "file-drop-disabled" : ""}`}
            onDrop={(files) => !disabled && onChange(files && files[0], files !== null && (props.maxBytes === undefined || files[0].size < props.maxBytes))}
        >
            <Dropdown className="options-dropdown" dir="right">
                <Dropdown.Toggle className="togglebutton" variant="light" id="options-dropdown">
                    <BsFillCaretDownFill size="20px" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item disabled={disabled} onClick={() => fileInputRef.current?.click()}>Upload File</Dropdown.Item>
                    <Dropdown.Item disabled={value === null || disabled} onClick={() => onChange(null, false)}>Clear</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <img src={srcUrl}  className={`img-thumbnail ${srcUrl ? '' : 'd-none'}`} style={props.aspectRatio ? {
                aspectRatio: props.aspectRatio,
                objectFit: 'cover',
                width: '100%',
                maxHeight: 300, /*fallback for browsers that don't support object-fit*/
            } : {}}/>
            {showAspectRatioWarning && <Alert variant="warning">{props.aspectRatioWarning}</Alert>}
            {overSizeLimit && <Alert variant="warning">{props.maxBytesError}</Alert>}
            {!srcUrl && <div className="img-thumbnail empty-state">
                <span>Drop your billboard image here</span>
            </div>}
        </FileDrop>
    </>
}

function calculateRatioValue(aspectRatio: string) {
    return parseInt(aspectRatio.split('/')[0]) / parseInt(aspectRatio.split('/')[1]);
}
