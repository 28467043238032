import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import AdminPage from '../../components/adminPage';
import { RestApiDocument } from '../../components/restApi';

type AdminDocumentPageProps = {

} & WithMultiverseApiProps;

type AdminDocumentPageState = {
};

class _AdminDocumentPage extends React.Component<AdminDocumentPageProps, AdminDocumentPageState> {
    constructor(props: AdminDocumentPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {
        return (
            <AdminPage>
                <RestApiDocument  >

                </RestApiDocument>
            </AdminPage>
        )
    }
}
export const AdminDocumentPage = withMultiverseApi(_AdminDocumentPage);

export default AdminDocumentPage;
