import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import DialogBox from '../dialogBox';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner';
import { Spinner, Col } from 'react-bootstrap';

export type MergeAccountsRequest = {
}

export default function MergeAccountsDialog(props: { currentRequest?: MergeAccountsRequest, onClose: () => void }) {

    const [visible,setVisible] = useState(false);
    const [loading,setLoading] = useState(true);
    const [mergeResponse,setMergeResponse] = useState<any | null>(null);

    
    /*
   
    const checkBillingInfoNeeded = async() => {
        const nbi = await api.get<{required: boolean}>("/v2/stripe/needsbillinginfo");
        if(nbi.required) {
            setVisible(true)
        }
    }*/

    const onConfirm = async() => {
        props.onClose();
    }

    const load = async() => {
        setLoading(true);
        const res = await api.get<any>("/v2/me/usermerge/checkmergeoptions")
        console.log(res);
        setMergeResponse(res);
        setLoading(false);
    }
    
    useEffect(() => {
        setVisible(props.currentRequest !== undefined);        
        if(props.currentRequest) {
            load();
        }
    }, [props.currentRequest])

    const isBusy = ()=>loading;

    const mergeDialog = () => {
        if(!mergeResponse) {
            return <></>
        }
        console.log(mergeResponse)

        const email = mergeResponse.email;
        const canMerge = mergeResponse.canMerge;
        const num_accounts = mergeResponse.accounts.length;
        const failReason = mergeResponse.failReason;
        
        if(canMerge) {
            return <p className="mt-1 mb-0">
                We have detected that you have {num_accounts} accounts using the email '{email}' (including this one) that can be consolidated into one. We've sent 
                you an email with instructions on how to do this, and a link that will activate the merge process.
                If you've changed your mind, just ignore the email and no changes will be made.
            </p>            
        } else if(failReason == 'no_accounts') {
            return <p className="mt-1 mb-0">
                We have not found any other accounts using the email '{email}'. Please ensure you're logged in with the correct email,
                or contact us if you believe this is an error.
            </p>               
        } else if(failReason == 'oculus') {
            return <p className="mt-1 mb-0">
                We've detected {num_accounts} accounts using the email '{email}' (including this one), however more than 1 of them is linked to an Oculus account. Oculus
                accounts can not currently be merged. Please contact us if you need further assistance.
            </p>   
        } else if(failReason == 'paid') {
            return <p className="mt-1 mb-0">
                We've detected {num_accounts} accounts using the email '{email}' (including this one), however more than 1 of them is linked to a paid subscription. To 
                merge these accounts, you'll need to contact us to help move all your paid Metaverses into 1 place.
            </p>   
        } else if(failReason == 'wip') {
            return <p className="mt-1 mb-0">
                We're working on support for account merging, but haven't enabled it for this account yet. We'll email '{email}' when it's ready to use.
            </p>   
        }else {
            return <p className="mt-1 mb-0">
                An unknown error occured attempting to merge your accounts. Please contact us for assistance.
            </p>   
        }
    }

    const showButton = (mergeResponse && mergeResponse.canMerge);

    return visible ? (
    <DialogBox title="Merge Accounts" show 
        button0Text={showButton ? "Continue" : undefined} 
        cancelButtonText={!showButton ? "Cancel" : undefined} onConfirm={onConfirm} onCancel={onConfirm}>
        {isBusy() ? 
        <Col style={{textAlign:'center'}}>
            <Spinner animation="border" role="status" />
            <p className="mt-3 mb-0">
                Searching for mergable accounts...
            </p>
        </Col> : 
        <Col>
            {mergeDialog()}
        </Col>}
    </DialogBox>)
    : ( 
    <></>
    )
}
