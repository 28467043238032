import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import "./restapi.css"

export const PERMISSIONS: any = {
    "search_for_hidden": "Can search for hidden locations",
    "search_for_devonly": "[Deprecated] Can search for developer only locations",
    "search_for_unpublished": "Can search for unpublished locations",
    "join_locked": "Can join locked rooms",
    "create_domains": "Can create new domains",
    "create_events": "Can create events",
    "manage_domain": "Can manage ALL domains",
    "guest": "[Deprecated] Is a domain guest",
    "host": "Is a domain host in ALL domains",
    'developer': "Is a developer (can access dev menus)",
    'hideslacktelemetry': "[Deprecated] Whether to prevent showing of telemetry in slack",
    'slack': "Can post data to slack (eg screenshots)",
    'debugservice': "Submits logs / events to debug service",
    'superuser': "Full super user controls",
    'tester': "Access to test only features",
    'stream': "Can stream in ALL domains",
    'search_all_domains': "Can search in any domain",
    'administrator': "Can access admin front end",
    "infinite_domains": "Can create unlimited free metaverses",
    'access_domain_drives': "Has access to the drive folders for metaverses",
    'access_upload_drive': "Has access to an uploads folder",
    'create_locations': "Can create new metaverse locations",
    'voice_control': "Can use in-app voice controls",
    "can_use_access_token": "Can use api access token",
    "can_buy_any_store": "Can buy any store in-app regardless of cost",
    'admin_edit_permissions': "Can edit user permissions",
    'admin_read_entities': "Can read database entities such as users, locations and rooms",
    'admin_write_entities': "Can edit database entities such as users, locations and rooms",
    'admin_read_infiniverse': "Can read infiniverse data",
    'admin_write_infiniverse': "Can write infiniverse data",
    'admin_read_files': "Can list and view stored files",
    'admin_approvals': "Can manage approvals",
    'admin_build': "Can manage accepted build versions",
    'admin_stats_graphs': "Can view stats graphs",
    'admin_stats_spenders': "Can view personal spender stats",
    'admin_missions': "Can administer missions",
    'edit_readonly_entities': "Can edit entities that are marked as readonly"
}


export function UserAccessToken(props: {userid: string}) {
    const [accessToken, setAccessToken] = useState<any>({clienttoken:"", servertoken:""})

    const loadData = async() => {
        const res = await api.tryGet<any>(`/v2/admin/users/${props.userid}/accesstoken`)
        console.log(res)
        return res;
    }

    useEffect(() => {
        loadData()
        .then(x => setAccessToken(x))
        .catch(err => console.log(err))

    }, [props.userid])

    return <div>
        <br></br>
        Client Token<br></br><code style={{fontSize:"65%"}}>{accessToken.clienttoken}</code><br></br>
        Server Token<br></br><code style={{fontSize:"65%"}}>{accessToken.servertoken}</code><br></br>
    </div>
   
}
