import React, { FunctionComponent } from 'react';
import { Button, Col, FormControl, FormControlProps, Modal, Spinner } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiverseDomainMemberListResult, MultiversePlan, MultiverseUser, WithMultiverseApiProps,  } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import DialogBox from '../dialogBox';
import DialogBoxWithSpinner from '../dialogBoxWithSpinner'
import { IGetMemberSummaryResults } from '../domainFrontPage/memberBox';
import ValidatedField from '../validatedField';

type InviteMembersDialogProps = {
    show?: boolean;
    onComplete?: (newMembers: MultiverseDomainMemberListResult[]) => void;
    onCancel?: () => void;
} & WithMultiverseApiProps & RouteComponentProps;

type InviteMembersDialogState = {
    inviteMembersText: string;
    inviteMembersValid: boolean;
    isLoading: boolean;
    plan?: MultiversePlan;
    memberSummary?: IGetMemberSummaryResults;
};

//minimal verification for comma separated list of email addresses 
const EMAIL_LIST_REGEX = /^(?:[^\,]+\@[^\,]+\,*)+$/

//simple dialog box
class InviteMembersDialog extends React.Component<InviteMembersDialogProps, InviteMembersDialogState> {
    constructor(props: InviteMembersDialogProps) {
        super(props);
        this.state = {
            inviteMembersText: "",
            inviteMembersValid: false,
            isLoading: true
        };
    }

    //mounting just gets the members list
    async componentDidMount() {
        const { multiverse: { get, getCurrentDomainId } } = this.props;
        try {
            const plan = await get<MultiversePlan>(`/v2/domains/${getCurrentDomainId()}/plan`);
            const summary = await get<IGetMemberSummaryResults>(`/v2/domains/${getCurrentDomainId()}/membersummary`);
            this.setState({
                plan,
                memberSummary: summary
            })
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                isLoading: false
            })
        }
    }

    renderInvite = () => {
        const { show, domain } = this.props;
        const { inviteMembersText, inviteMembersValid } = this.state;

        return (
            <DialogBoxWithSpinner title={`Invite Members`} show={show} button0Text="Invite" cancelButtonText="Cancel" onCancel={this.inviteMemberDialog_Cancel} onConfirm={this.inviteMemberDialog_Confirm} disableConfirm={!inviteMembersValid}>
            <p>Please enter the email addresses of the people you'd like to invite to <strong>{domain?.name}</strong> separated by commas.</p>
            <p>Each will be sent a unique link to join, and will be automatically asked to connect to Multiverse if they don't already have an account.</p>
            <ValidatedField value={inviteMembersText} regex={EMAIL_LIST_REGEX} onChange={this.inviteMemberDialog_InputChange} errorColor="white" />
        </DialogBoxWithSpinner>
        )        
    }

    /*
    renderCantInvite = () => {
        const { show, domain } = this.props;
        return (<DialogBox 
            title="Inviting Members" 
            show={show}
            onCancel={this.inviteMemberDialog_Cancel}
            onConfirm={(x:any)=>{this.props.history.push(`/domains/${domain!.uri}/upgrade`)}}
            button0Text="Upgrade"
            cancelButtonText="Cancel"
            >
            <p>Metaverses on a none-business plan can only invite members from the Multiverse App. To do so, open your wrist menu, select the player and click 'invite'.</p>
            <p>To get the full power of invites through emails and links, click upgrade and move to a business or enterprise plan!</p>
        </DialogBox>)
    }*/

    renderCantInviteDueToFull = () => {
        const { show, domain } = this.props;
        return (<DialogBox 
            title="Inviting Members" 
            show={show}
            onCancel={this.inviteMemberDialog_Cancel}
            onConfirm={this.inviteMemberDialogUpgrade}
            button0Text="Upgrade"
            cancelButtonText="Cancel"
            >
            <p>You can't add any more members to your metaverse on your current plan. You can open up your metaverse to everyone on the <Link to={`/domains/${domain?.uri}/manage`}>Manage</Link> page, or upgrade now! </p>
        </DialogBox>)
    }

    renderCantInviteByEmail = () => {
        const { show, domain } = this.props;
        return (<DialogBox 
            title="Inviting Members" 
            show={show}
            onCancel={this.inviteMemberDialog_Cancel}
            onConfirm={this.inviteMemberDialogUpgrade}
            button0Text="Upgrade"
            cancelButtonText="Cancel"
            >
            <p>Only business and enterprise metaverses can use invite-by-email. Visit the <Link to={`/domains/${domain?.uri}/manage`}>Manage</Link> page to find your <strong>invite link</strong>, or upgrade now!</p>
        </DialogBox>)
    }

    render() {
        if(!this.state.plan || !this.state.memberSummary) {
            return <DialogBox title="Invite Members"/>
        } else if(this.state.plan.maxMembers > 0 && this.state.plan.maxMembers <= this.state.memberSummary.count) {
            return this.renderCantInviteDueToFull();
        } else if(!this.state.plan.canInviteByEmail) {
            return this.renderCantInviteByEmail();
        } else {
            return this.renderInvite();
        }
    }

    //invite dialog cancel
    inviteMemberDialog_Cancel = () => {
        this.setState({
            inviteMembersText: ""
        })
        if(this.props.onCancel) {
            this.props.onCancel();
        }
    }

    //invite dialog cancel
    inviteMemberDialogUpgrade = () => {
        const { domain } = this.props;
        this.props.history.push(`/domains/${domain?.uri}/upgrade`)
    }
     //invite dialog confirm attempts to invite the members, then refreshes the member list
    inviteMemberDialog_Confirm = async (button?: 0 | 1) => {
        const { multiverse: { get, post }, domain } = this.props;
        const { inviteMembersText } = this.state;

        try {
            //check domain is valid
            if (!domain) {
                throw new Error("Invalid domain");
            }

            //add members then get member list
            const add_res = await post(`/v2/domains/${domain.id}/invite/singleuse`, {
                emails: inviteMembersText.split(",")
            })
            const new_members = await get<MultiverseDomainMemberListResult[]>(`/v2/domains/${domain.id}/members`);
            if(this.props.onComplete) {
                this.props.onComplete(new_members);
            }
        } catch (err) {
            if(this.props.onCancel) {
                this.props.onCancel();
            }           
        } finally {
            this.setState({
                inviteMembersText: "",
            })
        }
    }

    //change event for the email list box 
    inviteMemberDialog_InputChange = (val: string, isvalid: boolean) => {
        this.setState({
            inviteMembersText: val,
            inviteMembersValid: isvalid
        })
    }

}

export default withRouter(withMultiverseApi(InviteMembersDialog));
