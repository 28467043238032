import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import BasicPage from '../../components/basicPage';
import { Tabs, Tab, Row, Col, Button, Form, Dropdown, TabProps } from 'react-bootstrap';
import { CanContainChildProduct, MultiverseMissionEventConfig, MultiverseMissionEventConfigCreateArgs, MultiverseMissionEventCreateArgs, MultiverseMissionSettingsCreateArgs } from '../../hoc/multiverseApiProvider';
import PageTable, { PageTableCell } from '../../components/pageTable';
import { useGetAll, setDataFromUrl, useGet, TGet } from '../../utils/api';
import { Link, matchPath, useHistory } from 'react-router-dom';
import { formatDateTime, formatTime, shortDayOfWeek } from '../../utils/dates';

type IdMatchParams = {
    id: string;
}

type TEventConfigDoc = {
    id: string,
    [nm:string]: any
} & MultiverseMissionEventConfigCreateArgs

type TEventConfigQuery = CanContainChildProduct;

type TSettingsDoc = {
    id: string,
    [nm:string]: any
} & MultiverseMissionSettingsCreateArgs

type TSettingsQuery = CanContainChildProduct

let configs: TEventConfigDoc[] | null = null;
let settings: TSettingsDoc | null = null;
let futureEvents: TEventDoc[] | null = null;
let pastEvents: TEventDoc[] | null = null;

function EventConfigTable(props: {        
    query: TEventConfigQuery, 
    emptyMessage: string }) {
    const history = useHistory();
    const { query } = props;

    const url_product_prefix = `/admin/childproducts/${query.child_product}`;

    function onNewConfigClick() {
        const new_config_url = `${url_product_prefix}/missioneventconfig`;
        history.push(new_config_url);
    }

    return (
        <>
        {configs &&
        <>
        <Row className="mb-2 gap-above-children">
            <Col>
                <Button variant='outline-primary' type='button' onClick={onNewConfigClick}>New</Button>
            </Col>
        </Row>
        <PageTable className="mb-0">
            <thead>
                <PageTable.Row>
                    <th>Name</th>
                    <th>Display Name</th>
                    <th>Icon</th>
                    <th>Environment</th>
                    <th>Modes</th>
                    <th>Importance</th>
                </PageTable.Row>
            </thead>
            <tbody>
                {configs.map((x) => {
                    const url_suffix = `/missioneventconfig/${x.name}`
                    const url: string = `${url_product_prefix}${url_suffix}`
                    return (
                        <PageTable.Row key={x.id}>
                            <PageTable.InfoCell title={x.name} subtitle={<Link to={url}>{url_suffix}</Link>}/>
                            <PageTableCell key="display_name">{x.display_name}</PageTableCell>
                            <PageTableCell key="icon">{x.icon}</PageTableCell>
                            <PageTableCell key="environment">{x.environment}</PageTableCell>
                            <PageTableCell key="modes">{
                                x.any_mode || x.no_mode
                                ? (
                                    x.any_mode && x.no_mode ? "All"
                                    : x.any_mode ? "Any" : "None"
                                )
                                : x.modes ? x.modes.join(", ") : "" 
                            }</PageTableCell>
                            <PageTableCell key="importance">{x.importance}</PageTableCell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>
        </>}
        {
            props.emptyMessage && configs && configs.length === 0 && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

type TEventDoc = {
    id: string,
    [nm:string]: any
} & MultiverseMissionEventCreateArgs

type TEventQuery = CanContainChildProduct & { 
    direction: string,
    isUtc: boolean,
    setIsUtc: Dispatch<SetStateAction<boolean>>
}

function EventTable(props: { query: TEventQuery, emptyMessage: string }) {
    const history = useHistory();
    const { query } = props;
    const { isUtc, setIsUtc } = query;

    const events = query.direction === "future" ? futureEvents : pastEvents;

    const url_product_prefix = `/admin/childproducts/${query.child_product}`;

    function onNewItemClick() {
        const new_item_url = `${url_product_prefix}/missionevent`;
        history.push(new_item_url);
    }    

    function handleIsUtcChange() {
        setIsUtc(!isUtc);
    };

    return (
        <>
        {events &&
        <>
        <Row className="mb-2 gap-above-children">
            <Col>
                <Button variant='outline-primary' type='button' onClick={onNewItemClick}>New</Button>
            </Col>
        </Row>        
        <PageTable className="mb-0">
            <thead>
                <PageTable.Row>
                    <th><Form.Check
                        type="checkbox"
                        label="Use UTC"
                        checked={isUtc}
                        onChange={handleIsUtcChange}
                    /></th>
                    <th>Start Date ({isUtc ? "UTC": "Local"})</th>
                    <th>End Date ({isUtc ? "UTC": "Local"})</th>
                    <th>Config</th>
                    <th>Helicopter</th>
                </PageTable.Row>
            </thead>
            <tbody>
                {events.map((x) => {
                    const url_prefix = `/admin/childproducts/${query.child_product}`
                    const item_url_suffix = `/missionevent/${x.id}`
                    const item_url: string = `${url_prefix}${item_url_suffix}`
                    const config = configs && configs.find(y => y.id === x.mission_event_config)
                    const config_url_suffix = config
                        ? `/missioneventconfig/${config.name}`
                        : `/missioneventconfig/${x.mission_event_config}`
                    const config_url = `${url_prefix}${config_url_suffix}`
                    return (
                        <PageTable.Row key={x.id}>
                            <PageTableCell key="details"><Link to={item_url}>details</Link></PageTableCell>
                            <PageTableCell key="startdate">{formatDateTime(x.startdate, {isUtc:isUtc})}</PageTableCell>
                            <PageTableCell key="enddate">{formatDateTime(x.enddate, {isUtc:isUtc})}</PageTableCell>
                            {config ? (
                                <PageTable.InfoCell title={config?.display_name} subtitle={<Link to={config_url}>{config_url_suffix}</Link>}/>
                            ) : (
                                <PageTableCell key="config">{x.mission_event_config}</PageTableCell>
                            )}
                            <PageTableCell key="helicopter">{getHelicopter(x.option)}</PageTableCell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>
        </>}
        {
            props.emptyMessage && events && events.length === 0 && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

function EventCycleTable(props: { query: TSettingsQuery, emptyMessage: string }) {
    const { query } = props;

    const history = useHistory();

    const url_product_prefix = `/admin/childproducts/${query.child_product}`;

    function onNewCycleClick() {
        const new_cycle_url = `${url_product_prefix}/missioneventcycle`;
        history.push(new_cycle_url);
    }

    return (
        <>
        {settings &&
        <>
        <Row className="mb-2 gap-above-children">
            <Col>
                <Button variant='outline-primary' type='button' onClick={onNewCycleClick}>New</Button>
            </Col>
        </Row>            
        <PageTable className="mb-0">
            <thead>
                <PageTable.Row>
                    <th></th>
                    <th>Event Config</th>
                    <th>Day of Week (UTC)</th>
                    <th>Start Time (UTC)</th>
                    <th>End Time (UTC)</th>
                    <th>Helicopter</th>
                </PageTable.Row>
            </thead>
            <tbody>  
                {settings.event_cycles && settings.event_cycles.map((x) => {
                    const cycle_url_suffix = `/missioneventcycle/${x._id}`
                    const cycle_url = `${url_product_prefix}${cycle_url_suffix}`

                    const config_url_suffix = `/missioneventconfig/${x.mission_event_config_name}`
                    const config_url: string = `${url_product_prefix}${config_url_suffix}`

                    const config_display_name = configs ? configs.find(y => y.name === x.mission_event_config_name)?.display_name : x.mission_event_config_name;
                    return (
                        <PageTable.Row key={x._id} className={x.disabled ? 'disabled-row' : ''}>
                            <PageTableCell key="details"><Link to={cycle_url}>details</Link></PageTableCell>
                            <PageTable.InfoCell title={config_display_name} subtitle={<Link to={config_url}>{config_url_suffix}</Link>}/>
                            <PageTableCell key="day_of_week_utc">{shortDayOfWeek(x.day_of_week_utc)}</PageTableCell>
                            <PageTableCell key="start_time_utc">{formatTime(x.start_time_utc)}</PageTableCell>
                            <PageTableCell key="end_time_utc">{formatTime(x.end_time_utc)}</PageTableCell>
                            <PageTableCell key="helicopter">{getHelicopter(x.option)}</PageTableCell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>
        </>}
        {
            props.emptyMessage && configs && configs.length === 0 && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}    

const getHelicopter = (option?: number) => {
    switch (option) {
        case undefined: return "";
        case 0: return "left";
        case 1: return "right";
        default: return "unknown";
    }
}

export function AdminMissionEventsPage(props: {}) {

    const history = useHistory();
    const match = matchPath<IdMatchParams>(history.location.pathname, {
        path: '/admin/childproducts/:id',
        exact: false
    })
    const child_product = match?.params.id;

    const configsUrl = `/v2/admin/childproducts/${child_product}/missions/events/configs`
    const settingsUrl = `/v2/admin/childproducts/${child_product}/missions/settings`
    const futureEventsUrl = `/v2/admin/childproducts/${child_product}/missions/events?direction=future`
    const pastEventsUrl = `/v2/admin/childproducts/${child_product}/missions/events?direction=past`

    const [configData, setConfigData] = useState<{mission_event_configs:TEventConfigDoc[]} | null | undefined>(undefined);
    const [settingsData, setSettingsData] = useState<TSettingsDoc | null | undefined>(undefined);
    const [futureEventsData, setFutureEventsData] = useState<{mission_events:TEventDoc[]} | null | undefined>(undefined);
    const [pastEventsData, setPastEventsData] = useState<{mission_events:TEventDoc[]} | null | undefined>(undefined);

    let gets: TGet[] = [];

    gets.push({
        url: configsUrl,
        effect: () => setDataFromUrl(configsUrl, setConfigData),
        getData: () => { configs = configData?.mission_event_configs || [] }
    });

    gets.push({
        url: settingsUrl,
        effect: () => setDataFromUrl(settingsUrl, setSettingsData),
        getData: () => { settings = settingsData || null }
    });

    gets.push({
        url: futureEventsUrl,
        effect: () => setDataFromUrl(futureEventsUrl, setFutureEventsData),
        getData: () => { futureEvents = futureEventsData?.mission_events || [] }
    });

    gets.push({
        url: pastEventsUrl,
        effect: () => setDataFromUrl(pastEventsUrl, setPastEventsData),
        getData: () => { pastEvents = pastEventsData?.mission_events || [] }
    });

    useGetAll(gets);

    const [activeTab, setActiveTab] = useState("missioneventconfigs");
    const [isUtc, setIsUtc] = useState(false);

    // Load active tab from local storage on initial render
    useEffect(() => {
      const storedTab = localStorage.getItem('activeTab');
      if (storedTab) {
        setActiveTab(storedTab);
      }

      const isUtc = localStorage.getItem('isUtc');
      if (isUtc) {
        setIsUtc(isUtc === "true");
      }
    }, []);
  
    // Save active tab to local storage whenever it changes
    useEffect(() => {
      localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    // Save isUtc to local storage whenever it changes
    useEffect(() => {
        localStorage.setItem('isUtc', isUtc.toString());
    }, [isUtc]);
  
    const handleTabSelect = (eventKey: string | null, e: React.SyntheticEvent<unknown>) => {
        if (eventKey) {
            setActiveTab(eventKey);
        }
    };

    return (
        <BasicPage title="Mission Events">
            <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
                <Tab eventKey="missioneventconfigs" title="Configs">
                    <EventConfigTable query={{child_product: child_product}} emptyMessage="" />
                </Tab>
                <Tab eventKey="eventcycles" title="Cycles">
                    <EventCycleTable query={{child_product: child_product}} emptyMessage=""/>
                </Tab>
                <Tab eventKey="futureevents" title="Events">
                    <EventTable query={{
                        child_product: child_product,
                        direction: "future",
                        isUtc: isUtc,
                        setIsUtc: setIsUtc
                    }} emptyMessage=""/>
                </Tab>
                <Tab eventKey="pastevents" title="Event History">
                    <EventTable query={{
                        child_product: child_product,
                        direction: "past",
                        isUtc: isUtc,
                        setIsUtc: setIsUtc
                    }} emptyMessage=""/>
                </Tab>
            </Tabs>
        </BasicPage>
    )    
}
export default AdminMissionEventsPage;