import React from 'react'
import { useMultiverseContext } from '../../hoc/multiverseApiProvider'

export function HasPermission(props: React.PropsWithChildren<{ permission: string }>) {
    const context = useMultiverseContext()
    const has_permission = context.multiverse.user && context.multiverse.user.permissions && context.multiverse.user.permissions.includes(props.permission)
    return <>
        {has_permission && props.children}
    </>
}

export function HasNotPermission(props: React.PropsWithChildren<{ permission: string }>) {
    const context = useMultiverseContext()
    const has_permission = context.multiverse.user && context.multiverse.user.permissions && context.multiverse.user.permissions.includes(props.permission)
    return <>
        {has_permission && !props.children}
    </>
}