import React from 'react';
import { Subtract } from 'utility-types';
import { MultiverseApiContext, WithMultiverseApiProps } from './index';

type HOC<INJ extends Record<string, unknown>> = <P extends INJ>(Component: React.ComponentType<P>) => (props: Subtract<P, INJ>) => JSX.Element;

const withMultiverseApi: HOC<WithMultiverseApiProps> = <P extends WithMultiverseApiProps>(
  Component: React.ComponentType<P>,
) => function WithMultiverseApi(props: Subtract<P, WithMultiverseApiProps>):JSX.Element {
    return (
      <MultiverseApiContext.Consumer>
        {(contexts):JSX.Element => <Component {...props as P} {...contexts} />}
      </MultiverseApiContext.Consumer>
    );
  };

export default withMultiverseApi;
