import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import { Col, Row } from 'react-bootstrap';
import PurchaseDomain from '../../components/purchaseDomain';

type UpgradeDomainPageProps = {

} & WithMultiverseApiProps;

type UpgradeDomainPageState = {
    context?: string;
};

class _UpgradeDomainPage extends React.Component<UpgradeDomainPageProps, UpgradeDomainPageState> {
    constructor(props: UpgradeDomainPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {
        const url = new URL(document.location.href);
        console.log(url);
        const context = url.searchParams.get("context") || "upgrade"; 
        this.setState({context});
    }

    render(): JSX.Element {
        return (
            <BasicPage title={this.state.context === "upgrade" ? "Upgrade Metaverse" : "Change Plan"}>
                <Row>
                    <Col>
                        {this.state.context && <PurchaseDomain 
                            domain_id={this.props.multiverse.getCurrentDomainId()} 
                            title={this.state.context === "upgrade" ? "Upgrade to the plan that's right for you!" : "Change to the plan that's right for you!"}
                            context={this.state.context}
                        />}
                    </Col>
                </Row>

            </BasicPage>
        )
    }
}
export const UpgradeDomainPage = withMultiverseApi(_UpgradeDomainPage);

export default UpgradeDomainPage;
