import React from 'react';
import {  Button, Card, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap';
import BasicPage from '../../components/basicPage';
import { getApiUrl, getBlobUrl } from '../../config/api';
import { getDomainPlanInfo, MultiverseDomain, MultiverseDomainMemberListResult, MultiverseLocation, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import TestBanner from '../../assets/lobby_2048x512.jpg';
import OculusLogo from '../../assets/oculus-logo-transparent.png';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DomainFrontPage from '../../components/domainFrontPage'
import HeaderCard from '../../components/headerCard';
import './domainhomepage.css'
import MemberBox from '../../components/domainFrontPage/memberBox';
import DialogBox from '../../components/dialogBox';
import InviteMembersDialog from '../../components/inviteMembersDialog'
import LaunchCard from '../../components/launchCard';
import LocationsCard from '../../components/locationsCard';
import IsAuthenticated from '../../components/isAuthenticated';
import DiscoveryContentCard from '../../components/discoveryContentCard';
import EntityPage from '../entityPage';
import { getIconSrc } from '../../utils/entitycache';

type DomainHomePageProps = {
} & WithMultiverseApiProps & RouteComponentProps;

type DomainHomePageState = {
    isLoading: boolean;
    domain?: MultiverseDomain;
    requrestedDomainId?: string;
    showInviteMembers: boolean;
    joined: boolean
};

class DomainHomePage extends React.Component<DomainHomePageProps, DomainHomePageState> {
    constructor(props: DomainHomePageProps) {
        super(props);
        this.state = {
            isLoading: true,
            showInviteMembers: false,
            joined: false
        };
    }

    //on mounting, always refresh domain
    componentDidMount = async() => {
        this.refresh();
        const url = new URL(window.location.href);
        const joined = url.searchParams.get("joined");
        if (joined) {
            this.setState({
                joined: true
            })
        }
    }

    //on updating, if not currently loading, check if the requested domain matches the
    //current one (inferred from uri) and re-request if it has changed
    componentDidUpdate = async(prevProps: DomainHomePageProps, prevState: DomainHomePageState) => {
        if(!this.state.isLoading && this.state.requrestedDomainId !== this.props.multiverse.getCurrentDomainId()) {
            await this.refresh();
        }
    }

    //refresh attempts to load domain info that corresponds to current uri
    refresh = async() => {
        console.log("Refreshing ");
        try {
            const { multiverse: { get, getCurrentDomainId, isAuthenticated } } = this.props;

            //read domain id
            const id = getCurrentDomainId();
            if(!id) {
                throw new Error("Manage domain page should always have a domain id");
            }

            //store that loading, and record the new domain id we refer to
            this.setState({
                isLoading: true,
                requrestedDomainId: id
            })

            //load the domain
            const domain = await get<MultiverseDomain>(isAuthenticated ? `/v2/domains/${id}` : `/v2/public/domains/${id}` );
            if(!domain) {
                throw new Error("Failed to load ")
            }
            console.log(domain);

            //store updated domain and set loading to false
            this.setState({
                domain,
                isLoading: false
            });

        } catch(err) {
            console.log(err);
        }
    }

    /*
    onConnectButtonClicked = async () => {
        const { domainUri, domainName, inviteSecret, multiverse: { requestOculusLaunch, get } } = this.props;
        if (!domainUri || !domainName) {
            return;
        }

        requestOculusLaunch({
            title: `Launch ${domainName}`,
            domain: domainUri,
            location: "",
            inviteSecret: inviteSecret,
            prompt: "confirm"
        })
    }*/

    onSetIcon = async(icon: File) => {
        const domain = await this.props.multiverse.post<MultiverseDomain>({
            url: getIconSrc(this.state.domain),
            body: icon,
            headers: {
                'Content-Type': icon.type,
                'x-shapevr-name': icon.name,
            }
        })
        this.setState({
            domain: {
                ...this.state.domain,
               ... domain
            }
        })
    }
    onSetBanner = async(banner: File) => {
        const domain = await this.props.multiverse.post<MultiverseDomain>({
            url: `/v2/domains/${this.state.domain!.id}/banner`,
            body: banner,
            headers: {
                'Content-Type': banner.type,
                'x-shapevr-name': banner.name,
            }
        })
        this.setState({
            domain: {
                ...this.state.domain,
               ... domain
            }
        })

    }
    onSetTitle = async(title: string) => {
        const domain = await this.props.multiverse.post<MultiverseDomain>(`/v2/domains/${this.state.domain!.id}`, {
            name: title
        })
        this.setState({
            domain: {
                ...this.state.domain,
               ... domain
            }
        })
    }

    handleWelcomeDialogClose = () => {
        this.setState({
            joined: false
        })
    }

    rendeWelcomeDialog() {
        const { domain } = this.props;
        const { joined } = this.state;
        return (
            <DialogBox title="Domain Joined!" onCancel={this.handleWelcomeDialogClose} show={joined} closeButton >
                <p>Welcome to <b>{domain?.name}</b> (<Link to={`/domains/${domain?.uri}`}>{domain?.uri}</Link>)! Now that you're a member you can access it in the Multiverse app.</p>
                <p>To jump straight into <b>{domain?.name}</b> in VR, click the Launch button</p>
            </DialogBox>
        )
    }

    //invite members dialog box. has a validated input box for email list to invite
    renderInviteMemberDialogBox() {
        const { domain } = this.props;
        const { showInviteMembers } = this.state;
        return (
            <>
            <InviteMembersDialog show={showInviteMembers} onCancel={this.inviteMemberDialog_Cancel} onComplete={this.inviteMemberDialog_Complete}/>
            </>
        )
    }


    //invite dialog completion callbacks
    inviteMemberDialog_Complete = (newMembers: MultiverseDomainMemberListResult[]) => {
        this.setState({
            showInviteMembers: false
        })
    }
    inviteMemberDialog_Cancel = () => {
        this.setState({
            showInviteMembers: false
        })
    }

    onAddMemberClicked = () => {
        console.log("Add members clicked")
        this.setState({
            showInviteMembers: true
        })
    }

    onUpgradeClicked = () => {
        this.props.history.push(`/domains/${this.props.domain!.uri}/upgrade`);
    }
    
    render() {
        const { isLoading, domain } = this.state;

        const plan_info = getDomainPlanInfo(domain || {});
        const editable = domain && domain.domainPermissions?.includes("manage_domain");

        return (
            <>
                <IsAuthenticated>
                    {this.renderInviteMemberDialogBox()}
                </IsAuthenticated>
                {this.rendeWelcomeDialog()}
                <EntityPage/>
            </>
        )

    }
}

export default withRouter(withMultiverseApi(DomainHomePage));
