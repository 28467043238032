import React, { MouseEvent } from 'react';
import { Button, Col, Dropdown, Image } from 'react-bootstrap';
import { BsCameraVideo, BsImage, BsFolder, BsFileEarmark, BsInfo, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { getBlobUrl } from '../../config/api';
import driveapi, { TItemInfo } from '../../utils/driveapi';
import { DeleteButton, InfoButton, VerticalMenuButton } from '../circularButton';

const ItemStyle = `
    background-color: white;
    position: relative;
}`

//const FolderIcon = require('../../assets/folders/1908417 - directory folder open.svg') as string;
export type TItemProps = {
    onClick?: () => void;
    onDoubleClick?: ()=>void;
    selected?: boolean;
    showMenu?: boolean;
    large?: boolean;

    onInfoClicked?: ()=>void;
    onDeleteClicked?: ()=>void;
    disableInfo?: boolean;
    disableDelete?: boolean;    
} & TItemInfo;


export function DriveBrowserItemIcon(item: TItemInfo) {
    if(item.mimeType && item.mimeType.startsWith("image/")) {
        return <BsImage/>
    } else if( (item.mimeType && item.mimeType.startsWith("video/")) || item.mimeType === "application/x-mpegurl" || item.mimeType === "application/vnd.apple.mpegurl") {
        return <BsCameraVideo/>
    } else if(item.type === "directory") {
        return <BsFolder/>
    } else {
        return <BsFileEarmark/>
    }

}

export function DriveBrowseItemIconAndTitle(item: TItemProps) {

    let cn = "drive-browser-item-icontitle";
    if(item.large) {
        cn += " drive-browser-item-icontitle-large"
    }

     return <div className={cn}>
        {DriveBrowserItemIcon(item)}
        <div className="drive-browser-item-titletext">{item.name}</div>
        {item.showMenu && <div onClick={(ev)=>ev.stopPropagation()}>
            <Dropdown>
                <VerticalMenuButton as={Dropdown.Toggle} id ={`dropdowntoggle-${item.id}`}/>
                <Dropdown.Menu>
                    <Dropdown.Item disabled={item.disableInfo} onClick={item.onInfoClicked}><BsInfo/>Info</Dropdown.Item>
                    <Dropdown.Item disabled={item.disableDelete} onClick={item.onDeleteClicked}><BsTrash/>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>}
    </div>
}
function itemThumbnail(item: TItemProps) {
    return <div className="drive-browser-item-thumbnail">
        {item.thumbnailUri ? <Image referrerPolicy="no-referrer"  src={item.thumbnailUri}/> : <BsFileEarmark/> }
    </div>
}

function fileItem(item: TItemProps) {
    return (
        <div className="drive-browser-item-file square">
            <div className="square_content">
                <div className="drive-browser-item-margin">
                    <div className="drive-browser-item-content">
                        {itemThumbnail(item)}
                        {DriveBrowseItemIconAndTitle(item)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function folderItem(item: TItemProps) {
    return (
        <div className="drive-browser-item-folder">
            <div className="drive-browser-item-margin">
                <div className="drive-browser-item-content">
                    {DriveBrowseItemIconAndTitle(item)}
                </div>
            </div>
        </div>)
}

export function DriveBrowserItem(item: TItemProps): JSX.Element {
    const { name, id, onClick, onDoubleClick } = item;

    let cn = "drive-browser-item";
    if(item.selected) {
        cn += " drive-browser-item-selected";
    }

    return (
        <div 
            className={cn} 
            onClick={(ev: MouseEvent<HTMLDivElement>) => { ev.stopPropagation(); if(onClick) onClick(); }} 
            onDoubleClick={onDoubleClick}
            >

            {(item.type === "file" || item.type === "link") ? fileItem(item) : folderItem(item)}
            
        </div>
    );
}

export default DriveBrowserItem;
