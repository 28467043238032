import React from 'react';
import BasicPage from '../../components/basicPage';
import { getIconSrc, useCurrentDomainUri } from '../../utils/entitycache';
import { DomainEntityTable } from '../../components/domainEntityTable';
import { Tabs, Tab } from 'react-bootstrap';
import { MultiverseBaseEntity, MultiverseEvent } from '../../hoc/multiverseApiProvider';
import PageTable, { PageTableCell } from '../../components/pageTable';
import { useGet } from '../../utils/api';
import { getBlobUrl, getApiUrl } from '../../config/api';
import { Subtract } from 'utility-types';
import { Link } from 'react-router-dom';
import { TOculusEvent } from '../entityPage/oculusEventSelectDialog';

type TEventDoc = {
    _id: string,
    [nm:string]: any
} & Subtract<MultiverseEvent,{id:string}>

function EventTable(props: { query: any, emptyMessage: string }) {
    const events = useGet<{total:number,documents:TEventDoc[]}>(`/v2/admin/events?query=${encodeURIComponent(JSON.stringify(props.query))}&first=0&count=1000&sort=startdate&order=-1`)
    console.log(events)
    
    return (
        <>
        {events &&
        <PageTable className="mb-0">
            <thead>
                <PageTable.Row>
                    <th></th>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Published</th>
                    <th>OculusId</th>
                    <th>Approved</th>
                    <th>Subscribers</th>
                </PageTable.Row>
            </thead>
            <tbody>
                {events.documents.map((x) => {
                    return (
                        <PageTable.Row key={x.id}>
                            <PageTable.IconCell src={getIconSrc({...x,id:x._id})} roundedCircle/> 
                            <PageTable.InfoCell title={x.nickname} subtitle={<Link to={`/domains/${x._domain}/events/${x.name}`}>{`${x._domain}/events/${x.name}`}</Link>}/>
                            <PageTable.Cell>{x._owner}</PageTable.Cell>
                            <PageTable.Cell>{x.startdate && new Date(x.startdate).toLocaleString()}</PageTable.Cell>
                            <PageTable.Cell>{x.enddate && new Date(x.enddate).toLocaleString()}</PageTable.Cell>
                            <PageTable.Cell>{x.published ? "Yes" : "No"}</PageTable.Cell>
                            <PageTable.Cell>{x.oculuseventid ? <a href={`https://oculus.com/experiences/event/${x.oculuseventid}/`}>{x.oculuseventid}</a> : "none"}</PageTable.Cell>
                            <PageTable.Cell>{x.oculusapproved ? "Yes" : "No"}</PageTable.Cell>
                            <PageTable.Cell>{x.subscribers}</PageTable.Cell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>}
        {
            props.emptyMessage && events && events.documents.length === 0 && <div style={{textAlign:"center", paddingTop: 10, paddingBottom: 10, backgroundColor: "#f2f2f2"}}>{ props.emptyMessage }</div>
        }
        </>)
}

type TOculusEventDoc = {
    event_entities: (Subtract<MultiverseEvent,{id:string}>&{_id:string})[]
} & TOculusEvent

function OculusTable(props: { }) {
    const events = useGet<{total:number,documents:TOculusEventDoc[]}>(`/v2/admin/oculusevents`)
    console.log(events)
    
    return (
        <>
        {events &&
        <PageTable className="mb-0">
            <thead>
                <PageTable.Row>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Published</th>
                    <th>Subscribers</th>
                    <th>Linked Events</th>                    
                </PageTable.Row>
            </thead>
            <tbody>
                {events.documents.map((x) => {
                    return (
                        <PageTable.Row key={x.oculusid}>
                            <PageTable.Cell>{x.oculusid}</PageTable.Cell>
                            <PageTable.Cell>{x.title}</PageTable.Cell>
                            <PageTable.Cell>{new Date(x.start).toLocaleString()}</PageTable.Cell>
                            <PageTable.Cell>{new Date(x.end).toLocaleString()}</PageTable.Cell>
                            <PageTable.Cell>{x.published ? "Yes" : "No"}</PageTable.Cell>
                            <PageTable.Cell>{x.subscribers}</PageTable.Cell>
                            <PageTable.Cell>
                                {x.event_entities.map(y => (
                                    <div key={y._id}>{y.name}</div>
                                ))}
                            </PageTable.Cell>
                        </PageTable.Row>
                    )
                })}
            </tbody>
        </PageTable>}
        </>)
}

export function AdminEventsPage(props: {}) {
    const targeturl = useCurrentDomainUri();

    const COLUMNS = ["Start","End","Published"]

    const getColumnValues = (x: MultiverseBaseEntity) => {
        const event = x as MultiverseEvent;
        return [
            <PageTableCell key="start">{new Date(event.startdate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="end">{new Date(event.enddate!).toLocaleString()}</PageTableCell>,
            <PageTableCell key="published">{event.published ? "Yes" : "No"}</PageTableCell>
        ];
    }

    return (
        <BasicPage title="Events" fluid>
            <Tabs  defaultActiveKey="requestedfeatures">
                <Tab eventKey="acceptedfeatures" title="Accepted Features">
                    <EventTable query={{oculussubmission: "accepted"}} emptyMessage="You have no accepted features"/>
                </Tab>
                <Tab eventKey="requestedfeatures" title="Requested Features">
                    <EventTable query={{oculussubmission: "submitted"}} emptyMessage="You have no requested features"/>
                </Tab>
                <Tab eventKey="refusedfeatures" title="Refused Features">
                    <EventTable query={{oculussubmission: "refused"}} emptyMessage="You have no refused features"/>
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming">
                    <EventTable query={{startdate: { $gte: new Date() }}} emptyMessage="You have no upcoming events"/>
                </Tab>
                <Tab eventKey="past" title="Past">
                    <EventTable query={{startdate: { $lt: new Date() }}} emptyMessage="You've had no previous events"/>
                </Tab>
                <Tab eventKey="oculus" title="Oculus">
                    <OculusTable/>
                </Tab>
            </Tabs>
        </BasicPage>
    )    
}
export default AdminEventsPage;