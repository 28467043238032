import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Col, Container, Row } from 'react-bootstrap';

type PrivacyPolicyPageProps = {

} & WithMultiverseApiProps;

type PrivacyPolicyPageState = {
    text: string
};

class PrivacyPolicyPage extends React.Component<PrivacyPolicyPageProps, PrivacyPolicyPageState> {
    constructor(props: PrivacyPolicyPageProps) {
        super(props);
        this.state = {
            text: ""
        };
    }

    async componentDidMount(): Promise<void> {
        const rsp = await fetch("/privacy.txt");
        this.setState({
            text: await rsp.text()
        })
    }

    render(): JSX.Element {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <pre style={{wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            {this.state.text}
                        </pre>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default PrivacyPolicyPage;
