import './adminAvatarsPage.css';
import PageTable from '../../components/pageTable';
import { Col, Row, Spinner } from 'react-bootstrap';
import { MultiverseAvatar, WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import React from 'react';
import BasicPage from '../../components/basicPage';

type AvatarsPageProps = {
} & WithMultiverseApiProps;

type AvatarsPageState = {
    isLoadingCount: number;
    hasError: boolean;
    avatars: MultiverseAvatar[];
};

class _AdminAvatarsPage extends React.Component<AvatarsPageProps, AvatarsPageState> {

    constructor(props: AvatarsPageProps) {
        super(props);
        this.state = {
            isLoadingCount: 0,
            hasError: false,
            avatars: []
        };
    }

    async componentDidMount(): Promise<void> {
        const { multiverse: { refreshCurrentChildProduct } } = this.props;
        await refreshCurrentChildProduct();
        await this.refreshAvatars();
    }

    refreshAvatars = async () => {
        try {
            //show loading spinner
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount + 1 }));

            //load avatar configs
            const { multiverse: { get, getCurrentChildProductId } } = this.props;
            let result = await get<MultiverseAvatar[]>(`/v2/admin/childproducts/${getCurrentChildProductId()}/avatars`);

            const avatars = result.map(a => { return { 
                ...a, 
                default: a.default ?? false,
                iap_id: a.iap_id ?? "",
                skins: a.skins.map(s => { return {
                    ...s,
                    iap_id: s.iap_id ?? ""}}),
                weaponskins: a.weaponskins.map(s => { return {
                    ...s,
                    iap_id: s.iap_id ?? ""}})                    
            }});
            this.setState({ avatars: avatars });

        } catch (e) {
            console.log(e);
            this.setState({ hasError: true });
        } finally {
            this.setState((prevState) => ({ isLoadingCount: prevState.isLoadingCount - 1 }));
        }
    }

    canManage = (x: MultiverseAvatar): boolean => {
        const { multiverse: { user } } = this.props;
        if (!user) {
            throw new Error("Invalid user");
        }
        return true;
    }

    canDoAnything = (x: MultiverseAvatar): boolean => {
        return this.canManage(x);
    }

    renderLoadingSpinner() {
        return (
            <>
                <Row>
                    <Col className="col-12 p-3" style={{ textAlign: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            </>
        );
    }

    renderNoAvatars() {
        return (
            <>
                <Row>
                    <Col className="text-center pb-3">
                        This product currently contains no upcoming avatars.
                    </Col>
                </Row>
            </>
        );
    }

    renderAvatars = () => {
        const { } = this.props;

        return (
            <>
                <div className="table-responsive">
                    <PageTable>
                        <tbody>
                            {this.state.avatars.map((x) => {
                                return (
                                    <>
                                        <PageTable.Row key={"avatar_header_" + x._id}>
                                            <th>Character</th>
                                            <th>Unlock Level</th>
                                            <th>IAP ID</th>
                                            <th>Is Default Character</th>
                                            <th>Is Disabled</th>
                                        </PageTable.Row>
                                        <PageTable.Row key={x._id}>
                                            <PageTable.Cell><h3>{x.name}</h3></PageTable.Cell>
                                            <PageTable.Cell>{x.unlocklevel}</PageTable.Cell>
                                            <PageTable.Cell>{x.iap_id}</PageTable.Cell>
                                            <PageTable.Cell>{x.default ? "Yes" : "No"}</PageTable.Cell>
                                            <PageTable.Cell>{x.disabled ? "Yes" : "No"}</PageTable.Cell>
                                        </PageTable.Row>
                                        <PageTable.Row key={"skin_header_" + x._id}>
                                            <th>Skin</th>
                                            <th>Index</th>
                                            <th>Unlock Level</th>
                                            <th>IAP ID</th>
                                        </PageTable.Row>
                                        {x.skins?.map((skin) => {
                                            return (                                                
                                                <PageTable.Row key={skin.name}>
                                                    <PageTable.Cell>{skin.name}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.index}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.unlocklevel}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.iap_id}</PageTable.Cell>
                                                </PageTable.Row>
                                            )
                                        })}
                                        <PageTable.Row key={"weapon_skin_header_" + x._id}>
                                            <th>Weapon Skin</th>
                                            <th>Index</th>
                                            <th>Unlock Level</th>
                                            <th>IAP ID</th>
                                        </PageTable.Row>                                             
                                        {x.weaponskins?.map((skin) => {
                                            return (
                                                <PageTable.Row key={skin.name}>
                                                    <PageTable.Cell>{skin.name}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.index}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.unlocklevel}</PageTable.Cell>
                                                    <PageTable.Cell>{skin.iap_id}</PageTable.Cell>
                                                </PageTable.Row>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </tbody>
                    </PageTable>
                </div>
            </>
        )
    }

    renderPage() {
        if (this.state.isLoadingCount > 0)
            return this.renderLoadingSpinner();
        else if (!this.state.avatars) {
            return (<pre>error</pre>)
        }
        else if (this.state.avatars.length > 0)
            return this.renderAvatars();
        else
            return this.renderNoAvatars();
    }

    render() {
        const { childProduct } = this.props;

        return (
            <>
                <BasicPage title={(childProduct ? `${childProduct.name} ` : "") + "Avatars"}>
                    {this.renderPage()}
                </BasicPage>
            </>);
    }
}

export const AdminAvatarsPage = withMultiverseApi(_AdminAvatarsPage);

export default AdminAvatarsPage;
